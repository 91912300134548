import { createContext, useState } from 'react'

export const EnvironmentContext = createContext()

export const EnvironmentProvider = (props) => {
	const { values } = props
	const [environment, setEnvironment] = useState(values)

	return (
		<EnvironmentContext.Provider value={{ environment, setEnvironment }}>
			{props.children}
		</EnvironmentContext.Provider>
	)
}
