import { useQuery } from '@tanstack/react-query'
import menuApi from 'apis/disApi/menuApi'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { menuKeys } from './useMenuQuery'
import { logErrorMessage } from 'utils/functions/helpers'

export default function useMenuDetailQuery({ id } = {}) {
	const { environment } = useSelector((state) => state)

	const {
		data: detailData,
		isLoading,
		isFetching,
	} = useQuery(menuKeys.detail(id), () => menuApi.get({ id, token: environment.apiToken }), {
		enabled: !!id,
	})

	const menuItemOptions = useMemo(() => {
		try {
			const menuItems = detailData?.data?.items ?? []

			if (menuItems?.length > 0) {
				return menuItems?.map((menu) => ({
					label: menu?.name,
					value: menu?.dashboard?.key,
				}))
			}
			return []
		} catch (error) {
			logErrorMessage(error)
			return []
		}
	}, [detailData?.data?.items])

	return {
		isLoading: isFetching && isLoading,
		menuItemOptions,
		detailData: detailData?.data ?? [],
		menuItems: detailData?.data?.items ?? [],
	}
}
