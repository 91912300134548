import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Add as AddIcon, List as ListIcon } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { LOOKUPS_ACTIONS } from '../../../reducers/lookupsReducer'

const useStyles = makeStyles(() => ({
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const LookupsButton = ({ action, form, module }) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const { lookupsModule } = useSelector((state) => state)
	const { formSelected, action: actionStore } = lookupsModule
	const checkDisabledIcon = `${actionStore}${formSelected?.key}` === `${action}${form?.key}`

	const dispatch = useDispatch()

	const clickHandler = (e) => {
		e.preventDefault()
		e.stopPropagation()
		const reducerItems = { action: module.action, formSelected: module.formSelected }
		const selectedItems = { action: action, formSelected: form }
		if (action === 'audit' && _.isEqual(reducerItems, selectedItems)) {
			dispatch({
				type: LOOKUPS_ACTIONS.LOOKUPS_REFRESH,
				payload: true,
			})
		} else {
			dispatch({
				type: LOOKUPS_ACTIONS.FORM_SELECTED,
				payload: form,
			})
			dispatch({
				type: LOOKUPS_ACTIONS.ACTION,
				payload: action,
			})
		}
	}

	const computeIcon = (action) => {
		switch (action) {
			case 'add':
				return (
					<Tooltip
						title={`${t('tooltip.prefix.new')} - ${form.name}`}
						arrow
						placement="bottom-start"
						disableInteractive
					>
						<span>
							<IconButton
								aria-label="add"
								size="small"
								color="primary"
								edge="end"
								onClick={clickHandler}
							>
								<AddIcon fontSize="inherit" className={classes.icon} />
							</IconButton>
						</span>
					</Tooltip>
				)
			case 'audit':
				return (
					<Tooltip
						title={`${t('tooltip.prefix.data')} - ${form.name}`}
						arrow
						placement="bottom-start"
						disableInteractive
					>
						<span>
							<IconButton
								aria-label="audit"
								size="small"
								color="primary"
								edge="end"
								disabled={checkDisabledIcon}
								onClick={clickHandler}
							>
								<ListIcon
									fontSize="inherit"
									className={classes.icon}
									sx={{ color: checkDisabledIcon ? '#ddd !important' : 'inherit' }}
								/>
							</IconButton>
						</span>
					</Tooltip>
				)
			default:
				return
		}
	}

	return <>{computeIcon(action)}</>
}

export default LookupsButton
