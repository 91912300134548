import { Box, Button, Popover, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const ButtonWithConfirm = ({ children, title, onSubmit }) => {
	const { t } = useTranslation('common')

	const [openConfirmPopover, setOpenConfirmPopover] = useState(null)

	const handleOpenConfirmDialog = (event) => {
		event?.preventDefault()
		event?.stopPropagation()
		setOpenConfirmPopover(openConfirmPopover ? null : event?.currentTarget)
	}

	const handleCloseConfirmDialog = (event) => {
		event?.preventDefault()
		event?.stopPropagation()
		setOpenConfirmPopover(null)
	}

	const handleSubmit = (event) => {
		event?.preventDefault()
		event?.stopPropagation()
		onSubmit?.()
		handleCloseConfirmDialog(event)
	}

	const openPopover = Boolean(openConfirmPopover)
	const popoverId = openPopover ? title : undefined

	return (
		<>
			{React.cloneElement(children, { onClick: handleOpenConfirmDialog })}

			<Popover
				id={popoverId}
				open={openPopover}
				anchorEl={openConfirmPopover}
				onClose={handleCloseConfirmDialog}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Box sx={{ p: 2 }}>
					<Typography>{title}</Typography>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							mt: 1,
							gap: 2,
						}}
					>
						<Button size="small" onClick={handleSubmit}>
							{t('common:misc.ok')}
						</Button>
						<Button size="small" variant="contained" onClick={handleCloseConfirmDialog}>
							{t('common:misc.cancel')}
						</Button>
					</Box>
				</Box>
			</Popover>
		</>
	)
}

export default ButtonWithConfirm
