import {
	List,
	ListItem,
	ListItemButton,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import SkeletonLoaderSidePanel from '../../../custom-components/skeletons/SkeletonLoaderSidePanel'
import { REPORTS_ACTIONS } from '../../../reducers/reportsReducer'
import DashboardSearchBar from '../../core/Dashboard/DashboardSearchBar'
import ReportsButton from './ReportsButton'
import { VIEW } from '../../../constants'

const useStyles = makeStyles(() => ({
	reportItem: {
		padding: 0,
		display: 'flex',
		overflow: 'hidden',
		justifyContent: 'center',
		alignItems: 'stretch',
	},
	reportItemBtn: {
		display: 'flex',
		padding: '1px 0px 1px 16px',
		'&:hover': {
			backgroundColor: 'white',
		},
		'& .MuiTypography-root': {
			display: 'block',
			fontSize: '15px',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	reportItemToolbar: {
		position: 'relative',
		top: 'auto',
		right: 'auto',
		display: 'flex',
		flex: '1 1 auto',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
		backgroundColor: '#fff',
		transform: 'none',
		paddingRight: '2px',
	},
	reportMenuListContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		flex: '1 1 0',
		overflow: 'hidden',
	},
	reportMenuList: {
		paddingTop: 0,
		overflow: 'hidden',
		height: 'inherit',
		'&:not(.loading):hover': {
			overflowY: 'auto',
		},
		'& .MuiListItem-root': {
			flexDirection: 'column',
		},
		'& .MuiCollapse-root': {
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'stretch',
		},
		'& .MuiListItem-container': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		'& .MuiListItem-container:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	loadingWrapper: {
		position: 'absolute',
		top: '20%',
		left: '45%',
		zIndex: '99999',
	},
}))

const ReportsDashboard = (props) => {
	const [t] = useTranslation('common')
	const classes = useStyles()
	const { reports, loading } = props

	const { reportsModule } = useSelector((state) => state)
	const { reportSelected, action } = reportsModule

	const [itemName, setItemName] = useState(null)
	const [hovering, setHovering] = useState(false)
	const [skeletonLoading, setSkeletonLoading] = useState(true)
	const [searchText, setSearchText] = useState('')

	const filteredReports = useMemo(() => {
		if (searchText === '') {
			return reports
		} else {
			return reports.filter((report) => report.name.toLowerCase().includes(searchText))
		}
	}, [searchText, reports])

	const dispatch = useDispatch()

	useLayoutEffect(() => {
		if (reports && reports.length > 0) {
			setSkeletonLoading(false)
		} else {
			setSkeletonLoading(true)
		}
	}, [reports])

	const showLoading = () => (
		<>
			<div className={classes.loadingWrapper}>
				<LoadingSpinner withStyle={false} />
			</div>
			{skeletonLoading && <SkeletonLoaderSidePanel />}
		</>
	)

	const handlePopoverOpen = useCallback(
		(newItemName) => (event) => {
			event.preventDefault()
			setHovering((prev) => itemName !== newItemName || !prev)
			setItemName(newItemName)
		},
		[]
	)

	const handlePopoverClose = useCallback((e) => {
		e.preventDefault()
		setHovering(false)
	}, [])

	const showItemActions = (report) =>
		itemName === report.key && (
			<ListItemSecondaryAction className={classes.reportItemToolbar}>
				<ReportsButton action={'audit'} report={report} />
			</ListItemSecondaryAction>
		)

	const onChangeValueHandler = (value) => {
		const term = !value ? '' : value.toLowerCase()
		setSearchText(term)
	}

	const clickHandler = (action, report) => (event) => {
		event.preventDefault()
		dispatch({
			type: REPORTS_ACTIONS.REPORT_SELECTED,
			payload: { ...report },
		})
		dispatch({
			type: REPORTS_ACTIONS.ACTION,
			payload: action,
		})
	}

	return (
		<>
			<DashboardSearchBar onChangedValue={onChangeValueHandler} tab={VIEW.VIEW_TAB_REPORTS} />
			<div className={classes.reportMenuListContainer}>
				{loading && showLoading()}
				<List
					className={`${classes.reportMenuList} ${loading ? t('common:misc.loading').toLowerCase() : ''
						}`}
				>
					{filteredReports.length > 0 &&
						filteredReports.map((report) => (
							<ListItem
								key={report.key}
								className={classes.reportItem}
								onMouseEnter={handlePopoverOpen(`${report.key}`)}
								ContainerProps={{ onMouseLeave: handlePopoverClose }}
							>
								<ListItemButton
									className={classes.reportItemBtn}
									disabled={reportSelected?.key === report?.key && action === 'audit'}
									onClick={clickHandler('audit', report)}
								>
									<ListItemText
										primary={
											<Tooltip
												title={report.name}
												arrow
												placement="bottom-start"
												disableInteractive
												enterDelay={1000}
												enterNextDelay={1000}
											>
												<span>{report.name}</span>
											</Tooltip>
										}
									/>
								</ListItemButton>
								{hovering && showItemActions(report)}
							</ListItem>
						))}
				</List>
			</div>
		</>
	)
}

export default ReportsDashboard
