import { FormControl, InputLabel, MenuItem, Select, Skeleton } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import menuApi from 'apis/disApi/menuApi'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const PageForm = ({ outlinedInput, currentPath, onChangeCurrentPath }) => {
	const environment = useSelector((state) => state.environment)
	const { t } = useTranslation('common')

	const { data: menus, isLoading: menusLoading } = useQuery(['menu-list'], async () => {
		const response = await menuApi.getAll(environment.apiToken)
		const defaultMenu = response?.data?.find((menu) => menu.isDefault === true)
		return defaultMenu?.items ?? []
	})

	return (
		<>
			{!menusLoading ? (
				<FormControl
					variant={outlinedInput ? 'outlined' : 'standard'}
					sx={{ my: outlinedInput ? 0 : 1, minWidth: outlinedInput ? '50%' : '100%' }}
					size={outlinedInput ? 'small' : 'medium'}
				>
					<InputLabel id="view-select-small-label">{t('common:input.view')}</InputLabel>
					<Select
						labelId="view-select-small-label"
						id="view-select-small"
						value={currentPath}
						label={t('common:input.view')}
						onChange={(e) => {
							onChangeCurrentPath(e.target.value)
						}}
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{menus?.length > 0 &&
							menus.map((menu) => (
								<MenuItem value={menu?.page?.path} key={menu?.page?.path}>
									{menu.name}
								</MenuItem>
							))}
					</Select>
				</FormControl>
			) : (
				<Skeleton
					variant="rectangular"
					height={45}
					sx={{ mt: outlinedInput ? 0 : 2, width: outlinedInput ? '40%' : '100%' }}
				/>
			)}
		</>
	)
}

export default PageForm
