import axios from 'axios'
import { apiUrl } from '../apiUrl'

const url = apiUrl()
const apiHeader = (token) => {
	return {
		Accept: 'application/json',
		Authorization: `Bearer ${token}`,
	}
}

const tileApi = {
	getAll(dashboardKey, token, cancelToken) {
		return axios.get(`${url}/dashboards/${dashboardKey}/tiles`, {
			headers: apiHeader(token),
			cancelToken: cancelToken?.token,
		})
	},

	get(dashboardKey, tileKey, token, cancelToken) {
		if (!dashboardKey || !tileKey) return
		return axios.get(`${url}/dashboards/${dashboardKey}/tiles/${tileKey}`, {
			headers: apiHeader(token),
			cancelToken: cancelToken?.token,
		})
	},

	add({ dashboardKey, data, token }) {
		return axios.post(`${url}/dashboards/${dashboardKey}/tiles`, data, {
			headers: apiHeader(token),
		})
	},

	update({ dashboardKey, tileKey, data, token }) {
		return axios.put(`${url}/dashboards/${dashboardKey}/tiles/${tileKey}`, data, {
			headers: apiHeader(token),
		})
	},

	updateAll({ dashboardKey, data, token }) {
		return axios.put(`${url}/dashboards/${dashboardKey}/tiles`, data, {
			headers: apiHeader(token),
		})
	},

	remove({ dashboardKey, tileKey, token }) {
		return axios.delete(`${url}/dashboards/${dashboardKey}/tiles/${tileKey}`, {
			headers: apiHeader(token),
		})
	},
}

export default tileApi
