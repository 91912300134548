import { FormControl, InputLabel, MenuItem, Select, Skeleton, Stack } from '@mui/material'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TYPES_OF_TILE } from '../../../../../constants'
import useTileQuery from '../../hooks/useTileQuery'
import { unionBy } from 'lodash'

const ImageViewerForm = ({
	dashboardKey,
	filterData,
	enabledFetchTileList = true,
	datagridListWhenCreating = [],
	onFilterDataChange,
}) => {
	const { t } = useTranslation('common')

	const IMAGE_TYPES = [
		{
			name: t('common:input.slide'),
			value: 'slide',
		},
		{
			name: t('common:input.gallery'),
			value: 'gallery',
		},
	]

	const { tileList, isLoading } = useTileQuery({
		dashboardKey,
		enableConfig: enabledFetchTileList,
	})
	const dataGridTile = useMemo(() => {
		const currentList =
			tileList?.length > 0 ? tileList?.filter((tile) => tile?.type === TYPES_OF_TILE.DATA_GRID) : []

		return unionBy(currentList, datagridListWhenCreating, 'name')
	}, [tileList, datagridListWhenCreating])

	const handleSelectChange = (e) => {
		const key = e.target.value
		const name = dataGridTile.find((tile) => tile?.key === key)?.name
		const selectedTile = dataGridTile.find((tile) => tile?.key === key)
		onFilterDataChange('connectedDatagridKey', key)
		if (selectedTile?.is_creating) {
			onFilterDataChange('connectedNameWithNewDatagrid', name)
		}
	}

	return (
		<Stack spacing={2}>
			<FormControl variant="outlined" size="small" fullWidth>
				<InputLabel id="image-type-label">{t('common:input.imageType')}</InputLabel>
				<Select
					labelId="image-type-label"
					id="image-type"
					value={filterData?.imageType ?? ''}
					label={t('common:input.imageType')}
					onChange={(e) => {
						onFilterDataChange('imageType', e.target.value)
					}}
				>
					<MenuItem value="">
						<em>None</em>
					</MenuItem>
					{IMAGE_TYPES.map((type) => (
						<MenuItem value={type?.value} key={type?.value}>
							{type.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{dataGridTile?.length > 0 &&
				(!isLoading ? (
					<FormControl variant="outlined" size="small">
						<InputLabel id="datagrid-select-small-label">
							{t('common:input.datagridToConnect')}
						</InputLabel>
						<Select
							labelId="datagrid-select-small-label"
							id="datagrid-select-small"
							value={filterData?.connectedDatagridKey ?? ''}
							label={t('common:input.datagridToConnect')}
							onChange={handleSelectChange}
						>
							<MenuItem value="">
								<em>None</em>
							</MenuItem>
							{dataGridTile.map((tile) => (
								<MenuItem value={tile?.key} key={tile?.key}>
									{tile?.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				) : (
					<Skeleton variant="rectangular" height={40} />
				))}
		</Stack>
	)
}

export default ImageViewerForm
