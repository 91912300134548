import InboxIcon from '@mui/icons-material/Inbox'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

const EmptyDashboard = () => {
	const { t } = useTranslation('common')

	return (
		<Stack
			spacing={1}
			sx={{
				height: '100%',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<InboxIcon sx={{ fontSize: 40 }} color="disabled" />

			<Typography variant="body2" color="grey">
				{t('common:dis.emptyData')}
			</Typography>
		</Stack>
	)
}

export default EmptyDashboard
