export const operatorOptions = (t) => {
	return [
		{ name: t('common:filters.equals'), type: 'EQ' },
		{ name: t('common:filters.notEquals'), type: 'NE' },
		{ name: t('common:filters.between'), type: 'BT' },
		{ name: t('common:filters.contains'), type: 'CT' },
		{ name: t('common:filters.isEmpty'), type: 'EMPTY' },
		{ name: t('common:filters.isNotEmpty'), type: 'NOTEMPTY' },
	]
}

export const datesValues = (t) => {
	return [
		{ name: t('common:filters.today'), preset: 'TODAY', type: 'EQ' },
		{ name: t('common:filters.thisWeek'), preset: 'THISWEEK', type: 'BT' },
		{ name: t('common:filters.thisMonth'), preset: 'THISMONTH', type: 'BT' },
		{ name: t('common:filters.yesterday'), preset: 'YESTERDAY', type: 'EQ' },
		{ name: t('common:filters.lastWeek'), preset: 'LASTWEEK', type: 'BT' },
		{ name: t('common:filters.lastMonth'), preset: 'LASTMONTH', type: 'BT' },
		{ name: t('common:filters.last7Days'), preset: 'LAST7DAYS', type: 'BT' },
		{ name: t('common:filters.last30Days'), preset: 'LAST30DAYS', type: 'BT' },
		{ name: t('common:filters.tomorrow'), preset: 'TOMORROW', type: 'EQ' },
		{ name: t('common:filters.nextWeek'), preset: 'NEXTWEEK', type: 'BT' },
		{ name: t('common:filters.nextMonth'), preset: 'NEXTMONTH', type: 'BT' },
		{ name: t('common:filters.next7Days'), preset: 'NEXT7DAYS', type: 'BT' },
		{ name: t('common:filters.next30Days'), preset: 'NEXT30DAYS', type: 'BT' },
		{ name: t('common:filters.all'), preset: 'ALL', type: 'EQ' },
	]
}

export const nonDatesValues = (t) => {
	return [
		{ name: t('common:filters.pending'), type: 'EQ', preset: 'PENDING' },
		{ name: t('common:filters.scheduled'), type: 'EQ', preset: 'SCHEDULED' },
		{ name: t('common:filters.inProgress'), type: 'EQ', preset: 'INPROGRESS' },
		{ name: t('common:filters.emailed'), type: 'EQ', preset: 'EMAILED' },
		{ name: t('common:filters.sent'), type: 'EQ', preset: 'SENT' },
		{ name: t('common:filters.received'), type: 'EQ', preset: 'RECEIVED' },
		{ name: t('common:filters.opened'), type: 'EQ', preset: 'OPENED' },
		{ name: t('common:filters.viewed'), type: 'EQ', preset: 'VIEWED' },
		{ name: t('common:filters.forward'), type: 'EQ', preset: 'FORWARDED' },
		{ name: t('common:filters.recalled'), type: 'EQ', preset: 'RECALLED' },
		{ name: t('common:filters.rejected'), type: 'EQ', preset: 'REJECTED' },
		{ name: t('common:filters.acknowledged'), type: 'EQ', preset: 'ACKNOWLEDGED' },
		{ name: t('common:filters.completed'), type: 'EQ', preset: 'COMPLETED' },
		{ name: t('common:filters.all'), type: 'EQ', preset: 'ALL' },
	]
}

export function getConditionString(condition) {
	if (!condition) return {}

	return condition.preset || condition.type
}