import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
	Accordion,
	Box,
	Collapse,
	FormControl,
	InputLabel,
	List,
	ListItemButton,
	ListItemText,
	MenuItem,
	Popover,
	Select,
	Stack,
	TextField,
	Typography,
} from '@mui/material'

import { useSelector } from 'react-redux'
import { getAllProjects, getProjectForms } from 'components/data/dataServices'
import { performFormAction } from 'components/data/datagrid/recordsHelper'
import LoadingSpinner from 'custom-components/LoadingSpinner'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'

import { toZonedTime } from 'date-fns-tz'
import { getUserTimeZone } from 'utils/functions/helpers'
import { makeStyles } from '@mui/styles'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'
import DashboardList from '../DashboardKeyList'
import OtherOptions from 'components/pages/dis-v2/_components/OtherOptions'
import { isEmpty, cloneDeep, sortBy, isArray } from 'lodash'
import { SchedulerStatusColorPicker } from './SchedulerStatusColorPicker'
import { getDispatchSettings } from 'components/core/services/environmentService'

const initValues = {
	projectKey: null,
	projectName: '',
	formKey: null,
	formName: '',
}

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
	},
}))
const defaulViewType = 'day'
const defaulStartTime = 8
const defaulEndTime = 18

function DispatchSchedulerForm(props) {
	const { filterData, onFilterDataChange, allProjectForms, setAllProjectForms, dashboardKeyList } =
		props

	const { t } = useTranslation('common')
	const environment = useSelector((state) => state.environment)
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const [startEndTime, setStartEndTime] = useState({})
	const [defaultSchedulerStatus, setDefaultSchedulerStatus] = useState([])

	const RANGE_TYPES = [
		{
			name: t('common:misc.rangeDay'),
			value: 'day',
		},
		{
			name: t('common:misc.rangeWeek'),
			value: 'week',
		},
		{
			name: t('common:misc.rangeMonth'),
			value: 'month',
		},
	]

	const SCHEDULER_STATUS = [
		{ label: 'Sent', value: 'Sent' },
		{ label: 'Hold', value: 'Hold' },
		{ label: 'Completed', value: 'Completed' },
	]

	const RANGE_HOURS = [
		{
			name: '0',
			value: '0',
		},
		{
			name: '1',
			value: '1',
		},
		{
			name: '2',
			value: '2',
		},
		{
			name: '3',
			value: '3',
		},
		{
			name: '4',
			value: '4',
		},
		{
			name: '5',
			value: '5',
		},
		{
			name: '6',
			value: '6',
		},
		{
			name: '7',
			value: '7',
		},
		{
			name: '8',
			value: '8',
		},
		{
			name: '9',
			value: '9',
		},
		{
			name: '10',
			value: '10',
		},
		{
			name: '11',
			value: '11',
		},
		{
			name: '12',
			value: '12',
		},
		{
			name: '13',
			value: '13',
		},
		{
			name: '14',
			value: '14',
		},
		{
			name: '15',
			value: '15',
		},
		{
			name: '16',
			value: '16',
		},
		{
			name: '17',
			value: '17',
		},
		{
			name: '18',
			value: '18',
		},
		{
			name: '19',
			value: '19',
		},
		{
			name: '20',
			value: '20',
		},
		{
			name: '21',
			value: '21',
		},
		{
			name: '22',
			value: '22',
		},
		{
			name: '23',
			value: '23',
		},
		{
			name: '24',
			value: '24',
		},
	]

	const [loading, setLoading] = useState(false)
	const [anchorEl, setAnchorEl] = useState(null)
	const [projectForms, setProjectForms] = useState([])
	const [collapseProjects, setCollapseProjects] = useState([])
	let listProjectFrom = []

	const OTHER_OPTIONS = [{ label: t('common:misc.showAgenda'), value: 'showAgenda' }]

	const FIELD_LIST = [
		{
			name: 'Mobile_number',
			label: t('common:input.mobileNumber'),
		},
	]

	useEffect(() => {
		if (allProjectForms?.length > 0) {
			listProjectFrom = allProjectForms
			setProjectForms(allProjectForms)
		}
	}, [allProjectForms])

	const isSelectOpen = useMemo(() => {
		return Boolean(anchorEl)
	}, [anchorEl])

	
	const handleSelectOpen = (event) => {
		setAnchorEl(event.currentTarget)
	}
	const handleSelectClose = () => {
		setAnchorEl(null)
	}

	const loadAllProjects = async () => {
		let promise = await getAllProjects(environment.apiToken)
		return promise
	}

	const loadFormsByProject = async (projects) => {
		let promises = []
		for (let i = 0; i < projects.length; i++) {
			const promise = getProjectForms(projects[i].key, environment.apiToken).then((resp) => {
				let promiseObject = { key: projects[i].key, name: projects[i].name, forms: [] }
				if (resp?.data && isArray(resp.data) && resp.data.length) {
					promiseObject.forms = sortBy(resp.data, 'name')
				}
				return promiseObject
			})
			promises.push(promise)
		}

		return await Promise.all(promises)
	}

	const initiateLoadFormsByProject = (projects) => {
		loadFormsByProject(projects)
			.then((res) => {
				const newProjects = sortBy(res, 'name')
				setProjectForms(newProjects)
				setAllProjectForms(newProjects)
			})
			.catch((err) => {})
			.finally(() => {
				setLoading(false)
			})
	}

	const initiateLoadAllProjects = () => {
		if (projectForms?.length === 0) {
			setLoading(true)
			loadAllProjects()
				.then((res) => {
					const projects = sortBy(res.data, 'name')
					initiateLoadFormsByProject(projects)
				})
				.catch((err) => {})
				.finally(() => {})
		}
	}

	useEffect(() => {
		;(async () => {
			try {
				if (listProjectFrom?.length === 0) {
					initiateLoadAllProjects()
				}
			} catch (err) {
				console.log(err)
			}
		})()
	}, [environment.isProjectFormsLoaded])

	useEffect(() => {
		getDispatchSettings(environment.apiToken)
			.then((setting) => {
				setStartEndTime(setting?.data?.day)
				setDefaultSchedulerStatus(setting?.data?.status)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	const handleCollapsed = (projectKey) => {
		const found = collapseProjects.includes(projectKey)
		if (!found) {
			setCollapseProjects([...collapseProjects, projectKey])
		} else {
			setCollapseProjects(collapseProjects.filter((item) => item !== projectKey))
		}
	}

	const handleFormSelection = (project, form) => {
		const projectKey = project.key
		const formKey = form.key

		onFilterDataChange('projectFormInfo', {
			projectKey: projectKey,
			formKey: formKey,
			formName: form.name,
			projectName: project.name,
		})
		const projectSelected = projectForms.find((project) => project.key === projectKey)
		if (projectSelected) {
			const formSelected = projectSelected?.forms?.find((form) => form.key === formKey)

			performFormAction(environment, { ...formSelected, projectKey }, 'add', 'other')
				.then((res) => {
					onFilterDataChange('defaultUrl', res)
					handleSelectClose()
				})
				.catch((err) => {
					handleSelectClose()
				})
		}
	}

	const handleSelectDateChange = (newValue) => {
		if (newValue == null) {
			onFilterDataChange?.('schedulerSelectDate', newValue)
			return
		}

		const newStringValue = newValue.format('MM/DD/YYYY HH:mm:ss')

		const newDate = new Date(newStringValue)
		newDate.setHours(0, 0, 0, 0)
		const localDate = toZonedTime(newDate, getUserTimeZone())

		onFilterDataChange?.('schedulerSelectDate', localDate)
	}

	const showFieldForDevices = () => {
		const displayProject = filterData?.projectFormInfo || initValues
		let projectName = ''
		let formName = ''

		projectName = displayProject.projectName

		formName = displayProject.formName
		return (
			<TextField
				id="select-default-form"
				size="small"
				onClick={handleSelectOpen}
				value={projectName + ' / ' + formName}
				label={t('common:input.selectForm')}
				fullWidth
				InputProps={{
					readOnly: true,
					endAdornment: isSelectOpen ? (
						<>
							{loading ? (
								<LoadingSpinner withStyle={false} size={15} />
							) : (
								<ArrowDropUp position="end" />
							)}
						</>
					) : (
						<>
							{loading ? (
								<LoadingSpinner withStyle={false} size={15} />
							) : (
								<ArrowDropDown position="end" />
							)}
						</>
					),
				}}
			/>
		)
	}
	
	const RangeHourComponent = () => {
		return (
			<div style={{ display: 'flex', marginBottom: '16px' }}>
				<FormControl variant="outlined" size="small" fullWidth>
					<InputLabel id="range-hour-start-label">{t('common:misc.startTimeScheduler')}</InputLabel>
					<Select
						labelId="range-hour-start-label"
						id="hour-start-time"
						value={filterData?.schedulerStartTime || startEndTime?.begin || defaulStartTime}
						label={t('common:misc.startTimeScheduler')}
						onChange={(e) => {
							onFilterDataChange('schedulerStartTime', e.target.value)
						}}
					>
						{RANGE_HOURS.map((type) => (
							<MenuItem value={type?.value} key={type?.value}>
								{type.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						margin: '0 10px',
					}}
				>
					{' '}
					-{' '}
				</div>
				<FormControl variant="outlined" size="small" fullWidth>
					<InputLabel id="range-hour-end-label">{t('common:misc.endTimeScheduler')}</InputLabel>
					<Select
						labelId="range-hour-end-label"
						id="hour-start-time"
						value={filterData?.schedulerEndTime || startEndTime?.end || defaulEndTime}
						label={t('common:misc.endTimeScheduler')}
						onChange={(e) => {
							onFilterDataChange('schedulerEndTime', e.target.value)
						}}
					>
						{RANGE_HOURS.map((type) => (
							<MenuItem value={type?.value} key={type?.value}>
								{type.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
		)
	}
	return (
		<div>
			{showFieldForDevices()}

			<Popover
				anchorEl={anchorEl}
				id={'my-default-form-container'}
				//keepMounted
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				open={isSelectOpen}
				onClose={handleSelectClose}
				PaperProps={{
					style: { width: '400px', maxWidth: '45vw', maxHeight: '60vh' },
				}}
			>
				<List
					component="nav"
					dense={true}
					aria-labelledby="nested-list-subheader"
					onMouseDown={(event) => event.stopPropagation()}
				>
					{projectForms?.map((project, index) => (
						<div key={project.key}>
							<ListItemButton
								sx={{ backgroundColor: 'rgba(0, 0, 0, 0.04)' }}
								onClick={() => handleCollapsed(project.key)}
							>
								<ListItemText>
									<Typography variant={'h6'} fontSize={16}>
										{project.name}
									</Typography>
								</ListItemText>
								{!collapseProjects.includes(project.key) ? <ArrowDropUp /> : <ArrowDropDown />}
							</ListItemButton>
							<Collapse
								id={index}
								in={!collapseProjects.includes(project.key)}
								timeout="auto"
								unmountOnExit
							>
								{project?.forms?.map((form) => {
									return (
										<ListItemButton
											key={form.key}
											onClick={() => handleFormSelection(project, form)}
										>
											<ListItemText>
												<Typography variant={'body1'} fontSize={14}>
													{form.name}
												</Typography>
											</ListItemText>
										</ListItemButton>
									)
								})}
							</Collapse>
						</div>
					))}
				</List>
			</Popover>

			<FormControl sx={{ mt: 2, marginBottom: '16px' }} variant="outlined" size="small" fullWidth>
				<InputLabel id="range-type-label">{t('common:misc.rangeType')}</InputLabel>
				<Select
					labelId="range-type-label"
					id="range-type"
					value={filterData?.schedulerRangeType || defaulViewType}
					label={t('common:misc.rangeType')}
					onChange={(e) => {
						onFilterDataChange('schedulerRangeType', e.target.value)
					}}
				>
					{RANGE_TYPES.map((type) => (
						<MenuItem value={type?.value} key={type?.value}>
							{type.name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			{RangeHourComponent()}
			<SchedulerStatusColorPicker
				title={t('common:dis.colorSettings')}
				filterDataSetting={filterData?.schedulerColorStatusSettings || null}
				onFilterDataChange={onFilterDataChange}
				defaultSchedulerStatus={defaultSchedulerStatus}
			/>
			<Box>
				<DashboardList
					title={t('common:dis.dashboardKeys')}
					list={dashboardKeyList}
					fields={FIELD_LIST}
					onFieldsChange={(id, value) => {
						if (isEmpty(value)) {
							const currentLinkedFields = cloneDeep(filterData?.linkedFields)
							if (currentLinkedFields[id]) {
								delete currentLinkedFields[id]
							}
							onFilterDataChange('linkedFields', currentLinkedFields)
							return
						}

						onFilterDataChange('linkedFields', { ...filterData?.linkedFields, [id]: value })
					}}
					linkedFields={filterData?.linkedFields || {}}
					getLabelOption={(item) => item.name}
				/>
			</Box>
			<Box>
				<OtherOptions
					hideScroll={true}
					optionList={OTHER_OPTIONS}
					checkedOptions={filterData?.otherOptionsChecked}
					onChangeOptions={(value) => onFilterDataChange('otherOptionsChecked', value)}
				/>
			</Box>
		</div>
	)
}

export default DispatchSchedulerForm
