import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIdleTimer } from 'react-idle-timer'
import { useDispatch } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Routes from '../Routes'
import { signout } from '../components/user/userServices'
import DoformsMessage from '../custom-components/DoformsMessage'
import LoadingSpinner from '../custom-components/LoadingSpinner'
import { CoreProvider } from '../custom-components/context/CoreContext'
import SkeletonLoaderApp from '../custom-components/skeletons/SkeletonLoaderApp'
import { DEVICES_ACTIONS } from '../reducers/devicesReducer'
import { ENV_ACTIONS } from '../reducers/environmentReducer'
import { FORM_ACTIONS } from '../reducers/formsReducer'
import { LOOKUPS_ACTIONS } from '../reducers/lookupsReducer'
import { REPORTS_ACTIONS } from '../reducers/reportsReducer'
import { VIEWS_ACTIONS } from '../reducers/viewsReducer'
import { getCoreData } from './core/services/environmentService'
import Signout from './user/Signout'
import { computeOwnerNameByType } from './data/dataHelpers'

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
})

const AppFullScreen = ({ isAuthenticated, apiToken, timeoutMinutes, lockEnabled }) => {
	const [t] = useTranslation('common')

	const initialState = {
		account: null,
		theme: null,
		userCurrent: null,
		translations: [],
		isSignedIn: false,
		error: null,
		loading: true,
		loaded: false,
	}

	const [values, setValues] = useState(initialState)
	const { error, loading, loaded } = values
	const [signOutOpen, setSignOutOpen] = useState(false)

	const dispatch = useDispatch()

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const handleMessageClosed = () => {
		setValues({ ...values, error: null })
	}

	//these are handlers for auto log out when idling
	const handleOnIdle = (event) => {
		console.log('user is idle', event)
		console.log('last active', getLastActiveTime())
		setSignOutOpen(true)
	}

	const { getLastActiveTime } = useIdleTimer({
		// default log out in 4 hours
		timeout: lockEnabled && timeoutMinutes ? 1000 * 60 * timeoutMinutes : 1000 * 60 * 60 * 4,
		onIdle: handleOnIdle,
		debounce: 500,
	})

	const showLoading = () =>
		loading && (
			<>
				<LoadingSpinner />
				<SkeletonLoaderApp />
			</>
		)

	const loadCoreData = async () => {
		var newFullScreenDataString = localStorage.getItem('newFullScreenData_env')
		if (newFullScreenDataString != null) {
			var newFullScreenData = JSON.parse(newFullScreenDataString)
			var env = newFullScreenData.environment
			var res = {
				account: env.account,
				theme: env.theme,
				userCurrent: env.userCurrent,
				translations: env.translations,
				devices: env.devices,
				owners: env.owners,
				links: env.links,
				settings: env.settings,
				isProjectFormsLoaded: env.isProjectFormsLoaded,
				environment: env,
			}
			dispatch({
				type: ENV_ACTIONS.CORE_DATA_LOAD,
				payload: res,
			})
			setValues({
				...values,
				account: res.account || [],
				theme: res.theme || {},
				userCurrent: res.userCurrent || {},
				translations: res.translations || [],
				isSignedIn: true,
				loaded: true,
				loading: false,
			})
			dispatch({
				type: ENV_ACTIONS.GET_TEAMS,
				payload: env.teams,
			})
			localStorage.removeItem('newFullScreenData_env')
			return
		}
		setValues({ ...values, loaded: false, loading: true })
		try {
			const res = await getCoreData(apiToken, t)

			if (res?.userCurrent?.code === 401) {
				signout(() => {
					dispatch({
						type: ENV_ACTIONS.SIGN_OUT,
					})
					dispatch({
						type: DEVICES_ACTIONS.RESET,
					})
					dispatch({
						type: FORM_ACTIONS.RESET,
					})
					dispatch({
						type: LOOKUPS_ACTIONS.RESET,
					})
					dispatch({
						type: VIEWS_ACTIONS.RESET,
					})
					dispatch({
						type: REPORTS_ACTIONS.RESET,
					})
				})
				return
			}

			dispatch({
				type: ENV_ACTIONS.CORE_DATA_LOAD,
				payload: res,
			})
			setValues({
				...values,
				account: res?.account || [],
				theme: res?.theme || {},
				userCurrent: res?.userCurrent || {},
				translations: res?.translations || [],
				isSignedIn: true,
				loaded: true,
				loading: false,
			})
		} catch (error) {
			setValues({ ...values, error, loading: false })
		}
	}

	useEffect(() => {
		if (!isAuthenticated) return
		loadCoreData()
	}, [isAuthenticated])

	return (
		<>
			{showLoading()}
			{showErrorMessage()}
			{loaded && (
				<QueryClientProvider client={queryClient}>
					<CoreProvider values={values}>
						<BrowserRouter>
							<Routes />
						</BrowserRouter>
					</CoreProvider>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			)}
			{signOutOpen && (
				<QueryClientProvider client={queryClient}>
					<Signout
						open={signOutOpen}
						theme={values.theme}
						translations={values.translations}
						source="timeout"
						onClose={() => setSignOutOpen(false)}
					/>
				</QueryClientProvider>
			)}
		</>
	)
}

export default AppFullScreen
