import { Box, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getFileMimeType } from 'utils/functions/helpers'

const VideoScreen = ({ videos }) => {
	const { t } = useTranslation('common')

	return (
		<Box>
			{videos?.length === 0 ? (
				<Typography>{t('misc.noVideoFound')}.</Typography>
			) : (
				<Stack direction="row" flexWrap="wrap" spacing={2}>
					{videos.map((video, index) => (
						<VideoPlayer
							key={index}
							videoBlob={video.blob}
							fileName={video.fileName}
							fileType={video.fileType}
						/>
					))}
				</Stack>
			)}
		</Box>
	)
}

const VideoPlayer = ({ videoBlob, fileName, fileType }) => {
	const { t } = useTranslation('common')
	const [videoUrl, setVideoUrl] = React.useState('')
	const type = getFileMimeType(fileType)

	React.useEffect(() => {
		if (videoBlob) {
			const url = URL.createObjectURL(videoBlob)
			setVideoUrl(url)
			return () => {
				URL.revokeObjectURL(url)
			}
		}
	}, [videoBlob])

	return (
		<Box sx={{ maxWidth: '33.33%' }}>
			{videoUrl && (
				<video controls style={{ width: '100%', height: '100%' }}>
					<source src={videoUrl} type={type} />
					{t('misc.yourBrowserDoesNotSupportTheVideoElement')}
				</video>
			)}
			<Typography
				sx={{
					textAlign: 'center',
				}}
			>
				{fileName}
			</Typography>
		</Box>
	)
}

export default VideoScreen
