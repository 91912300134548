import { Box } from '@mui/material'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import mapApi from 'apis/disApi/mapApi'
import tileApi from 'apis/disApi/tileApi'
import _ from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useForceRerender from 'utils/hooks/useForceRerender'
import { useSearchParams } from 'utils/hooks/useSearchParams'
import { MAP_LOGGED_IN_INFO } from '../../../../../constants'
import {
	getMapLoggedInInfoFromStorage,
	isJson,
	logErrorMessage,
} from '../../../../../utils/functions/helpers'
import ToastAlert from '../../../../core/Layouts/ToastAlert'
import TileWrapper from '../../components/TileWrapper'
import useDashboardQuery from '../../hooks/useDashboardQuery'
import { tileKeys } from '../../hooks/useTileQuery'
import MapInfo from './MapInfo'
import MapLoginForm from './MapLoginForm'
import MapSettingsDialog from './MapSettingsDialog'

function MapTile({ tile }) {
	const environment = useSelector((state) => state.environment)
	const queryClient = useQueryClient()
	const { id: dashboardKey } = useParams()
	const { dashboardKeyList } = useDashboardQuery({
		dashboardKey,
	})
	const { isMapLogout } = useSearchParams()

	const settings = useMemo(() => {
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}

		return {}
	}, [tile?.settings])
	const {
		locationData = {},
		showFilters = true,
		showHistory = true,
		pointOfInterest = false,
		linkedFields = {},
		repeatReloadMapSeconds,
	} = settings

	// get user info from storage
	const userInfo = getMapLoggedInInfoFromStorage()

	const isLoggedIn = !_.isEmpty(userInfo) && isMapLogout !== 'true'

	const formMapRef = useRef(null)

	const [settingsOpen, setSettingsOpen] = useState(false)
	const [alertInfo, setAlertInfo] = useState({
		open: false,
		text: '',
		type: 'success',
	})

	const [, triggerReRender] = useForceRerender()

	const updateTile = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})

	const setSessionIdToLS = async () => {
		try {
			const response = await mapApi.resetSession(environment.apiToken)
			const userValues = response?.data

			if (!userValues) return
			localStorage.setItem(MAP_LOGGED_IN_INFO, JSON.stringify(userValues))

			// Trigger re-render to update map
			triggerReRender()
		} catch (error) {
			// Clear session id if error 403
			if (error?.response?.status === 403) {
				localStorage.removeItem(MAP_LOGGED_IN_INFO)
			}
			setSettingsOpen(false)
			logErrorMessage(error)
		}
	}

	const {
		data: vehicleList,
		isLoading: vehicleLoading,
		isFetching: vehicleFetching,
		refetch,
	} = useQuery({
		queryKey: ['vehicle-list', tile.key, userInfo],
		queryFn: async () => {
			const response = await mapApi.getAllDevices(userInfo)
			// Trim name to remove white spaces because API returns some names with white spaces
			return response?.data?.map((item) => ({ ...item, name: item.name.trim() })) ?? []
		},
		onError: async (error) => {
			// Reset sessionId if sessionId expired
			if (error?.response?.status === 401) {
				await setSessionIdToLS()
				refetch()
			}
		},
		retry: false,
		enabled: Boolean(isLoggedIn),
	})
	const isVehicleLoading = vehicleLoading || vehicleFetching

	useEffect(() => {
		if (isLoggedIn) return
		;(async () => {
			await setSessionIdToLS()
		})()
	}, [isLoggedIn])

	const handleOpenDialog = () => {
		setSettingsOpen(true)
	}

	const handleCloseDialog = () => {
		setSettingsOpen(false)
	}

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({ ...settings, tileWidth: width })
			await updateTile.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const handleSubmit = async (data) => {
		try {
			const {
				locationData,
				showFilters,
				showHistory,
				pointOfInterest,
				linkedFields,
				repeatReloadMapSeconds,
			} = data
			const { locationData: locationFromSettings, ...rest } = settings
			await updateTile.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: {
					settings: JSON.stringify({
						...rest,
						showFilters: showFilters,
						showHistory: showHistory,
						pointOfInterest: pointOfInterest,
						repeatReloadMapSeconds,
						locationData: {
							...locationFromSettings,
							...locationData,
						},
						linkedFields,
					}),
				},
				token: environment.apiToken,
			})
			handleCloseDialog()
		} catch (error) {
			logErrorMessage(error)
		}
	}

	return (
		<>
			<ToastAlert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />
			<TileWrapper
				title={tile?.i}
				onSettingClick={handleOpenDialog}
				ref={formMapRef}
				hidden={!isLoggedIn}
			>
				{isLoggedIn ? (
					<MapInfo
						titleKey={tile?.key}
						vehicleList={vehicleList}
						vehicleLoading={isVehicleLoading}
						userInfo={userInfo}
						showFilters={showFilters}
						repeatReloadMapSeconds={repeatReloadMapSeconds}
						showHistory={showHistory}
						pointOfInterest={pointOfInterest}
						locationData={locationData}
						linkedFields={linkedFields}
						dashboardKeyList={dashboardKeyList}
					/>
				) : (
					<Box
						sx={{
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<MapLoginForm />
					</Box>
				)}

				<MapSettingsDialog
					defaultTileWidth={300}
					tileElementWidth={formMapRef?.current?.clientWidth}
					open={settingsOpen}
					tile={tile}
					isSubmitting={updateTile?.isLoading}
					vehicleList={vehicleList}
					vehicleLoading={vehicleLoading}
					locationData={locationData}
					showFilters={showFilters}
					repeatReloadMapSeconds={repeatReloadMapSeconds}
					showHistory={showHistory}
					pointOfInterest={pointOfInterest}
					dashboardKeyList={dashboardKeyList}
					linkedFields={linkedFields}
					onClose={handleCloseDialog}
					onResizeTileWidth={handleResizeTileWidth}
					onSubmit={handleSubmit}
				/>
			</TileWrapper>
		</>
	)
}

export default MapTile
