import { useState, useEffect, useContext, useMemo, memo, useLayoutEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { Delete } from '@mui/icons-material'
import { InputLabel, FormControl, Select, MenuItem, Button, Stack } from '@mui/material'
import { isEqual, isEmpty, cloneDeep } from 'lodash'

import CollapseComponent from './CollapseComponent'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'
import {
	DATA_OBJ_ACTION_TYPES,
	SETTING_TYPES,
	shouldPreventRender,
	dataObjectReducer,
} from '../ViewDialogUtils'

const useStyles = makeStyles(() => ({}))

const SortTheDataComponent = ({
	viewData,
	sortModel,
	allColumns,
	listActiveFields,
	listActiveFieldsOptions,
	saveSettingDispatch,
	expandedAccordion,
	setExpandedAccordion,
}) => {
	const [t] = useTranslation('common')
	const [nameTitleMapOfField, setNameTitleMapOfField] = useState({})
	const [value, setValue] = useState('')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const title = useMemo(() => t('common:view.sortTheData'), [t])
	// sort the data collapse
	const [sortData, sortDataDispatch] = useReducer(dataObjectReducer, [])

	useLayoutEffect(() => {
		if (isEmpty(listActiveFieldsOptions) || isEmpty(sortModel)) return

		if (!isEmpty(sortData)) return

		sortDataDispatch({
			type: DATA_OBJ_ACTION_TYPES.INIT_DATA,
			initData: sortModel,
		})
	}, [listActiveFieldsOptions, sortModel])

	useLayoutEffect(() => {
		saveSettingDispatch({
			type: SETTING_TYPES.SORT_DATA,
			value: sortData,
		})
	}, [sortData])

	function addSort(e) {
		const newItem = {
			field: isEmpty(listActiveFields) ? '' : listActiveFields[0],
			sort: 'asc',
		}
		sortDataDispatch({
			type: DATA_OBJ_ACTION_TYPES.ADD,
			target: {
				defaultItem: newItem,
			},
		})
	}

	function sortChange(newValue, item, index, changeFor) {
		//changeFor= 1=>change field, 2=>change direction
		const newItem = cloneDeep(item)
		if (1 === changeFor) {
			newItem['field'] = newValue
		} else if (2 === changeFor) {
			newItem['sort'] = newValue
		}

		sortDataDispatch({
			type: DATA_OBJ_ACTION_TYPES.UPDATE,
			target: {
				index,
				value: newItem,
			},
		})
	}

	function deleteSort(index) {
		sortDataDispatch({
			type: DATA_OBJ_ACTION_TYPES.DELETE,
			target: {
				index,
			},
		})
	}

	useEffect(() => {
		const map = {}
		if (allColumns?.length > 0) {
			allColumns.map((item) => {
				map[item.name] = item.title
			})
		} else if (viewData?.columns?.length > 0) {
			viewData.columns.map((item) => {
				map[item.name] = item.title
			})
		}
		setNameTitleMapOfField(map)
	}, [allColumns])

	const generateSortValue = () => {
		let nameTmp = ' '
		if (sortData.length > 0) {
			nameTmp = sortData
				.map((item) => {
					let tmp =
						nameTitleMapOfField && nameTitleMapOfField[item.field]
							? nameTitleMapOfField[item.field]
							: item.field
					tmp += ' '
					tmp += item.sort == 'desc' ? 'Descending' : 'Ascending'
					return tmp
				})
				.join(', ')
		}
		if (nameTmp !== value) {
			setValue(nameTmp)
		}
	}

	useEffect(() => {
		if (isEmpty(sortData) || isEmpty(nameTitleMapOfField)) {
			setValue('')
			return
		}

		generateSortValue()
	}, [sortData, nameTitleMapOfField])

	return (
		<CollapseComponent
			title={title}
			value={value}
			expanded={expandedAccordion === title}
			setExpandedAccordion={setExpandedAccordion}
			content={
				<div>
					{!isEmpty(listActiveFieldsOptions) &&
						sortData.map((item, index) => (
							<Stack
								direction="row"
								alignItems="center"
								spacing={1}
								style={{ justifyContent: 'center', marginBottom: '10px', alignItems: 'baseline' }}
							>
								<FormControl variant="standard" fullWidth>
									<InputLabel variant="standard" htmlFor="uncontrolled-native">
										Field
									</InputLabel>
									<Select
										value={item?.field || ''}
										defaultValue={item?.field || ''}
										onChange={(e) => sortChange(e.target.value, item, index, 1)}
										inputProps={{
											name: 'age',
											id: 'uncontrolled-native',
										}}
									>
										{listActiveFieldsOptions.map((tmpItem, index) => (
											<MenuItem key={`${index}_${tmpItem.name}`} value={tmpItem.name}>
												{tmpItem.title}
											</MenuItem>
										))}
									</Select>
								</FormControl>
								<FormControl variant="standard" style={{ width: '50%' }}>
									<InputLabel variant="standard" htmlFor="uncontrolled-native">
										Order
									</InputLabel>
									<Select
										defaultValue={item?.sort || 'asc'}
										value={item?.sort}
										onChange={(e) => sortChange(e.target.value, item, index, 2)}
										inputProps={{
											name: 'age',
											id: 'uncontrolled-native',
										}}
									>
										<MenuItem key="ascending" value={'asc'}>
											Ascending
										</MenuItem>
										<MenuItem key="descending" value={'desc'}>
											Descending
										</MenuItem>
									</Select>
								</FormControl>
								<div>
									<Button
										aria-label="delete"
										size="small"
										variant="contained"
										sx={{
											borderRadius: '50%',
											width: '25px !important',
											minWidth: '25px !important',
											height: '25px !important',
										}}
										onClick={() => deleteSort(index)}
									>
										<Delete fontSize="small" />
									</Button>
								</div>
							</Stack>
						))}
					<div>
						<Button variant="text" className={classes.rightButtonSort} onClick={addSort}>
							+ Add sort
						</Button>
					</div>
				</div>
			}
		/>
	)
}

export const SortTheDataCollapse = memo(SortTheDataComponent, shouldPreventRender)
