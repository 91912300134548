import { useState } from 'react'

const useForceRerender = () => {
	const [reRenderNumber, setReRenderNumber] = useState(0)
	const handleChange = () => setReRenderNumber(Math.random())

	return [reRenderNumber, handleChange]
}

export default useForceRerender
