import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { makeStyles } from '@mui/styles'
import { IconThemeProvider } from '../../../custom-components/context/IconThemesContext'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import '../../../styles.css'
import DashboardSplitPane from '../../core/Dashboard/DashboardSplitPane'
import { getAllReports } from '../../data/dataServices'
import ReportsDashboard from './ReportsDashboard'
import ReportsData from './ReportsData'
import { useParams } from 'react-router-dom'
import { useUrlQueryParams } from 'utils/hooks/useUrlQueryParams'
import { REPORTS_ACTIONS } from '../../../reducers/reportsReducer'

const useStyles = makeStyles(() => ({
	formSidePanel: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		height: '100%',
	},
	formBody: {
		display: 'contents',
	},
}))

const ReportsModule = () => {
	const { id: paramId } = useParams()

	const urlQueryParam = useUrlQueryParams()

	const classes = useStyles()

	const { environment, reportsModule } = useSelector((state) => state)

	const iconTheme = environment.theme.icons

	const [reports, setReports] = useState([])
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)

	const dispatch = useDispatch()
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true
		return () => (isMounted.current = false)
	}, [])

	useEffect(() => {
		var newFullScreenDataModString = localStorage.getItem('newFullScreenData_mod')
		if (!_.isEmpty(paramId) && newFullScreenDataModString != null) {
			var newFullScreenDataMod = JSON.parse(newFullScreenDataModString)
			var reportsModule = newFullScreenDataMod.reportsModule
			dispatch({
				type: REPORTS_ACTIONS.REPORT_RESTORE_PRODUCER,
				payload: reportsModule,
			})
			localStorage.removeItem('newFullScreenData_mod')
		} else {
			dispatch({
				type: REPORTS_ACTIONS.TAB_CHANGE,
				payload: REPORTS_ACTIONS.NAME,
			})

			if (!environment.reports.length) {
				initiateLoadAllReports()
			} else {
				setReports(environment.reports)
			}
		}
	}, [])

	useEffect(() => {
		if (!environment.refresh) return
		setLoading(true)
		dispatch({
			type: ENV_ACTIONS.CLEAR_REPORTS,
		})
		initiateLoadAllReports()
		dispatch({
			type: ENV_ACTIONS.REFRESH,
			payload: false,
		})
	}, [environment.refresh])

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={() => setError(null)} />
		)

	const initiateLoadAllReports = () => {
		setLoading(true)
		loadAllReports(environment.apiToken)
			.then((res) => {
				let reports = _.sortBy(res.data, (report) => report.name.toLowerCase())
				if (isMounted.current) {
					setReports(reports)
				}
				dispatch({
					type: ENV_ACTIONS.GET_REPORTS,
					payload: reports,
				})
			})
			.catch((err) => {
				setError('Code ' + err.response?.data?.code + ': ' + err.response?.data?.message)
			})
			.finally(() => {
				if (isMounted.current) {
					setLoading(false)
				}
			})
	}

	const loadAllReports = async () => {
		let promise = await getAllReports(environment.apiToken)
		return promise
	}

	const sidePanel = () => (
		<div id="form-side-panel" className={classes.formSidePanel}>
			<ReportsDashboard reports={environment.reports} environment={environment} loading={loading} />
		</div>
	)

	const formBody = () => (
		<div id="form-body" className={classes.formBody}>
			{showErrorMessage()}
			<ReportsData
				environment={environment}
				module={reportsModule}
				key={reportsModule?.reportSelected?.key}
			/>
		</div>
	)

	return (
		<IconThemeProvider values={iconTheme}>
			<DashboardSplitPane sidePanel={sidePanel()} mainContent={formBody()} />
		</IconThemeProvider>
	)
}

export default ReportsModule
