import { rectSortingStrategy } from '@dnd-kit/sortable'
import {
	Add,
	AlignHorizontalLeft,
	Construction,
	Fullscreen,
	HeadsetMic,
	QuestionMark,
	Settings,
	Videocam,
} from '@mui/icons-material'
import AlignHorizontalRightIcon from '@mui/icons-material/AlignHorizontalRight'
import {
	Alert,
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormGroup,
	Skeleton,
	Stack,
	TextField,
	Typography,
} from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import useGroupQuery, { groupKeys } from 'components/pages/dis/hooks/useGroupQuery'
import AppButton from 'custom-components/AppButton'
import differenceBy from 'lodash/differenceBy'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { logErrorMessage } from 'utils/functions/helpers'
import { useMenu } from '../../contexts/MenuContext'
import { MultipleContainers } from '../../dnd/MultipleContainers'
import MenuCard from './MenuCard'
import WebGroupDialog from './WebGroupDialog'
import WebGroupSelect from './WebGroupSelect'
import { MARKET_PLACE } from 'components/core/Layouts/DisLayout'

const MenuForm = ({
	menuData,
	onMenuFormChange,
	onGroupMenuChange,
	onRightSideMenuCheck,
	onCreateNewMenuItem,
}) => {
	const { t } = useTranslation('common')
	const { environment } = useSelector((state) => state)
	const queryClient = useQueryClient()

	const { groups, groupOptions, isGroupLoading, updateWebGroup, addWebGroup, removeWebGroup } =
		useGroupQuery()
	const { dragDropItems, setDragDropItems, containers, setContainers } = useMenu()

	const [openWebGroupDialog, setOpenWebGroupDialog] = useState(false)
	const [isSubmittingGroup, setIsSubmittingGroup] = useState(false)

	const handleClickOpenWebGroupDialog = () => {
		setOpenWebGroupDialog(true)
	}

	const handleCloseWebGroupDialog = () => {
		setOpenWebGroupDialog(false)
	}

	const RightSideMenu = [
		{
			value: 'question',
			icon: <QuestionMark sx={{ color: '#999' }} />,
			title: t('common:input.help'),
		},
		{
			value: 'video',
			icon: <Videocam sx={{ color: '#999' }} />,
			title: t('common:input.video'),
		},
		{
			value: 'headset',
			icon: <HeadsetMic sx={{ color: '#999' }} />,
			title: t('common:input.chat'),
		},
		// {
		// 	value: 'contruction',
		// 	icon: <Construction sx={{ color: '#999' }} />,
		// 	title: t('common:input.build'),
		// },
		// {
		// 	value: 'setting',
		// 	icon: <Settings sx={{ color: '#999' }} />,
		// 	title: t('common:input.manage'),
		// },
		{
			value: 'fullScreen',
			icon: <Fullscreen sx={{ color: '#999' }} />,
			title: t('common:input.fullScreen'),
		},
		{
			value: MARKET_PLACE,
			// icon: <Settings sx={{ color: '#999' }} />,
			title: t('common:tabs.marketplace'),
		},
	]

	const groupKeyByOtherMenu = useMemo(
		() => {
			if (!groups) return [];
			return groups?.filter((group) => group?.menuKey != null && group?.menuKey !== menuData?.key)
		},
		[groups, menuData?.key]
	)

	const handleSubmitEditGroup = async (newGroup) => {
		const newWebGroups = newGroup.filter((group) => group.isCreating && !group.isDeleting)
		const deletedWebGroups = newGroup.filter((group) => group.isDeleting && !group.isCreating)
		const existingWebGroups = newGroup.filter((group) => !group.isCreating && !group.isDeleting)

		const differedWebGroups = differenceBy(existingWebGroups, groupOptions, 'label')

		setIsSubmittingGroup(true)
		const updateWebGroupsPromises = differedWebGroups.map((group) => {
			const newData = {
				name: group.label,
			}
			return updateWebGroup.mutateAsync({
				groupKey: group.value,
				data: newData,
				token: environment.apiToken,
			})
		})

		const createWebGroupsPromises = newWebGroups.map((group) => {
			const newData = {
				name: group.label,
			}
			return addWebGroup.mutateAsync({
				data: newData,
				token: environment.apiToken,
			})
		})

		const deletedWebGroupsPromises = deletedWebGroups.map((group) => {
			return removeWebGroup.mutateAsync({
				groupKey: group.value,
				token: environment.apiToken,
			})
		})

		try {
			await Promise.all([
				...updateWebGroupsPromises,
				...createWebGroupsPromises,
				...deletedWebGroupsPromises,
			])
			toast.success(t('common:toast.saveSuccess'))
		} catch (error) {
			logErrorMessage(error)
		} finally {
			setIsSubmittingGroup(false)
			queryClient.invalidateQueries(groupKeys.all)
		}
	}

	return (
		<>
			<Box sx={{ width: '30%' }}>
				<TextField
					size="small"
					id="menu-name"
					label={t('common:input.menuName')}
					variant="outlined"
					name="name"
					fullWidth
					value={menuData?.name || ''}
					onChange={onMenuFormChange}
				/>
			</Box>

			<Stack direction="row" alignItems="center" gap={2} sx={{ mt: 2, width: '30%' }}>
				{!isGroupLoading ? (
					<Box sx={{ width: '100%' }}>
						<WebGroupSelect
							currentMenuName={menuData?.name}
							label={t('common:dis.webGroup')}
							options={[{ label: 'None', value: '' }, ...groupOptions]}
							value={menuData?.groupKey ?? ''}
							groupKeyByOtherMenu={groupKeyByOtherMenu}
							onChange={onGroupMenuChange}
						/>
					</Box>
				) : (
					<Skeleton variant="rectangular" width="100%" height={56} />
				)}
				<AppButton
					size="small"
					variant="text"
					sx={{ width: 'fit-content', flexShrink: 0 }}
					onClick={handleClickOpenWebGroupDialog}
				>
					{t('common:dis.manageWebGroups')}
				</AppButton>
				<WebGroupDialog
					groupOptions={groupOptions}
					open={openWebGroupDialog}
					onClose={handleCloseWebGroupDialog}
					onSave={handleSubmitEditGroup}
					isSubmittingGroup={isSubmittingGroup}
				/>
			</Stack>

			<Box sx={{ mt: 2 }}>
				<Stack direction="row" alignItems="center" gap={1}>
					<AlignHorizontalRightIcon />
					<Typography variant="subtitle1" fontWeight="bold">
						{t('common:dis.rightSideMenu')}
					</Typography>
				</Stack>

				<FormGroup sx={{ flexDirection: 'row', alignItems: 'center'}}>
					{RightSideMenu.map((item, index) => (
						<FormControlLabel
							key={index}
							control={
								<Checkbox
									name={item.value}
									checked={menuData?.settings?.includes(item.value) ?? false}
									onChange={onRightSideMenuCheck}
								/>
							}
							label={
								<Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
									{item.icon}
									{item.title}
								</Box>
							}
						/>
					))}

					<Alert
						severity="info"
						sx={{
							bgcolor: 'background.paper',
							position: 'relative',
							top: '-2px',
							paddingRight:'0px',
							paddingLeft:'3px'
						}}
					>
						{t('common:dis.additionalIconsWillAppearBaseOnRole')}
					</Alert>
				</FormGroup>
			</Box>

			<Box sx={{ mt: 2 }}>
				<Stack direction="row" alignItems="center" justifyContent="space-between" gap={1}>
					<Stack direction="row" alignItems="center" gap={1}>
						<AlignHorizontalLeft />
						<Typography variant="subtitle1" fontWeight="bold">
							{t('common:dis.leftSideMenu')}
						</Typography>
					</Stack>
					<AppButton startIcon={<Add />} onClick={onCreateNewMenuItem}>
						{t('common:dis.addNewTab')}
					</AppButton>
				</Stack>

				<MultipleContainers
					items={dragDropItems}
					setItems={setDragDropItems}
					containers={containers}
					setContainers={setContainers}
					strategy={rectSortingStrategy}
					vertical
					renderItem={(data) => {
						return <MenuCard {...data} />
					}}
				/>
			</Box>
		</>
	)
}

export default MenuForm
