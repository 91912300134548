import { useState, useRef, useMemo, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { isEmpty, sortBy, isArray } from 'lodash'
import moment from 'moment'

import { Scheduler } from '@aldabil/react-scheduler'
import { Box, Dialog, DialogContent, DialogContentText, DialogTitle, DialogActions, Button, IconButton } from '@mui/material'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'



import TileWrapper from '../../components/TileWrapper'
import tileApi from 'apis/disApi/tileApi'

import { IconThemeProvider, IconThemeContext } from 'custom-components/context/IconThemesContext'
import {
	isJson,
	logErrorMessage,
	convertArrayToObject,
} from '../../../../../utils/functions/helpers'
import { tileKeys } from '../../hooks/useTileQuery'
import { ENV_ACTIONS } from 'reducers/environmentReducer'

import DispatchSchedulerSettingsDialog from './DispatchSchedulerSettingDialog'
import DoformsSendDispatch from 'components/data/dispatch/DoformsSendDispatch'
import LoadingSpinner from 'custom-components/LoadingSpinner'
import DoformsPortal from '../../../../../custom-components/DoformsPortal'
import ToastAlert from 'components/core/Layouts/ToastAlert'
import useDashboardQuery from '../../hooks/useDashboardQuery'
import useSchedulerQuery from '../../hooks/useSchedulerQuery'
import { useTileDashboard } from '../../dashboard/Dashboard'

import { getAllProjects, getAllTeams, getProjectForms } from 'components/data/dataServices'
import { getDevices, getDispatchSettings, updateAppointments } from 'components/core/services/environmentService'
import { getAutoUpdateFilters } from '../../helpers'
import { DISPATCH_STATUS } from '../utils/constants'
import { viewRecord, getPrintPreview } from 'components/data/datagrid/recordsService'
import { capitalizeFirstLetter } from "../../../../../components/data/dataHelpers"
import { performRecordAction } from 'components/data/datagrid/recordsHelper'

const useStyles = makeStyles(() => ({
	dialog: {
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	dialogTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
	exitConfirmation: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	paper: {
		position: 'absolute',
		padding: '16px 32px 24px',
		borderRadius: '5px',
	},
	hidden: {
		display: 'none',
	},
}))

function DispatchSchedulerTile(props) {
	const { tile } = props

	const [t] = useTranslation()

	const { environment } = useSelector((state) => state)
	const dispatch = useDispatch()
	const iconTheme = environment.theme.icons
	const { id: dashboardKey } = useParams()

	const queryClient = useQueryClient()
	const updateTileMutation = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})
	const tileRef = useRef(null)
	const { dashboardKeyList } = useDashboardQuery({
		dashboardKey,
	})

	const settings = useMemo(() => {
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}

		return {}
	}, [tile?.settings])

	const {
		tileWidth,
		projectFormInfo,
		schedulerRangeType,
		schedulerStartTime,
		schedulerEndTime,
		schedulerColorStatusSettings,
		linkedFields = {},
		otherOptionsChecked,
	} = settings

	const { selectedFields } = useTileDashboard()
	const { filterConfigs } = useMemo(
		() => getAutoUpdateFilters(linkedFields, selectedFields, dashboardKeyList, true),
		[linkedFields, selectedFields]
	)

	const schedulerBoxRef = useRef(null)
	const schedulerRef = useRef(null)

	// Remove id because it is random property and make app re-render many times
	const filterWithoutId = useMemo(
		() =>
			filterConfigs.map((item) => {
				const { id, ...rest } = item
				return rest
			}),
		[filterConfigs]
	)

	useEffect(() => {
		const { Mobile_number: mobileNumber } = convertArrayToObject(filterWithoutId)

		const device = environment?.devices?.find((device) => device.number === mobileNumber)

		setSelectedDevice(device || null)
	}, [environment?.devices, filterWithoutId])

	const formSelected = useMemo(() => {
		const allForms = environment?.forms
		if (isEmpty(allForms)) {
			return {}
		}

		const form = allForms.find((item) => item.key === projectFormInfo?.formKey)
		if (!form) {
			return {}
		}

		return {
			...form,
			projectKey: projectFormInfo?.projectKey,
		}
	}, [projectFormInfo, environment])

	// data states
	const [view, setView] = useState(schedulerRangeType)
	const [schedulerColorStatus, setSchedulerColorStatus] = useState(schedulerColorStatusSettings)
	const [events, setEvents] = useState([])
	const [settingsOpen, setSettingsOpen] = useState(false)
	const [alertInfo, setAlertInfo] = useState({
		open: false,
		text: '',
		type: 'success',
	})
	const [schedulerKey, setSchedulerKey] = useState(0)
	const [loading, setLoading] = useState(false)
	const [selectedDevice, setSelectedDevice] = useState(null)
	const [selectedDate, setSelectedDate] = useState(null)
	const [selectedDateDisplay, setSelectedDateDisplay] = useState(moment())
	const [defaultSchedulerStatus, setDefaultSchedulerStatus] = useState([])
	const defaultDuration = '0.5'
	useEffect(() => {
		const showAgenda = otherOptionsChecked.some((item) => item === 'showAgenda')

		if (schedulerRef.current?.scheduler) {
			if (schedulerRef.current.scheduler.agendada !== showAgenda) {
				schedulerRef.current.scheduler.handleState(showAgenda, 'agenda')
			}
		}
	}, [schedulerRef.current?.scheduler, otherOptionsChecked])

	useEffect(() => {
		const showAgenda = otherOptionsChecked.some((item) => item === 'showAgenda')

		if (schedulerRef.current?.scheduler) {
			if (schedulerRef.current.scheduler.agendada !== showAgenda) {
				schedulerRef.current.scheduler.handleState(showAgenda, 'agenda')
			}
		}
	}, [schedulerKey])

	useEffect(() => {
		if (environment.isProjectFormsLoaded) return

		initiateLoadAllProjects()
	}, [environment.isProjectFormsLoaded])

	useEffect(() => {
		getDevices(environment.apiToken)
			.then((data) => {
				dispatch({
					type: ENV_ACTIONS.GET_DEVICES,
					payload: data.json(),
				})
				initiateLoadAllTeams(data)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	const initiateLoadAllTeams = (devices) => {
		setLoading(true)
		loadAllTeams()
			.then((res) => {
				let teams = sortBy(res.data, (team) => team.name.toLowerCase())
				if (devices.length) {
					teams = teams.map((team) => {
						let teamDevices = devices.filter((device) => device.teamKey === team.key)
						teamDevices = sortBy(teamDevices, (team) => team.name.toLowerCase())
						return { ...team, devices: teamDevices }
					})
				}

				dispatch({
					type: ENV_ACTIONS.GET_TEAMS,
					payload: teams,
				})
			})
			.catch((err) => {
				const errorMessage = 'Code ' + err.response?.data?.code + ': ' + err.response?.data?.message
				setAlertInfo((prev) => ({
					...prev,
					open: true,
					type: 'error',
					text: errorMessage,
				}))
			})
	}

	const loadAllTeams = async () => {
		let promise = await getAllTeams(environment.apiToken)
		return promise
	}
	useEffect(() => {
		setView(schedulerRangeType)
		setSchedulerKey((prevKey) => prevKey + 1)
	}, [schedulerRangeType])

	useEffect(() => {
		setSchedulerColorStatus(schedulerColorStatusSettings)
		setSchedulerKey((prevKey) => prevKey + 1)
	}, [schedulerColorStatusSettings])

	const initiateLoadAllProjects = () => {
		dispatch({
			type: ENV_ACTIONS.IS_LOADING_PROJECTS,
			payload: true,
		})
		loadAllProjects()
			.then((res) => {
				const projects = sortBy(res.data, (project) => project.name.toLowerCase())
				initiateLoadFormsByProject(projects)
			})
			.catch((err) => {
				const errorMessage = 'Code ' + err.response?.data?.code + ': ' + err.response?.data?.message
				setAlertInfo((prev) => ({
					...prev,
					open: true,
					type: 'error',
					text: errorMessage,
				}))
			})
			.finally(() => {
				dispatch({
					type: ENV_ACTIONS.IS_PROJECT_FORMS_LOADED,
					payload: true,
				})
			})
	}

	const loadAllProjects = async () => {
		let promise = await getAllProjects(environment.apiToken)
		return promise
	}

	const initiateLoadFormsByProject = (projects) => {
		let promises = []
		let newProjects = []
		for (let i = 0; i < projects.length; i++) {
			const promise = loadFormsByProject(projects[i])
				.then((res) => {
					if (res.name.toLowerCase() !== 'lookups') {
						newProjects[i] = { ...res }
					}
					dispatch({
						type: ENV_ACTIONS.GET_PROJECTS,
						payload: newProjects.filter((val) => val),
					})
				})
				.catch((err) => {
					const errorMessage = 'Code ' + err.response?.data?.code + ': ' + err.response?.data?.message
					setAlertInfo((prev) => ({
						...prev,
						isOpen: true,
						type: 'error',
						text: errorMessage,
					}))
				})
			promises.push(promise)
		}

		Promise.all(promises).then(() => {
			dispatch({
				type: ENV_ACTIONS.IS_LOADING_PROJECTS,
				payload: false,
			})
		})
	}

	const loadFormsByProject = async (project) => {
		return getProjectForms(project.key, environment.apiToken).then((resp) => {
			let promiseObject = { key: project.key, name: project.name, forms: [] }
			if (resp?.data && isArray(resp.data) && resp.data.length) {
				promiseObject.forms = sortBy(resp.data, (project) => project.name.toLowerCase())
				dispatch({
					type: ENV_ACTIONS.GET_FORMS,
					payload: promiseObject.forms,
				})
			}
			return promiseObject
		})
	}

	function getColorStatusDispatch(status) {
		if (!schedulerColorStatus) return ''
		const statusColor = schedulerColorStatus.find(item => item.label === status)
		if (!statusColor) return ''
		return statusColor.color
	}

	function getEditableDispatch(status) {
		const schedulerStatus = defaultSchedulerStatus.find(item => item.label === status)
		if (!schedulerStatus) return false
		return schedulerStatus.edit
	}

	const { appoinments, isLoadingData } = useSchedulerQuery({
		tileKey: tile.key,
		rangeType: view,
		selectedDevice: selectedDevice,
		selectedDateDisplay: selectedDateDisplay
	})

	useEffect(() => {
		if (!appoinments) return

		let eventList = []
		for (let i = 0; i < appoinments.data.length; i++) {
			const currentData = appoinments.data[i]
			const startMoment = moment(`${currentData.date} ${currentData.time}`, 'YYYY-MM-DD HH:mm')
			const endMoment = startMoment.clone().add(currentData.duration, 'minutes')
			const viewType = getEditableDispatch(currentData?.dispatch?.status)
				? 'edit'
				: 'view'
			eventList.push({
				event_id: currentData.key,
				title: currentData.dispatch.name,
				start: startMoment.toDate(),
				end: endMoment.toDate(),
				dispatch: currentData.dispatch,
				deletable: false,
				editable: true,
				viewType,
				color: getColorStatusDispatch(currentData?.dispatch?.status),
				draggable: getEditableDispatch(currentData?.dispatch?.status),
			})
		}
		setEvents(eventList)
	}, [appoinments, schedulerColorStatus])

	const isScheduleLoading = useMemo(() => loading || isLoadingData, [loading, isLoadingData])

	useEffect(() => {
		setSchedulerKey((prev) => prev + 1)
	}, [isScheduleLoading])

	useEffect(() => {
		getDispatchSettings(environment.apiToken)
			.then((setting) => {
				setDefaultSchedulerStatus(setting?.data?.status)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])
	
	const handleOpenDialog = () => {
		setSettingsOpen(true)
	}

	const handleCloseDialog = () => {
		setSettingsOpen(false)
	}

	const handleSubmitSetting = async (data) => {
		try {
			const { projectFormInfo, schedulerRangeType, schedulerStartTime, schedulerEndTime, schedulerColorStatusSettings, linkedFields, otherOptionsChecked } = data
			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: {
					settings: JSON.stringify({
						...settings,
						projectFormInfo,
						schedulerRangeType,
						schedulerStartTime,
						schedulerEndTime,
						schedulerColorStatusSettings,
						linkedFields,
						otherOptionsChecked,
					}),
				},
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		} finally {
			handleCloseDialog()
		}
	}

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({
				...settings,
				tileWidth: width,
			})

			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const handleEventAdd = (newEvent) => {
		setEvents([...events, newEvent])
	}

	const handleEventUpdate = (updatedEvent) => {
		const updatedEvents = events.map((event) =>
			event.event_id === updatedEvent.event_id ? updatedEvent : event
		)
		setEvents(updatedEvents)
	}

	const handleEventDelete = (eventId) => {
		const updatedEvents = events.filter((event) => event.event_id !== eventId)
		setEvents(updatedEvents)
	}

	const handleViewChange = (view, agenda) => {
		setView(view)
	}

	const handleCellClick = (source) => {
		if (checkNotValidDateTime(source)) return
		if (view !== 'month') {
			setSelectedDate(moment(source).format('YYYY-MM-DDTHH:mm:ss'))
		}
		else {
			const sourceMoment = moment(source)
			const momentToday = moment().clone()
			if (momentToday.isBefore(sourceMoment, 'day')) {
				const selectMoment = sourceMoment.clone().startOf('day').add(8, 'hours').format('YYYY-MM-DDTHH:mm:ss')
				setSelectedDate(selectMoment)
			}
			else {
				const currentMinute = momentToday.clone().minutes()
				let selectMoment
				if (currentMinute < 30) {
					selectMoment = momentToday.clone().startOf('hour').add(30, 'minutes')
				} else {
					selectMoment = momentToday.clone().add(1, 'hour').set({ 'minute': 0, 'second': 0, 'millisecond': 0 })
				}
				setSelectedDate(selectMoment)
			}
		}
		dispatch({
			type: ENV_ACTIONS.SHOW_SEND_DISPATCH,
			payload: 'dashboard',
		})
	}

	const checkNotValidDateTime = (date) => {
		const isNotValid = moment(date).isBefore(moment(), view !== 'month' ? null : 'day')
		if (isNotValid) {
			setAlertInfo((prev) => ({
				...prev,
				open: true,
				type: 'error',
				text: 'You can not schedule dispatch in the past',
			}))
		}
		return isNotValid
	}
	const handleEventDrop = async (event, droppedOn, updatedEvent, originalEvent) => {
		if (checkNotValidDateTime(droppedOn)) return

		const sourceMoment = moment(droppedOn)
		let selectMoment
		if (view !== 'month') {
			selectMoment = sourceMoment.clone()
		}
		else {
			const momentToday = moment().clone()
			if (momentToday.isBefore(sourceMoment, 'day')) {
				selectMoment = sourceMoment.clone().startOf('day').add(8, 'hours')
			}
			else {
				const currentMinute = momentToday.clone().minutes()
				if (currentMinute < 30) {
					selectMoment = momentToday.clone().startOf('hour').add(30, 'minutes')
				} else {
					selectMoment = momentToday.clone().add(1, 'hour').set({ 'minute': 0, 'second': 0, 'millisecond': 0 })
				}
			}
		}
		const updatedEvents = events.map((event) =>
			event.event_id === updatedEvent.event_id ? updatedEvent : event
		)

		try {
			setLoading(true)
			const res = await updateAppointments(environment.apiToken, updatedEvent.event_id, {
				date: selectMoment.format('YYYY-MM-DD'),
				time: selectMoment.format('HH:mm'),
			})
			if (res.status === 200) {
				setEvents(updatedEvents)
			}
			setLoading(false)
		}
		catch (err) {
			const errorMessage = 'Code ' + err.response?.data?.code + ': ' + err.response?.data?.message
			setAlertInfo((prev) => ({
				...prev,
				open: true,
				type: 'error',
				text: errorMessage,
			}))
			setLoading(false)
		}
	}

	const CustomEditor = (props) => {
		const { edited: currentEvent, close } = props
		const [iframeSrc, setIframeSrc] = useState('')
		const viewType = useMemo(() => currentEvent?.viewType, [currentEvent])
		const { iconTheme } = useContext(IconThemeContext)
		const classes = useStyles(iconTheme)
		useEffect(() => {
			if (viewType === 'view' || viewType === 'edit') return
			if (props.close) {
				props.close()
			}
		}, [viewType])

		useEffect(() => {
			(async () => {
				if (viewType === 'view') {
					const record = {
						dispatchKey: currentEvent.dispatch.key,
						type: 'DISPATCH',
					}
	
					const res = await viewRecord(record, environment?.apiToken, formSelected)
					const iframeSrc = getPrintPreview(res.data, environment.apiToken)
					setIframeSrc(iframeSrc)
					return
				}
				
				if (viewType === 'edit') {
					const record = {
                        dispatchKey: currentEvent.dispatch.key,
                        type: 'DISPATCH',
						sourceType: 'DISPATCH',
						key: currentEvent.dispatch.key,
                    }
					const iframePromise = await performRecordAction(
										environment,
										record,
										viewType,
										formSelected,
										'forms'
									)
					setIframeSrc(iframePromise)
				}
			})()
		}, [currentEvent])

		return (
			<Dialog
				open={true}
				onClose={close}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				maxWidth={'md'}
				fullWidth
				className={classes.root}
				sx={{
					'& .MuiDialog-paper': {
						height: '90% !important',
					}
				}}
			>
				<DialogTitle id="record-dialog-title" className={classes.dialogTitle}>
					{capitalizeFirstLetter(currentEvent?.title)}
					<IconButton onClick={close}>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				<DialogContent >
					<DialogContentText id="alert-dialog-description">
						<DoformsPortal
							iframeSrc={iframeSrc}
							onClose={close}
							onLoaded={() => { }}
							refreshData={() => { }}
						/>
					</DialogContentText>
				</DialogContent >

				<DialogActions>
					<Button onClick={close} className={classes.icon}>
						{t('common:misc.cancel')}
					</Button>
				</DialogActions>

			</Dialog >
		)
	}

	return (
		<IconThemeProvider values={iconTheme}>
			<TileWrapper
				title={tile?.i}
				onSettingClick={handleOpenDialog}
				ref={tileRef}
				isExpandDialogBtn
			>
				<DispatchSchedulerSettingsDialog
					tileElementWidth={tileRef?.current?.clientWidth}
					defaultTileWidth={tileWidth}
					tile={tile}
					settings={settings}
					isSubmitting={updateTileMutation.isLoading}
					open={settingsOpen}
					onClose={handleCloseDialog}
					onSubmit={handleSubmitSetting}
					onResizeTileWidth={handleResizeTileWidth}
					dashboardKeyList={dashboardKeyList}
				/>

				<Box
					ref={schedulerBoxRef}
					sx={{
						position: 'absolute',
						left: 0,
						right: 0,
						top: 30,
						bottom: 0,
						overflowY: 'auto',
						height: '95%',
						background: '#fff',

						'& .MuiDataGrid-selectedRowCount': {
							opacity: '0 !important',
						},
					}}
				>
					{environment?.isLoadingProjects ? (
						<LoadingSpinner />
					) : (
						<div>
							<ToastAlert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />
							<Scheduler
								key={schedulerKey}
								ref={schedulerRef}
								view={view}
								events={events}
								selectedDate={selectedDateDisplay}
								loading={isScheduleLoading}
								customEditor={CustomEditor}
								onViewChange={(view, agenda) => {
									handleViewChange(view, agenda)
								}}
								onEventAdd={handleEventAdd}
								onEventUpdate={handleEventUpdate}
								onEventDelete={handleEventDelete}
								onCellClick={handleCellClick}
								onEventDrop={(event, droppedOn, updatedEvent, originalEvent) => {
									handleEventDrop(event, droppedOn, updatedEvent, originalEvent)
								}}
								onSelectedDateChange={(date) => {
									setSelectedDateDisplay(date)
								}}
								day={{
									startHour: schedulerStartTime || 8,
									endHour: schedulerEndTime || 18,
								}}
								week={{
									startHour: schedulerStartTime || 8,
									endHour: schedulerEndTime || 18,
								}}
								editable={false}
								draggable={true}
							/>
							<DoformsSendDispatch
								onDialogOpen={handleCellClick}
								formSelected={formSelected}
								action={'send'}
								title={t('common:tabs.dispatchScheduler')}
								recordsLoading={false}
								source={'dashboard'}
								tab={'forms'}
								environment={environment}
								deviceSelected={selectedDevice}
								selectedDate={selectedDate || null}
								duration={defaultDuration}
							/>
						</div>
					)}
				</Box>
			</TileWrapper>
		</IconThemeProvider>
	)
}

export default DispatchSchedulerTile
