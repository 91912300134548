import { RefreshOutlined } from '@mui/icons-material'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { IconButton, LinearProgress, List, Stack, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { sortByPosition } from 'components/pages/dis/helpers'
import useMenuQuery from 'components/pages/dis/hooks/useMenuQuery'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'
import SkeletonLoaderSidePanel from 'custom-components/skeletons/SkeletonLoaderSidePanel'
import { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import EmptyDashboard from '../_components/EmptyDashboard'
import SearchInput from '../_components/SearchInput'
import WrapperWithHeading from '../_components/WrapperWithHeading'
import MenuItem from './_components/MenuItem'
import { useMenu } from '../contexts/MenuContext'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'

const useStyles = makeStyles(() => ({
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const MenuDashboard = () => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const location = useLocation()
	const history = useHistory()

	const [searchText, setSearchText] = useState('')
	const [isSubmitting, setIsSubmitting] = useState(false)

	const { menuList, isLoading, onRefreshMenu } = useMenuQuery()
	const { onResetMenu } = useMenu()

	const filteredMenuList = useMemo(() => {
		if (menuList?.length === 0) return []

		const filterList = menuList?.filter((menu) => {
			if (!searchText) return true
			return menu.name.toLowerCase().includes(searchText.toLowerCase())
		})

		return sortByPosition(filterList)
	}, [menuList, searchText])

	const handleChangeSearchText = (value) => {
		const term = !value ? '' : value.toLowerCase()
		setSearchText(term)
	}

	return (
		<WrapperWithHeading title={t('common:dis.menuManager')}>
			<Stack direction="row" alignItems="center" gap={1} sx={{ position: 'relative' }}>
				{isSubmitting && (
					<LinearProgress sx={{ zIndex: 1000, position: 'absolute', top: -8, left: 0, right: 0 }} />
				)}

				<SearchInput onChangeValue={handleChangeSearchText} />

				<IconButton
					aria-label="refresh"
					size="small"
					className={classes.button}
					onClick={onRefreshMenu}
				>
					<RefreshOutlined fontSize="inherit" className={classes.icon} />
				</IconButton>

				<Tooltip title={t('common:dis.createNewMenu')} placement="top" arrow>
					<Link to="/dis/menu-manager/form">
						<IconButton aria-label="add-new-menu" size="small" className={classes.button}>
							<AddCircleOutlineIcon fontSize="inherit" className={classes.icon} />
						</IconButton>
					</Link>
				</Tooltip>
			</Stack>

			<List sx={{ overflowY: 'auto', height: '95%' }}>
				{isLoading && <SkeletonLoaderSidePanel />}

				{!isLoading &&
					filteredMenuList?.length > 0 &&
					filteredMenuList.map((item) => {
						return <MenuItem key={item.key} data={item} setIsSubmitting={setIsSubmitting} />
					})}

				{!isLoading && filteredMenuList?.length === 0 && <EmptyDashboard />}
			</List>
		</WrapperWithHeading>
	)
}

export default MenuDashboard
