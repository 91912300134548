import useGetPathName from 'utils/hooks/useGetPathName'
import { InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
	Button,
	Card,
	CardHeader,
	Checkbox,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Tooltip,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { CALL_RECORDS_STORAGE } from '../constants'
import { CoreContext } from '../custom-components/context/CoreContext'
import { ACTIVITY_ACTIONS } from '../reducers/activityReducer'
import { DEVICES_ACTIONS } from '../reducers/devicesReducer'
import { ENV_ACTIONS } from '../reducers/environmentReducer'
import { FORM_ACTIONS } from '../reducers/formsReducer'
import { LOOKUPS_ACTIONS } from '../reducers/lookupsReducer'
import { VIEWS_ACTIONS } from '../reducers/viewsReducer'
import DoformsMessage from './DoformsMessage'
import LoadingSpinner from './LoadingSpinner'
import { callRecordsStorage, isNotEqualWithPosition } from '../utils/functions/helpers'

const useStyles = makeStyles(() => ({
	root: {
		margin: 'auto',
	},
	cardHeader: {
		padding: '8px 16px',
	},
	bgLoading: {
		position: 'absolute',
		width: 300,
		height: 400,
		zIndex: 1000,
	},
	list: {
		width: 300,
		height: 400,
		// backgroundColor: 'ghostwhite',
		backgroundColor: 'white',
		overflow: 'auto',
	},
	listItem: {
		paddingTop: '0px',
		paddingBottom: '0px',
		'& .MuiCheckbox-root': {
			paddingTop: 0,
			paddingBottom: 0,
		},
		'& .MuiListItemSecondaryAction-root': {
			display: 'flex',
			alignItems: 'center',
		},
	},
	button: {
		margin: '4px 0',
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
	systemFieldIcon: {
		color: '#bdbdbd',
	},
	footer: {
		display: 'flex',
		minHeight: '52px',
		alignItems: 'center',
		justifyContent: 'space-between',
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
}))

function not(a, b) {
	return a.filter((value) => b.indexOf(value) === -1)
}

function intersection(a, b) {
	return a.filter((value) => b.indexOf(value) !== -1)
}

function union(a, b) {
	return [...a, ...not(b, a)]
}

function sort(a, name) {
	return _.sortBy(a, name)
}

const TransferList = (props) => {
	const [t] = useTranslation('common')

	const { coreValues } = useContext(CoreContext)
	const { account } = coreValues

	const {
		allColumns,
		formColumns,
		loading,
		iconTheme,
		tab,
		from,
		isActivityView,
		onLoadRepeatableColumns,
		onColumnsChangedClosed,
	} = props
	const classes = useStyles(iconTheme)

	const [checked, setChecked] = useState([])
	const [left, setLeft] = useState([])
	const [right, setRight] = useState([])
	const [repeatableColumns, setRepeatableColumns] = useState(false)
	const [dataNames, setDataNames] = useState(false)
	const [defaultDataNames, setDefaultDataNames] = useState(false)
	const [error, setError] = useState(false)

	const [cookies, setCookie] = useCookies(['dataNames_' + account.code])

	const firstPathName = useGetPathName()
	const dispatch = useDispatch()

	const selectedColumns = useMemo(
		() =>
			formColumns.map((item) => ({
				...item,
			})),
		[formColumns]
	)

	const availableColumns = useMemo(() => {
		let result = allColumns.filter((e) => !right.some((sel) => sel.name === e.name))
		result = sort(result, 'title')
		return result
	}, [allColumns, right])

	useEffect(() => {
		let dataNamesChecked =
			!cookies['dataNames_' + account.code] || cookies['dataNames_' + account.code] === '0'
				? false
				: true
		setDataNames(dataNamesChecked)
		setDefaultDataNames(dataNamesChecked)

		window.onunload = function () {
			sessionStorage.removeItem(CALL_RECORDS_STORAGE)
		}
	}, [])

	useEffect(() => {
		setRight(selectedColumns)
	}, [selectedColumns])

	useEffect(() => {
		setLeft(availableColumns)
	}, [availableColumns])

	const leftChecked = intersection(checked, left)
	const rightChecked = intersection(checked, right)

	const handleToggle = (value) => () => {
		const currentIndex = checked.indexOf(value)
		const newChecked = [...checked]

		if (currentIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked.splice(currentIndex, 1)
		}

		setChecked(newChecked)
	}

	const numberOfChecked = (items) => intersection(checked, items).length

	const handleToggleAll = (items) => () => {
		if (numberOfChecked(items) === items.length) {
			setChecked(not(checked, items))
		} else {
			setChecked(union(checked, items))
		}
	}

	const handleCheckedRight = () => {
		setRight(right.concat(leftChecked))
		setLeft(not(left, leftChecked))
		setChecked(not(checked, leftChecked))
	}

	const handleCheckedLeft = () => {
		setLeft(sort(left.concat(rightChecked), 'title'))
		setRight(not(right, rightChecked))
		setChecked(not(checked, rightChecked))
	}

	const handleRepeatableColumnsChecked = (event) => {
		if (event && event.target.checked) {
			setRepeatableColumns(true)
			onLoadRepeatableColumns(true)
		} else {
			setRepeatableColumns(false)
			onLoadRepeatableColumns(false)
		}
	}

	const handleDataNamesChecked = (event) => {
		const checked = event && event.target.checked ? true : false
		setDataNames(checked)
	}

	const showTitle = (value) => {
		if (!value) return ''
		if (dataNames) {
			const name = value.name + ''
			if (name.slice(0, 1) === '@') {
				return name.slice(1)
			}
			return name
		}
		return value.title
	}

	const customList = (title, items, position) => (
		<Card>
			<CardHeader
				className={classes.cardHeader}
				avatar={
					<Checkbox
						onClick={handleToggleAll(items)}
						checked={numberOfChecked(items) === items.length && items.length !== 0}
						indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
						disabled={items.length === 0}
						inputProps={{ 'aria-label': 'all items selected' }}
					/>
				}
				title={title}
				subheader={`${numberOfChecked(items)}/${items.length} ${t(
					'common:misc.selected'
				).toLowerCase()}`}
			/>
			<Divider />
			{position === 'available' && loading && (
				<div className={classes.bgLoading}>
					<LoadingSpinner />
				</div>
			)}
			<List className={classes.list} dense component="div" role="list">
				{items.map((value, index) => {
					const labelId = `transfer-list-all-item-${index}-label`

					return (
						<ListItem
							className={classes.listItem}
							key={index}
							role="listitem"
							button
							dense={true}
							secondaryAction={
								(value.name + '').slice(0, 1) === '@' && (
									<Tooltip
										title={`${value.title} (${t('tooltip.systemProvided')})`}
										arrow
										placement="bottom-start"
										disableInteractive
									>
										<span>
											<IconButton aria-label="system-field" size="small" edge="end" disableRipple>
												<InfoOutlinedIcon fontSize="inherit" className={classes.systemFieldIcon} />
											</IconButton>
										</span>
									</Tooltip>
								)
							}
							onClick={handleToggle(value)}
						>
							<ListItemIcon>
								<Checkbox
									checked={checked.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
									inputProps={{ 'aria-labelledby': labelId }}
								/>
							</ListItemIcon>
							<ListItemText id={labelId} primary={showTitle(value)} />
						</ListItem>
					)
				})}
				<ListItem />
			</List>
		</Card>
	)

	const handleColumnsChanged = () => {
		// check right to have new items from selectedColumns
		let newColumns = right.filter((e) => !selectedColumns.some((sel) => sel.name === e.name))

		if (
			(newColumns.length === 0 && right.length < selectedColumns.length) ||
			isNotEqualWithPosition(right, selectedColumns)
		) {
			callRecordsStorage.set(firstPathName, true)
		} else {
			callRecordsStorage.set(firstPathName, false)
		}

		setCookie('dataNames_' + account.code, dataNames ? 1 : 0)
		if (right.length === 0) {
			setError(t('common:misc.errorAtLeast1Column'))
			return
		}

		if (from === 'condition-builder') {
			dispatch({
				type: ENV_ACTIONS.FORM_COLUMNS_CHANGED,
				payload: right,
			})
			onColumnsChangedClosed()
			//return;
		}

		switch (tab) {
			case 'lookups':
				dispatch({
					type: LOOKUPS_ACTIONS.FORM_COLUMNS,
					payload: right,
				})
				break
			case 'views':
				dispatch({
					type: VIEWS_ACTIONS.FORM_COLUMNS,
					payload: right,
				})
				break
			case 'devices':
				dispatch({
					type: DEVICES_ACTIONS.FORM_COLUMNS,
					payload: right,
				})
				break
			case 'activity':
				dispatch({
					type: ACTIVITY_ACTIONS.FORM_COLUMNS,
					payload: right,
				})
				break
			default: //FORMS
				dispatch({
					type: FORM_ACTIONS.FORM_COLUMNS,
					payload: right,
				})
				break
		}
		if (tab === 'forms' || tab === 'lookups') {
			onColumnsChangedClosed()
		}
	}

	const onCancel = () => {
		onColumnsChangedClosed()
	}

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={() => setError(null)} />
		)

	return (
		<div>
			<Grid container spacing={2} justifyContent="center" alignItems="center">
				<Grid item>{customList(t('common:misc.available'), left, 'available')}</Grid>
				<Grid item>
					<Grid container direction="column" alignItems="center">
						<Button
							variant="outlined"
							size="small"
							className={classes.button}
							onClick={handleCheckedRight}
							disabled={leftChecked.length === 0}
							aria-label="move selected right"
						>
							&gt;
						</Button>
						<Button
							variant="outlined"
							size="small"
							className={classes.button}
							onClick={handleCheckedLeft}
							disabled={rightChecked.length === 0}
							aria-label="move selected left"
						>
							&lt;
						</Button>
					</Grid>
				</Grid>
				<Grid item>{customList(t('common:misc.selected'), right, 'selected')}</Grid>
			</Grid>
			<div className={classes.footer}>
				<div style={{ display: 'flex' }}>
					<List dense component="div" role="list">
						{!isActivityView && (
							<ListItem className={classes.listItem} role="listitem" dense={true}>
								<ListItemIcon>
									<Checkbox checked={repeatableColumns} onChange={handleRepeatableColumnsChecked} />
								</ListItemIcon>
								<ListItemText primary={t('common:chart.includeRepeatedFields')} />
							</ListItem>
						)}
						<ListItem className={classes.listItem} role="listitem" dense={true}>
							<ListItemIcon>
								<Checkbox checked={dataNames} onChange={handleDataNamesChecked} />
							</ListItemIcon>
							<ListItemText primary={t('common:chart.showDataNames')} />
						</ListItem>
					</List>
				</div>
				<div style={{ display: 'flex' }}>
					<LoadingButton
						id="apply-columns-changed"
						className={classes.icon}
						disabled={_.isEqual(selectedColumns, right) && _.isEqual(defaultDataNames, dataNames)}
						onClick={handleColumnsChanged}
					>
						{t('common:misc.ok')}
					</LoadingButton>
					<Button id="apply-columns-closed" className={classes.icon} onClick={onCancel}>
						{t('common:misc.cancel')}
					</Button>
				</div>
			</div>
			{showErrorMessage()}
		</div>
	)
}

export default TransferList
