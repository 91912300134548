import { ContentCopy, Delete } from '@mui/icons-material'
import {
	Box,
	Button,
	IconButton,
	ListItemButton,
	ListItemText,
	Popover,
	Stack,
	TextField,
	Tooltip,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useQueryClient } from '@tanstack/react-query'
import dashboardApi from 'apis/disApi/dashboardApi'
import { createItemsOfSubmenu, processCreateTilesOfNewMenu } from 'components/pages/dis/helpers'
import useGroupQuery from 'components/pages/dis/hooks/useGroupQuery'
import useMenuQuery, { menuKeys } from 'components/pages/dis/hooks/useMenuQuery'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useSearchParams } from 'utils/hooks/useSearchParams'
import { logErrorMessage } from '../../../../../utils/functions/helpers'
import { useMenu } from '../../contexts/MenuContext'
import ButtonWithConfirm from '../../menu-items/_components/ButtonWithConfirm'

const useStyles = makeStyles(() => ({
	menuNavLink: {
		color: 'inherit',

		'&:hover': {
			color: 'inherit',
			textDecoration: 'none',
		},
	},
}))

const MenuItem = ({ data, setIsSubmitting }) => {
	const [t] = useTranslation('common')
	const location = useLocation()
	const history = useHistory()

	const { menu: menuKeyInUrl = '' } = useSearchParams()
	const { environment } = useSelector((state) => state)
	const classes = useStyles()

	const queryClient = useQueryClient()

	const { addMutation, removeMutation } = useMenuQuery()
	const { onResetMenu } = useMenu()
	const { groups, assignToMenuMutation } = useGroupQuery()

	const [copyEl, setCopyEl] = useState(null)

	const handleCopyClick = (event) => {
		event.preventDefault()
		setCopyEl(copyEl ? null : event.currentTarget)
	}

	const handleCopyCancel = (event) => {
		setCopyEl(null)
	}

	const copyOpen = Boolean(copyEl)
	const copyId = copyOpen ? 'simple-copy-popper' : undefined

	const handleCreateNewMenu = async ({ newName, copiedMenu }) => {
		handleCopyCancel()
		try {
			if (!newName) return
			const { settings } = copiedMenu

			setIsSubmitting(true)

			let childrenMenuItems = []

			if (copiedMenu?.items?.length > 0) {
				for (const menuItem of copiedMenu.items) {
					const newDashboard = {
						name: menuItem.name,
					}

					const dashboardResponse = await dashboardApi.add(newDashboard, environment.apiToken)

					// Create Tiles of new menu
					await processCreateTilesOfNewMenu({
						copiedMenu: menuItem,
						dashboardResponse,
						token: environment.apiToken,
					})

					const itemsOfSubmenu = await createItemsOfSubmenu(menuItem, environment.apiToken)

					const newMenuItem = {
						name: menuItem.name,
						position: menuItem.position,
						type: menuItem.type,
						dashboard: {
							key: dashboardResponse.data.key,
						},
						items: itemsOfSubmenu,
					}

					childrenMenuItems = [...childrenMenuItems, newMenuItem]
				}
			}

			const newMenu = {
				name: newName,
				settings: JSON.stringify(settings),
				items: childrenMenuItems,
			}

			await addMutation.mutateAsync({
				data: newMenu,
				token: environment.apiToken,
			})

			toast.success(t('common:dis.addTabSuccess'))
		} catch (error) {
			logErrorMessage(error)
		}
		setIsSubmitting(false)
	}

	const handleCancelMenu = () => {
		const searchParams = new URLSearchParams(location.search)

		searchParams.delete('menu')
		history.push({
			pathname: location.pathname.replace('/form', ''),
			search: searchParams.toString(),
		})

		requestIdleCallback(() => {
			onResetMenu()
		})
	}

	const handleDeleteMenu = async (menuKey) => {
		try {
			const groupKeyByMenuId = groups.find((group) => group.menuKey === menuKey)

			setIsSubmitting(true)

			// If have already assigned to other menu, remove it
			if (groupKeyByMenuId) {
				await assignToMenuMutation.mutateAsync({
					groupKey: groupKeyByMenuId.key,
					data: {
						menuKey: null,
					},
					token: environment.apiToken,
				})
			}

			await removeMutation.mutate({
				id: menuKey,
				token: environment.apiToken,
			})
			queryClient.removeQueries(menuKeys.detail(menuKey))

			toast.success(t('common:dis.removeTabSuccess'))

			handleCancelMenu()
		} catch (error) {
			logErrorMessage(error)
		}
		setIsSubmitting(false)
	}

	return (
		<NavLink className={classes.menuNavLink} to={`/dis/menu-manager/form?menu=${data?.key}`}>
			<ListItemButton
				key={data?.key}
				selected={data?.key === menuKeyInUrl}
				sx={{
					'&:hover': {
						textDecoration: 'underline',
						backgroundColor: 'transparent',
					},
				}}
			>
				<ListItemText primary={data.name} />
				<Tooltip title={t('common:dis.copyMenu')} placement="top" arrow>
					<IconButton aria-label="copy" size="small" onClick={(e) => handleCopyClick(e)}>
						<ContentCopy fontSize="small" />
					</IconButton>
				</Tooltip>

				<ButtonWithConfirm
					title={t('common:dis.confirmDeleteItem')}
					onSubmit={(e) => handleDeleteMenu(data?.key)}
				>
					<Tooltip title={t('common:dis.deleteMenu')} placement="top" arrow>
						<IconButton aria-label="copy" size="small">
							<Delete fontSize="small" />
						</IconButton>
					</Tooltip>
				</ButtonWithConfirm>
				<Popover
					id={copyId}
					open={copyOpen}
					anchorEl={copyEl}
					sx={{ zIndex: 2 }}
					onClick={(e) => e.stopPropagation()}
				>
					<CopyPopup menu={data} onSubmit={handleCreateNewMenu} onCancel={handleCopyCancel} />
				</Popover>
			</ListItemButton>
		</NavLink>
	)
}

function CopyPopup({ menu, onSubmit, onCancel }) {
	const [newMenuName, setNewMenuName] = useState('')

	return (
		<Box
			sx={{
				minWidth: 350,
				border: 1,
				py: 2,
				px: 4,
				bgcolor: 'background.paper',
			}}
		>
			<TextField
				inputRef={(input) => input && input.focus()}
				value={newMenuName}
				onChange={(e) => setNewMenuName(e.target.value)}
				fullWidth
				id="new-menu-basic"
				label="New menu name"
				variant="standard"
			/>
			<Stack spacing={2} direction="row" justifyContent="flex-end" mt={2}>
				<Button
					size="small"
					variant="contained"
					onClick={() => onSubmit({ newName: newMenuName, copiedMenu: menu })}
				>
					OK
				</Button>
				<Button size="small" variant="text" onClick={onCancel}>
					Cancel
				</Button>
			</Stack>
		</Box>
	)
}

export default MenuItem
