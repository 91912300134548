import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, TextField } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'utils/hooks/useDebounce'

/**
 * Renders a search input component with a refresh button.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onChangeValue - The function to be called when the search input value changes.
 * @return {JSX.Element} The rendered search input component.
 */
const SearchInput = ({ onChangeValue }) => {
	const [t] = useTranslation('common')

	const [searchInput, setSearchInput] = useState('')
	const debounceSearchInput = useDebounce(searchInput, 500)

	const inputRef = useRef()

	useEffect(() => {
		onChangeValue(debounceSearchInput)
	}, [debounceSearchInput])

	const onCleared = (e) => {
		e.preventDefault()
		inputRef.current.value = ''
		setSearchInput('')
	}

	const handleChangeInput = (e) => {
		setSearchInput(e.target.value)
	}

	return (
		<TextField
			id="search-input"
			ref={inputRef}
			InputLabelProps={{ shrink: false }}
			placeholder={t('common:misc.search')}
			size="small"
			fullWidth
			value={searchInput}
			onChange={handleChangeInput}
			InputProps={{
				endAdornment: debounceSearchInput ? (
					<IconButton aria-label="clear" size="small" edge="end" onClick={onCleared}>
						<ClearIcon fontSize="inherit" />
					</IconButton>
				) : (
					<IconButton aria-label="clear" size="small" edge="end" disabled>
						<SearchIcon fontSize="inherit" />
					</IconButton>
				),
			}}
		/>
	)
}

export default SearchInput
