import { Box, Fade, Grow, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import SliderSingleImage from 'components/pages/dis-v2/_components/SliderSingleImage'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PhotoAlbum from 'react-photo-album'
import { useSelector } from 'react-redux'
import Lightbox from 'yet-another-react-lightbox'
import Captions from 'yet-another-react-lightbox/dist/plugins/captions'
import Fullscreen from 'yet-another-react-lightbox/dist/plugins/fullscreen'
import Slideshow from 'yet-another-react-lightbox/dist/plugins/slideshow'
import Zoom from 'yet-another-react-lightbox/dist/plugins/zoom'

const useStyles = makeStyles(() => ({
	imageCard: {
		position: 'relative',
		'&:hover': {
			'& .imageCaption': {
				display: 'block',
				position: 'absolute',
				backgroundColor: '#eee',
				borderRadius: '2px',
				left: '3px',
				bottom: '5px',
				padding: '3px',
				fontSize: '12px',
				lineHeight: '12px',
				maxWidth: '90%',
				opacity: 0.8,
			},
		},
		'& .imageCaption': {
			display: 'none',
		},
	},
}))

const ImageScreen = ({ photos, type, isLocation }) => {
	const { t } = useTranslation('common')
	const { environment } = useSelector((state) => state)
	const iconTheme = environment.theme.icons
	const classes = useStyles(iconTheme)

	const [index, setIndex] = React.useState(-1)
	const [hovering, setHovering] = React.useState(false)

	const imagesSrc = React.useMemo(() => photos.map((image) => image.src), [photos])

	const slides =
		photos?.length > 0 &&
		photos?.map(({ src, width, height, alt, description }) => ({
			src,
			width,
			height,
			alt,
			description,
		}))

	const handlePopoverOpen = React.useCallback(
		(newItemName) => (event) => {
			event.preventDefault()
			setHovering(newItemName)
		},
		[]
	)

	const handlePopoverClose = (event) => {
		event.preventDefault()
		setHovering(false)
	}

	const renderRowContainer = ({ rowContainerProps, children }) => {
		const newRowContainerProps = { ...rowContainerProps, style: { ...rowContainerProps.style } }
		return <div {...newRowContainerProps}>{children}</div>
	}

	const renderCustomPhoto = ({ imageProps, photo, layout, layoutOptions }) => {
		const { src, alt, srcSet, sizes, style, ...restImageProps } = imageProps
		const { description } = photo
		const { photoIndex } = layout
		const { spacing } = layoutOptions
		return (
			<div
				className={classes.imageCard}
				style={{ ...style, maxHeight: '200px', position: 'relative' }}
				onMouseOver={handlePopoverOpen(`${photo.key}`)}
				onMouseLeave={handlePopoverClose}
			>
				<Grow in={true} style={{ transformOrigin: '0 0 0' }} timeout={300 * (photoIndex + 1)}>
					<img
						src={src}
						alt={alt}
						style={{ width: 'auto', height: '100%' }}
						{...(srcSet ? { srcSet, sizes } : null)}
						{...restImageProps}
					/>
				</Grow>
				{hovering === photo.key && (
					<Fade in={!!hovering}>
						<div className={'imageCaption'}>
							<span>{description}</span>
						</div>
					</Fade>
				)}
			</div>
		)
	}

	return (
		<Box>
			{photos?.length === 0 ? (
				<Typography>{isLocation ? t('misc.noLocationFound') : t('misc.noImageFound')}.</Typography>
			) : (
				<>
					{(type === 'gallery' || !type) && (
						<PhotoAlbum
							photos={photos}
							renderRowContainer={renderRowContainer}
							renderPhoto={renderCustomPhoto}
							layout="rows"
							targetRowHeight={200}
							onClick={(event, photo, index) => setIndex(index)}
						/>
					)}
					{type === 'slide' && (
						<SliderSingleImage imagesSrc={imagesSrc} onShowLightBox={(index) => setIndex(index)} />
					)}
					<Lightbox
						slides={slides}
						open={index >= 0}
						index={index}
						close={() => setIndex(-1)}
						carousel={{ finite: false }}
						styles={{
							root: {
								'--yarl__color_backdrop': 'rgba(0, 0, 0, .9)',
								'--yarl__slide_description_text_align': 'center',
							},
						}}
						plugins={[Captions, Fullscreen, Slideshow, Zoom]}
					/>
				</>
			)}
		</Box>
	)
}

export default ImageScreen
