import { usePrevious } from 'utils/hooks/usePrevious'
import { Refresh as RefreshIcon } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { off, onValue, ref } from 'firebase/database'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
	ACTIVITY_VIEW_FIREBASE_PATH,
	ACTIVITY_VIEW_TYPE,
	DEVICE_ACTIVITY_FIREBASE_PATH,
	DEVICE_ACTIVITY_TYPE,
} from '../../../constants'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import database from '../../../firebase-config'

const useStyles = makeStyles(() => ({
	container: {
		position: 'relative',
	},
	containerHidden: {
		position: 'absolute',
	},
	headingContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	updateAlert: {
		position: 'absolute',
		right: 0,
		top: 0,
		display: 'flex',
		alignItems: 'center',
		padding: '0px 12px 10px',
		backgroundColor: '#1a6891',
		color: '#fff',
		textAlign: 'center',
		cursor: 'pointer',
	},
	updateAlertHidden: {
		position: 'absolute',
		right: 16,
		top: 8,
		display: 'flex',
		alignItems: 'center',
		padding: '0px 12px 10px',
		backgroundColor: '#1a6891',
		color: '#fff',
		textAlign: 'center',
		cursor: 'pointer',
	},
}))

const UpdateAlert = (props) => {
	const {
		children,
		isFormDeleted,
		isRefresh,
		formSelected,
		viewData,
		queryView,
		deviceSelected,
		tab,
		viewDataType,
		onHandlePresetReloadAll,
		onHandleReLoadFirstPage,
	} = props
	const { environment } = useSelector((state) => state)

	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const [fbLastUpdated, setFBLastUpdated] = useState('')
	const [updateAlert, setUpdateAlert] = useState(false)
	const [starCountRef, setStarCountRef] = useState(null)
	const [isAutoUpdateRefresh, setIsAutoUpdateRefresh] = useState(false)

	const isMounted = useRef(true)
	const updateAlertRef = useRef(false)
	const triggerGetFbLastUpdatedRef = useRef(false)
	const fbOriginalValueRef = useRef('')

	const prevAutoRefreshRef = usePrevious(environment.userCurrent.autoRefresh)
	const autoRefreshRef = useRef(environment.userCurrent.autoRefresh)

	const isHidden = tab === 'activity'
	const filterPreset = queryView?.queries?.[0]?.filter?.conditions?.[0]?.preset ?? ''
	const hasMoreRecords = useMemo(() => queryView.more, [queryView])

	const showUpdateAlert =
		updateAlert && (hasMoreRecords || environment.userCurrent.autoRefresh === 'notify')

	useEffect(() => {
		autoRefreshRef.current = environment.userCurrent.autoRefresh
		triggerGetFbLastUpdatedRef.current = false
		if (
			(prevAutoRefreshRef.current.prev === 'off' &&
				(autoRefreshRef.current === 'notify' || autoRefreshRef.current === 'on')) ||
			(prevAutoRefreshRef.current.prev === 'off' && autoRefreshRef.current === 'on')
		) {
			fbOriginalValueRef.current = fbLastUpdated
		}
	}, [environment.userCurrent.autoRefresh])

	useEffect(() => {
		isMounted.current = true
		return () => {
			isMounted.current = false
			setUpdateAlert(false)
			updateAlertRef.current = false
		}
	}, [])

	useEffect(() => {
		if (isRefresh) {
			setUpdateAlert(false)
			triggerGetFbLastUpdatedRef.current = false
			updateAlertRef.current = false
			prevAutoRefreshRef.current.prev = autoRefreshRef.current
		}
	}, [isRefresh])

	useEffect(() => {
		setUpdateAlert(false)
		updateAlertRef.current = false
	}, [viewDataType, filterPreset])

	useEffect(() => {
		if (starCountRef == null) return
		onValue(starCountRef, (snapshot) => {
			snapshot.forEach((childSnap) => {
				var lastUpdated = childSnap.val()
				if (fbLastUpdated === lastUpdated) return
				let hiddenImportOpening = document.getElementById('hiddenImportOpening')
				if (!hiddenImportOpening || hiddenImportOpening.value !== 'true') {
					if (!isMounted.current) return
					if (updateAlertRef.current) {
						// do nothing
					} else {
						if (
							(prevAutoRefreshRef.current.prev === 'off' &&
								(autoRefreshRef.current === 'notify' || autoRefreshRef.current === 'on')) ||
							(prevAutoRefreshRef.current.prev === 'off' && autoRefreshRef.current === 'on')
						) {
							handleFbLastUpdatedChanged(lastUpdated)
						} else {
							if (!triggerGetFbLastUpdatedRef.current || autoRefreshRef.current === 'on') {
								setFBLastUpdated(lastUpdated)
							} else {
								triggerGetFbLastUpdatedRef.current = true
							}
						}
					}
				}
			})
		})
	}, [starCountRef])

	useEffect(() => {
		if (starCountRef != null) {
			off(starCountRef)
		}
		if (tab !== 'forms') return
		setStarCountRef(
			ref(database, 'formSessions/' + formSelected.projectKey + '/' + formSelected.key)
		)
	}, [formSelected])

	useEffect(() => {
		if (starCountRef != null) {
			off(starCountRef)
		}
		if (viewData.type === ACTIVITY_VIEW_TYPE) {
			const customer_key = viewData.ownerKey
			setStarCountRef(ref(database, `${ACTIVITY_VIEW_FIREBASE_PATH}${customer_key}`))
		}
		if (viewData.type === DEVICE_ACTIVITY_TYPE) {
			const mobile_key = deviceSelected?.key
			setStarCountRef(ref(database, `${DEVICE_ACTIVITY_FIREBASE_PATH}${mobile_key}`))
		}
	}, [viewData, formSelected])

	const handleFbLastUpdatedChanged = (fbLastUpdated) => {
		if (fbLastUpdated === '') return
		if (fbOriginalValueRef.current !== '') {
			triggerGetFbLastUpdatedRef.current = true
			switch (autoRefreshRef.current) {
				case 'on':
					if (tab === 'forms') {
						setIsAutoUpdateRefresh(false)
					} else {
						setIsAutoUpdateRefresh(true)
					}
					if (environment.disableAutoRefresh) return
					onHandledUpdateRecords()
					break
				case 'notify':
					if (environment.disableAutoRefresh) return
					onHandledUpdateRecords()
					break
				case 'off':
					setUpdateAlert(false)
					updateAlertRef.current = false
					break
				default:
					setUpdateAlert(true)
					break
			}
		}
		fbOriginalValueRef.current = fbLastUpdated
	}

	useEffect(() => {
		// when deleted record, not run this useEffect
		if (isFormDeleted) return
		handleFbLastUpdatedChanged(fbLastUpdated)
	}, [fbLastUpdated])

	useEffect(() => {
		if (
			!queryView ||
			!queryView.queries ||
			!queryView.queries[0] ||
			!queryView.queries[0].filter ||
			!queryView.queries[0].filter.conditions ||
			!queryView.queries[0].filter.conditions[0]
		) {
			return
		}
		let firstCondition = queryView.queries[0].filter.conditions[0]
		switch (firstCondition.preset) {
			case 'ALL':
				if (tab !== 'forms') {
					setIsAutoUpdateRefresh(true)
					return
				}
				if (!hasMoreRecords) {
					setIsAutoUpdateRefresh(true)
					return
				}
				if (isAutoUpdateRefresh) {
					setIsAutoUpdateRefresh(false)
				}
				break
			default:
				if (tab === 'forms' && hasMoreRecords) {
					setIsAutoUpdateRefresh(false)
					return
				}
				if (!isAutoUpdateRefresh) {
					setIsAutoUpdateRefresh(true)
				}
		}
	}, [queryView])

	const onHandledUpdateRecords = () => {
		if (autoRefreshRef.current === 'on') {
			if (isAutoUpdateRefresh || !hasMoreRecords) {
				onHandleReLoadFirstPage?.(viewData, fbLastUpdated)
				prevAutoRefreshRef.current.prev = autoRefreshRef.current
			} else {
				if (!updateAlertRef.current) {
					setUpdateAlert(true)
					updateAlertRef.current = true
				}
			}
		} else if (autoRefreshRef.current === 'notify') {
			if (!updateAlertRef.current) {
				setUpdateAlert(true)
				updateAlertRef.current = true
			}
		}
	}

	const handleUpdateRecordsClick = () => {
		setUpdateAlert(false)
		triggerGetFbLastUpdatedRef.current = false
		updateAlertRef.current = false
		prevAutoRefreshRef.current.prev = autoRefreshRef.current
		if (tab !== 'forms') {
			onHandleReLoadFirstPage?.(viewData, fbLastUpdated)
			return
		}
		if (isAutoUpdateRefresh) {
			onHandleReLoadFirstPage?.(viewData, fbLastUpdated)
		} else {
			onHandlePresetReloadAll()
		}
	}

	return (
		<Grid container spacing={2} className={isHidden ? classes.containerHidden : classes.container}>
			<Grid item xs={updateAlert ? 7 : 12} className={classes.headingContainer}>
				{children}
			</Grid>
			{showUpdateAlert && (
				<Grid
					item
					xs={5}
					className={isHidden ? classes.updateAlertHidden : classes.updateAlert}
					onClick={handleUpdateRecordsClick}
				>
					<RefreshIcon />{' '}
					{isAutoUpdateRefresh
						? t('common:dialog.updatesAvailablePleaseClickToRefresh')
						: t('common:dialog.updatesAvailablePleaseClickLoadAllRecordsToRefresh')}
				</Grid>
			)}
		</Grid>
	)
}

export default UpdateAlert
