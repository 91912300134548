import { Box, Checkbox, FormControlLabel, Skeleton } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { useQuery } from '@tanstack/react-query'
import tileApi from 'apis/disApi/tileApi'
import _, { isEmpty, isEqual, uniqBy } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { logErrorMessage } from 'utils/functions/helpers'
import useForceRerender from 'utils/hooks/useForceRerender'
import { parsedDataGridRecords, getViewClientFilters, getSavedFilterList } from '../../../../data/dataHelpers'
import {
	cancelLoadNextRecordsAction,
	getViewTabViewRecords,
	loadNextRecordsQuery,
	loadRecordsQuery,
} from '../../../../data/dataServices'
import DoformsDataChart from '../../../../data/datagrid/DoformsDataChart'
import useFields from '../../hooks/useFields'
import useView from '../../hooks/useView'
import DashboardList from '../DashboardKeyList'
import ViewsData from 'components/pages/views/ViewsData'
import OtherOptions from 'components/pages/dis-v2/_components/OtherOptions'
import { useRecordQuery } from '../utils/tileRecordQuery'

const ChartForm = ({
	dashboardKey,
	tileKey, // Optional
	linkedFields,
	viewKeySetting,
	filterData,
	onFilterDataChange,
	enableLoadView,
	dashboardKeyList,
	outlinedInput,
	setIsChartLoading, // Optional
	masterDateTimeConditions,
	dispatchChartData,
	setChartSetting: setDefaultChartSetting,
}) => {
	const { environment } = useSelector((state) => state)

	const { t } = useTranslation('common')
	const { viewLoading, views } = useView({ config: { enabled: enableLoadView } })
	const [forceId, setForceRerender] = useForceRerender()

	const [currentRecords, setCurrentRecords] = useState(null)

	const [conditionFilters, setConditionFilters] = useState({})

	const OTHER_OPTIONS = [
		//{ label: t('common:chart.showSubmit'), value: 'showSubmit' },
		{
			label: t('common:chart.showResizeSlider'),
			value: 'showResizeSlider',
		},
	]

	const { viewKey } = filterData

	const { fields } = useFields({
		viewKey,
	})

	const [chartSetting, setChartSetting] = useState({})

	const { data, isLoading, isFetching } = useQuery(
		['chart-tile', viewKey, tileKey],
		async () => {
			// const [tileResponse, viewResponse] = await Promise.all([
			// 	tileApi.get(dashboardKey, tileKey, environment.apiToken),

			// ])

			const viewResponse = await getViewTabViewRecords({
				viewKey: filterData?.viewKey,
				viewSession: false,
				token: environment.apiToken,
			})

			// let chart

			// if (tileResponse?.data?.chart) {
			// 	chart = tileResponse?.data?.chart
			// } else if (viewResponse?.data?.chart) {
			// 	chart = viewResponse?.data?.chart
			// }

			const conditions = viewResponse.data.queries[0]?.filter?.conditions ?? []
			const clientFilter = viewResponse?.data?.clientFilter ? JSON.parse(viewResponse?.data?.clientFilter) : []

			const savedFilterList = getSavedFilterList(clientFilter)
			const currentFiltersWithUserFilter = getViewClientFilters(
				savedFilterList,
				environment?.userCurrent?.dataGroupKey
			)

			const viewFilter = {
				items: currentFiltersWithUserFilter?.items
					? currentFiltersWithUserFilter.items
					: currentFiltersWithUserFilter,
				conditions,
			}

			return {
				viewChart: viewResponse?.data?.chart || {},
				columns: viewResponse?.data?.columns || [],
				viewFilter
			}
		},
		{
			enabled: Boolean(viewKey),
			staleTime: Infinity,
		}
	)

	const masterDateTimeOperator = useMemo(() => {
		if (!isEmpty(masterDateTimeConditions)) {
			const allConditions = []
			for (const key in masterDateTimeConditions) {
				const value = masterDateTimeConditions[key]
				const condition = {
					...value,
					target: key,
					join: 'AND',
				}
				allConditions.push(condition)
			}
			return allConditions
		}
		return []
	}, [masterDateTimeConditions])

	useEffect(() => {
		if (
			filterData?.otherOptionsChecked === undefined ||
			filterData?.otherOptionsChecked?.includes('isNewShowResizeSlider') === false
		) {
			onFilterDataChange('otherOptionsChecked', ['showResizeSlider', 'isNewShowResizeSlider'])
		}
	}, [filterData])

	// get view records by view filter
	useEffect(() => {
		if (isLoading || isFetching || isEmpty(data)) return

		setConditionFilters(data.viewFilter)
		// only init viewChart when tileChart is empty
		if (isEmpty(filterData?.chart)) {
			// set default setting for Chart TileTile
			if (setDefaultChartSetting) {
				setDefaultChartSetting(data?.viewChart)
			}

			// set default setting for filter data
			setChartSetting(data?.viewChart)
		} else {
			setChartSetting(filterData?.chart)
		}
	}, [data, isLoading, isFetching, filterData?.chart])

	const { records, recordsLoading } = useRecordQuery({
		viewKey: filterData?.viewKey,
		clientFilter: conditionFilters,
		enabled: !isEmpty(chartSetting)
	})

	useEffect(() => {
		// should fetch record
		setIsChartLoading?.(recordsLoading)
		if (recordsLoading) return

		if (isEqual(records, currentRecords)) return
		setCurrentRecords(records)
	}, [records, recordsLoading])

	// get view records by masterDateTimeOperator
	useEffect(() => {
		; (async () => {
			if (isEmpty(masterDateTimeOperator) || isEmpty(conditionFilters)) return

			setIsChartLoading?.(true)
			const clonedFilter = _.cloneDeep(conditionFilters)
			const checkedConditions = clonedFilter.conditions.map((condition, index) => {
				const keyToCheck = index === 0 ? 'Master_DateTime' : index === 1 ? 'Master_Text' : ''
				if (!keyToCheck) return condition

				const newCondition = masterDateTimeOperator.find(
					(operator) => operator.keyData === keyToCheck
				)
				if (!newCondition) return condition

				return newCondition
			})

			clonedFilter.conditions = checkedConditions
			const shouldNotUpdate = _.isEqual(clonedFilter, conditionFilters)
			if (shouldNotUpdate) {
				setIsChartLoading?.(false)
				return
			}

			setConditionFilters(clonedFilter)
			setIsChartLoading?.(false)
		})()
	}, [masterDateTimeOperator, data?.viewFilter])

	const gridRows = useMemo(() => {
		if (!currentRecords?.length || !data?.columns?.length) return []

		return parsedDataGridRecords(currentRecords, data?.columns, environment, [])
	}, [currentRecords, JSON.stringify(data?.columns)])

	const addChartLoading = isLoading && isFetching
	useEffect(() => {
		if (isEqual(filterData?.viewKey, viewKeySetting)) return
		if (viewKeySetting) {
			onFilterDataChange('viewKey', viewKeySetting)
		}
	}, [viewKeySetting])

	useEffect(() => {
		if (_.isEmpty(linkedFields)) return
		onFilterDataChange('linked', linkedFields)
	}, [linkedFields])

	useEffect(() => {
		if (!dispatchChartData) return
		dispatchChartData({
			type: 'columns',
			payload: data?.columns,
		})
	}, [JSON.stringify(data?.columns)])

	useEffect(() => {
		if (!dispatchChartData) return
		dispatchChartData({
			type: 'gridRows',
			payload: gridRows,
		})
	}, [JSON.stringify(gridRows)])

	useEffect(() => {
		if (isEmpty(chartSetting)) return

		onFilterDataChange('chart', chartSetting)
	}, [JSON.stringify(chartSetting)])

	const handleSelectViewKey = (newViewKey) => {
		const selectedView = views.find((view) => view.key === newViewKey)

		onFilterDataChange('viewKey', newViewKey)

		if (selectedView) {
			onFilterDataChange('formKey', selectedView?.formKey)
			onFilterDataChange('projectKey', selectedView?.projectKey)
		}
	}

	const handleLinkedChange = (id, value) => {
		if (value === '') {
			// Remove the field from linked
			const newLinkedFields = { ...filterData?.linked }
			delete newLinkedFields[id]
			onFilterDataChange('linked', newLinkedFields)
		} else {
			const newLinked = { ...filterData?.linked, [id]: value }
			onFilterDataChange('linked', newLinked)
		}
	}

	const formattedOtherOptions = OTHER_OPTIONS.map((item) => {
		if (item.value !== 'showResizeSlider') {
			return { ...item, hide: false }
		}
		return item
	})

	const handleChangeValue = (value) => {
		onFilterDataChange('otherOptionsChecked', value)
	}
	return (
		<>
			{!viewLoading ? (
				<FormControl
					variant={outlinedInput ? 'outlined' : 'standard'}
					sx={{ m: outlinedInput ? 0 : 1, minWidth: outlinedInput ? '50%' : 120 }}
					size="small"
				>
					<InputLabel id="view-select-small-label">{t('common:input.view')}</InputLabel>
					<Select
						labelId="view-select-small-label"
						id="view-select-small"
						value={viewKey || ''}
						label={t('common:input.view')}
						onChange={(e) => handleSelectViewKey(e.target.value)}
					>
						<MenuItem value="">
							<em>None</em>
						</MenuItem>
						{views?.length > 0 &&
							views
								.sort((a, b) => a.name.localeCompare(b.name))
								.map((viewItem) => (
									<MenuItem value={viewItem.key} key={viewItem.key}>
										{viewItem.name}
									</MenuItem>
								))}
					</Select>
				</FormControl>
			) : (
				<Skeleton
					variant="rectangular"
					height={45}
					sx={{ mt: outlinedInput ? 0 : 2, width: outlinedInput ? '40%' : 120 }}
				/>
			)}

			{viewKey && !_.isEmpty(data) && (
				<Box sx={{ py: 2 }}>
					<DoformsDataChart
						hiddenDisplayChartBtn
						isAddChart={!chartSetting?.display}
						showOnlyChart={false}
						columns={data?.columns || []}
						gridRows={gridRows || []}
						chartSetting={chartSetting || {}}
						setChartSetting={(newChartData) => {
							setChartSetting(newChartData)
						}}
						recordsLoading={false}
						environment={environment}
						onForceUpdate={setForceRerender}
						showResizeSlider={
							filterData?.otherOptionsChecked?.includes('showResizeSlider') ? true : false
						}
					/>
				</Box>
			)}

			{addChartLoading && <Skeleton height={40} width={105} sx={{ my: 2 }} />}

			<Box>
				<DashboardList
					title={t('common:dis.dashboardKeys')}
					list={dashboardKeyList}
					fields={fields}
					onFieldsChange={handleLinkedChange}
					linkedFields={filterData?.linked || {}}
					getLabelOption={(item) => item.name}
				/>
			</Box>

			<Box spacing={2} sx={{ width: '100% !important' }}>
				<OtherOptions
					optionList={formattedOtherOptions}
					checkedOptions={filterData?.otherOptionsChecked}
					onChangeOptions={handleChangeValue}
				// onChangeOptions={(value) => {
				// 	let a = tilePositionList
				// 	onFilterDataChange('otherOptionsChecked', value)
				// }}
				/>
			</Box>
		</>
	)
}

export default ChartForm
