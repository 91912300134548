import { DeleteForever as DeleteForeverIcon } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { IconButton, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import DoformsDialog from '../../../custom-components/DoformsDialog'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import { VIEWS_ACTIONS } from '../../../reducers/viewsReducer'
import { deleteView } from '../dataServices'

const useStyles = makeStyles(() => ({
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const DoformsDataViewDelete = (props) => {
	const [t] = useTranslation('common')
	const { environment, view, recordsLoading, source } = props
	const { iconTheme } = useContext(IconThemeContext)
	const { apiToken } = environment
	const classes = useStyles(iconTheme)
	const history = useHistory()
	const { viewsModule } = useSelector((state) => state)

	const [open, setOpen] = useState(false)
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(false)

	const dispatch = useDispatch()

	const dialog = useMemo(() => {
		return {
			title: t('common:misc.viewDelete'),
			message: view?.name
				? t('common:misc.areYouSureYouWantToDelete') + ` ${view.name} ?`
				: t('common:misc.areYouSureYouWantToDeleteThisView'),
		}
	}, [view])

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={() => setError(null)} />
		)

	const handleClick = (e) => {
		e.preventDefault()
		setOpen(true)
	}

	const handleViewDelete = () => {
		setLoading(true)
		let hasError = false
		deleteView(view.key, apiToken)
			.then((res) => {
				dispatch({
					type: VIEWS_ACTIONS.VIEW_DELETED,
					payload: view,
				})
			})
			.catch((err) => {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
				hasError = true
			})
			.finally(() => {
				setLoading(false)
				handleClose()
				if (hasError) return
				dispatch({
					type: ENV_ACTIONS.REFRESH,
					payload: true,
				})
				if (viewsModule?.viewSelected?.key === view.key) {
					dispatch({
						type: VIEWS_ACTIONS.VIEW_SELECTED,
						payload: {},
					})
				}
				// dispatch({
				//     type: VIEWS_ACTIONS.VIEW_DELETED,
				//     payload: {} })
			})
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<div>
			{showErrorMessage()}
			{source === 'grid' && (
				<Tooltip
					title={`${t('tooltip.prefix.delete')} - ${view.name}`}
					arrow
					placement="bottom-start"
					disableInteractive
				>
					<LoadingButton
						loadingPosition="start"
						className={classes.icon}
						disabled={recordsLoading}
						onClick={handleClick}
						startIcon={<DeleteForeverIcon />}
					>
						{t('common:misc.deleteView')}
					</LoadingButton>
				</Tooltip>
			)}
			{source === 'button' && (
				<Tooltip
					title={`${t('tooltip.prefix.delete')} - ${view.name}`}
					arrow
					placement="bottom-start"
					disableInteractive
				>
					<span>
						<IconButton
							aria-label="add"
							size="small"
							color="primary"
							edge="end"
							onClick={handleClick}
						>
							<DeleteForeverIcon fontSize="inherit" className={classes.icon} />
						</IconButton>
					</span>
				</Tooltip>
			)}
			<DoformsDialog
				environment={environment}
				open={open}
				dialog={dialog}
				loading={loading}
				dialogAction={true}
				onClose={handleClose}
				onConfirm={handleViewDelete}
			></DoformsDialog>
		</div>
	)
}

export default DoformsDataViewDelete
