import React, { useEffect, useMemo } from 'react'
import classNames from 'classnames'

import { Handle, Remove } from './components'

import styles from './Item.module.scss'
import { useMenu } from 'components/pages/dis-v2/contexts/MenuContext'
import { isEmpty } from 'lodash'
import { logErrorMessage } from 'utils/functions/helpers'

export const Item = React.memo(
	React.forwardRef(
		(
			{
				color,
				dragOverlay,
				dragging,
				disabled,
				fadeIn,
				handle,
				handleProps,
				height,
				index,
				listeners,
				onRemove,
				renderItem,
				sorting,
				style,
				transition,
				transform,
				value,
				wrapperStyle,
				...props
			},
			ref
		) => {
			const { detailMenu } = useMenu()

			const currentSubMenu = useMemo(() => {
				try {
					if (isEmpty(detailMenu?.items)) return null

					for (const item of detailMenu?.items) {
						const foundItem = item?.items?.find((x) => x?.dashboard?.key === value)
						if (foundItem) return foundItem
					}
				} catch (error) {
					logErrorMessage(error)
					return null
				}
			}, [detailMenu?.items, value])

			useEffect(() => {
				if (!dragOverlay) {
					return
				}

				document.body.style.cursor = 'grabbing'

				return () => {
					document.body.style.cursor = ''
				}
			}, [dragOverlay])

			return renderItem ? (
				renderItem({
					dragOverlay: Boolean(dragOverlay),
					dragging: Boolean(dragging),
					sorting: Boolean(sorting),
					index,
					fadeIn: Boolean(fadeIn),
					listeners,
					ref,
					style,
					transform,
					transition,
					value,
					wrapperStyle,
					color,
					handle,
					disabled,
					subMenu: currentSubMenu,
				})
			) : (
				<li
					className={classNames(
						styles.Wrapper,
						fadeIn && styles.fadeIn,
						sorting && styles.sorting,
						dragOverlay && styles.dragOverlay
					)}
					style={{
						...wrapperStyle,

						transition: [transition, wrapperStyle?.transition].filter(Boolean).join(', '),

						'--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,

						'--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,

						'--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,

						'--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,

						'--index': index,
						'--color': color,
					}}
					ref={ref}
				>
					<div
						className={classNames(
							styles.Item,
							dragging && styles.dragging,
							handle && styles.withHandle,
							dragOverlay && styles.dragOverlay,
							disabled && styles.disabled,
							color && styles.color
						)}
						style={style}
						data-cypress="draggable-item"
						// {...props}
						tabIndex={!handle ? 0 : undefined}
					>
						<div {...listeners}>Drag me</div>
						{value}
						<span className={styles.Actions}>
							{onRemove ? <Remove className={styles.Remove} onClick={onRemove} /> : null}
							{handle ? <Handle {...handleProps} {...listeners} /> : null}
						</span>
					</div>
				</li>
			)
		}
	)
)
