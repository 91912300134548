export const colorScheme = () => {
	return [
		'#FF4500', //Orange Red
		'#808000', //Olive
		'#4B0082', //Indigo
		'#00BFFF', //Sky Blue
		'#8FBC8F', //Dark Sea Green
		'#F4A460', //Sandy Brown
		'#778899', //Slate Grey
		'#FFB6C1', //Light Pink
		'#FFD700', //Gold
		'#A0522D', //Sienna
		'#B03309', //ORANGE RED
		'#228B22', //Forest Green
		'#A52A2A', //Brown
		'#9ACD32', //Yellow Green
		'#6495ED', //Corn Flower Blue
		'#4169E1', //Royal Blue
		'#FF7F50', //Coral
		'#8B008B', //Dark Magenta
		'#DB7093', //Pale Violet Red
		'#1E90FF', //Doger Blue
		'#DA70D6', //Orchid
	]
}

export default colorScheme
