import { useLocation } from 'react-router-dom'

const useGetPathName = () => {
	const location = useLocation()
	const FIRST_PATH_NAME = location.pathname.split('/')[1]

	return FIRST_PATH_NAME
}

export default useGetPathName
