import { Add, Delete, CalendarMonthOutlined, AccessTimeOutlined } from '@mui/icons-material'
import {
	FormControl,
	InputLabel,
	MenuItem,
	Popover,
	Select,
	Stack,
	TextField,
	Grid,
	Autocomplete,
	Box,
	Button,
	IconButton,
} from '@mui/material'
import {
	DatePicker,
	LocalizationProvider,
	DateTimePicker,
	DesktopTimePicker,
} from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Compact from '@uiw/react-color-compact'
import ColorPicker from 'custom-components/ColorPicker/ColorPicker'
import { makeStyles } from '@mui/styles'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'

import { enUS } from 'date-fns/locale'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getColorWithoutId } from 'utils/functions/helpers'
import ButtonWithConfirm from '../ButtonWithConfirm'
import {
	SETTING_DISPATCH_TYPE,
	VALUE_TYPE,
	OPERATOR_TYPE,
	DATETIME_TYPE,
	getColumnOperatorType,
	getOperatorByPrimaryColumnType,
	getOperatorLabel,
	DATE_OPERATORS,
	DATE_VALUE,
} from './utils'
import moment from 'moment'
import {
	calculateUserTimezoneOffset,
	computeDateValue,
	parseAs,
	toDayjs,
} from 'utils/functions/doformsDateUtil'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(() => ({
	datetimePicker: {
		'& .MuiInputBase-input': {
			display: 'none',
		},

		'& .MuiInputAdornment-root': {
			display: 'block',
			margin: '0',
			width: '20px',
		},

		'& .MuiOutlinedInput-notchedOutline': {
			display: 'none',
		},
	},
}))

const COLOR_POPUP_MOUSETYPE = {
	COLOR_CLICK: 0,
	RGB_INPUT: 1,
}

function DatePickerIcon({ color, operatorType, initValue, setInputValue, onSettingChange }) {
	const [isOpen, setIsOpen] = useState(false)
	// const [anchorEl, setAnchorEl] = useState(null)
	const classes = useStyles()

	const handleClick = (event) => {
		setIsOpen((isOpen) => !isOpen)
		// setAnchorEl(event.currentTarget)
	}

	const getDateTimePicker = useCallback(
		(isOpen, color, onSettingChange, setInputValue) => {
			switch (operatorType) {
				case OPERATOR_TYPE.DATE: {
					const initDate = toDayjs(initValue)
					return (
						<DatePicker
							open={isOpen}
							onClose={() => setIsOpen(false)}
							className={classes.datetimePicker}
							value={initDate}
							defaultValue={initDate}
							onChange={(newValue) => {
								if (!newValue) return
								const dateValue = new Date(newValue.format('MM/DD/YYYY HH:mm:ss'))
								const dateString = dateValue.toLocaleDateString('en-US')
								// 'en-US' is mm/dd/yyyy format
								setInputValue(dateString)
								onSettingChange({
									type: SETTING_DISPATCH_TYPE.CHANGE_VALUE,
									color,
									setting: {
										value: dateString,
										type: VALUE_TYPE.CONSTANT,
									},
								})
							}}
							slots={{
								openPickerButton: (params) => (
									<IconButton
										{...params}
										size="small"
										aria-label="date-picker"
										onClick={handleClick}
									>
										<CalendarMonthOutlined />
									</IconButton>
								),
							}}
						/>
					)
				}
				case OPERATOR_TYPE.DATETIME: {
					const initDate = toDayjs(initValue)
					return (
						<DateTimePicker
							open={isOpen}
							className={classes.datetimePicker}
							onClose={() => setIsOpen(false)}
							defaultValue={initDate}
							value={initDate}
							selectedSections={'all'}
							views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
							timeSteps={{ minutes: 1, seconds: 1 }}
							onChange={(newValue) => {
								if (!newValue) return
								const momentValue = moment(newValue.format('MM/DD/YYYY HH:mm:ss'))

								const dateString = momentValue.format('MM/DD/YYYY HH:mm:ss')
								setInputValue(dateString)
								onSettingChange({
									type: SETTING_DISPATCH_TYPE.CHANGE_VALUE,
									color,
									setting: {
										value: dateString,
										type: VALUE_TYPE.CONSTANT,
									},
								})
							}}
							slots={{
								openPickerButton: (params) => (
									<IconButton
										{...params}
										size="small"
										aria-label="datetime-picker"
										onClick={handleClick}
									>
										<CalendarMonthOutlined />
									</IconButton>
								),
							}}
						/>
					)
				}
				case OPERATOR_TYPE.TIME: {
					const initDate = toDayjs(moment(initValue, 'HH:mm:ss'))
					return (
						<DesktopTimePicker
							open={isOpen}
							onClose={() => setIsOpen(false)}
							className={classes.datetimePicker}
							value={initDate}
							defaultValue={initDate}
							views={['hours', 'minutes', 'seconds']}
							timeSteps={{ minutes: 1, seconds: 1 }}
							onChange={(newValue) => {
								if (!newValue) return

								const timeString = newValue.format('HH:mm:ss')
								setInputValue(timeString)
								onSettingChange({
									type: SETTING_DISPATCH_TYPE.CHANGE_VALUE,
									color,
									setting: {
										value: timeString,
										type: VALUE_TYPE.CONSTANT,
									},
								})
							}}
							slots={{
								openPickerButton: (params) => (
									<IconButton
										{...params}
										size="small"
										aria-label="datetime-picker"
										onClick={handleClick}
									>
										<AccessTimeOutlined />
									</IconButton>
								),
							}}
						/>
					)
				}
				default:
					return null
			}
		},
		[initValue, operatorType]
	)

	return (
		<Box sx={{ display: 'flex' }}>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={enUS}>
				{getDateTimePicker(isOpen, color, onSettingChange, setInputValue)}
			</LocalizationProvider>
		</Box>
	)
}

function ColorPickerItem({
	columns,
	color,
	primaryColumnDetail,
	formDataList,
	isShowAddMoreButton,
	onSettingChange,
	isCollapse,
}) {
	const { t } = useTranslation('common')
	const { value: initValue, type, operator } = formDataList
	const { environment } = useSelector((state) => state)

	const dateValue = DATE_VALUE(t)
	const userOffset = useMemo(() => {
		return calculateUserTimezoneOffset(environment)
	}, [environment])

	const operatorType = useMemo(() => {
		if (!primaryColumnDetail) return null

		return getColumnOperatorType(primaryColumnDetail.type)
	}, [primaryColumnDetail])

	const operatorOptions = useMemo(() => {
		if (!operatorType) return []
		return getOperatorByPrimaryColumnType(operatorType)
	}, [operatorType])

	const columnOptions = useMemo(() => {
		let colsOption = columns
			.filter(
				(col) =>
					col.name !== primaryColumnDetail?.name &&
					getColumnOperatorType(primaryColumnDetail.type) === getColumnOperatorType(col.type)
			)
			.map((col) => ({
				value: col.name,
				label: col.title,
			}))

		if (primaryColumnDetail?.type === 'DATETIME' || primaryColumnDetail?.type === 'DATE') {
			let listDateValue = []
			for (const item in dateValue) {
				listDateValue.push({
					value: item,
					label: dateValue[item],
				})
			}
			colsOption.unshift(...listDateValue)
		}
		return colsOption
	}, [primaryColumnDetail, columns, color, dateValue])

	const [value, setValue] = useState(initValue || '')
	const [inputValue, setInputValue] = useState('')
	const isDateTimeType = DATETIME_TYPE.includes(primaryColumnDetail?.type?.toUpperCase())
	const [colorPickerEl, setColorPickerEl] = useState(null)

	const [lastInteraction, setLastInteraction] = useState(COLOR_POPUP_MOUSETYPE.COLOR_CLICK)

	const [selectedColor, setSelectedColor] = useState(null)

	useEffect(() => {
		const initColor = getColorWithoutId(color)
		setSelectedColor(initColor)
	}, [color])

	useEffect(() => {
		if (!selectedColor || lastInteraction !== COLOR_POPUP_MOUSETYPE.COLOR_CLICK) return
		onSettingChange({
			type: SETTING_DISPATCH_TYPE.CHANGE_COLOR,
			oldColor: color,
			newColor: selectedColor,
		})
		setColorPickerEl(null)
	}, [lastInteraction, selectedColor])

	const handleOpenColorPicker = (event) => {
		setColorPickerEl(event.currentTarget)
	}

	const handleCloseColorPicker = () => {
		onSettingChange({
			type: SETTING_DISPATCH_TYPE.CHANGE_COLOR,
			oldColor: color,
			newColor: selectedColor,
		})
		setColorPickerEl(null)
	}

	const handleMouseDown = (event) => {
		const clickedElement = event.target

		// Check if the user clicked on a color swatch
		if (clickedElement.tagName === 'INPUT') {
			setLastInteraction(COLOR_POPUP_MOUSETYPE.RGB_INPUT)
		} else {
			setLastInteraction(COLOR_POPUP_MOUSETYPE.COLOR_CLICK)
		}
	}

	const isOpenColorPicker = Boolean(colorPickerEl)
	const colorPickerId = isOpenColorPicker ? 'color-picker-popover' : undefined

	return (
		<div
			style={{
				marginBottom: '3px',
				marginTop: 0,
			}}
		>
			<Grid container spacing={1}>
				<Grid item xs={3} md={3} lg={3}>
					<FormControl fullWidth size="small">
						<InputLabel id="operator-select-label">{t('common:filters.operator')}</InputLabel>
						<Select
							sx={{
								...(isCollapse
									? {
											'& .MuiSelect-select': {
												paddingLeft: '14px !important',
												padding: '8px',
												fontSize: '0.875rem',
											},

											'& .MuiTypography-root': {
												fontSize: '0.875rem',
											},
									  }
									: {}),
							}}
							labelId="operator-select-label"
							id="operator-select"
							value={operator}
							label={t('common:filters.operator')}
							onChange={(e) => {
								onSettingChange({
									type: SETTING_DISPATCH_TYPE.CHANGE_VALUE,
									color,
									setting: {
										operator: e.target.value,
									},
								})
							}}
						>
							{operatorOptions?.map((options) => (
								<MenuItem
									style={{
										...(isCollapse
											? {
													fontSize: '0.875rem',
											  }
											: {}),
									}}
									value={options}
								>
									{getOperatorLabel(options)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>

				<Grid item xs md lg>
					<Stack direction={'row'} spacing={1}>
						<Autocomplete
							sx={{
								width: '100%',
								...(isCollapse
									? {
											'& .MuiFormControl-root': {
												maxHeight: '39px',
											},
											'& .MuiAutocomplete-inputRoot': {
												minHeight: '1.65em',
												fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
												fontWeight: '400',
												lineHeight: '1.4375em',
												letterSpacing: '0.00938em',
											},
											// '& .MuiOutlinedInput-root': {
											// 	paddingTop: '3px',
											// },
									  }
									: {}),
							}}
							id="color"
							variant="outlined"
							value={value}
							onChange={(event, newValue) => {
								setValue(newValue)
							}}
							inputValue={inputValue}
							onInputChange={(event, newInputValue) => {
								const column = columnOptions.find((col) => {
									if (type === VALUE_TYPE.COLUMN) {
										return col.value === newInputValue || col.label === newInputValue
									}
									return col.label === newInputValue
								})
								if (column) {
									setValue(column.value)
									setInputValue(column.label)
									onSettingChange({
										type: SETTING_DISPATCH_TYPE.CHANGE_VALUE,
										color,
										setting: {
											value: column.value,
											type: VALUE_TYPE.COLUMN,
										},
									})
									return
								}

								setInputValue(newInputValue)
								setValue(newInputValue)
								onSettingChange({
									type: SETTING_DISPATCH_TYPE.CHANGE_VALUE,
									color,
									setting: {
										value: newInputValue,
										type: VALUE_TYPE.CONSTANT,
									},
								})
							}}
							options={columnOptions}
							renderOption={(props, option) => {
								return (
									<li {...props}>
										<span
											style={{
												...(isCollapse
													? {
															fontSize: '0.875rem',
															fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
															lineHeight: '1.4375em',
															letterSpacing: '0.00938em',
													  }
													: {}),
											}}
										>
											{option.label}
										</span>
									</li>
								)
							}}
							renderInput={(params) => (
								<TextField
									style={
										isCollapse
											? {
													fontSize: '0.875rem',
											  }
											: {}
									}
									{...params}
									fullWidth
									size="small"
									label={`${t('common:filters.value')} / ${t('common:filters.column')} `}
								/>
							)}
							freeSolo
						/>
						{isDateTimeType && (
							<DatePickerIcon
								color={color}
								initValue={initValue}
								setInputValue={setInputValue}
								onSettingChange={onSettingChange}
								operatorType={operatorType}
							/>
						)}
					</Stack>
				</Grid>

				<Grid item xs={3} md={3} lg={3}>
					<Stack direction="row" alignItems="center" spacing={1}>
						<FormControl fullWidth size="small">
							<ColorPicker
								value={selectedColor}
								onClick={handleOpenColorPicker}
								customStyle={
									isCollapse
										? {
												label: {
													display: 'flex',
													alignItems: 'center',
													height: '39px',
												},
												circle: {
													width: '20px',
													height: '20px',
												},
										  }
										: {}
								}
							/>
							<Popover
								id={colorPickerId}
								open={isOpenColorPicker}
								anchorEl={colorPickerEl}
								onClose={handleCloseColorPicker}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								onMouseDown={handleMouseDown}
							>
								<Compact
									color={selectedColor}
									style={{
										boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px',
									}}
									onChange={(newColor, evn) => {
										setSelectedColor(newColor.hex)
									}}
								/>
							</Popover>
						</FormControl>

						<ButtonWithConfirm
							title={t('common:dis.confirmDeleteItem')}
							onSubmit={() =>
								onSettingChange({
									type: SETTING_DISPATCH_TYPE.DELETE_COLOR,
									value: color,
								})
							}
						>
							<Button
								aria-label="delete"
								size="small"
								variant="contained"
								sx={{
									borderRadius: '50%',
									width: '25px !important',
									minWidth: '25px !important',
									height: '25px !important',
								}}
							>
								<Delete fontSize="small" />
							</Button>
						</ButtonWithConfirm>
					</Stack>
				</Grid>
			</Grid>

			{isShowAddMoreButton && (
				<Grid
					container
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
						alignItems: 'center',
						marginTop: 0,
						marginBottom: '5px',
					}}
				>
					<Grid item>
						<Button
							aria-label="delete"
							size="small"
							variant="contained"
							sx={{
								borderRadius: '50%',
								width: '25px !important',
								minWidth: '25px !important',
								height: '25px !important',
							}}
							onClick={() => {
								onSettingChange({
									type: SETTING_DISPATCH_TYPE.ADD_NEW_COLOR,
								})
							}}
						>
							<Add fontSize="small" />
						</Button>
					</Grid>
				</Grid>
			)}
		</div>
	)
}

export default ColorPickerItem
