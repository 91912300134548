import { Delete } from '@mui/icons-material'
import { FormControl, Popover, Stack, TextField, Grid, Autocomplete, Button } from '@mui/material'
import Compact from '@uiw/react-color-compact'
import ColorPicker from 'custom-components/ColorPicker/ColorPicker'
import { makeStyles } from '@mui/styles'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ButtonWithConfirm from '../../../dis-v2/menu-items/_components/ButtonWithConfirm'
import { DATA_OBJ_ACTION_TYPES } from './SchedulerStatusColorPicker'
import { cloneDeep, debounce } from 'lodash'

const useStyles = makeStyles(() => ({
	datetimePicker: {
		'& .MuiInputBase-input': {
			display: 'none',
		},

		'& .MuiInputAdornment-root': {
			display: 'block',
			margin: '0',
			width: '20px',
		},

		'& .MuiOutlinedInput-notchedOutline': {
			display: 'none',
		},
	},
}))

const COLOR_POPUP_MOUSETYPE = {
	COLOR_CLICK: 0,
	RGB_INPUT: 1,
}

function SchedulerColorPickerItem({
	statusColorDisplay,
	color,
	shedulerStatus,
	statusValue,
	onFilterDataChange,
	index,
}) {
	const { t } = useTranslation('common')
	const [value, setValue] = useState(statusValue || '')
	const [inputValue, setInputValue] = useState('')
	const [colorPickerEl, setColorPickerEl] = useState(null)

	const [lastInteraction, setLastInteraction] = useState(COLOR_POPUP_MOUSETYPE.COLOR_CLICK)

	const [selectedColor, setSelectedColor] = useState(color)

	const handleOpenColorPicker = (event) => {
		setColorPickerEl(event.currentTarget)
	}

	const handleCloseColorPicker = (index) => {
		handleUpdateColor(selectedColor)
		setColorPickerEl(null)
	}

	const handleMouseDown = (event) => {
		const clickedElement = event.target

		// Check if the user clicked on a color swatch
		if (clickedElement.tagName === 'INPUT') {
			setLastInteraction(COLOR_POPUP_MOUSETYPE.RGB_INPUT)
		} else {
			setLastInteraction(COLOR_POPUP_MOUSETYPE.COLOR_CLICK)
		}
	}

	const handleUpdateColor = (newColor) => {
		const currentState = cloneDeep(statusColorDisplay)
		currentState[index].color = newColor
		onFilterDataChange('schedulerColorStatusSettings', currentState)
	}

	const handleDebouncedInputChange = React.useMemo(
		() =>
			debounce((newInputValue) => {
			setValue(newInputValue)
			handleUpdateStatus(newInputValue)
			}, 500), 
		[]
	)
	
	const handleUpdateStatus = (newStatus) => {
		const currentState = cloneDeep(statusColorDisplay)
		currentState[index].label = newStatus
		currentState[index].value = newStatus
		onFilterDataChange('schedulerColorStatusSettings', currentState)
	}

	const isOpenColorPicker = Boolean(colorPickerEl)
	const colorPickerId = isOpenColorPicker ? 'color-picker-popover' : undefined

	return (
		<div
			style={{
				display: 'flex',
				width: '100%',
				gap: '16px',
			}}
		>
			<Autocomplete
				sx={{
					width: '100%',
					'& .MuiFormControl-root': {
						maxHeight: '39px',
					},
					'& .MuiAutocomplete-inputRoot': {
						minHeight: '1.65em',
						fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
						fontWeight: '400',
						lineHeight: '1.4375em',
						letterSpacing: '0.00938em',
					},
				}}
				id="color"
				variant="outlined"
				value={value}
				onChange={(event, newValue) => {
					setValue(newValue)
				}}
				inputValue={inputValue}
				onInputChange={(event, newInputValue) => {
					setInputValue(newInputValue)
					handleDebouncedInputChange(newInputValue)
				}}
				options={shedulerStatus}
				renderInput={(params) => (
					<TextField
						style={{ fontSize: '0.875rem' }}
						{...params}
						fullWidth
						size="small"
						label={`${t('common:filters.status')}`}
					/>
				)}
				freeSolo
			/>
			<FormControl fullWidth size="small">
				<ColorPicker
					value={selectedColor}
					onClick={handleOpenColorPicker}
					customStyle={{
						label: {
							display: 'flex',
							alignItems: 'center',
							height: '39px',
						},
						circle: {
							width: '20px',
							height: '20px',
						},
					}}
				/>
				<Popover
					id={colorPickerId}
					open={isOpenColorPicker}
					anchorEl={colorPickerEl}
					onClose={() => handleCloseColorPicker(index)}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
					onMouseDown={handleMouseDown}
				>
					<Compact
						color={selectedColor}
						style={{
							boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px',
						}}
						onChange={(newColor, evn) => {
							setSelectedColor(newColor.hex)
							handleUpdateColor(newColor.hex)
						}}
					/>
				</Popover>
			</FormControl>
		</div>
	)
}

export default SchedulerColorPickerItem
