import { Close } from '@mui/icons-material'
import { Box, Dialog, IconButton, Typography, Tooltip } from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import { isEmpty } from 'lodash'

const ExpandedDialog = ({ children, title, open, onClose, tooltipValue }) => {
	return (
		<Dialog
			onClose={onClose}
			open={open}
			sx={{
				'& .MuiDialog-paper': {
					maxWidth: '100%',
					width: '95%',
					height: '90%',
					margin: 0,
					padding: 0,
					borderRadius: 0,
					backgroundColor: blueGrey[50],
				},
			}}
		>
			{isEmpty(tooltipValue) ? (
				<Typography
					sx={{
						ml: 2,
						mt: 1,
					}}
					fontWeight="bold"
				>
					{title}
				</Typography>
			) : (
				<Tooltip title={tooltipValue} placement="top">
					<Typography
						sx={{
							ml: 2,
							mt: 1,
						}}
						fontWeight="bold"
					>
						{title}
					</Typography>
				</Tooltip>
			)}

			<IconButton
				aria-label="close-expand-dialog"
				sx={{
					position: 'absolute',
					top: 5,
					right: 5,
					zIndex: 1000,
				}}
				onClick={onClose}
			>
				<Close />
			</IconButton>
			<Box
				sx={{
					position: 'relative',
					top: 0,
					height: 'calc(100% - 48px)',
				}}
			>
				{children}
			</Box>
		</Dialog>
	)
}

export default ExpandedDialog
