import { Box, Button, CircularProgress, Stack, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ToastAlert from '../../../../core/Layouts/ToastAlert'
import SideDialog from '../../components/SideDialog'
import ImageViewerForm from './ImageViewerForm'

function ImageViewerSettingsDialog({
	tileElementWidth,
	defaultTileWidth,
	tile,
	open,
	settings,
	isSubmitting,
	showStable,

	onClose,
	onSubmit,
	onResizeTileWidth,
}) {
	const { t } = useTranslation('common')
	const { id: dashboardKey } = useParams()

	const [alertInfo, setAlertInfo] = useState({
		open: false,
		text: '',
		type: 'success',
	})

	const [filterData, setFilterData] = useState({})

	const dialogWidth = tile?.w ?? 6
	const STABLE_WIDTH = 6

	React.useEffect(() => {
		if (settings?.imageType) {
			setFilterData((prev) => ({ ...prev, imageType: settings?.imageType }))
		}
	}, [JSON.stringify(settings?.imageType)])

	React.useEffect(() => {
		if (settings?.connectedDatagridKey) {
			setFilterData((prev) => ({ ...prev, connectedDatagridKey: settings?.connectedDatagridKey }))
		}
	}, [JSON.stringify(settings?.connectedDatagridKey)])

	const handleFilterDataChange = (id, value) => {
		setFilterData((prev) => ({ ...prev, [id]: value }))
	}

	const handleSubmit = () => {
		if (!filterData?.imageType) {
			setAlertInfo((prev) => ({
				...prev,
				open: true,
				type: 'error',
				text: t('common:dis.pleaseFillInformation'),
			}))
			return
		}

		onSubmit?.({
			imageType: filterData?.imageType,
			connectedDatagridKey: filterData?.connectedDatagridKey,
		})
	}

	return (
		<>
			<ToastAlert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />

			<SideDialog
				defaultTileWidth={defaultTileWidth}
				open={open}
				onClose={onClose}
				width={showStable ? STABLE_WIDTH : dialogWidth}
				tileElementWidth={tileElementWidth}
				onResizeWidth={onResizeTileWidth}
			>
				<Stack
					sx={{
						'& .MuiInputBase-input': {
							fontSize: '0.9rem !important',
						},
						'& .MuiCheckbox-root': {
							p: 0,
						},
					}}
				>
					<Box sx={{ px: 1 }}>
						<TextField
							fullWidth
							id="label-basic"
							label={t('common:chart.labels')}
							variant="standard"
							value={tile.i}
							disabled={true}
							size="small"
						/>
					</Box>

					<Box sx={{ px: 1, mt: 2 }}>
						<ImageViewerForm
							dashboardKey={dashboardKey}
							filterData={filterData}
							onFilterDataChange={handleFilterDataChange}
						/>
					</Box>

					<Stack direction="row" justifyContent="flex-end" mt={2}>
						<Stack spacing={2} direction="row">
							<Button
								size="small"
								variant="contained"
								onClick={handleSubmit}
								disabled={isSubmitting}
								startIcon={isSubmitting ? <CircularProgress color="inherit" size="1em" /> : null}
							>
								{t('common:misc.ok')}
							</Button>
							<Button size="small" variant="text" onClick={onClose}>
								{t('common:misc.cancel')}
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</SideDialog>
		</>
	)
}

export default ImageViewerSettingsDialog
