import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { IconThemeProvider } from '../../../custom-components/context/IconThemesContext'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import { useUrlQueryParams } from 'utils/hooks/useUrlQueryParams'
import { DEVICES_ACTIONS } from '../../../reducers/devicesReducer'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import '../../../styles.css'
import DashboardSplitPane from '../../core/Dashboard/DashboardSplitPane'
import { getDevices } from '../../core/services/environmentService'
import { getAllTeams } from '../../data/dataServices'
import DevicesDashboard from './DevicesDashboard'
import DevicesData from './DevicesData'

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		padding: '8px',
		'& .MuiButton-root': {
			textDecoration: 'none',
		},
	},
	formSidePanel: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'stretch',
		height: '100%',
	},
	formBody: {
		display: 'contents',
	},
}))

const DevicesModule = () => {
	const { id: paramId } = useParams()

	const urlQueryParam = useUrlQueryParams()

	const actionQuery = urlQueryParam.get('action')

	const classes = useStyles()

	const { environment, devicesModule } = useSelector((state) => state)

	const iconTheme = environment.theme.icons

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)

	const dispatch = useDispatch()
	const isMounted = useRef(true)

	useEffect(() => {
		isMounted.current = true
		return () => (isMounted.current = false)
	}, [])

	useEffect(() => {
		// reset form filter conditions when changed page
		return () => {
			dispatch({
				type: DEVICES_ACTIONS.FORM_FILTER_CONDITIONS,
				payload: {},
			})
		}
	}, [])

	useEffect(() => {
		var newFullScreenDataModString = localStorage.getItem('newFullScreenData_mod')
		if (!_.isEmpty(paramId) && newFullScreenDataModString != null) {
			var newFullScreenDataMod = JSON.parse(newFullScreenDataModString)
			var devicesModule = newFullScreenDataMod.devicesModule
			dispatch({
				type: DEVICES_ACTIONS.DEVICES_RESTORE_PRODUCER,
				payload: devicesModule,
			})
			localStorage.removeItem('newFullScreenData_mod')
		} else {
			dispatch({
				type: DEVICES_ACTIONS.TAB_CHANGE,
				payload: DEVICES_ACTIONS.NAME,
			})

			if (!environment.teams.length) {
				initiateLoadAllTeams(environment.devices)
			}
		}
	}, [])

	useEffect(() => {
		if (!environment.refresh) return
		setLoading(true)
		dispatch({
			type: ENV_ACTIONS.CLEAR_DEVICES,
		})
		getDevices(environment.apiToken)
			.then((res) => res.json())
			.then((data) => {
				dispatch({
					type: ENV_ACTIONS.GET_DEVICES,
					payload: data,
				})
				initiateLoadAllTeams(data)
			})
			.catch((err) => {
				console.log(err)
			})
			.finally(() => {
				dispatch({
					type: ENV_ACTIONS.REFRESH,
					payload: false,
				})
			})
	}, [environment.refresh])

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={() => setError(null)} />
		)

	//Doesn't need to load devices since we load devices from environment load
	const initiateLoadAllTeams = (devices) => {
		setLoading(true)
		loadAllTeams()
			.then((res) => {
				let teams = _.sortBy(res.data, (team) => team.name.toLowerCase())
				if (devices.length) {
					teams = teams.map((team) => {
						let teamDevices = devices.filter((device) => device.teamKey === team.key)
						teamDevices = _.sortBy(teamDevices, (team) => team.name.toLowerCase())
						return { ...team, devices: teamDevices }
					})
				}

				dispatch({
					type: ENV_ACTIONS.GET_TEAMS,
					payload: teams,
				})
			})
			.catch((err) => {
				setError('Code ' + err.response?.data?.code + ': ' + err.response?.data?.message)
			})
			.finally(() => {
				if (isMounted.current) {
					setLoading(false)
				}
			})
	}

	const loadAllTeams = async () => {
		let promise = await getAllTeams(environment.apiToken)
		return promise
	}

	const sidePanel = () => (
		<div id="form-side-panel" className={classes.formSidePanel}>
			<DevicesDashboard teams={environment.teams} environment={environment} loading={loading} />
		</div>
	)

	const formBody = () => (
		<div id="form-body" className={classes.formBody}>
			{showErrorMessage()}
			<DevicesData environment={environment} module={devicesModule} />
		</div>
	)

	return (
		<IconThemeProvider values={iconTheme}>
			<DashboardSplitPane sidePanel={sidePanel()} mainContent={formBody()} />
		</IconThemeProvider>
	)
}

export default DevicesModule
