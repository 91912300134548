import { Add, Delete, Settings } from '@mui/icons-material'
import { Box, Button, Popover, Stack, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

const MenuCardAction = ({
	showAddBtn = true,
	showConfigBtn = true,
	onDelete,
	onAddNewSubMenu,
	onGoToMenuItems,
}) => {
	const { t } = useTranslation('common')
	const [openConfirmDelete, setOpenConfirmDelete] = React.useState(null)

	const handleClick = (event) => {
		setOpenConfirmDelete(openConfirmDelete ? null : event.currentTarget)
	}

	const handleClose = () => {
		setOpenConfirmDelete(null)
	}

	const open = Boolean(openConfirmDelete)
	const id = open ? 'delete-menu-popper' : undefined

	return (
		<Stack direction="row" alignItems="center" gap={0.5} sx={{ ml: 'auto', mr: 1 }}>
			{showAddBtn && (
				<Tooltip title={t('common:dis.addChildrenMenuItem')} arrow placement="bottom-start">
					<Box
						sx={{
							p: 1,
							height: '100%',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							'&:hover': {
								cursor: 'pointer',
								backgroundColor: 'rgba(0, 0, 0, 0.08)',
							},
						}}
						onClick={onAddNewSubMenu}
					>
						<Add sx={{ color: '#999' }} />
					</Box>
				</Tooltip>
			)}

			{showConfigBtn && (
				<Box
					sx={{
						p: 1,
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						opacity: 1,

						'&:hover': {
							cursor: 'pointer',
							backgroundColor: 'rgba(0, 0, 0, 0.08)',
						},
					}}
					onClick={onGoToMenuItems}
				>
					<Settings sx={{ color: '#999' }} />
				</Box>
			)}

			<Tooltip title={t('common:dis.deleteMenuItem')} arrow placement="bottom-start">
				<Box
					sx={{
						p: 1,
						height: '100%',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						'&:hover': {
							cursor: 'pointer',
							backgroundColor: 'rgba(0, 0, 0, 0.08)',
						},
					}}
					onClick={handleClick}
				>
					<Delete sx={{ color: '#999' }} />
				</Box>
			</Tooltip>
			<Popover
				id={id}
				open={open}
				anchorEl={openConfirmDelete}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
			>
				<Box sx={{ p: 2 }}>
					<Typography>{t('common:dis.confirmDeleteItem')}</Typography>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							mt: 1,
							gap: 2,
						}}
					>
						<Button
							size="small"
							onClick={() => {
								onDelete?.()
								handleClose()
							}}
						>
							{t('common:misc.delete')}
						</Button>
						<Button size="small" variant="contained" onClick={handleClose}>
							{t('common:misc.cancel')}
						</Button>
					</Box>
				</Box>
			</Popover>
		</Stack>
	)
}

export default MenuCardAction
