import { useEffect, useMemo, useState } from 'react'

import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getAllViews } from '../../../data/dataServices'
import { isEmpty, isEqual } from 'lodash'

const viewKeys = {
	all: ['views'],
	account: ['account'],
	group: ['group']
}

function useView({ config = {}, selectedWebGroupKey } = {}) {
	const { environment } = useSelector((state) => state)
	const [allViews, setAllViews] = useState([])

	const {
		data: accountViewsResult,
		isLoading: accountViewsLoading,
		isFetching: accountViewsFetching,
		refetch: refetchAccountViews
	} = useQuery(
		[...viewKeys.all, ...viewKeys.account, environment.account.key],
		() => getAllViews(environment.apiToken, environment.account.key),
		{
			staleTime: Infinity,
			...config,
		}
	)

	const {
		data: groupViewsResult,
		isLoading: groupViewsLoading,
		isFetching: groupViewsFetching,
		refetch: refetchGroupViews
	} = useQuery(
		[...viewKeys.all, ...viewKeys.group, selectedWebGroupKey],
		() => getAllViews(environment.apiToken, selectedWebGroupKey),
		{
			enabled: Boolean(selectedWebGroupKey),
			staleTime: Infinity,
			...config,
		}
	)

	const viewLoading = useMemo(() => {
		if (selectedWebGroupKey)
			return accountViewsLoading || accountViewsFetching || groupViewsLoading || groupViewsFetching

		return accountViewsLoading || accountViewsFetching
	}, [selectedWebGroupKey, accountViewsLoading, accountViewsFetching, groupViewsLoading, groupViewsFetching])


	useEffect(() => {
		if (viewLoading) return

		const views = []
		if (!isEmpty(accountViewsResult?.data)) {
			views.push(...accountViewsResult.data)
		}

		if (selectedWebGroupKey && !isEmpty(groupViewsResult?.data)) {
			views.push(...groupViewsResult.data)
		}

		if (isEqual(allViews, views)) return
		setAllViews(views)
	}, [selectedWebGroupKey, viewLoading, accountViewsResult, groupViewsResult])


	const reloadViews = () => {
		refetchAccountViews()
		refetchGroupViews()
	}

	return { views: allViews || [], viewLoading, reloadViews }
}

export default useView
