import { Box, Button, CircularProgress, Stack, TextField } from '@mui/material'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ToastAlert from '../../../../core/Layouts/ToastAlert'
import SideDialog from '../../components/SideDialog'
import DispatchSchedulerForm from './DispatchSchedulerForm'

function DispatchSchedulerSettingsDialog({
    tileElementWidth,
    defaultTileWidth,
    tile,
    open,
    settings,
    isSubmitting,
    showStable,
    onClose,
    onSubmit,
    onResizeTileWidth,
    dashboardKeyList
}) {
    const { t } = useTranslation('common')

    const [alertInfo, setAlertInfo] = useState({
        open: false,
        text: '',
        type: 'success',
    })

    const [filterData, setFilterData] = useState({})

    const dialogWidth = tile?.w ?? 6
    const STABLE_WIDTH = 6

    useEffect(() => {
        const initFilterData = {}
        if (settings?.projectFormInfo) {
            initFilterData.projectFormInfo = settings?.projectFormInfo
        }
        if (settings?.schedulerRangeType) {
            initFilterData.schedulerRangeType = settings?.schedulerRangeType
        }
        if (settings?.linkedFields) {
            initFilterData.linkedFields = settings?.linkedFields
        }
        if (settings?.schedulerStartTime) {
            initFilterData.schedulerStartTime = settings?.schedulerStartTime
        }
        if (settings?.schedulerEndTime) {
            initFilterData.schedulerEndTime = settings?.schedulerEndTime
        }
        if (settings?.schedulerColorStatusSettings) {
            initFilterData.schedulerColorStatusSettings = settings?.schedulerColorStatusSettings
        }
        if (settings?.otherOptionsChecked) {
            initFilterData.otherOptionsChecked = settings?.otherOptionsChecked
        }
        
        setFilterData((prev) => ({ ...prev, ...initFilterData }))
    }, [
        JSON.stringify(settings?.projectFormInfo),
        JSON.stringify(settings?.schedulerSelectDate),
        JSON.stringify(settings?.schedulerRangeType),
        JSON.stringify(settings?.schedulerStartTime),
        JSON.stringify(settings?.schedulerEndTime),
        JSON.stringify(settings?.schedulerColorStatusSettings),
        JSON.stringify(settings?.otherOptionsChecked)
    ])

    const handleFilterDataChange = (id, value) => {
        setFilterData((prev) => ({ ...prev, [id]: value }))
    }

    const handleSubmit = () => {
        if (!filterData?.projectFormInfo || !filterData?.projectFormInfo?.projectKey || !filterData?.schedulerRangeType
            || !filterData?.schedulerStartTime || !filterData?.schedulerEndTime || !filterData?.schedulerColorStatusSettings
        ) {
            setAlertInfo((prev) => ({
                ...prev,
                open: true,
                type: 'error',
                text: t('common:dis.pleaseFillInformation'),
            }))
            return
        }
        if (Number(filterData?.schedulerStartTime) >= Number(filterData?.schedulerEndTime)) {
            setAlertInfo((prev) => ({
                ...prev,
                open: true,
                type: 'error',
                text: t('common:dis.validateRangeHours'),
            }))
            return
        }
        onSubmit?.({
            projectFormInfo: filterData?.projectFormInfo,
            schedulerRangeType: filterData?.schedulerRangeType,
            schedulerStartTime: filterData?.schedulerStartTime,
            schedulerEndTime: filterData?.schedulerEndTime,
            schedulerColorStatusSettings: filterData?.schedulerColorStatusSettings,
            linkedFields: filterData?.linkedFields,
            otherOptionsChecked: filterData?.otherOptionsChecked,
        })
    }

    return (
        <>
            <ToastAlert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />

            <SideDialog
                defaultTileWidth={defaultTileWidth}
                open={open}
                onClose={onClose}
                width={showStable ? STABLE_WIDTH : dialogWidth}
                tileElementWidth={tileElementWidth}
                onResizeWidth={onResizeTileWidth}
            >
                <Stack
                    sx={{
                        '& .MuiInputBase-input': {
                            fontSize: '0.9rem !important',
                        },
                        '& .MuiCheckbox-root': {
                            p: 0,
                        },
                    }}
                >
                    <Box sx={{ px: 1 }}>
                        <TextField
                            fullWidth
                            id="label-basic"
                            label={t('common:chart.labels')}
                            variant="standard"
                            value={tile.i}
                            disabled={true}
                            size="small"
                        />
                    </Box>

                    <Box sx={{ px: 1, mt: 2 }}>
                        <DispatchSchedulerForm
                            filterData={filterData}
                            settings={settings}
                            onFilterDataChange={handleFilterDataChange}
                            dashboardKeyList={dashboardKeyList}
                        />
                    </Box>

                    <Stack direction="row" justifyContent="flex-end" mt={2}>
                        <Stack spacing={2} direction="row">
                            <Button
                                size="small"
                                variant="contained"
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                                startIcon={isSubmitting ? <CircularProgress color="inherit" size="1em" /> : null}
                            >
                                {t('common:misc.ok')}
                            </Button>
                            <Button size="small" variant="text" onClick={onClose}>
                                {t('common:misc.cancel')}
                            </Button>
                        </Stack>
                    </Stack>
                </Stack>
            </SideDialog>
        </>
    )
}

export default DispatchSchedulerSettingsDialog
