import { useRef, useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Box } from '@mui/material'

import TileWrapper from '../../components/TileWrapper'
import DoFormsFormSettingsDialog from './DoformsFormSettingDialog'
import tileApi from 'apis/disApi/tileApi'

import { IconThemeProvider } from 'custom-components/context/IconThemesContext'
import { isJson, logErrorMessage } from '../../../../../utils/functions/helpers'
import { tileKeys } from '../../hooks/useTileQuery'
import { checkKeyIsExist } from '../utils/helpers'
import { DEFAULT_DOFORM_CHECKED_OPTIONS } from './DoformsForm'
import { DEFAULT_CHECKED_OPTIONS } from 'components/pages/dis-v2/_components/OtherOptions'
import { isEqual } from 'lodash'

function DoFormsFormTile(props) {
	const { tile } = props
	const { environment } = useSelector((state) => state)
	const iconTheme = environment.theme.icons
	const { id: dashboardKey } = useParams()

	const queryClient = useQueryClient()

	const updateTileMutation = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})

	const tileRef = useRef(null)
	const [openTileFrmGrid, setOpenTileFrmGrid] = useState(false)
	const settings = useMemo(() => {
		setOpenTileFrmGrid(tile?.isShowDialog || false)
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}
		return {}
	}, [tile?.settings])
	const { tileWidth, defaultUrl, defaultProjectForm, otherOptionsChecked } = settings

	const [newUrl, setNewUrl] = useState(defaultUrl || '')
	const [settingsOpen, setSettingsOpen] = useState(false)
	const [isReloadFrm, setIsReloadFrm] = useState(undefined)
	const [refreshValue, setRefreshValue] = useState(0)
	const innerRef = useRef(null)
	const [titleLabel, setTitleLabel] = useState('')

	useEffect(() => {
		if (
			isEqual(settings?.otherOptionsChecked, DEFAULT_CHECKED_OPTIONS) &&
			settings?.otherOptionsChecked
		) {
			settings.otherOptionsChecked = DEFAULT_DOFORM_CHECKED_OPTIONS
		}
		let isRefesh = checkKeyIsExist('refreshOnSubmit', settings?.otherOptionsChecked)
		setIsReloadFrm(isRefesh)

		let pathname = addTokenToURL(defaultUrl)
		setNewUrl(pathname)
		setRefreshValue(0)
	}, [defaultUrl, otherOptionsChecked])

	useEffect(() => {
		const ifrm = innerRef.current
		if (!newUrl) {
			setNewUrl(newUrl)
			ifrm.removeEventListener('load', handler)
			return
		}

		ifrm.addEventListener('load', handler)

		if (refreshValue === 2) {
			handleSubmitted()
		}

		return () => ifrm.removeEventListener('load', handler)
	}, [newUrl, isReloadFrm, refreshValue])

	const handleOpenDialog = () => {
		setSettingsOpen(true)
	}

	const handleCloseDialog = () => {
		setSettingsOpen(false)
	}

	function setOrtherOption(url, listOption) {
		let items = url?.split('&') || []
		if (items?.length > 0) {
			let isShowTileLabel = checkKeyIsExist('showTileLabel', listOption)
			let isSubmit = checkKeyIsExist('showSubmit', listOption)
			let isCancel = checkKeyIsExist('showCancel', listOption)

			let nameTile = ''
			if (isShowTileLabel === true) {
				nameTile = tile?.i
			}
			setTitleLabel(nameTile)

			let iLen = items.length - 1
			for (let i = iLen; i >= 0; i--) {
				let oTemp = items[i]
				if (oTemp.search('canSave') >= 0) {
					items.splice(i, 1)
				} else if (oTemp.search('showCancel') >= 0) {
					items.splice(i, 1)
				}
			}
			items.push('canSave=' + isSubmit)
			items.push('showCancel=' + isCancel)
			url = items.join('&')
		}
		return url
	}
	function addTokenToURL(url) {
		url = setOrtherOption(url, otherOptionsChecked)
		if (url?.search('&token=') < 0) {
			return url + '&token=' + environment.apiToken
		} else {
			return url
		}
	}

	const handleSubmitSetting = async (data) => {
		try {
			const { defaultUrl, defaultProjectForm, otherOptionsChecked, connectedDatagridKey } = data

			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: {
					settings: JSON.stringify({
						...settings,
						defaultUrl,
						connectedDatagridKey,
						defaultProjectForm,
						otherOptionsChecked,
					}),
				},
				token: environment.apiToken,
			})
			handleCloseDialog()
		} catch (error) {
			logErrorMessage(error)
		} finally {
			handleCloseDialog()
		}
	}

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({
				...settings,
				tileWidth: width,
			})

			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		}
	}
	const handler = (event) => {
		if (event?.type === 'load') {
			if (refreshValue === 1) {
				setRefreshValue(2)
			} else if (refreshValue === 0) {
				setRefreshValue(1)
			}
		}
	}

	const handleSubmitted = () => {
		if (openTileFrmGrid === true) {
			if (props.setIsOpenDialog) {
				props.setIsOpenDialog(false)
			}
		} else {
			let isRefesh = checkKeyIsExist('refreshOnSubmit', settings?.otherOptionsChecked)
			if (isRefesh === true) {
				const d = new Date()
				setNewUrl(addTokenToURL(defaultUrl + '&time:' + d.getTime()))
				setRefreshValue(0)
			}
		}
	}

	return (
		<IconThemeProvider values={iconTheme}>
			{openTileFrmGrid === false && (
				<TileWrapper
					title={titleLabel}
					onSettingClick={handleOpenDialog}
					ref={tileRef}
					isExpandDialogBtn
				>
					<DoFormsFormSettingsDialog
						tileElementWidth={tileRef?.current?.clientWidth}
						defaultTileWidth={tileWidth}
						tile={tile}
						settings={settings}
						isSubmitting={updateTileMutation.isLoading}
						open={settingsOpen}
						onClose={handleCloseDialog}
						onSubmit={handleSubmitSetting}
						onResizeTileWidth={handleResizeTileWidth}
					/>
					<Box
						sx={{
							position: 'absolute',
							left: 0,
							right: 0,
							top: 30,
							bottom: 0,
							background: '#fff',
							display: 'flex',

							'& .MuiDataGrid-selectedRowCount': {
								opacity: '0 !important',
							},
						}}
					>
						<iframe src={newUrl} width="100%" ref={innerRef} allow="geolocation" />
					</Box>
				</TileWrapper>
			)}

			{openTileFrmGrid === true && (
				<Box
					sx={{
						position: 'absolute',
						left: 0,
						right: 0,
						top: 55,
						bottom: 0,
						display: 'flex',

						'& .MuiDataGrid-selectedRowCount': {
							opacity: '0 !important',
						},
					}}
				>
					<iframe
						src={newUrl}
						background="#fff"
						border="0px !important"
						width="100%"
						ref={innerRef}
						allow="geolocation"
					/>
				</Box>
			)}
		</IconThemeProvider>
	)
}

export default DoFormsFormTile
