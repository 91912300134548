import {
	Collapse,
	List,
	ListItem,
	ListItemButton,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useCallback, useContext, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { EnvironmentContext } from '../../../custom-components/context/EnvironmentContext'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import SkeletonLoaderSidePanel from '../../../custom-components/skeletons/SkeletonLoaderSidePanel'
import { FORM_ACTIONS } from '../../../reducers/formsReducer'
import DashboardSearchBar from '../../core/Dashboard/DashboardSearchBar'
import DoformsSendDispatch from '../../data/dispatch/DoformsSendDispatch'
import DoformsCreateSubmission from '../../data/submissions/DoformsCreateSubmission'
import FormsButton from './FormsButton'
import { VIEW } from '../../../constants'

const useStyles = makeStyles(() => ({
	formHeading: {
		display: 'flex',
		alignSelf: 'stretch',
		paddingTop: 0.5,
		paddingBottom: 0.5,
		paddingRight: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)',
		},
		'& .MuiTypography-root': {
			fontWeight: 'bold',
			display: 'block',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	formItem: {
		padding: 0,
		display: 'flex',
		overflow: 'hidden',
		justifyContent: 'center',
		alignItems: 'stretch',
	},
	formItemBtn: {
		display: 'flex',
		padding: '1px 0px 1px 16px',
		'&:hover': {
			backgroundColor: 'white',
		},
		'& .MuiTypography-root': {
			display: 'block',
			fontSize: '15px',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	formItemToolbar: {
		position: 'relative',
		top: 'auto',
		right: 'auto',
		display: 'flex',
		flex: '1 1 auto',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
		backgroundColor: '#fff',
		transform: 'none',
		paddingRight: '2px',
	},
	formMenuListContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		flex: '1 1 0',
		overflow: 'hidden',
	},
	formMenuList: {
		paddingTop: 0,
		overflow: 'hidden',
		height: 'inherit',
		'&:not(.loading):hover': {
			overflowY: 'auto',
		},
		'& .MuiListItem-root': {
			flexDirection: 'column',
		},
		'& .MuiCollapse-root': {
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'stretch',
		},
		'& .MuiListItem-container': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		'& .MuiListItem-container:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	formMenuChildList: {
		'& ul li:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	loadingWrapper: {
		position: 'absolute',
		top: '20%',
		left: '40%',
		zIndex: '99999',
	},
}))

const FormsDashboard = (props) => {
	const [t] = useTranslation('common')
	const classes = useStyles()
	const { projects, loading, module, env } = props
	const { environment } = useContext(EnvironmentContext)
	const userRights = environment.userCurrent.rights

	const { formsModule } = useSelector((state) => state)
	const { formSelected, action } = formsModule

	const [collapseProjects, setCollapseProjects] = useState([])
	const [itemName, setItemName] = useState(null)
	const [hovering, setHovering] = useState(false)
	const [openSubmission, setOpenSubmission] = useState(false)
	const [openSendDispatch, setOpenSendDispatch] = useState(false)
	const [skeletonLoading, setSkeletonLoading] = useState(true)
	const [searchText, setSearchText] = useState('')

	const filteredProjects = useMemo(() => {
		if (searchText === '') {
			return projects
		} else {
			return projects.reduce((r, { key, name, forms }) => {
				let o = forms.filter(({ name }) => name.toLowerCase().includes(searchText))
				if (o && o.length) r.push({ key, name, forms: [...o] })
				return r
			}, [])
		}
	}, [searchText, projects])

	const dispatch = useDispatch()

	useLayoutEffect(() => {
		if (projects && projects.length > 0) {
			setSkeletonLoading(false)
		} else {
			setSkeletonLoading(true)
		}
	}, [projects])

	const showLoading = () => (
		<>
			<div className={classes.loadingWrapper}>
				<LoadingSpinner withStyle={false} />
			</div>
			{skeletonLoading && <SkeletonLoaderSidePanel />}
		</>
	)

	const handlePopoverOpen = useCallback(
		(newItemName) => (event) => {
			event.preventDefault()
			setHovering((prev) => itemName !== newItemName || !prev)
			setItemName(newItemName)
		},
		[]
	)

	const handlePopoverClose = (e) => {
		e.preventDefault()
		if (openSubmission || openSendDispatch) return
		setHovering(false)
	}

	const handleOpenSubmission = (value) => {
		setOpenSubmission(!!value)
	}

	const handleOpenSendDispatch = (value) => {
		setOpenSendDispatch(!!value)
	}

	const showItemActions = (form, project) => {
		form = { ...form, projectKey: project.key }
		return (
			itemName === `${project.name} / ${form.name}` && (
				<ListItemSecondaryAction className={classes.formItemToolbar}>
					<FormsButton action={'audit'} form={form} projectKey={project.key} />
					{userRights?.map && <FormsButton action={'map'} form={form} projectKey={project.key} />}
					{!environment.isReadOnlySubmission && !form.abandoned && (
						<DoformsCreateSubmission
							onDialogOpen={handleOpenSubmission}
							formSelected={form}
							action={'add'}
							title={itemName}
							recordsLoading={false}
							source={'button'}
							tab={'forms'}
						/>
					)}
					{!environment.isReadOnlyDispatch && !form.abandoned && (
						<DoformsSendDispatch
							onDialogOpen={handleOpenSendDispatch}
							formSelected={form}
							action={'send'}
							title={itemName}
							recordsLoading={false}
							source={'button'}
							tab={'forms'}
							environment={env}
						/>
					)}
				</ListItemSecondaryAction>
			)
		)
	}

	const onChangeValueHandler = (value) => {
		const term = !value ? '' : value.toLowerCase()
		setSearchText(term)
	}

	const handleCollapse = (projectKey) => {
		const found = collapseProjects.includes(projectKey)
		if (!found) {
			setCollapseProjects([...collapseProjects, projectKey])
		} else {
			setCollapseProjects(collapseProjects.filter((item) => item !== projectKey))
		}
	}

	const clickHandler = (action, form, project) => (event) => {
		event.preventDefault()
		dispatch({
			type: FORM_ACTIONS.FORM_SELECTED,
			payload: { ...form, projectKey: project.key },
		})
		dispatch({
			type: FORM_ACTIONS.ACTION,
			payload: action,
		})
	}

	return (
		<>
			<DashboardSearchBar onChangedValue={onChangeValueHandler} tab={VIEW.VIEW_TAB_FORMS} />
			<div className={classes.formMenuListContainer}>
				{loading && showLoading()}
				<List
					className={`${classes.formMenuList} ${loading ? t('common:misc.loading').toLowerCase() : ''
						}`}
				>
					{filteredProjects.length > 0 &&
						filteredProjects.map((project, index) => {
							if (!project.forms) return null
							if (!project.forms.length) return null
							return (
								<ListItem
									key={project.key}
									ContainerProps={{ onMouseLeave: handlePopoverClose }}
									disablePadding
								>
									<ListItemButton
										className={classes.formHeading}
										onClick={() => handleCollapse(project.key)}
									>
										<ListItemText primary={project.name} />
									</ListItemButton>
									<Collapse
										id={index}
										in={!collapseProjects.includes(project.key)}
										timeout="auto"
										unmountOnExit
									>
										<List className={classes.formMenuChildList} disablePadding>
											{project.forms.map((form) => (
												<ListItem
													key={form.key}
													className={classes.formItem}
													onMouseOver={handlePopoverOpen(`${project.name} / ${form.name}`)}
													ContainerProps={{ onMouseLeave: handlePopoverClose }}
												>
													<ListItemButton
														className={classes.formItemBtn}
														disabled={
															`${formSelected?.key}${formSelected?.projectKey}` ===
															`${form?.key}${project?.key}` && action === 'audit'
														}
														onClick={clickHandler('audit', form, project)}
													>
														<ListItemText
															primary={
																<Tooltip
																	title={form.displayName}
																	arrow
																	placement="bottom-start"
																	disableInteractive
																	enterDelay={1000}
																	enterNextDelay={1000}
																>
																	<span>{form.name}</span>
																</Tooltip>
															}
														/>
													</ListItemButton>
													{hovering && showItemActions(form, project)}
												</ListItem>
											))}
										</List>
									</Collapse>
								</ListItem>
							)
						})}
				</List>
			</div>
			{!environment.isReadOnlyDispatch && <DoformsSendDispatch
				onDialogOpen={handleOpenSendDispatch}
				formSelected={module.formSelected}
				action={'send'}
				title={itemName}
				recordsLoading={false}
				source={'FormsDashboard'}
				tab={'forms'}
				environment={env}
			/>}
		</>
	)
}

export default FormsDashboard
