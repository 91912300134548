import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'

const SkeletonLoaderGridChart = () => {
	return (
		<Stack style={{ marginLeft: 10, marginRight: 10, marginBottom: 10, position: 'relative' }}>
			<Skeleton variant="rectangular" height={300} />
		</Stack>
	)
}

export default SkeletonLoaderGridChart
