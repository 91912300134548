import { useMemo } from 'react'
import { useTranslation } from 'react-i18next';
import { unionBy } from 'lodash'

import { Stack, FormControl, Select, InputLabel, MenuItem, Skeleton } from '@mui/material'

import useTileQuery from '../../hooks/useTileQuery'
import { TYPES_OF_TILE } from '../../../../../constants'

function FeedForm(props) {
    const {
        dashboardKey,
        filterData,
        enabledFetchTileList = true,
        mobileUnitListWhenCreating = [],
        onFilterDataChange,
    } = props

    const { t } = useTranslation('common')

    const { tileList, isLoading } = useTileQuery({
        dashboardKey,
        enableConfig: enabledFetchTileList,
    })

    const mobileUnitTile = useMemo(() => {
        const currentList =
            tileList?.length > 0 ? tileList?.filter((tile) => tile?.type === TYPES_OF_TILE.MOBILE_UNITS) : []

        return unionBy(currentList, mobileUnitListWhenCreating, 'name')
    }, [tileList, mobileUnitListWhenCreating])

    const handleSelectChange = (e) => {
        const key = e.target.value
        const name = mobileUnitTile.find((tile) => tile?.key === key)?.name
        const selectedTile = mobileUnitTile.find((tile) => tile?.key === key)
        onFilterDataChange('connectedDatagridKey', key)
        if (selectedTile?.is_creating) {
            onFilterDataChange('connectedNameWithNewDatagrid', name)
        }
    }

    return (
        <Stack spacing={2}>
            {mobileUnitTile?.length > 0 &&
                (!isLoading ? (
                    <FormControl variant="outlined" size="small">
                        <InputLabel id="mobile-unit-select-small-label">
                            {t('common:input.mobileUnitToConnect')}
                        </InputLabel>
                        <Select
                            labelId="mobile-unit-select-small-label"
                            id="mobile-unit-select-small"
                            value={filterData?.connectedDatagridKey ?? ''}
                            label={t('common:input.mobileUnitToConnect')}
                            onChange={handleSelectChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {mobileUnitTile.map((tile) => (
                                <MenuItem value={tile?.key} key={tile?.key}>
                                    {tile?.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <Skeleton variant="rectangular" height={40} />
                ))}
        </Stack>
    )
}

export default FeedForm