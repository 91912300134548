import { Box, Button, CircularProgress, Stack, TextField } from '@mui/material'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ToastAlert from '../../../../core/Layouts/ToastAlert'
import SideDialog from '../../components/SideDialog'
import DoFormsForm from './DoformsForm'

function DoFormsFormSettingsDialog({
	tileElementWidth,
	defaultTileWidth,
	tile,
	open,
	settings,
	isSubmitting,
	showStable,
	onClose,
	onSubmit,
	onResizeTileWidth,
}) {
	const { t } = useTranslation('common')
	const { id: dashboardKey } = useParams()

	const [alertInfo, setAlertInfo] = useState({
		open: false,
		text: '',
		type: 'success',
	})

	const [filterData, setFilterData] = useState({})

	const dialogWidth = tile?.w ?? 6
	const STABLE_WIDTH = 6

	useEffect(() => {
		if (settings?.defaultUrl) {
			setFilterData((prev) => ({ ...prev, defaultUrl: settings?.defaultUrl }))
		}
	}, [settings?.defaultUrl])

	// useEffect(() => {
	// 	if (settings?.isReload === false || settings?.isReload === true) {
	// 		setFilterData((prev) => ({ ...prev, isReload: settings?.isReload }))
	// 	}
	// }, [settings?.isReload])

	useEffect(() => {
		setFilterData((prev) => ({ ...prev, otherOptionsChecked: settings?.otherOptionsChecked }))
		
	}, [settings?.otherOptionsChecked])
	useEffect(() => {
		if (settings?.defaultProjectForm) {
			setFilterData((prev) => ({ ...prev, defaultProjectForm: settings?.defaultProjectForm }))
		}
	}, [settings?.defaultProjectForm])

	const handleFilterDataChange = (id, value) => {
		setFilterData((prev) => ({ ...prev, [id]: value }))
	}

	const handleSubmit = () => {
		if (!filterData?.defaultProjectForm || !filterData?.defaultProjectForm?.projectKey) {
			setAlertInfo((prev) => ({
				...prev,
				open: true,
				type: 'error',
				text: t('common:dis.pleaseFillInformation'),
			}))
			return
		}
		onSubmit?.({
			defaultUrl: filterData?.defaultUrl,
			defaultProjectForm: filterData?.defaultProjectForm,
			//isReload: filterData?.isReload,
			otherOptionsChecked: filterData?.otherOptionsChecked,
			columnName: filterData?.columnName,
			connectedDatagridKey: filterData?.connectedDatagridKey,
		})
	}

	return (
		<>
			<ToastAlert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />

			<SideDialog
				defaultTileWidth={defaultTileWidth}
				open={open}
				onClose={onClose}
				width={showStable ? STABLE_WIDTH : dialogWidth}
				tileElementWidth={tileElementWidth}
				onResizeWidth={onResizeTileWidth}
			>
				<Stack
					sx={{
						'& .MuiInputBase-input': {
							fontSize: '0.9rem !important',
						},
						'& .MuiCheckbox-root': {
							p: 0,
						},
					}}
				>
					<Box sx={{ px: 1 }}>
						<TextField
							fullWidth
							id="label-basic"
							label={t('common:chart.labels')}
							variant="standard"
							value={tile.i}
							disabled={true}
							size="small"
						/>
					</Box>
					
					<Box sx={{ px: 1, mt: 2}}>
						<DoFormsForm
							dashboardKey={dashboardKey}
							filterData={filterData}
							settings={settings}
							isDialog={true}
							onFilterDataChange={handleFilterDataChange}
							// onFilterDataChange={(id, value) => {
							// 	//if (filterData?.type === TYPES_OF_TILE.DO_FORMS_FORM) {
							// 		handleFilterDataChange(id, value)
							// 	//}
							// }}
						/>
					</Box>

					<Stack direction="row" justifyContent="flex-end" mt={2}>
						<Stack spacing={2} direction="row">
							<Button
								size="small"
								variant="contained"
								onClick={handleSubmit}
								disabled={isSubmitting}
								startIcon={isSubmitting ? <CircularProgress color="inherit" size="1em" /> : null}
							>
								{t('common:misc.ok')}
							</Button>
							<Button size="small" variant="text" onClick={onClose}>
								{t('common:misc.cancel')}
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</SideDialog>
		</>
	)
}

export default DoFormsFormSettingsDialog
