import React, { useEffect, useRef, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { FORM_ACTIONS } from '../reducers/formsReducer'
import SkeletonLoaderDialog from './skeletons/SkeletonLoaderDialog'
import { Box } from '@mui/material'

const IFRAME_MIN_HEIGHT = 500
const IFRAME_MAX_HEIGHT = 800

//init dialog height = 90%
function calculateIFrameHeight(suffix = '') {
	var h = window.innerHeight || 800
	let dialogContentHeight = parseInt((h * 0.9) * 0.84)
	let iHeight = dialogContentHeight - 20
	return !suffix ? iHeight : `${iHeight + suffix}`
}

function getBoxHeight(iframeHeight) {
	const iframeHeightStringWithoutPrefix = iframeHeight.replace('px', '')
	const currentHeightNumber = parseInt(iframeHeightStringWithoutPrefix)
	return `${currentHeightNumber + 5}px`
}

const DoformsPortal = ({ iframeSrc, onClose, onLoaded, iframeHeight, refreshData }) => {
	const ref = useRef()
	const initialState = {
		height: `${iframeHeight || IFRAME_MIN_HEIGHT + 'px'}`,
		width: '0px',
		minWidth: '0px',
	}
	const [values, setValues] = useState(initialState)
	const { height, width, minWidth } = values
	const dispatch = useDispatch()

	const onLoad = () => {
		let standardHeight = iframeHeight || calculateIFrameHeight('px')
		setValues({ ...values, height: standardHeight, loaded: true })
	}

	const handleClose = () => {
		setValues(initialState)
		onClose()
	}

	const handleSubmitted = () => {
		setValues(initialState)
		dispatch({
			type: FORM_ACTIONS.RECORDS_CHANGED,
			payload: true,
		})
		if (refreshData) {
			refreshData()
		}
		onClose()
	}

	const handleImportComplete = (event, reason) => {
		setValues(initialState)
		onClose(event, reason)
	}

	const onResized = (event) => {
		let newHeight = event.data.height
		let newWidth = event.data.width
		//resizing it to standard height when form is large.
		if (newHeight && newWidth) {
			let newMinWidth = newWidth + 60 + 'px'
			newWidth = newWidth + 60 + 'px'
			newHeight = iframeHeight || calculateIFrameHeight('px')
			setValues({ ...values, height: newHeight, width: newWidth, minWidth: newMinWidth })
		}
	}

	const handler = (event) => {
		if (event.data.eventType) {
			if (event.data.eventType === 'doforms-form-resized') {
				onResized(event)
			} else if (event.data.eventType === 'doforms-form-canceled') {
				handleClose()
			} else if (event.data.eventType === 'doforms-form-submitted') {
				handleSubmitted()
			} else if (event.data.eventType === 'doforms-form-loaded') {
				onLoaded(event)
			} else if (event.data.eventType === 'doforms-import-cancel') {
				handleClose()
			} else if (event.data.eventType === 'doforms-import-complete') {
				handleImportComplete(event, 'doforms-import-complete')
			}
		}
	}

	useEffect(() => {
		if (!iframeSrc) {
			setValues(initialState)
			// window.removeEventListener("message", handler);
			return
		}

		window.addEventListener('message', handler)
		// setValues({...values, height: "1200px"});

		return () => window.removeEventListener('message', handler)
	}, [iframeSrc])

	const boxHeight = useMemo(() => iframeHeight || getBoxHeight(height), [iframeHeight, height])

	return (
		<>
			{iframeSrc ? (
				<Box
					sx={{
						minHeight: `${boxHeight}`,
						maxHeight: `${IFRAME_MAX_HEIGHT}px`,
						background: '#fff',
						display: 'flex',
						height: boxHeight,
						overflowY: 'hidden',
					}}
				>
					<iframe
						ref={ref}
						onLoad={onLoad}
						id="myFrame"
						style={{
							// maxWidth: 640,
							width: '100%',
							overflow: 'auto',
							border: 0,
							height,
						}}
						sandbox="allow-forms allow-modals allow-popups allow-same-origin allow-scripts allow-downloads"
						src={iframeSrc}
					/>
				</Box>
			) : (
				<SkeletonLoaderDialog />
			)}
		</>
	)
}

export default DoformsPortal
