import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { List as ListIcon } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useTranslation } from 'react-i18next'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { REPORTS_ACTIONS } from '../../../reducers/reportsReducer'

const useStyles = makeStyles(() => ({
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const ReportsButton = ({ action, report }) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const { reportsModule } = useSelector((state) => state)
	const { reportSelected, action: actionStore } = reportsModule
	const checkDisabledIcon = `${actionStore}${reportSelected?.key}` === `${action}${report?.key}`

	const dispatch = useDispatch()

	const clickHandler = (e) => {
		e.preventDefault()
		e.stopPropagation()
		dispatch({
			type: REPORTS_ACTIONS.REPORT_SELECTED,
			payload: { ...report },
		})
		dispatch({
			type: REPORTS_ACTIONS.ACTION,
			payload: action,
		})
	}

	return (
		<Tooltip
			title={`${t('tooltip.prefix.reports')} - ${report.name}`}
			arrow
			placement="bottom-start"
			disableInteractive
		>
			<span>
				<IconButton
					aria-label="audit"
					size="small"
					color="primary"
					edge="end"
					disabled={checkDisabledIcon}
					onClick={(e) => clickHandler(e)}
				>
					<ListIcon
						fontSize="inherit"
						className={classes.icon}
						sx={{ color: checkDisabledIcon ? '#ddd !important' : 'inherit' }}
					/>
				</IconButton>
			</span>
		</Tooltip>
	)
}

export default ReportsButton
