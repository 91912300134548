import {
	Collapse,
	List,
	ListItem,
	ListItemButton,
	ListItemSecondaryAction,
	ListItemText,
	Tooltip,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import { EnvironmentContext } from '../../../custom-components/context/EnvironmentContext'
import { getDisplayedViews } from '../../../utils/functions/helpers'
import SkeletonLoaderSidePanel from '../../../custom-components/skeletons/SkeletonLoaderSidePanel'
import { VIEWS_ACTIONS } from '../../../reducers/viewsReducer'
import DashboardSearchBar from '../../core/Dashboard/DashboardSearchBar'
import DoformsDataViewDelete from '../../data/datagrid/DoformsDataViewDelete'
import ViewsButton from './ViewsButton'
import { VIEW } from '../../../constants'

const useStyles = makeStyles(() => ({
	viewHeading: {
		display: 'flex',
		alignSelf: 'stretch',
		paddingTop: 0.5,
		paddingBottom: 0.5,
		paddingRight: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)',
		},
		'& .MuiTypography-root': {
			fontWeight: 'bold',
			display: 'block',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	viewItem: {
		padding: 0,
		display: 'flex',
		overflow: 'hidden',
		justifyContent: 'center',
		alignItems: 'stretch',
	},
	viewItemBtn: {
		display: 'flex',
		padding: '1px 0px 1px 16px',
		'&:hover': {
			backgroundColor: 'white',
		},
		'& .MuiTypography-root': {
			display: 'block',
			fontSize: '15px',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	viewItemToolbar: {
		position: 'relative',
		top: 'auto',
		right: 'auto',
		display: 'flex',
		flex: '1 1 auto',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
		backgroundColor: '#fff',
		transform: 'none',
		paddingRight: '2px',
	},
	viewMenuListContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		flex: '1 1 0',
		overflow: 'hidden',
	},
	viewMenuList: {
		paddingTop: 0,
		overflow: 'hidden',
		height: 'inherit',
		'&:not(.loading):hover': {
			overflowY: 'auto',
		},
		'& .MuiListItem-root': {
			flexDirection: 'column',
		},
		'& .MuiCollapse-root': {
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'stretch',
		},
		'& .MuiListItem-container': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		'& .MuiListItem-container:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	viewMenuChildList: {
		'& ul li:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	loadingWrapper: {
		position: 'absolute',
		top: '20%',
		left: '45%',
		zIndex: '99999',
	},
}))

const ViewsDashboard = (props) => {
	const [t] = useTranslation('common')
	const { owners, loading } = props
	const fullViews = useMemo(() => {
		if (owners.length === 0) return []
		return owners?.reduce((r, data) => {
			if ('views' in data) {
				return [...r, ...data.views]
			} else return []
		}, [])
	}, [owners])
	const classes = useStyles()
	const { environment } = useContext(EnvironmentContext)
	const history = useHistory()
	const { viewsModule } = useSelector((state) => state)
	const { viewSelected, action } = viewsModule

	const [collapseOwners, setCollapseOwners] = useState([])
	const [itemName, setItemName] = useState(null)
	const [hovering, setHovering] = useState(false)
	const [searchText, setSearchText] = useState('')

	const filteredOwners = useMemo(() => {
		if (searchText === '') {
			return owners
		} else {
			return owners.reduce((r, { key, name, views }) => {
				let o = views?.filter(({ name }) => name.toLowerCase().includes(searchText))
				if (o && o.length) r.push({ key, name, views: [...o] })
				return r
			}, [])
		}
	}, [searchText, owners])
	const dispatch = useDispatch()

	useEffect(() => {
		const isLoadingKey = history.location.search.split('=')[0] === '?loading'
		if (fullViews.length === 0) return
		if (isLoadingKey) return

		const currentViewKey = history.location.search.split('=')[1]
		if (currentViewKey) {
			const currentView = fullViews?.find(({ key }) => key === currentViewKey)
			dispatch({
				type: VIEWS_ACTIONS.VIEW_SELECTED,
				payload: { ...currentView },
			})
			dispatch({
				type: VIEWS_ACTIONS.ACTION,
				payload: 'audit',
			})
		}
	}, [fullViews, history.location.search])

	const showLoading = () => (
		<>
			<div className={classes.loadingWrapper}>
				<LoadingSpinner withStyle={false} />
			</div>
			<SkeletonLoaderSidePanel />
		</>
	)

	const handlePopoverOpen = useCallback(
		(newItemName) => (event) => {
			event.preventDefault()
			setHovering((prev) => itemName !== newItemName || !prev)
			setItemName(newItemName)
		},
		[]
	)

	const handlePopoverClose = useCallback((e) => {
		e.preventDefault()
		setHovering(false)
	}, [])

	const showItemActions = (view, owner) => {
		return (
			itemName === `${owner.key} / ${view.key}` && (
				<ListItemSecondaryAction className={classes.viewItemToolbar}>
					<ViewsButton action={'audit'} view={view} ownerKey={owner.key} />
					<DoformsDataViewDelete
						environment={environment}
						view={view}
						recordsLoading={false}
						source={'button'}
					/>
				</ListItemSecondaryAction>
			)
		)
	}

	const onChangeValueHandler = (value) => {
		const term = !value ? '' : value.toLowerCase()
		setSearchText(term)
	}

	const handleCollapse = (ownerKey) => {
		const found = collapseOwners.includes(ownerKey)
		if (!found) {
			setCollapseOwners([...collapseOwners, ownerKey])
		} else {
			setCollapseOwners(collapseOwners.filter((item) => item !== ownerKey))
		}
	}

	const clickHandler = (action, view, owner) => (event) => {
		event.preventDefault()
		dispatch({
			type: VIEWS_ACTIONS.VIEW_SELECTED,
			payload: { ...view, ownerKey: owner.key },
		})
		dispatch({
			type: VIEWS_ACTIONS.ACTION,
			payload: action,
		})
	}

	return (
		<>
			<DashboardSearchBar onChangedValue={onChangeValueHandler} tab={VIEW.VIEW_TAB_VIEWS} />
			<div className={classes.viewMenuListContainer}>
				{loading && showLoading()}
				{!loading && (
					<List
						className={`${classes.viewMenuList} ${loading ? t('common:misc.loading').toLowerCase() : ''
							}`}
					>
						{filteredOwners.length > 0 &&
							filteredOwners.map((owner, index) => {
								const filteredView = getDisplayedViews(owner.views)
								if (!filteredView) return null
								if (!filteredView.length) return null
								return (
									<ListItem
										key={owner.key}
										ContainerProps={{
											onMouseLeave: handlePopoverClose,
										}}
										disablePadding
									>
										<ListItemButton
											className={classes.viewHeading}
											onClick={() => handleCollapse(owner.key)}
										>
											<ListItemText primary={owner.name} />
										</ListItemButton>
										<Collapse
											id={index}
											in={!collapseOwners.includes(owner.key)}
											timeout="auto"
											unmountOnExit
										>
											<List className={classes.viewMenuChildList} disablePadding>
												{filteredView.map((view) => (
													<ListItem
														key={view.key}
														className={classes.viewItem}
														onMouseEnter={handlePopoverOpen(`${owner.key} / ${view.key}`)}
														ContainerProps={{
															onMouseLeave: handlePopoverClose,
														}}
													>
														<ListItemButton
															className={classes.viewItemBtn}
															disabled={viewSelected?.key === view?.key}
															onClick={clickHandler('audit', view, owner)}
														>
															<ListItemText
																primary={
																	<Tooltip
																		title={view.name}
																		arrow
																		placement="bottom-start"
																		disableInteractive
																		enterDelay={1000}
																		enterNextDelay={1000}
																	>
																		<span>{view.name}</span>
																	</Tooltip>
																}
															/>
														</ListItemButton>
														{hovering && showItemActions(view, owner)}
													</ListItem>
												))}
											</List>
										</Collapse>
									</ListItem>
								)
							})}
					</List>
				)}
			</div>
		</>
	)
}

export default ViewsDashboard
