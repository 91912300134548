import { Collapse, List, ListItem, ListItemButton, ListItemText, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Alert, LoadingButton } from '@mui/lab'

import { useContext, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { API } from '../../../config'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import { EnvironmentContext } from '../../../custom-components/context/EnvironmentContext'
import SkeletonLoaderSidePanel from '../../../custom-components/skeletons/SkeletonLoaderSidePanel'
import DashboardSearchBar from '../../core/Dashboard/DashboardSearchBar'
import DoformsSendDispatch from '../../data/dispatch/DoformsSendDispatch'
import DoformsCreateSubmission from '../../data/submissions/DoformsCreateSubmission'
import { hasCreatePermission } from 'utils/functions/helpers'
import AlertDialog from './AlertDialog'

const useStyles = makeStyles(() => ({
	formHeading: {
		display: 'flex',
		alignSelf: 'stretch',
		paddingTop: 0.5,
		paddingBottom: 0.5,
		paddingRight: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)',
		},
		'& .MuiTypography-root': {
			fontWeight: 'bold',
			display: 'block',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	formItem: {
		padding: 0,
		display: 'flex',
		overflow: 'hidden',
		justifyContent: 'center',
		alignItems: 'stretch',
	},
	formItemBtn: {
		display: 'flex',
		padding: '1px 0px 1px 16px',
		'&:hover': {
			backgroundColor: 'white',
			cursor: 'pointer',
		},
		'& .MuiTypography-root': {
			display: 'block',
			fontSize: '15px',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	formItemToolbar: {
		position: 'relative',
		top: 'auto',
		right: 'auto',
		display: 'flex',
		flex: '1 1 auto',
		justifyContent: 'flex-end',
		alignItems: 'flex-start',
		backgroundColor: '#fff',
		transform: 'none',
		paddingRight: '2px',
	},
	formMenuListContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		flex: '1 1 0',
		overflow: 'hidden',
	},
	formMenuList: {
		paddingTop: 0,
		overflow: 'hidden',
		height: 'inherit',
		'&:not(.loading):hover': {
			overflowY: 'auto',
		},
		'& .MuiListItem-root': {
			flexDirection: 'column',
		},
		'& .MuiCollapse-root': {
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'stretch',
		},
		'& .MuiListItem-container': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		'& .MuiListItem-container:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	formMenuChildList: {
		'& ul li:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	loadingWrapper: {
		position: 'absolute',
		top: '20%',
		left: '40%',
		zIndex: '99999',
	},
	formIconDefault: (props) => ({
		color: props?.color,
		'&:hover': {
			backgroundColor: 'transparent',
		},
		cursor: 'pointer',
		height: '32px',
		width: '32px',
		paddingLeft: '',
		paddingRight: '',
	}),
	icon: (props) => ({
		color: props?.color,
		'&:hover': {
			backgroundColor: 'transparent',
		},
		cursor: 'pointer',
		height: '32px',
		width: '32px',
		paddingLeft: '',
		paddingRight: '',
	}),
}))

const FormsTile = (props) => {
	const [t] = useTranslation('common')
	const classes = useStyles()
	const { projects, loading, module, env } = props
	const { environment } = useContext(EnvironmentContext)
	const permissions = environment?.userCurrent?.rights

	const { formsModule } = useSelector((state) => state)
	const { formSelected, action } = formsModule

	const [openSendDispatch, setOpenSendDispatch] = useState(false)
	const [collapseProjects, setCollapseProjects] = useState([])
	const [skeletonLoading, setSkeletonLoading] = useState(true)
	const [searchText, setSearchText] = useState('')
	const [selectedForm, setSelectedForm] = useState(null)
	const [itemName, setItemName] = useState(null)

	const [openAlertDialog, setOpenAlertDialog] = useState(false)
	const handleOpenAlertDialog = () => {
		setOpenAlertDialog(true)
	}
	const handleCloseAlertDialog = () => {
		setOpenAlertDialog(false)
	}

	const filteredProjects = useMemo(() => {
		if (searchText === '') {
			return projects
		} else {
			return projects.reduce((r, { key, name, forms }) => {
				let o = forms.filter(({ name }) => name.toLowerCase().includes(searchText))
				if (o && o.length) r.push({ key, name, forms: [...o] })
				return r
			}, [])
		}
	}, [searchText, projects])

	useLayoutEffect(() => {
		if (projects && projects.length > 0) {
			setSkeletonLoading(false)
		} else {
			setSkeletonLoading(true)
		}
	}, [projects])

	const showLoading = () => (
		<>
			<div className={classes.loadingWrapper}>
				<LoadingSpinner withStyle={false} />
			</div>
			{skeletonLoading && <SkeletonLoaderSidePanel />}
		</>
	)

	const handleOpenSendDispatch = (value) => {
		setOpenSendDispatch(!!value)
	}

	const onChangeValueHandler = (value) => {
		const term = !value ? '' : value.toLowerCase()
		setSearchText(term)
	}

	const handleCollapse = (projectKey) => {
		const found = collapseProjects.includes(projectKey)
		if (!found) {
			setCollapseProjects([...collapseProjects, projectKey])
		} else {
			setCollapseProjects(collapseProjects.filter((item) => item !== projectKey))
		}
	}

	const getFormIcon = (formIcon) => {
		var iconPath = ''
		var url = ''
		if (!formIcon || !formIcon?.url) {
			iconPath = '/img/mobile/formIconDefault.png'
			url = API + iconPath
		} else {
			iconPath = formIcon.url
			if (iconPath.indexOf('http://') >= 0 || iconPath.indexOf('https://') >= 0) {
				url = API + iconPath
			} else if (iconPath.indexOf('/') < 0) {
				if (iconPath.indexOf('imageViewer?') == 0) {
					url = API + '/' + iconPath + '&x=' + new Date()
				} else {
					url = API + '/imageViewer?blobKey=' + iconPath + '&x=' + new Date()
				}
			} else {
				url = API + '/' + iconPath
			}
		}
		return (
			<div>
				<img src={url} class={classes.icon} />
			</div>
		)
	}

	return (
		<>
			<AlertDialog
				open={openAlertDialog}
				handleClose={handleCloseAlertDialog}
				title=""
				msg={t('common:formsData.notPrivilegeAddNewRecord')}
			/>
			<DashboardSearchBar onChangedValue={onChangeValueHandler} />
			<div className={classes.formMenuListContainer}>
				{loading && showLoading()}
				<List
					className={`${classes.formMenuList} ${loading ? t('common:misc.loading').toLowerCase() : ''
						}`}
				>
					{filteredProjects.length > 0 &&
						filteredProjects.map((project, index) => {
							if (!project.forms) return null
							if (!project.forms.length) return null
							return (
								<ListItem key={project.key} disablePadding>
									<ListItemButton
										className={classes.formHeading}
										onClick={() => handleCollapse(project.key)}
									>
										<ListItemText primary={project.name} />
									</ListItemButton>
									<Collapse
										id={index}
										in={!collapseProjects.includes(project.key)}
										timeout="auto"
										unmountOnExit
									>
										<List className={classes.formMenuChildList} disablePadding>
											{project.forms.map((form) => (
												<ListItem key={form.key} className={classes.formItem}>
													<ListItemButton
														className={classes.formItemBtn}
														disabled={
															`${formSelected?.key}${formSelected?.projectKey}` ===
															`${form?.key}${project?.key}` && action === 'audit'
														}
														onClick={() => {
															if (permissions && hasCreatePermission(permissions)) {
																setSelectedForm({ ...form, projectKey: project.key })
																setItemName(`${project.name} / ${form.name}`)
															} else {
																handleOpenAlertDialog()
															}
														}}
													>
														<LoadingButton
															id={'create-submission'}
															className={classes.icon}
															loadingPosition="start"
														>
															{getFormIcon(form.icon)}
														</LoadingButton>
														<ListItemText
															primary={
																<Tooltip
																	title={t('common:misc.new') + ' - [' + form.displayName + ']'}
																	arrow
																	placement="bottom-start"
																	disableInteractive
																	enterDelay={1000}
																	enterNextDelay={1000}
																>
																	<span>{form.name}</span>
																</Tooltip>
															}
														/>
													</ListItemButton>
												</ListItem>
											))}
										</List>
									</Collapse>
								</ListItem>
							)
						})}
				</List>
			</div>

			{!environment.isReadOnlySubmission && <DoformsCreateSubmission
				formSelected={selectedForm}
				action={'add'}
				title={itemName}
				recordsLoading={false}
				source={'formTiles'}
				tab={'forms'}
			/>}

			{!environment.isReadOnlyDispatch && <DoformsSendDispatch
				onDialogOpen={handleOpenSendDispatch}
				formSelected={module.formSelected}
				action={'send'}
				title={itemName}
				recordsLoading={false}
				source={'FormsDashboard'}
				tab={'forms'}
				environment={env}
			/>}
		</>
	)
}

export default FormsTile
