import { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { unionBy } from 'lodash'
import { FormControl, InputLabel, MenuItem, Select, Skeleton, Stack } from '@mui/material'

import useTileQuery from '../../hooks/useTileQuery'
import { TYPES_OF_TILE } from '../../../../../constants'
import CheckboxList from 'components/pages/dis-v2/_components/CheckboxList'

function MobileUnitsForm(props) {
    const {
        dashboardKey,
        filterData,
        enabledFetchTileList = true,
        datagridListWhenCreating = [],
        onFilterDataChange,
    } = props

    const { t } = useTranslation('common')
    const mobileUnitTilePrimaryKeyColumns = [
        {
            title: 'Mobile number',
            name: 'Mobile_number',
            mobileUnitKey: 'number',
            hide: false
        },
        {
            title: 'Nickname',
            name: 'Nickname',
            mobileUnitKey: 'name',
            hide: false
        },
    ]

    const [primaryKeyList, setPrimaryKeyList] = useState([])

    const { tileList, isLoading } = useTileQuery({
        dashboardKey,
        enableConfig: enabledFetchTileList,
    })

    const dataGridTile = useMemo(() => {
        const currentList =
            tileList?.length > 0 ? tileList?.filter((tile) => tile?.type === TYPES_OF_TILE.DATA_GRID) : []

        return unionBy(currentList, datagridListWhenCreating, 'name')
    }, [tileList, datagridListWhenCreating])

    const handleSelectChange = (e) => {
        const key = e.target.value
        const name = dataGridTile.find((tile) => tile?.key === key)?.name
        const selectedTile = dataGridTile.find((tile) => tile?.key === key)
        onFilterDataChange('connectedDatagridKey', key)
        if (selectedTile?.is_creating) {
            onFilterDataChange('connectedNameWithNewDatagrid', name)
        }
    }

    useEffect(() => {
        if (filterData?.primaryKeyList) {
            setPrimaryKeyList(filterData?.primaryKeyList)
        }
    }, [filterData?.primaryKeyList])

    const handleSetPrimaryKey = (id) => {
        if (primaryKeyList.includes(id)) {
            onFilterDataChange('primaryKeyList', primaryKeyList.filter((item) => item !== id))
        } else {
            onFilterDataChange('primaryKeyList', [...primaryKeyList, id])
        }
    }

    return (
        <Stack spacing={2}>
            {dataGridTile?.length > 0 &&
                (!isLoading ? (
                    <FormControl variant="outlined" size="small">
                        <InputLabel id="datagrid-select-small-label">
                            {t('common:input.datagridToConnect')}
                        </InputLabel>
                        <Select
                            labelId="datagrid-select-small-label"
                            id="datagrid-select-small"
                            value={filterData?.connectedDatagridKey ?? ''}
                            label={t('common:input.datagridToConnect')}
                            onChange={handleSelectChange}
                        >
                            <MenuItem value="">
                                <em>None</em>
                            </MenuItem>
                            {dataGridTile.map((tile) => (
                                <MenuItem value={tile?.key} key={tile?.key}>
                                    {tile?.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <Skeleton variant="rectangular" height={40} />
                ))}

            <CheckboxList
                title={t('common:dis.selectFields')}
                enableIcon
                enableCheckbox={false}
                onIconClick={handleSetPrimaryKey}
                checkedIconList={primaryKeyList}
                data={mobileUnitTilePrimaryKeyColumns}
                onItemClick={() => { }}
                checkedList={[]}
                getLabel={(option) => option.title}
                getValue={(option) => option.name}
            />

        </Stack>
    )
}

export default MobileUnitsForm