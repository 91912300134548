import { useMemo, memo, useReducer, useContext, useLayoutEffect, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@mui/styles'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'

import { isEmpty, cloneDeep } from 'lodash'
import { Add, Delete } from '@mui/icons-material'
import { Stack, Grid, Button, Typography, List, ListItem, ListItemIcon } from '@mui/material'

import CollapseComponent from '../../../../data/datagrid/CreatViewComponents/collapses/CollapseComponent'
import ColorPickerItem from '../../../dis-v2/menu-items/_components/ConditionalColorPickerDialog/ColorPickerItem'
import SelectField from 'components/pages/dis/components/SelectField'
import ButtonWithConfirm from '../../../dis-v2/menu-items/_components/ButtonWithConfirm'
import {
	settingReducer,
	SETTING_DISPATCH_TYPE,
	VALUE_TYPE,
	getOperatorLabel,
	DATE_VALUE,
} from '../../../dis-v2/menu-items/_components/ConditionalColorPickerDialog/utils'

import {
	SETTING_TYPES,
	shouldPreventRender,
} from '../../../../data/datagrid/CreatViewComponents/ViewDialogUtils'
import { DEFAULT_LOGIC_OPERATOR, getColorWithoutId } from 'utils/functions/helpers'
import SchedulerColorPickerItem from './SchedulerColorPickerItem'

const useStyles = makeStyles(() => ({
	primaryColumn: {
		margin: 0,
		marginBottom: '12px',

		'& .MuiOutlinedInput-root ': {
			height: '39px',
		},
	},

	colRight: {
		display: 'inline-grid',
		width: '65%',
		textAlign: 'left',
		color: '#a6a6a6',
		minHeight: '30px',
		fontSize: '0.875rem',
		fontWeight: 400,
		'&:hover': {
			cursor: 'pointer',
		},
	},
}))

export const DATA_OBJ_ACTION_TYPES = {
	INIT_DATA: 'initdata',
	ADD: 'add',
	UPDATE_COLOR: 'update_color',
	UPDATE_STATUS: 'update_status',
	DELETE: 'delete',
}

function dataObjectReducer(state, action) {
	switch (action.type) {
		case DATA_OBJ_ACTION_TYPES.INIT_DATA:
			return action.initData
		case DATA_OBJ_ACTION_TYPES.ADD: {
			const currentState = cloneDeep(state)
			const DEFAULT_COLOR = '#000000'
			const newSetting = { label: '', value: '', color: DEFAULT_COLOR }
			currentState.push(newSetting)
			return currentState
		}
		case DATA_OBJ_ACTION_TYPES.UPDATE_COLOR: {
			const currentState = cloneDeep(state)
			currentState[action.target.index].value = action.target.value
			return currentState
		}
		case DATA_OBJ_ACTION_TYPES.UPDATE_STATUS: {
			const currentState = cloneDeep(state)
			currentState[action.target.index].label = action.target.value
			currentState[action.target.index].value = action.target.value
			return currentState
		}
		case DATA_OBJ_ACTION_TYPES.DELETE: {
			const currentState = cloneDeep(state)
			currentState.splice(action.target.index, 1)
			
			return currentState
		}
		default:
			return { ...state }
	}
}

function ColorPickerComponent(props) {
	const {
		title,
		filterDataSetting,
		onFilterDataChange,
		defaultSchedulerStatus,
	} = props

	const [t] = useTranslation('common')
	const statusColorDisplay = useMemo(() => {
		if (filterDataSetting) return filterDataSetting

		const statusSettings =  defaultSchedulerStatus.map((status) => {
			return { label: status.label, value: status.name, color: `#${status.color}`}
		})
		
		return statusSettings
	}, [filterDataSetting, defaultSchedulerStatus])
	
	const statusOptions = useMemo(() => {
		return defaultSchedulerStatus.map((status) => {
			return { label: status.label, value: status.name }
		})
	}, [defaultSchedulerStatus])
	const handleAddColor = () => {
		const currentState = cloneDeep(statusColorDisplay)
		const DEFAULT_COLOR = '#000000'
		const newSetting = { label: '', value: '', color: DEFAULT_COLOR }
		currentState.push(newSetting)
		onFilterDataChange('schedulerColorStatusSettings', currentState)
	}
	const handleDeleteItem = (index) => {
		const currentState = cloneDeep(statusColorDisplay)
		currentState.splice(index, 1)
		onFilterDataChange('schedulerColorStatusSettings', currentState)
	}
	return (
		<div>
			<Typography variant="subtitle2" sx={{ pl: 1 }}>
				{title}
			</Typography>
			<List
				sx={{
					width: '100%',
					minHeight: '180px',
					overflowY: 'none',
					border: '1px solid #ddd',
					borderRadius: 1,
					mb: 2,
				}}
				dense
			>
				{statusColorDisplay?.length > 0 &&
					statusColorDisplay.map((item, index) => {
						return (
							<ListItem
								// key={optionValue}
								disablePadding
								sx={{
									marginBottom: '5px',
									marginLeft: '5px',
									'& .MuiButtonBase-root': {
										py: '2px',
									},
								}}
							>
								<SchedulerColorPickerItem
									statusColorDisplay={statusColorDisplay}
									key={item.label}
									index={index}
									shedulerStatus={statusOptions}
									color={item.color}
									statusValue={item.label}
									onFilterDataChange={onFilterDataChange}
								/>
								<ListItemIcon>
									<ButtonWithConfirm
										title={t('common:dis.confirmDeleteItem')}
										onSubmit={() => handleDeleteItem(index)}
									>
										<Button
											aria-label="delete"
											size="small"
											variant="contained"
											sx={{
												borderRadius: '50%',
												width: '25px !important',
												minWidth: '25px !important',
												height: '25px !important',
												marginLeft: '10px !important',
											}}
										>
											<Delete fontSize="small" />
										</Button>
									</ButtonWithConfirm>
								</ListItemIcon>
							</ListItem>
						)
					})}
				<div style={{ display: 'flex', alignItems: 'center', gap: 8}}>
					<Button
						aria-label="add"
						size="small"
						variant="contained"
						sx={{
							borderRadius: '50%',
							width: '25px !important',
							minWidth: '25px !important',
							height: '25px !important',
							marginLeft: 'auto',
							marginRight: '16px',
						}}
						onClick={() => {
							handleAddColor()
						}}
					>
						<Add fontSize="small" />
					</Button>
				</div>
			</List>
		</div>
	)
}

export const SchedulerStatusColorPicker = memo(ColorPickerComponent, shouldPreventRender)
