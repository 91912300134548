import { OpenInNew as OpenInNewIcon } from '@mui/icons-material'
import { Box, Grid, IconButton, MenuItem, TextField, Tooltip, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import { formatDate } from '../../../utils/functions/doformsDateUtil'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import { getReportExcelSrc, getReportList, getReportPdfSrc } from '../../data/dataServices'
import { getLinkPrefix } from '../../../utils/functions/helpers'

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		padding: '8px',
		'& .MuiButton-root': {
			textDecoration: 'none',
		},
		'& .MuiOutlinedInput-input': {
			cursor: 'pointer',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			'& legend': {
				display: 'none',
			},
		},
	},
	container: {
		width: '100%',
		height: '100%',
	},
	headingContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	heading: {
		display: 'flex',
		paddingLeft: '10px',
	},
	selectionBox: {
		display: 'inline-grid',
		marginTop: '10px',
		textAlign: 'left',
	},
	headingAction: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const ReportsData = ({ environment, module }) => {
	const [t] = useTranslation('common')

	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)

	const [reportSelected, setReportSelected] = useState(null)
	const [reportList, setReportList] = useState([])
	const [optionSelected, setOptionSelected] = useState(null)
	const [optionSelectedType, setOptionSelectedType] = useState(null)
	const [reportMessage, setReportMessage] = useState(null)

	const dispatch = useDispatch()

	useEffect(() => {
		if (_.isEmpty(module.reportSelected)) return
		var newFullScreenDataStr = localStorage.getItem('newFullScreenData_reports')
		if (newFullScreenDataStr !== null) {
			var newFullScreenData = JSON.parse(newFullScreenDataStr)
			if (newFullScreenData != null && newFullScreenData.reportsData != null) {
				var reportsData = newFullScreenData.reportsData
				setReportSelected(reportsData.reportSelected)
				setReportList(reportsData.reportList)
				setOptionSelected(reportsData.optionSelected)
				setOptionSelectedType(reportsData.optionSelectedType)
				setReportMessage(reportsData.reportMessage)

				/*setLoading(reportsData.loading);
                setError(reportsData.error);*/
			}

			localStorage.removeItem('newFullScreenData_reports')
		} else {
			setReportSelected(module.reportSelected)
			loadReportList(module.reportSelected)
		}
	}, [module.reportSelected])

	const loadReportList = (report) => {
		if (!report.key) {
			setReportMessage(t('common:misc.noReportsToShow'))
			return
		}
		setLoading(true)
		getReportList(report.key, environment.apiToken)
			.then((res) => {
				if (!res.data.length) {
					setReportMessage(t('common:misc.noReportsToShow'))
				} else {
					setReportList(_.sortBy([...res.data], 'createDate'))
				}
			})
			.catch((err) => {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const showLoading = () => loading && <LoadingSpinner />

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={() => setError(null)} />
		)

	const displayMessage = () => (
		<Typography variant="h6">{t('common:misc.noResultsForReport')}</Typography>
	)

	const previewPdf = () => {
		if (!reportSelected || !optionSelected) return
		const src = getReportPdfSrc(reportSelected.key, optionSelected, environment.apiToken)
		return <iframe id="pdfViewer" src={src} frameBorder="0" height="100%" width="100%" />
	}

	const downloadExcel = () => {
		if (!reportSelected || !optionSelected) return
		const src = getReportExcelSrc(reportSelected.key, optionSelected, environment.apiToken)
		let link = document.createElement('a')
		link.href = src
		link.click()
	}

	const handleOptionSelection = (e) => {
		let value = e.target.value
		let optionType = reportList.find((item) => item.key === value).type
		setOptionSelectedType(optionType)
		setOptionSelected(value)
	}

	const showReportListSelection = () => (
		<>
			<Typography variant={'body1'}>{t('common:misc.pleaseSelectAReportDate')}</Typography>
			{
				<div className={classes.headingAction}>
					<TextField
						id="report-list-options"
						className={classes.selectionBox}
						sx={{ width: '300px' }}
						size="small"
						color="primary"
						variant="outlined"
						s
						select={true}
						value={optionSelected}
						onChange={handleOptionSelection}
					>
						{reportList.map((option, index) => (
							<MenuItem key={index} value={option.key}>
								{`${formatDate(option.createDate, 0, 'M/D/YYYY')} (${option.type})`}
							</MenuItem>
						))}
					</TextField>
					<Tooltip
						title={`${t('tooltip.openInNewTab')}`}
						arrow
						placement="bottom-start"
						disableInteractive
					>
						<span>
							<IconButton
								aria-label="Open in new tab"
								size="small"
								color="primary"
								edge="end"
								onClick={() => onHandleOpenFullScreenTab()}
							>
								<OpenInNewIcon fontSize="inherit" className={classes.icon} />
							</IconButton>
						</span>
					</Tooltip>
				</div>
			}
		</>
	)

	const showReportTitle = () => {
		return (
			<Box sx={{ flexGrow: 1 }}>
				<Grid container spacing={2} style={{ alignItems: 'center' }}>
					<Grid item xs={12} className={classes.headingContainer}>
						<Typography variant={'h6'} className={classes.heading}>
							{reportSelected.name}
						</Typography>
					</Grid>
				</Grid>
			</Box>
		)
	}

	const onHandleOpenFullScreenTab = () => {
		localStorage.setItem('newFullScreenData_env', JSON.stringify({ environment: environment }))
		localStorage.setItem('newFullScreenData_mod', JSON.stringify({ reportsModule: module }))
		var newFullScreenDataReports = {
			reportSelected: reportSelected,
			reportList: reportList,
			optionSelected: optionSelected,
			optionSelectedType: optionSelectedType,
			reportMessage: reportMessage,
			loading: loading,
			error: error,
		}

		localStorage.setItem(
			'newFullScreenData_reports',
			JSON.stringify({ reportsData: newFullScreenDataReports })
		)

		//window.open('/reports/' + reportSelected.key, '_blank_' + Math.random())
		window.open(getLinkPrefix('/reports'), '_blank_' + Math.random())
	}

	return (
		<div className={classes.root}>
			{showLoading()}
			{showErrorMessage()}
			<div className={classes.container}>
				{reportSelected && showReportTitle()}
				{!_.isEmpty(reportList) && showReportListSelection()}
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						height: 'CALC(100% - 100px)',
						paddingBottom: '5px',
					}}
				>
					{reportMessage && displayMessage()}
					{/*We want to preview reports that are in PDF type on screen and download Excel type locally*/}
					{optionSelected && optionSelectedType === 'pdf' ? previewPdf() : downloadExcel()}
				</div>
			</div>
		</div>
	)
}

export default ReportsData
