import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { Resizable } from 're-resizable'
import { useTranslation } from 'react-i18next'

export default function SideDialog({
	defaultTileWidth,
	open,
	onClose,
	children,
	width,
	tileElementWidth,
	onResizeWidth,
}) {
	const { t } = useTranslation('common')
	const isSmallSize = width < 4
	const minWidthResize = isSmallSize ? '100%' : 400

	const handleResizeWidth = (e, direction, ref, d) => {
		onResizeWidth?.(ref.clientWidth)
	}

	return (
		<Resizable
			style={{
				height: '100%',
				maxWidth: '300px',
				position: 'fixed',
				right: 0,
				top: 0,
				bottom: 0,
				backgroundColor: '#fff',
				display: open ? 'block' : 'none',
				zIndex: 9999,
			}}
			handleStyles={{ right: { cursor: 'ew-resize' } }}
			enable={{
				top: false,
				right: false,
				bottom: false,
				left: true,
				topRight: false,
				bottomRight: false,
				bottomLeft: false,
				topLeft: false,
			}}
			defaultSize={{ width: defaultTileWidth }}
			minWidth={minWidthResize}
			maxWidth={tileElementWidth || minWidthResize}
			onResizeStop={handleResizeWidth}
		>
			<Box
				sx={{
					borderRadius: '8px 0 0 8px',
					boxShadow: 6,
					height: '100%',
					overflowY: 'auto',
					p: 1,
				}}
			>
				<Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
					<Typography variant="body2" ml="7px">
						{t('common:dis.options')}
					</Typography>
					<IconButton aria-label="close-settings-dialog" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Stack>

				<Box>{children}</Box>
			</Box>
		</Resizable>
	)
}
