import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import { getViewTabViewRecords } from '../../../data/dataServices'

const fieldKeys = {
	all: ['fields'],
	detail: (viewKey) => [...fieldKeys.all, 'detail', viewKey],
}

function useFields ({ viewKey, viewType }) {
	const { environment } = useSelector((state) => state)

	const { data, isLoading, isFetching } = useQuery(
		fieldKeys.detail(viewKey),
		() =>
			getViewTabViewRecords({
				viewKey,
				token: environment.apiToken,
				shouldRemoveViewSession: true,
				viewType,
			}),
		{
			enabled: Boolean(viewKey),
		}
	)

	return {
		fields: data?.data?.columns ?? [],
		fieldLoading: isLoading && isFetching,
	}
}

export default useFields
