import { Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { isEmpty } from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from '../../../utils/functions/doformsDateUtil'

const StyledTableCell = styled(TableCell)(() => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#F8F7F7',
		color: 'black',
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}))

const StyledTableRow = styled(TableRow)(() => ({
	'&:nth-of-type(odd)': {
		backgroundColor: '#FBFBFB',
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}))

const DoformsRecordHistory = (props) => {
	const [t] = useTranslation('common')
	const { environment, recordHistory, columns } = props

	const rows = recordHistory?.history?.length ? recordHistory.history.reverse() : []

	const timeZoneOffset = useMemo(() => {
		if (!recordHistory || !recordHistory.meta) {
			return 0
		}
		return recordHistory.meta.offset || 0
	}, [recordHistory])

	const dateTimeFormat = useMemo(() => {
		if (columns) {
			let filteredColumns = columns.filter(
				(item) => ['DATETIME'].includes(item.type) && !isEmpty(item.format)
			)
			if (filteredColumns.length > 0) {
				return filteredColumns[0].format
			}
		}
		if (!environment.userCurrent || !environment.userCurrent.time) {
			return 'MM/DD/YYYY h:mm a'
		}
		return (
			environment.userCurrent.time.dateFormat.toUpperCase() +
			' ' +
			environment.userCurrent.time.timeFormat.replace('aa', 'A')
		)
	}, [environment.userCurrent, columns])

	const renderTable = () => {
		return (
			<TableContainer
				sx={{ mt: 2, maxHeight: 400, border: '0.5px solid lightgray', borderRadius: '5px' }}
			>
				<Table stickyHeader sx={{ minWidth: 650 }} size="small" aria-label="record history table">
					<TableHead>
						<TableRow>
							<StyledTableCell>Status</StyledTableCell>
							<StyledTableCell>Date</StyledTableCell>
							<StyledTableCell>By</StyledTableCell>
							<StyledTableCell>Note</StyledTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.map((row) => (
							<StyledTableRow
								key={row.key}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<StyledTableCell component="th" scope="row">
									{row.status}
								</StyledTableCell>
								<StyledTableCell>
									{formatDate(row.date, timeZoneOffset, dateTimeFormat)}
								</StyledTableCell>
								<StyledTableCell>{row.name}</StyledTableCell>
								<StyledTableCell>{row.note ? row.note : ''}</StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		)
	}

	return (
		<>
			<Typography variant="subtitle1">{recordHistory.name}</Typography>
			{recordHistory.history.length ? renderTable() : t('common:misc.noRecordHistory')}
		</>
	)
}

export default DoformsRecordHistory
