import { useQuery } from '@tanstack/react-query'
import mapApi from 'apis/disApi/mapApi'

export const currentVehicleKeys = {
	all: ['current-map-user'],
	detail: (userInfo) => [...currentVehicleKeys.all, userInfo],
}

function useCurrentLoggedMapUser({ userInfo }) {
	const { data, isLoading, isFetching } = useQuery({
		queryKey: currentVehicleKeys.detail(userInfo),
		queryFn: () =>
			mapApi.getCurrentUserFromMygeotab({
				userInfo,
			}),
		enabled: Boolean(userInfo),
	})

	return {
		data: data?.data?.result ?? [],
		isLoading,
		isFetching,
	}
}

export default useCurrentLoggedMapUser
