import { useDebounce } from 'utils/hooks/useDebounce'
import useGetPathName from 'utils/hooks/useGetPathName'
import { RefreshOutlined, AddCircleOutline } from '@mui/icons-material'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, TextField, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { SEARCH_INPUT_STORAGE } from '../../../constants'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { searchInputStorage } from '../../../utils/functions/helpers'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import '../../../styles.css'
import DoformsDataSaveViewDialog from 'components/data/datagrid/DoformsDataSaveViewDialog'

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		justifyContent: 'start',
		width: '95%',
	},
	button: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const DashboardSearchBar = ({ onChangedValue, tab }) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const firstPathName = useGetPathName()
	const { environment } = useSelector((state) => state)

	const [searchInput, setSearchInput] = useState(() => {
		const value = searchInputStorage.get(firstPathName) ?? ''
		if (value) {
			return value
		} else return ''
	})
	const debounceSearchInput = useDebounce(searchInput, 500)

	const inputRef = useRef()
	const dispatch = useDispatch()

	useEffect(() => {
		onChangedValue(debounceSearchInput)
	}, [debounceSearchInput])

	useEffect(() => {
		const value = searchInputStorage.get(firstPathName) ?? ''
		if (value) {
			setSearchInput(value)
		}
	}, [])

	useEffect(() => {
		// Clear session storage when reload page
		window.onunload = function () {
			sessionStorage.removeItem(SEARCH_INPUT_STORAGE)
		}
	}, [])

	const setSearchGroupToSessionStorage = (value = '') => {
		searchInputStorage.set(firstPathName, value)
	}

	const onCleared = (e) => {
		e.preventDefault()
		inputRef.current.value = ''
		setSearchInput('')
		setSearchGroupToSessionStorage('')
	}

	const handleRefresh = () => {
		dispatch({
			type: ENV_ACTIONS.REFRESH,
			payload: true,
		})
	}

	const handleChangeInput = (e) => {
		setSearchInput(e.target.value)
		setSearchGroupToSessionStorage(e.target.value || '')
	}

	return (
		<div className={classes.root}>
			<TextField
				id="search-input"
				ref={inputRef}
				sx={{ mt: 0.5, mb: 0.5, pl: 1, pr: 1 }}
				InputLabelProps={{ shrink: false }}
				placeholder={t('common:misc.search')}
				size="small"
				fullWidth
				value={searchInput}
				onChange={handleChangeInput}
				InputProps={{
					endAdornment: debounceSearchInput ? (
						<IconButton aria-label="clear" size="small" edge="end" onClick={onCleared}>
							<ClearIcon fontSize="inherit" />
						</IconButton>
					) : (
						<IconButton aria-label="clear" size="small" edge="end" disabled>
							<SearchIcon fontSize="inherit" />
						</IconButton>
					),
				}}
			/>
			<Tooltip title={`${t('tooltip.refresh')}`} arrow placement="bottom-start" disableInteractive>
				<span>
					<IconButton
						aria-label="refresh"
						size="small"
						className={classes.button}
						sx={{ p: 2 }}
						onClick={handleRefresh}
						style={
							tab === 'views'
								? {
										paddingRight: '5px',
								  }
								: {}
						}
					>
						<RefreshOutlined fontSize="inherit" className={classes.icon} />
					</IconButton>
				</span>
			</Tooltip>
			{tab === 'views' && (
				<IconButton
					aria-label="add-view"
					size="small"
					sx={{ p: 2 }}
					className={classes.button}
					loadingPosition="start"
					onClick={() =>
						dispatch({
							type: ENV_ACTIONS.SHOW_ADD_VIEW,
							payload: true,
						})
					}
				>
					<AddCircleOutline fontSize="inherit" className={classes.icon} />
				</IconButton>
			)}
			{environment.showAddView && (
				<DoformsDataSaveViewDialog
					tab={tab}
					densityParam={'compact'}
					isAddNew={true}
				/>
			)}
		</div>
	)
}

export default DashboardSearchBar
