import { useFirstRender } from 'utils/hooks/useFirstRender'
import useGetCommonFunc from 'utils/hooks/useGetCommonFunc'
import { makeStyles } from '@mui/styles'
import axios from 'axios'
import _, { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import LoadingSpinner from '../../../custom-components/LoadingSpinner'
import { checkLoadColumnStorage, checkNeedCallQueryAPI } from '../../../utils/functions/helpers'
import useGetPathName from 'utils/hooks/useGetPathName'
import SkeletonLoaderDataGrid from '../../../custom-components/skeletons/SkeletonLoaderDataGrid'
import { ENV_ACTIONS } from '../../../reducers/environmentReducer'
import { LOOKUPS_ACTIONS } from '../../../reducers/lookupsReducer'
import {
	cancelLoadNextRecordsAction,
	getViewRecords,
	loadRecordsQuery,
} from '../../data/dataServices'

import { getLinkPrefix } from '../../../utils/functions/helpers'

import DoformsWizardDataGrid from '../../data/datagrid/DoformsWizardDataGrid'
import DoformsCreateSubmission from '../../data/submissions/DoformsCreateSubmission'

const useStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		padding: '8px',
		'& .MuiButton-root': {
			textDecoration: 'none',
		},
	},
	dataGridToolbar: {
		minHeight: 20,
		paddingLeft: '0px',
		paddingBottom: '8px',
	},
	dialog: {
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	dialogTitle: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		textAlign: 'center',
	},
	loading: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		position: 'absolute',
		top: '50%',
		left: '50%',
	},
	datagrid: {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: '30px',
		padding: '8px',
	},
}))

const LookupsData = ({ environment, lookupsProject, module }) => {
	const [t] = useTranslation('common')
	const classes = useStyles()
	const apiToken = environment.apiToken
	const isFirstRender = useFirstRender()
	const firstPathName = useGetPathName()

	const {
		action,
		formColumns,
		dataGrid: { query, queryView, columns, records, title, viewSession },
		formDeleteSelection,
		formFilterConditions,
		lookupsRefresh,
		formSelected,
		viewCreate,
		viewData,
		viewUpdate,
		rowsPerPage,
		clientFilters,
		serverClientFilters
	} = module
	const dispatch = useDispatch()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [info, setInfo] = useState(null)
	const [linkViewInfo, setLinkViewInfo] = useState(null)

	const [allRecordsLoading, setAllRecordsLoading] = useState(false)
	const [startLoadAllRecords, setStartLoadAllRecords] = useState(false)
	const [recordLoading, setRecordLoading] = useState(false)
	const [isCancelLoadingRecords, setIsCancelLoadingRecords] = useState(false)

	const { getAllRecords, loadUpdateColumns } = useGetCommonFunc({
		tab: 'lookups',
		firstPathName,
		viewData,
		query,
		queryView,
		viewSession,
		apiToken,
		records,
		formColumns,
		setAllRecordsLoading,
		setStartLoadAllRecords,
		setLoading,
		setError,
	})

	useEffect(() => {
		// Checked when change column then switch tab will call back api to get correct data
		; (async () => {
			if (
				!_.isEmpty(formColumns) &&
				viewData.key &&
				isFirstRender &&
				checkLoadColumnStorage.get(firstPathName)
			) {
				await loadUpdateColumns()
				checkLoadColumnStorage.set(firstPathName, false)
				setRecordLoading(true)
			}
		})()
	}, [])

	useEffect(() => {
		checkLoadColumnStorage.set(firstPathName, false)
	}, [])

	useEffect(() => {
		if (_.isEmpty(formSelected)) return
		const formTitle = formSelected.displayName
		dispatch({
			type: LOOKUPS_ACTIONS.DATA_GRID,
			payload: {
				title: formTitle,
			},
		})
	}, [formSelected])

	useEffect(() => {
		if (recordLoading) {
			loadRecords()
			setRecordLoading(false)
		}
	}, [recordLoading])

	function setPresetQueryAllData(query) {
		if (_.isEmpty(query?.filter?.conditions)) return query

		const newQuery = _.cloneDeep(query)
		const newFilter = {
			conditions: newQuery.filter.conditions.map((condition) => ({
				...condition,
				preset: 'ALL',
			})),
		}
		newQuery.filter = newFilter

		return newQuery
	}

	useEffect(() => {
		if (!action || action !== 'audit') return
		if (!_.isEmpty(queryView)) return
		dispatch({
			type: LOOKUPS_ACTIONS.VIEW_DATA,
			payload: {},
		})

		const cancelToken = axios.CancelToken.source()

		const getViewData = () => {
			if (!formSelected.key || !lookupsProject.key) return
			setLoading(true)
			getViewRecords(formSelected.key, lookupsProject.key, viewSession, apiToken, cancelToken)
				.then((response) => {
					let responseData = response.data

					const clientFilter = responseData?.clientFilter
						? JSON.parse(responseData?.clientFilter)
						: undefined
					if (clientFilter) {
						const clientFilterKey = formSelected?.key ?? ''
						dispatch({
							type: LOOKUPS_ACTIONS.SERVER_CLIENT_FILTERS,
							payload: {
								...serverClientFilters,
								[clientFilterKey]: clientFilter,
							},
						})
					}

					dispatch({
						type: LOOKUPS_ACTIONS.VIEW_DATA,
						payload: responseData,
					})

					const queryAll = setPresetQueryAllData(responseData.queries[0])
					dispatch({
						type: LOOKUPS_ACTIONS.DATA_GRID,
						payload: {
							columns: responseData.columns,
							query: queryAll,
						},
					})
					setRecordLoading(true)
				})
				.catch((err) => {
					if (!axios.isCancel(err)) {
						setError(
							err.response
								? 'Code ' + err.response.data.code + ': ' + err.response.data.message
								: err.message
						)
					}
					setLoading(false)
				})
		}
		getViewData()

		return () => {
			cancelToken.cancel()
		}
	}, [action, formSelected])

	useEffect(() => {
		if (_.isEmpty(formFilterConditions)) return
		if (query.filter?.conditions && _.isEqual(formFilterConditions, query.filter.conditions)) return
		dispatch({
			type: LOOKUPS_ACTIONS.DATA_GRID,
			payload: {
				query: {
					...query,
					filter: {
						conditions: formFilterConditions,
					},
				},
			},
		})
		setRecordLoading(true)
	}, [formFilterConditions])

	useEffect(() => {
		; (async () => {
			if (!lookupsRefresh) return
			try {
				setIsCancelLoadingRecords(false)
				if (
					!_.isEmpty(formColumns) &&
					!isFirstRender &&
					checkLoadColumnStorage.get(firstPathName)
				) {
					await loadUpdateColumns()
					checkLoadColumnStorage.set(firstPathName, false)
				}
				loadRecords()
			} catch (err) {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
			}
		})()
	}, [lookupsRefresh])

	useEffect(() => {
		if (startLoadAllRecords) {
			setStartLoadAllRecords(false)
			try {
				getAllRecords()
			} catch (err) {
				setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
			} finally {
			}
		}
	})

	useEffect(() => {
		if (isFirstRender) return
		checkLoadColumnStorage.set(firstPathName, true)
	}, [formColumns])

	useEffect(() => {
		; (async () => {
			try {
				if (_.isEmpty(formColumns) && isFirstRender) return
				//if (environment.formColumnsChanged) return;
				setLoading(true)
				if (checkNeedCallQueryAPI(formColumns.map((item) => item.name)) || isCancelLoadingRecords) {
					if (!_.isEmpty(formColumns)) {
						await loadUpdateColumns()
					}
					setRecordLoading(true)
					setIsCancelLoadingRecords(false)
					checkLoadColumnStorage.set(firstPathName, false)
				} else {
					dispatch({
						type: LOOKUPS_ACTIONS.DATA_GRID,
						payload: {
							columns: formColumns,
							viewSession: true,
						},
					})
					setLoading(false)
				}
			} catch (err) {
				setError('Code ' + err?.response?.data?.code + ': ' + err?.response?.data?.message)
				setLoading(false)
			}
		})()
	}, [formColumns])

	useEffect(() => {
		if (_.isEmpty(viewUpdate)) return
		setInfo(t('formsData.successfullySavedChanges'))
		const payload = {}
		payload.dataGrid = {
			columns: viewUpdate.columns,
			query: viewUpdate.queries[0],
		}

		payload.serverClientFilters = {
			...serverClientFilters,
			[viewUpdate.formKey]: JSON.parse(viewUpdate.clientFilter),
		}

		dispatch({
			type: LOOKUPS_ACTIONS.HANDLE_VIEW_UPDATE,
			payload,
		})
	}, [viewUpdate])

	useEffect(() => {
		if (_.isEmpty(viewCreate)) return
		setInfo(t('common:misc.successfullyCreatedView') + ' ')
		setLinkViewInfo(viewCreate)
		dispatch({
			type: LOOKUPS_ACTIONS.VIEW_CREATE,
			payload: {},
		})
		dispatch({
			type: ENV_ACTIONS.HAS_NEW_VIEW,
			payload: true,
		})
	}, [viewCreate])

	useEffect(() => {
		if (formDeleteSelection.length === 0) return
		setInfo(null)
		const selectedRecords = formDeleteSelection
		dispatch({
			type: LOOKUPS_ACTIONS.DATA_GRID,
			payload: {
				records: records.filter((rec) =>
					rec.type === 'DISPATCH'
						? !selectedRecords.includes(rec.dispatchKey)
						: !selectedRecords.includes(rec.submissionKey)
				),
			},
		})
		const infoMessage =
			selectedRecords.length > 1
				? t('common:misc.successfullyDeleted') +
				` ${selectedRecords.length} ` +
				t('common:misc.records') +
				`.`
				: t('common:misc.successfullyDeleted1Record')
		setInfo(infoMessage)
		dispatch({
			type: LOOKUPS_ACTIONS.FORM_DELETE_SELECTION,
			payload: [],
		})
	}, [formDeleteSelection])

	const loadRecords = () => {
		if (!viewData.key) return
		setLoading(true)
		loadRecordsQuery(viewData.key, viewSession, query.filter, apiToken)
			.then((response) => {
				let queries = [...response.data.view.queries]
				queries[0].filter = { ...query.filter }
				dispatch({
					type: LOOKUPS_ACTIONS.DATA_GRID,
					payload: {
						queryView: { ...response.data.view, queries: queries },
						records: response.data.records,
					},
				})
				setLoading(false)
				return response.data.records
			})
			.catch((err) => {
				setLoading(false)
				setError(
					err.response
						? 'Code ' + err.response.data.code + ': ' + err.response.data.message
						: err.message
				)
				return err
			})
			.finally(() => {
				dispatch({
					type: LOOKUPS_ACTIONS.LOOKUPS_REFRESH,
					payload: false,
				})
			})
	}

	const showLoading = () =>
		loading && (
			<>
				<LoadingSpinner />
				{_.isEmpty(viewData) && <SkeletonLoaderDataGrid />}
			</>
		)

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const showInfoMessage = () =>
		info && (
			<DoformsMessage
				linkViewInfo={linkViewInfo}
				message={info}
				severity={'success'}
				onMessageClosed={handleMessageClosed}
			/>
		)

	const handleMessageClosed = () => {
		setError(null)
		setInfo(null)
	}

	const handleResizeColumn = (selectedField, newWidth) => {
		const newColumns = columns.map((col) => {
			if (col.name === selectedField) {
				col.width = newWidth
			}
			return col
		})
		dispatch({
			type: LOOKUPS_ACTIONS.DATA_GRID,
			payload: {
				columns: newColumns,
			},
		})
	}

	const handleLoadAllRecords = () => {
		setStartLoadAllRecords(true)
	}

	const handleCancelLoadingRecords = () => {
		setIsCancelLoadingRecords(true)
		setStartLoadAllRecords(false)
		cancelLoadNextRecordsAction()
	}

	const onHandleOpenFullScreenTab = () => {
		localStorage.setItem('newFullScreenData_env', JSON.stringify({ environment: environment }))
		localStorage.setItem('newFullScreenData_mod', JSON.stringify({ lookupsModule: module }))
		//window.open('/lookups/' + viewData.key + '?action=' + action, '_blank_' + Math.random())
		window.open(getLinkPrefix('/lookups'), '_blank_' + Math.random())
	}

	return (
		<div className={classes.root}>
			{showLoading()}
			{showErrorMessage()}
			{showInfoMessage()}
			{/*{showAddAction()}*/}
			<div className={classes.datagrid}>
				{!isEmpty(viewData) && (
					<DoformsWizardDataGrid
						action={action}
						isLoading={loading}
						key={viewData?.formkey}
						isRefresh={lookupsRefresh}
						title={title}
						environment={environment}
						query={query}
						viewData={viewData}
						formSelected={formSelected}
						columns={columns}
						records={records}
						queryView={queryView}
						clientFilters={clientFilters}
						serverClientFilters={serverClientFilters}
						recordsLoading={false}
						allRecordsLoading={allRecordsLoading}
						tab={'lookups'}
						rowsPerPage={rowsPerPage}
						setError={setError}
						onHandledResizeColumn={handleResizeColumn}
						onHandledLoadAllRecords={handleLoadAllRecords}
						onHandledCancelLoadingRecords={handleCancelLoadingRecords}
						onHandleOpenFullScreenLookupsTab={onHandleOpenFullScreenTab}
						setLoading={setLoading}
					/>
				)}
			</div>
			{!environment.isReadOnlySubmission && !formSelected.abandoned && (
				<DoformsCreateSubmission
					environment={environment}
					formSelected={formSelected}
					action={'add'}
					title={title}
					tab={'lookups'}
					source={'dashboard'}
				/>
			)}
		</div>
	)
}

export default LookupsData
