import { Box, LinearProgress, Paper } from '@mui/material'
import dashboardApi from 'apis/disApi/dashboardApi'
import tileApi from 'apis/disApi/tileApi'
import axios from 'axios'
import { sortByPosition } from 'components/pages/dis/helpers'
import useGroupQuery from 'components/pages/dis/hooks/useGroupQuery'
import useMenuDetailQuery from 'components/pages/dis/hooks/useMenuDetailQuery'
import RouteLeavingGuard from 'custom-components/RouteLeavingGuard'
import { cloneDeep, isArray } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { isJson, logErrorMessage } from 'utils/functions/helpers'
import { useSearchParams } from 'utils/hooks/useSearchParams'
import { useMenu } from '../contexts/MenuContext'
import ActionButtons from './_components/ActionButtons'
import MenuForm from './_components/MenuForm'
import PreviewNav from './_components/PreviewNav'
import { convertToDndKitData } from './_utils/common'
import { validateMenuInfo } from './_utils/validation'
import { IS_NEW_MARKET_PLACE, MARKET_PLACE } from 'components/core/Layouts/DisLayout'

export const getMenuItemsWithTileInfo = async (environment, items, cancelToken) => {
	const clonedItems = cloneDeep(items)

	if (!isArray(clonedItems) || clonedItems?.length === 0) return []

	try {
		const newItems = await Promise.all(
			clonedItems?.map(async (item) => {
				const dashboardKey = item?.dashboard?.key

				const tileListPromise = tileApi.getAll(dashboardKey, environment.apiToken, cancelToken)
				const dashboardPromise = dashboardApi.get(dashboardKey, environment.apiToken, cancelToken)

				const [tileListResponse, dashboardResponse] = await Promise.all([
					tileListPromise,
					dashboardPromise,
				])

				const tileList = (await tileListResponse?.data) || []
				const dashboardList = (await dashboardResponse?.data) || []

				const dashboardKeyList =
					dashboardList?.variables && isJson(dashboardList?.variables)
						? JSON.parse(dashboardList?.variables ?? '{}')
						: []

				const newTileInfoList = tileList?.map((tile) => {
					return {
						...tile,
						primaryKeyList: dashboardKeyList
							.filter((x) => x.tileName === tile.name)
							.map((x) => x.key),
					}
				})

				if (item.items && item.items.length > 0) {
					item.items = await getMenuItemsWithTileInfo(environment, item.items, cancelToken)
				}

				const updatedMenuItem = {
					...item,
					tileInfo: newTileInfoList,
				}

				return updatedMenuItem
			})
		)

		return newItems
	} catch (error) {
		logErrorMessage(error)
		return clonedItems
	}
}

const MenuContent = ({ onSubmitCreateMenu, onSubmitEditMenu }) => {
	const { t } = useTranslation('common')
	const { environment } = useSelector((state) => state)
	const history = useHistory()
	const location = useLocation()
	const { menu: menuKeyInUrl = '' } = useSearchParams()

	const isEditMenu = menuKeyInUrl !== ''

	const {
		detailMenu,
		copiedDetailMenu,
		setDragDropItems,
		setContainers,
		onUpdateMenu,
		onAddNewMenuItem,
		onAssignMenu,
		onResetMenu,
	} = useMenu()

	const hasModifiedData = useMemo(() => {
		return JSON.stringify(copiedDetailMenu?.current) === JSON.stringify(detailMenu)
	}, [copiedDetailMenu?.current, detailMenu])

	const { groups } = useGroupQuery()

	const { detailData, isLoading: isDetailMenuLoading } = useMenuDetailQuery({
		id: menuKeyInUrl,
	})

	const [isLoading, setIsLoading] = useState(false)

	const sortedMenuList = useMemo(() => {
		return sortByPosition(detailMenu?.items)
	}, [detailMenu?.items])

	const groupKeyByMenuId = useMemo(() => {
		if (!Array.isArray(groups) || !groups?.length) return []

		return groups?.find((group) => group?.menuKey === menuKeyInUrl)
	}, [groups, menuKeyInUrl])

	useEffect(() => {
		// If menuKeyInUrl is empty, reset menu (example: when clicking "Create new menu" button)
		if (!menuKeyInUrl) {
			onResetMenu()
		}
	}, [menuKeyInUrl])

	useEffect(() => {
		;(async () => {
			try {
				// If same key as old detail menu, do not fetch again
				const isSameKeyAsOldDetailMenu = detailData?.key === detailMenu?.key

				if (!detailMenu) {
					onUpdateMenu('settings', [MARKET_PLACE, IS_NEW_MARKET_PLACE])
				}
				if (!menuKeyInUrl || !detailData?.items || isSameKeyAsOldDetailMenu) return
				setIsLoading(true)

				const cancelToken = axios.CancelToken.source()
				const newItems = await getMenuItemsWithTileInfo(environment, detailData?.items, cancelToken)

				if (isJson(detailData.settings)) {
					let tmpSettings = JSON.parse(detailData.settings)
					if (!tmpSettings.includes(IS_NEW_MARKET_PLACE)) {
						tmpSettings.push(MARKET_PLACE)
						tmpSettings.push(IS_NEW_MARKET_PLACE)
						detailData.settings = JSON.stringify(tmpSettings)
					}
				}
				onAssignMenu({
					key: detailData.key,
					name: detailData.name,
					settings: isJson(detailData.settings) ? JSON.parse(detailData.settings) : [],
					items: newItems,
					groupKey: groupKeyByMenuId?.key || '',
				})

				const dragDropData = convertToDndKitData(detailData?.items)
				setDragDropItems(dragDropData)
				setContainers(Object.keys(dragDropData))

				setIsLoading(false)
			} catch (error) {
				logErrorMessage(error)
				setIsLoading(false)
			}
		})()
	}, [JSON.stringify(detailData), JSON.stringify(detailMenu), groupKeyByMenuId?.key])

	const handleMenuFormChange = (e) => {
		onUpdateMenu([e.target.name], e.target.value)
	}

	const handleGroupMenuChange = (value) => {
		onUpdateMenu('groupKey', value)
	}

	const handleRightSideMenuCheck = (event) => {
		const value = event.target.name

		const settings = detailMenu?.settings

		if (!Boolean(settings)) {
			onUpdateMenu('settings', [value])
		} else {
			const currentIndex = settings?.indexOf(value)
			const newChecked = [...settings]

			if (currentIndex === -1) {
				newChecked.push(value)
			} else {
				newChecked.splice(currentIndex, 1)
			}
			onUpdateMenu('settings', newChecked)
		}
	}

	const handleSaveMenu = async () => {
		try {
			if (validateMenuInfo(detailMenu, t)) return

			setIsLoading(true)
			if (isEditMenu) {
				await onSubmitEditMenu()
			} else {
				await onSubmitCreateMenu()
				// Update copiedDetailMenu for compare hasModifiedData
				copiedDetailMenu.current = detailMenu
			}

			toast.success(t('common:toast.saveSuccess'))
		} catch (error) {
			toast.error(error.message)
			logErrorMessage(error)
		}
		setIsLoading(false)
	}

	return (
		<>
			<RouteLeavingGuard
				when={!hasModifiedData}
				navigate={(path) => {
					history.push(path)
				}}
				shouldBlockNavigation={(nextLocation) => {
					if (!hasModifiedData) {
						const isOnFormCreationScreen =
							location.pathname === '/dis/menu-manager/form' && !location.search.includes('menu')
						const cancelMenuEvent = nextLocation.pathname === '/dis/menu-manager'
						const goToTileDashboardEvent = nextLocation.pathname.includes('menu-items')

						if (goToTileDashboardEvent || cancelMenuEvent || isOnFormCreationScreen) {
							return false
						}
						return true
					}
					return false
				}}
				positiveLabel={t('common:misc.ok')}
				negativeLabel={t('common:misc.cancel')}
				content="Attention: You have unsaved changes. Please save your work to avoid losing your changes."
			/>
			<Paper
				sx={{
					ml: 2,
					height: '100%',
					px: 2,
					pb: 2,
					overflowY: 'auto',
					position: 'relative',
				}}
			>
				{(isDetailMenuLoading || isLoading) && (
					<LinearProgress sx={{ zIndex: 1000, position: 'absolute', top: 0, left: 0, right: 0 }} />
				)}

				<Box sx={{ mt: 2 }}>
					<PreviewNav menuItems={sortedMenuList ?? []} menuSettings={detailMenu?.settings ?? []} />
				</Box>

				<Box
					sx={{
						pt: 2,
						pb: 1,
						display: 'flex',
						justifyContent: 'flex-end',
						position: 'sticky',
						top: 0,
						zIndex: 100,
						backgroundColor: '#fff',
					}}
				>
					<ActionButtons
						disabledBtn={isDetailMenuLoading || isLoading}
						showDeleteBtn={isEditMenu}
						onSave={handleSaveMenu}
					/>
				</Box>

				<Box
					sx={{
						mt: 1,
					}}
				>
					<MenuForm
						menuData={detailMenu}
						onMenuFormChange={handleMenuFormChange}
						onGroupMenuChange={handleGroupMenuChange}
						onRightSideMenuCheck={handleRightSideMenuCheck}
						onCreateNewMenuItem={onAddNewMenuItem}
					/>
				</Box>
			</Paper>
		</>
	)
}

export default MenuContent
