import React, { useEffect, useState } from 'react'
import { Prompt } from 'react-router-dom'
import ConfirmBox from './ConfirmBox'

export const RouteLeavingGuard = ({
	navigate,
	when,
	shouldBlockNavigation,
	positiveLabel,
	positiveAction,
	negativeLabel,
	negativeAction,
	content,
}) => {
	const [modalVisible, updateModalVisible] = useState(false)
	const [lastLocation, updateLastLocation] = useState()
	const [confirmedNavigation, updateConfirmedNavigation] = useState(false)

	const showModal = (location) => {
		updateModalVisible(true)
		updateLastLocation(location)
	}

	const closeModal = (cb) => {
		updateModalVisible(false)

		if (cb && typeof cb === 'function') {
			cb()
		}
	}

	const handleBlockedNavigation = (nextLocation) => {
		if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
			showModal(nextLocation)
			return false
		}
		return true
	}

	const handleConfirmNavigationClick = () => {
		if (positiveAction) {
			positiveAction()
		}
		closeModal(() => {
			if (lastLocation) {
				updateConfirmedNavigation(true)
			}
		})
	}

	useEffect(() => {
		if (confirmedNavigation) {
			navigate(`${lastLocation.pathname}${lastLocation.search}`)
			updateConfirmedNavigation(false)
		}
	}, [confirmedNavigation])

	return (
		<>
			<Prompt when={when} message={handleBlockedNavigation} />
			<ConfirmBox
				positiveLabel={positiveLabel}
				positiveAction={positiveAction}
				negativeLabel={negativeLabel}
				negativeAction={negativeAction}
				visible={modalVisible}
				onCancel={closeModal}
				onConfirm={handleConfirmNavigationClick}
			>
				{content}
			</ConfirmBox>
		</>
	)
}

export default RouteLeavingGuard
