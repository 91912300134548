import _ from 'lodash'

const defaultQuery = {
	columns: [],
	filter: {
		conditions: [
			{ preset: 'TODAY', target: '@StartTime', type: 'EQ' },
			{ preset: 'ALL', target: '@UserStatus', type: 'EQ' },
		],
	},
}

export const FORM_ACTIONS = {
	ACTION: 'FORM_ACTIONS',
	FORM_COLUMNS: 'FORM_COLUMNS',
	FORM_FILTER_CONDITIONS: 'FORM_FILTER_CONDITIONS',
	FORM_SELECTED: 'FORM_SELECTED',
	FORM_DIALOG_SELECTED: 'FORM_DIALOG_SELECTED',
	FORM_SELECTED_COLUMNS: 'FORM_SELECTED_COLUMNS',
	FORM_VIEW: 'FORM_VIEW',
	FORM_VIEW_SESSION: 'FORM_VIEW_SESSION',
	FORM_REFRESH: 'FORM_REFRESH',
	FORM_DELETE_SELECTION: 'FORM_DELETE_SELECTION',
	FORM_RESTORE_PRODUCER: 'FORM_RESTORE_PRODUCER',
	DATA_GRID: 'FORM_DATA_GRID',
	NO_FORMS_PROJECTS: 'FORM_NO_FORMS_PROJECTS',
	LOAD_MORE_RECORDS: 'FORM_LOAD_MORE_RECORDS',
	RECORDS_CHANGED: 'FORM_RECORDS_CHANGED',
	DONE_LOAD_MORE_RECORDS: 'FORM_DONE_LOAD_MORE_RECORDS',
	QUERY_BUILD_APPLIED: 'FORM_QUERY_BUILD_APPLIED',
	VIEW_DATA: 'FORM_VIEW_DATA',
	VIEW_UPDATE: 'FORM_VIEW_UPDATE',
	HANDLE_VIEW_UPDATE: 'HANDLE_VIEW_UPDATE',
	VIEW_CREATE: 'FORM_VIEW_CREATE',
	RESET: 'FORM_RESET',
	TAB_CHANGE: 'FORM_TAB_CHANGE',
	NAME: 'Forms',
	ROWS_PER_PAGE: 'FORM_ROWS_PER_PAGE',
	CLIENT_FILTERS: 'FORM_CLIENT_FILTERS',
	SERVER_CLIENT_FILTERS: 'FORM_SERVER_CLIENT_FILTERS',
}

const initialState = {
	action: null,
	formSelected: {},
	formDialogSelected: {},
	formView: {},
	formViewSession: false,
	formFilterConditions: {},
	formColumns: [],
	formSelectedColumns: [],
	formRefresh: false,
	formDeleteSelection: [],
	noFormsProjects: [],
	loadMoreRecords: false,
	doneLoadMoreRecords: false,
	queryBuildApplied: false,
	viewUpdate: {},
	viewCreate: {},
	viewData: {},
	dispatchRecord4Map: null,
	showMapCloseIcon: false,
	recordsChanged: false,
	dataGrid: {
		title: null,
		query: defaultQuery,
		queryView: {},
		columns: [],
		records: [],
		viewSession: false,
	},
	rowsPerPage: 100,
	clientFilters: {},
	serverClientFilters: {},
}

const formsReducer = (state = initialState, action) => {
	switch (action.type) {
		case FORM_ACTIONS.ACTION: {
			return {
				...state,
				action: action.payload,
				dispatchRecord4Map: action.dispatchRecord ? action.dispatchRecord : null,
				showMapCloseIcon: action.dispatchRecord ? action.dispatchRecord : false,
			}
		}
		case FORM_ACTIONS.DATA_GRID: {
			return {
				...state,
				dataGrid: { ...state.dataGrid, ...action.payload },
			}
		}
		case FORM_ACTIONS.FORM_SELECTED: {
			return {
				...state,
				formSelected: action.payload,
				dataGrid: _.cloneDeep(initialState.dataGrid),
			}
		}
		case FORM_ACTIONS.FORM_DIALOG_SELECTED: {
			return {
				...state,
				formDialogSelected: action.payload,
			}
		}
		case FORM_ACTIONS.FORM_VIEW: {
			return {
				...state,
				formView: action.payload,
			}
		}
		case FORM_ACTIONS.FORM_VIEW_SESSION: {
			return {
				...state,
				formViewSession: action.payload,
			}
		}
		case FORM_ACTIONS.FORM_FILTER_CONDITIONS: {
			return {
				...state,
				formFilterConditions: action.payload,
			}
		}
		case FORM_ACTIONS.FORM_COLUMNS: {
			return {
				...state,
				formColumns: action.payload,
			}
		}
		case FORM_ACTIONS.FORM_SELECTED_COLUMNS: {
			return {
				...state,
				formSelectedColumns: action.payload,
			}
		}
		case FORM_ACTIONS.NO_FORMS_PROJECTS: {
			return {
				...state,
				noFormsProjects: [...state.noFormsProjects, action.payload],
			}
		}
		case FORM_ACTIONS.FORM_REFRESH: {
			return {
				...state,
				formRefresh: action.payload,
			}
		}
		case FORM_ACTIONS.FORM_DELETE_SELECTION: {
			return {
				...state,
				formDeleteSelection: action.payload,
			}
		}
		case FORM_ACTIONS.LOAD_MORE_RECORDS: {
			return {
				...state,
				loadMoreRecords: action.payload,
			}
		}
		case FORM_ACTIONS.DONE_LOAD_MORE_RECORDS: {
			return {
				...state,
				doneLoadMoreRecords: action.payload,
			}
		}
		case FORM_ACTIONS.RECORDS_CHANGED: {
			return {
				...state,
				recordsChanged: action.payload,
			}
		}
		case FORM_ACTIONS.QUERY_BUILD_APPLIED: {
			return {
				...state,
				queryBuildApplied: action.payload,
			}
		}
		case FORM_ACTIONS.VIEW_DATA: {
			return {
				...state,
				viewData: action.payload,
			}
		}
		case FORM_ACTIONS.VIEW_UPDATE: {
			return {
				...state,
				viewUpdate: action.payload,
			}
		}
		case FORM_ACTIONS.VIEW_CREATE: {
			return {
				...state,
				viewCreate: action.payload,
			}
		}
		case FORM_ACTIONS.RESET: {
			return initialState
		}
		case FORM_ACTIONS.FORM_RESTORE_PRODUCER: {
			return {
				...state,
				action: action.payload.action,
				formSelected: action.payload.formSelected || {},
				formDialogSelected: action.payload.formDialogSelected || {},
				formView: action.payload.formView || {},
				formViewSession: action.payload.formViewSession,
				formFilterConditions: action.payload.formFilterConditions || {},
				formColumns: action.payload.formColumns || [],
				formColumnsUpdate: action.payload.formColumnsUpdate || [],
				formSelectedColumns: action.payload.formSelectedColumns || [],
				formRefresh: action.payload.formRefresh,
				formDeleteSelection: action.payload.formDeleteSelection || [],
				formTitle: action.payload.formTitle,
				noFormsProjects: action.payload.noFormsProjects || [],
				loadMoreRecords: action.payload.loadMoreRecords,
				doneLoadMoreRecords: action.payload.doneLoadMoreRecords,
				queryBuildApplied: action.payload.queryBuildApplied,
				viewUpdate: action.payload.viewUpdate || {},
				viewCreate: action.payload.viewCreate || {},
				viewData: action.payload.viewData || {},
				dispatchRecord4Map: action.payload.dispatchRecord4Map,
				dataGrid: action.payload.dataGrid || initialState.dataGrid,
				rowsPerPage: action?.payload?.rowsPerPage || 100,
			}
		}
		case FORM_ACTIONS.TAB_CHANGE: {
			if (FORM_ACTIONS.NAME === action.payload) {
				return state
			} else {
				return {
					...state,
					// formSelected: {}
				}
			}
		}
		case FORM_ACTIONS.ROWS_PER_PAGE: {
			return {
				...state,
				rowsPerPage: action.payload,
			}
		}
		case FORM_ACTIONS.CLIENT_FILTERS: {
			return {
				...state,
				clientFilters: action.payload,
			}
		}
		case FORM_ACTIONS.HANDLE_VIEW_UPDATE: {
			const viewData = action.payload?.viewData || state.viewData
			return {
				...state,
				viewData: viewData,
				dataGrid: { ...state.dataGrid, ...action.payload?.dataGrid },
				serverClientFilters: { ...action.payload?.serverClientFilters },
				viewUpdate: {},
			}
		}
		case FORM_ACTIONS.SERVER_CLIENT_FILTERS: {
			return {
				...state,
				serverClientFilters: action.payload,
			}
		}
		default:
			return state
	}
}

export default formsReducer
