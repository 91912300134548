import { useRef, useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { Box } from '@mui/material'

import TileWrapper from '../../components/TileWrapper'
import BrowserSettingsDialog from './BrowserSettingDialog'
import tileApi from 'apis/disApi/tileApi'

import { IconThemeProvider } from 'custom-components/context/IconThemesContext'
import { useTileDashboard } from '../../dashboard/Dashboard'

import { isJson, logErrorMessage } from '../../../../../utils/functions/helpers'
import { tileKeys } from '../../hooks/useTileQuery'
import { validURL, getDefaultXFOUrl, getURLWithProtocol } from '../utils/helpers'
import { isEmpty } from 'lodash'

const DEFAULT_PROTOCOL = 'https://'

function BrowserTile(props) {
	const { tile } = props
	const { environment } = useSelector((state) => state)
	const iconTheme = environment.theme.icons
	const { id: dashboardKey } = useParams()

	const queryClient = useQueryClient()

	const updateTileMutation = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})

	const tileRef = useRef(null)

	const settings = useMemo(() => {
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}

		return {}
	}, [tile?.settings])

	const { tileWidth, defaultUrl, columnName } = useMemo(() => settings, [settings])

	const [newUrl, setNewUrl] = useState(() => {
		if (isEmpty(defaultUrl)) return ''

		return getURLWithProtocol(defaultUrl, DEFAULT_PROTOCOL)
	})

	const { selectedFields } = useTileDashboard()
	const [settingsOpen, setSettingsOpen] = useState(false)

	const selectedRecordFromConnectedDatagrid = useMemo(() => {
		const selectedField = selectedFields?.[settings?.connectedDatagridKey]
		if (settings?.connectedDatagridKey && selectedField) {
			return selectedField
		}
		return null
	}, [settings?.connectedDatagridKey, JSON.stringify(selectedFields)])

	useEffect(() => {
		if (!selectedRecordFromConnectedDatagrid) {
			const displayURL = getDefaultXFOUrl(defaultUrl)
			setNewUrl(getURLWithProtocol(displayURL, DEFAULT_PROTOCOL))
			return
		}

		let checkedUrl = selectedRecordFromConnectedDatagrid[columnName || '']
		if (!validURL(checkedUrl, true)) {
			checkedUrl = ''
		}

		const displayURL = getDefaultXFOUrl(checkedUrl)
		setNewUrl(getURLWithProtocol(displayURL, DEFAULT_PROTOCOL))
	}, [defaultUrl, columnName, selectedRecordFromConnectedDatagrid])

	const handleOpenDialog = () => {
		setSettingsOpen(true)
	}

	const handleCloseDialog = () => {
		setSettingsOpen(false)
	}

	const handleSubmitSetting = async (data) => {
		try {
			const { defaultUrl, columnName, connectedDatagridKey } = data
			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: {
					settings: JSON.stringify({
						...settings,
						defaultUrl,
						connectedDatagridKey,
						columnName,
					}),
				},
				token: environment.apiToken,
			})
			handleCloseDialog()
		} catch (error) {
			logErrorMessage(error)
		} finally {
			handleCloseDialog()
		}
	}

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({
				...settings,
				tileWidth: width,
			})

			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		}
	}

	return (
		<IconThemeProvider values={iconTheme}>
			<TileWrapper
				title={tile?.i}
				onSettingClick={handleOpenDialog}
				ref={tileRef}
				isExpandDialogBtn
			>
				<BrowserSettingsDialog
					tileElementWidth={tileRef?.current?.clientWidth}
					defaultTileWidth={tileWidth}
					tile={tile}
					settings={settings}
					isSubmitting={updateTileMutation.isLoading}
					open={settingsOpen}
					onClose={handleCloseDialog}
					onSubmit={handleSubmitSetting}
					onResizeTileWidth={handleResizeTileWidth}
				/>

				<Box
					sx={{
						position: 'absolute',
						left: 0,
						right: 0,
						top: 30,
						bottom: 0,
						background: '#fff',
						display: 'flex',

						'& .MuiDataGrid-selectedRowCount': {
							opacity: '0 !important',
						},
					}}
				>
					<iframe title="url" src={newUrl} width="100%" allow="geolocation" />
				</Box>
			</TileWrapper>
		</IconThemeProvider>
	)
}

export default BrowserTile
