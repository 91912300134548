import { getTempToken } from './recordsService'
import { getLinkTarget, getSelectedProject, getSelectedFormInfo } from '../dataHelpers'
import _ from 'lodash'
import { originUrl } from '../../../apis/apiUrl'
import moment from 'moment'

export const performFormAction = (environment, formSelected, action, tab, deviceSelected, selectedDate, duration) => {
	const links = environment.links
	const account = environment.account
	const form = formSelected
	const project = getSelectedProject(environment, formSelected, tab)
	const user = environment.user
	const token = environment.apiToken
	const host = originUrl().replace('-hrd', '')

	let sources = {
		customerKey: account.key,
		userKey: user.userKey,
		form: form,
		project: [project],
		recordKey: '',
		prjKey: project ? project.key : null,
	}

	if (tab === 'other') {
		sources.prjKey = formSelected.projectKey
	}

	if (deviceSelected != null) {
		sources.mobileUserKey = deviceSelected.key
	}

	let missing = []
	if (!form) missing.push('form')
	if (!project) missing.push('project')
	if (missing.length) throw new Error('missing required params: ' + missing.join(', '))

	let linkObj
	if (action === 'add') {
		linkObj = {
			target: 'submission',
			operation: 'create',
		}
	} else if (action === 'send') {
		linkObj = {
			target: 'dispatch',
			operation: 'create',
		}
	}

	let link = getLinkTarget(links, linkObj)

	return getTempToken(token).then((res) => {
		sources.token = res.data.token

		let previewUrl = ''
		if (tab === 'other') {
			previewUrl = generateUrl(host, link, sources, false, true)
		} else {
			previewUrl = generateUrl(host, link, sources)
		}

		if (selectedDate != null) {
			const selectedMoment = moment(selectedDate).format('YYYY-MM-DDTHH:mm:ss')
			previewUrl += '&scheduleDateTime=' + selectedMoment
			previewUrl += '&scheduleDuration=' + duration
		}
		
		return previewUrl
	})
}

export function getActivityProjectKey(activityKey) {
	return ''
}

export const performRecordAction = (
	environment,
	record,
	action,
	formSelected,
	tab,
	actionRecord
) => {
	// const {environment, gridAction, formSelected} = props;
	const formInfo = getSelectedFormInfo(environment, formSelected)
	const links = environment.links
	const account = environment.account
	const form = { ...formInfo }
	const project = getSelectedProject(environment, formSelected, tab)

	const user = environment.user
	const token = environment.apiToken
	const host = originUrl().replace('-hrd', '')

	switch (tab) {
		case 'views':
		case 'tile':
			if (action == 'edit') {
				if (form.formKey != undefined) {
					form.key = form.formKey
				}
			}
			break
	}
	let sources = {
		account: account,
		userKey: user.userKey,
		form: form,
		project: [project],
		recordKey: record?.key,
		prjKey: project ? project.key : null,
	}

	let missing = []
	if (!form) missing.push('form')
	if (!project && actionRecord?.type !== 'ACTIVITY') missing.push('project')
	if (!record) missing.push('record')
	if (missing.length) throw new Error('missing required params: ' + missing.join(', '))

	let linkObj
	if (action === 'edit') {
		linkObj = {
			target: 'submission',
			operation: 'update',
		}
	} else if (action === 'copy') {
		linkObj = {
			target: 'submission',
			operation: 'copy',
		}
	} else if (action === 'send') {
		linkObj = {
			target: 'dispatch',
			operation: 'send',
		}
		sources.dispatchKey = record.dispatchKey
	} else if (action === 'recall') {
		linkObj = {
			target: 'dispatch',
			operation: 'recall',
		}
		sources.dispatchKey = record.dispatchKey
	}

	let link = getLinkTarget(links, linkObj)

	return getTempToken(token).then((res) => {
		sources.token = res.data.token

		const isEditDispatchRecord = action === 'edit' && record?.sourceType === 'DISPATCH'
		let previewUrl = generateUrl(host, link, sources, isEditDispatchRecord)

		return previewUrl
	})
}

export const performImportAction = (environment, action, formSelected, tab) => {
	const links = environment.links
	const account = environment.account
	const form = formSelected
	const project = getSelectedProject(environment, formSelected, tab)
	const user = environment.user
	const token = environment.apiToken
	const host = originUrl().replace('-hrd', '')

	let sources = {
		account: account,
		userKey: user.userKey,
		form: form,
		project: project,
	}

	let missing = []
	if (!form) missing.push('form')
	if (!project) missing.push('project')
	if (missing.length) throw new Error('missing required params: ' + missing.join(', '))

	let linkObj
	if (action === 'dispatching') {
		linkObj = {
			target: 'dispatch',
			operation: 'import',
		}
	} else if (action === 'data') {
		linkObj = {
			target: 'submission',
			operation: 'import',
		}
	}

	let link = getLinkTarget(links, linkObj)

	return getTempToken(token).then((res) => {
		sources.token = res.data.token

		let previewUrl = generateUrl(host, link, sources)
		return previewUrl
	})
}

const generateUrl = (host, link, sources, isEditDispatchRecord = false, isRemoveToken = false) => {
	let path = link.path
	let query = []
	let params = (link.params || []).filter(function (param) {
		return param.location === 'path' || param.location === 'query'
	})

	// adjust params if isEditDispatchRecord is true
	if (isEditDispatchRecord) {
		const paramsWithNotViewDataTab = params.filter(
			(param) => param.name !== 'tab' && param.value !== 'viewData'
		)
		params = paramsWithNotViewDataTab.map((param) =>
			param.name === 'canSchedule' ? { ...param, value: 'true' } : param
		)
	}

	for (let i = 0; i < params.length; i++) {
		let param = params[i]
		let value = evalParam(param, sources)

		if (isRemoveToken === true && param?.name === 'token') {
			continue
		}

		if (value !== undefined) {
			switch (param.location) {
				case 'path':
					path = path.replace('{' + param.name + '}', encodeURIComponent(value))
					break
				case 'query':
					query.push(param.name + '=' + encodeURIComponent(value))
					break
				default:
					break
			}
		} else if (param.required) {
			throw new Error('Missing required param: ' + param.name)
		}
	}

	let url = host + path

	if (query.length) {
		url += '?' + query.join('&')
	}

	return url
}

const evalParam = (param, sources) => {
	let value

	// if (param.name && sources && sources.hasOwnProperty(param.name)) {
	if (param.name && sources && _.has(sources, param.name)) {
		value = sources[param.name]
	} else if (param.source) {
		let paths = param.source.split(',')
		for (let i = 0; i < paths.length; i++) {
			value = _.get(sources, paths[i])
			if (value !== undefined) break
		}
	}

	if (value === undefined && _.has(param, 'value')) return param.value
	// if (value === undefined && param.hasOwnProperty('value')) return param.value;

	return value
}
