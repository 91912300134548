import { useRef, useMemo, useState } from 'react'
import { makeStyles } from '@mui/styles'
import {
	IconButton,
	TextField,
	Tooltip,
	Box,
	List,
	ListItem,
	ListItemButton,
	ListItemText,
	Collapse,
} from '@mui/material'
import { ClearOutlined, SearchOutlined, RefreshOutlined } from '@mui/icons-material'
import { cloneDeep, isEmpty, uniqBy, orderBy } from 'lodash'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import SkeletonLoaderSidePanel from '../../../../../custom-components/skeletons/SkeletonLoaderSidePanel'
import TileWrapper from '../../components/TileWrapper'
import MobileUnitsSettingsDialog from './MobileUnitsSettingDialog'
import tileApi from 'apis/disApi/tileApi'
import useMobileUnitsQuery from '../../hooks/useMobileUnitsQuery'
import { useTileDashboard } from '../../dashboard/Dashboard'
import { tileKeys } from '../../hooks/useTileQuery'
import useDashboardQuery from '../../hooks/useDashboardQuery'

import { IconThemeProvider } from 'custom-components/context/IconThemesContext'
import { useDebounce } from 'utils/hooks/useDebounce'
import { mobileUnitTileFieldIdMap } from 'utils/params/helpers'
import { isJson, logErrorMessage } from '../../../../../utils/functions/helpers'
import { generateRandomID } from '../../helpers'

const useStyles = makeStyles(() => ({
	formHeading: {
		display: 'flex',
		alignSelf: 'stretch',
		paddingTop: 0.5,
		paddingBottom: 0.5,
		paddingRight: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.04)',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0.04)',
		},
		'& .MuiTypography-root': {
			fontWeight: 'bold',
			display: 'block',
			whiteSpace: 'nowrap',
			textOverflow: 'ellipsis',
			overflow: 'hidden',
		},
	},
	formItem: {
		padding: 0,
		display: 'flex',
		overflow: 'hidden',
		justifyContent: 'center',
		alignItems: 'stretch',
	},
	formMenuListContainer: {
		display: 'flex',
		flexDirection: 'column',
		position: 'relative',
		flex: '1 1 0',
		overflow: 'hidden',
		background: '#fff',
	},
	formMenuList: {
		paddingTop: 0,
		overflow: 'hidden',
		height: 'inherit',
		'&:not(.loading):hover': {
			overflowY: 'auto',
		},
		'& .MuiListItem-root': {
			flexDirection: 'column',
		},
		'& .MuiCollapse-root': {
			display: 'flex',
			flexDirection: 'column',
			alignSelf: 'stretch',
		},
		'& .MuiListItem-container': {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center',
		},
		'& .MuiListItem-container:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	formMenuChildList: {
		'& ul li:hover .MuiTypography-root': {
			textDecoration: 'underline',
		},
	},
	loadingWrapper: {
		position: 'absolute',
		top: '20%',
		left: '40%',
		zIndex: '99999',
	},
}))

function MobileUnitsTile(props) {
	const { tile } = props

	const [t] = useTranslation('common')
	const classes = useStyles()
	const { environment } = useSelector((state) => state)
	const iconTheme = environment.theme.icons
	const { id: dashboardKey } = useParams()

	const queryClient = useQueryClient()

	const updateTileMutation = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})

	const { updateMutation: updateDashboard, dashboardKeyList } = useDashboardQuery({
		dashboardKey,
	})

	const tileRef = useRef(null)

	const settings = useMemo(() => {
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}

		return {}
	}, [tile?.settings])

	const { tileWidth, connectedDatagridKey, primaryKeyList } = settings
	const [searchInput, setSearchInput] = useState('')

	// data states
	const [collapseGroupKeys, setCollapseGroupKeys] = useState([])
	const { isLoading, data: groupedData, refetchData } = useMobileUnitsQuery({ tile, searchInput })
	const { onSelectMobileUnit, resetSelectedMobileNumber } = useTileDashboard()
	const [settingsOpen, setSettingsOpen] = useState(false)

	const [selectedDevice, setSelectedDevice] = useState({})

	function handleSelectDevice(device) {
		if (selectedDevice?.key === device?.key) {
			setSelectedDevice({})
			return
		}
		setSelectedDevice(device)
	}

	const handleCollapse = (teamKey) => {
		const found = collapseGroupKeys.includes(teamKey)
		if (!found) {
			setCollapseGroupKeys([...collapseGroupKeys, teamKey])
		} else {
			setCollapseGroupKeys(collapseGroupKeys.filter((item) => item !== teamKey))
		}
	}

	const inputRef = useRef()
	const debounceSearchInput = useDebounce(searchInput, 500)

	function onSearchChange(event) {
		event.preventDefault()
		setSearchInput(event.target.value)
	}

	const onSearchClear = (e) => {
		e.preventDefault()
		inputRef.current.value = ''
		setSearchInput('')
	}

	const handleOpenDialog = () => {
		setSettingsOpen(true)
	}

	const handleCloseDialog = () => {
		setSettingsOpen(false)
	}

	const handleSubmitSetting = async (data) => {
		try {
			const { primaryKeyList, connectedDatagridKey } = data
			const currentDashboardKeyList = cloneDeep(dashboardKeyList)
			const tileName = tile.i
			const tileKey = tile.key
			const otherDashboardKeys = currentDashboardKeyList.filter(
				(item) => item.tileKey !== tileKey && item.tileName !== tileName
			)
			const primaryKeysToSave = [...otherDashboardKeys]
			if (!isEmpty(primaryKeyList)) {
				const newPrimaryKeyList = [
					...primaryKeyList.map((item) => ({
						id: generateRandomID(),
						key: item,
						tileName,
						tileKey,
						mobileUnitKey: mobileUnitTileFieldIdMap[item],
					})),
				]
				const uniqNewPrimaryKeyList = uniqBy(
					newPrimaryKeyList,
					(item) => `${item.key}-${tile.tileName}-${tile.tileKey}`
				)

				// mark delete primary key
				const deletedPrimaryKeyList = currentDashboardKeyList
					.filter((item) => tileName === item.tileName && tileKey === item.tileKey)
					.map((oldItem) => {
						const hasInNewList = uniqNewPrimaryKeyList.find(
							(newItem) =>
								oldItem.key === newItem.key &&
								oldItem.tileName === newItem.tileName &&
								oldItem.tileKey === newItem.tileKey
						)

						if (hasInNewList) return null

						return {
							...oldItem,
							deleted: true,
						}
					})
					.filter((item) => !!item)

				const allTileKeys = [...uniqNewPrimaryKeyList, ...deletedPrimaryKeyList]

				const keepOldPrimaryKeyList = allTileKeys
					.map((item) => {
						const deletedKey = deletedPrimaryKeyList.find(
							(deletedItem) =>
								deletedItem.key === item.key &&
								deletedItem.tileName === item.tileName &&
								deletedItem.tileKey === item.tileKey
						)
						if (Boolean(deletedKey)) {
							return null
						}

						const oldDashboardKeyItem = currentDashboardKeyList.find(
							(oldItem) =>
								oldItem.key === item.key &&
								oldItem.tileName === item.tileName &&
								oldItem.tileKey === item.tileKey
						)
						if (Boolean(oldDashboardKeyItem)) {
							return { ...oldDashboardKeyItem, old: true }
						}
						return item
					})
					.filter((item) => !!item)

				primaryKeysToSave.push(...keepOldPrimaryKeyList)
			}

			const orderedSaveList = orderBy(
				primaryKeysToSave,
				(item) => `${item.tileName}-${item.tileKey}-${item.id}`
			)

			await Promise.all([
				updateDashboard.mutateAsync({
					key: dashboardKey,
					data: {
						variables: JSON.stringify(orderedSaveList),
					},
					token: environment.apiToken,
				}),
				updateTileMutation.mutateAsync({
					dashboardKey,
					tileKey: tile.key,
					data: {
						settings: JSON.stringify({
							...settings,
							connectedDatagridKey,
						}),
					},
					token: environment.apiToken,
				}),
			])
		} catch (error) {
			logErrorMessage(error)
		} finally {
			resetSelectedMobileNumber()
			handleCloseDialog()
		}
	}

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({
				...settings,
				tileWidth: width,
			})

			await updateTileMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		}
	}

	return (
		<IconThemeProvider values={iconTheme}>
			<TileWrapper
				title={tile?.i}
				onSettingClick={handleOpenDialog}
				ref={tileRef}
				isExpandDialogBtn
			>
				<MobileUnitsSettingsDialog
					tileElementWidth={tileRef?.current?.clientWidth}
					defaultTileWidth={tileWidth}
					tile={tile}
					settings={settings}
					isSubmitting={updateTileMutation.isLoading}
					open={settingsOpen}
					onClose={handleCloseDialog}
					onSubmit={handleSubmitSetting}
					onResizeTileWidth={handleResizeTileWidth}
					dashboardKeyList={dashboardKeyList}
				/>

				<Box
					sx={{
						position: 'absolute',
						left: 0,
						right: 0,
						top: 30,
						bottom: 0,
						background: '#fff',

						'& .MuiDataGrid-selectedRowCount': {
							opacity: '0 !important',
						},
					}}
				>
					<div style={{ display: 'flex' }}>
						<TextField
							id="search-input"
							ref={inputRef}
							sx={{ mt: 0.5, mb: 0.5, pl: 1, pr: 1 }}
							InputLabelProps={{ shrink: false }}
							placeholder={t('common:misc.search')}
							size="small"
							fullWidth
							value={searchInput}
							onChange={onSearchChange}
							InputProps={{
								endAdornment: debounceSearchInput ? (
									<IconButton aria-label="clear" size="small" edge="end" onClick={onSearchClear}>
										<ClearOutlined fontSize="inherit" />
									</IconButton>
								) : (
									<IconButton aria-label="clear" size="small" edge="end" disabled>
										<SearchOutlined fontSize="inherit" />
									</IconButton>
								),
							}}
						/>
						<Tooltip
							title={`${t('tooltip.refresh')}`}
							arrow
							placement="bottom-start"
							disableInteractive
						>
							<span>
								<IconButton
									aria-label="refresh"
									size="small"
									className={classes.button}
									sx={{ p: 2 }}
									onClick={refetchData}
								>
									<RefreshOutlined fontSize="inherit" className={classes.icon} />
								</IconButton>
							</span>
						</Tooltip>
					</div>
					<div className={classes.formMenuListContainer}>
						{isLoading && <SkeletonLoaderSidePanel />}
						<List
							className={`${classes.formMenuList} ${
								isLoading ? t('common:misc.loading').toLowerCase() : ''
							}`}
						>
							{groupedData?.map((data, index) => {
								return (
									<ListItem key={data.teamName} disablePadding>
										<ListItemButton
											className={classes.formHeading}
											onClick={() => handleCollapse(data.teamKey)}
										>
											<ListItemText primary={data.teamName} />
										</ListItemButton>
										<Collapse
											id={index}
											in={!collapseGroupKeys.includes(data.teamKey)}
											timeout="auto"
											unmountOnExit
										>
											<List className={classes.formMenuChildList} disablePadding>
												{data.devices?.map((device) => (
													<ListItem
														key={device.key}
														className={classes.formItem}
														style={
															device?.key === selectedDevice?.key
																? { backgroundColor: 'rgba(0,0,0,0.3)' }
																: {}
														}
													>
														<ListItemButton
															className={classes.formItemBtn}
															onClick={() => {
																onSelectMobileUnit(tile.key, connectedDatagridKey, device)
																handleSelectDevice(device)
															}}
														>
															<ListItemText
																primary={
																	<Tooltip title={device.number} placement="bottom-start">
																		<span>{device.name || device.number}</span>
																	</Tooltip>
																}
															/>
														</ListItemButton>
													</ListItem>
												))}
											</List>
										</Collapse>
									</ListItem>
								)
							})}
						</List>
					</div>
				</Box>
			</TileWrapper>
		</IconThemeProvider>
	)
}

export default MobileUnitsTile
