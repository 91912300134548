import { formatDate } from '../../../utils/functions/doformsDateUtil'
import { getDeviceName, getFormName, getProjectName } from '../../../utils/functions/helpers'
import { getTimeZoneOffset } from '../dataHelpers'
import { createSvgIcon } from '@mui/material/utils'
import React from 'react'
import _ from 'lodash'

const eventSvgPath =
	'M24,0 l24,0 0,36 -18,0 -6,18 -6,-18 -18,0 0,-36 24,0 M12,18 l6,0 m0,0 l3,-12 m0,0 l6,24 m0,0 l3,-12 m0,0 l6,0'
const locationSvgPath =
	'M24,0 l24,0 0,36 -18,0 -6,18 -6,-18 -18,0 0,-36 24,0 M12,18 l8,0 m8,0 l8,0 m-12,-12 l0,8 m0,8 l0,8 m-0,-3 a9,9 0 0 1 0,-18 a9,9 0 0 1 0,18'
const tracksSvgPath = 'M2,13 l10,-13 10,13 -5,0 0,11 -10,0 0,-11 -5,0'
const circleSvgPath = 'M0,6 a6,6 0 0 1 12,0 a6,6 0 0 1 -12,0'
const timeSheetSvgPath =
	'M 8,0 C 4.7864317,-0.0427081 1.70581,2.080534 0.55721283,5.0741348 -0.59733665,7.8951091 0.08491653,11.335787 2.2156845,13.512462 4.2874735,15.741894 7.6859459,16.591619 10.55876,15.577861 13.632597,14.569135 15.9112,11.548456 15.994103,8.3080206 16.157076,5.1591233 14.234715,2.0545256 11.372687,0.75025082 10.32167,0.25614714 9.161299,-0.00143188 8,0 Z M 7.957031,1.2011719 c 2.1923,-0.00595 4.378511,1.0599709 5.610141,2.8939246 1.165822,1.6067504 1.609018,3.7085801 1.126991,5.6421057 C 14.358015,11.52602 13.066755,12.986593 11.495879,13.833502 9.7141057,14.846545 7.4737434,15.209051 5.5516271,14.359675 2.7758633,13.25911 0.87179087,10.274251 1.0561693,7.2962589 1.387338,4.6635819 3.3996375,2.3315763 5.9181826,1.5303542 6.5758675,1.3187626 7.2655665,1.2015526 7.957031,1.2011719 Z M 7,3 C 7,5 7,7 7,9 8.6666667,9 10.333333,9 12,9 12,8.6666667 12,8.3333333 12,8 10.666667,8 9.3333333,8 8,8 8,6.3333333 8,4.6666667 8,3 7.6666667,3 7.3333333,3 7,3 Z'
const geofenceEnterSvgPath =
	'M8,0v8H4l6,8l6-8h-4V0H8z M4.81,13.03C1.67,13.67,0,14.78,0,16.25c0,2.92,6.29,3.69,10,3.69s10-0.77,10-3.69c0-1.47-1.67-2.59-4.81-3.22l-0.38,1.94c2.41,0.48,3.12,1.16,3.19,1.22c-0.2,0.5-2.88,1.75-8,1.75s-7.8-1.26-8-1.66c0.06-0.15,0.78-0.83,3.19-1.31L4.81,13.03z'
const geofenceExitSvgPath =
	'M12,16V8h4l-6-8L4,8h4v8H12z M4.81,13.03C1.67,13.67,0,14.78,0,16.25c0,2.92,6.29,3.69,10,3.69s10-0.77,10-3.69c0-1.47-1.67-2.59-4.81-3.22l-0.38,1.94c2.41,0.48,3.12,1.16,3.19,1.22c-0.2,0.5-2.88,1.75-8,1.75s-7.8-1.26-8-1.66c0.06-0.15,0.78-0.83,3.19-1.31L4.81,13.03z'
const MARKER_CIRCLE_SCALE_NORMAL = 1
const MARKER_CIRCLE_SCALE_ACTIVE = 1.5
const MARKER_TRACK_SCALE_NORMAL = 0.75
const MARKER_TRACK_SCALE_ACTIVE = 1.5
const MARKER_GEO_SCALE_NORMAL = 1
const MARKER_GEO_SCALE_ACTIVE = 1.5
const MARKER_TIMESHEET_SCALE_NORMAL = 1
const MARKER_TIMESHEET_SCALE_ACTIVE = 1.5
const MARKER_EVENT_SCALE_NORMAL = 0.5
const MARKER_EVENT_SCALE_ACTIVE = 1
const MARKER_LOCATION_SCALE_NORMAL = 0.5
const MARKER_LOCATION_SCALE_ACTIVE = 1
const CIRCLE_ANCHOR_X = 6
const CIRCLE_ANCHOR_Y = 6
const TRACK_ANCHOR_X = 11
const TRACK_ANCHOR_Y = 12
const GEO_ANCHOR_X = 10
const GEO_ANCHOR_Y = 10
const TIMESHEET_ANCHOR_X = 9
const TIMESHEET_ANCHOR_Y = 9
const EVENT_ANCHOR_X = 24
const EVENT_ANCHOR_Y = 27
const LOCATION_ANCHOR_X = 24
const LOCATION_ANCHOR_Y = 27

export const generateMapItemProps = (record, environment, deviceSelected) => {
	let offset = getTimeZoneOffset(environment, record)
	let result = {
		...record,
		category: 'mapItem',
		recordKey: getRecordKey(record, record.type),
		time: record.date ? formatDate(record.date, offset) : '',
		device:
			deviceSelected && deviceSelected.key
				? deviceSelected.name
				: record.user
				? record.user.email
				: record.device
				? getDeviceName(record.device.key, environment.devices)
				: '',
		addressLoading: false,
		recordNameLoading: false,
		formLoading: false,
	}

	if (record.position) {
		result = {
			...result,
			accuracy: record.position.accuracy
				? formatAccuracy(record.position.accuracy)
				: formatAccuracy(0),
			latLng: {
				lat: record.position.latitude,
				lng: record.position.longitude,
			},
		}
	}

	if (record.formKey) {
		let formName = record.formKey ? getFormName(record.formKey, environment.forms) : null
		if (record.type !== 'event') {
			result = {
				...result,
				form: record.formKey
					? getProjectName(record.projectKey, environment.projects) + ' / ' + formName
					: '',
			}
		} else if (formName && !_.isEmpty(formName)) {
			result = { ...result, form: formName }
		}
	}

	return result
}

export const getRecordKey = (record, type) => {
	switch (type) {
		case 'event':
			return record.dispatchKey
		case 'location':
			return record.submissionKey
		default:
			return record.key
	}
}

export const formatAccuracy = (accuracy) => {
	if (accuracy > 160) {
		let miles = convertDistance(accuracy, 'miles')
		return Math.round(miles * 10) / 10 + ' mi'
	} else if (accuracy != null) {
		let feet = convertDistance(accuracy, 'feet')
		return Math.round(feet) + ' ft'
	}
	return ''
}

export const convertDistance = (distance, unit) => {
	let result = distance || 0

	switch (unit) {
		case 'feet':
			result *= 1 / 0.3048
			break
		case 'kilometers':
			result *= 1 / 1000
			break
		case 'meters':
			break
		case 'miles':
			result *= 1 / 1609.34
			break
		default:
			throw new Error(`invalid unit: ${unit}`)
	}

	return result
}

export const createMarkerIcon = (place, active) => {
	switch (place.type) {
		case 'event':
			return {
				fillColor: '#f98182',
				fillOpacity: 1,
				path: eventSvgPath,
				scale: active ? MARKER_EVENT_SCALE_ACTIVE : MARKER_EVENT_SCALE_NORMAL,
				strokeColor: '#ffffff',
				strokeWeight: 1,
				anchor: new window.google.maps.Point(EVENT_ANCHOR_X, EVENT_ANCHOR_Y),
			}
		case 'location':
			return {
				fillColor: '#8f9ce2',
				fillOpacity: 1,
				path: locationSvgPath,
				scale: active ? MARKER_LOCATION_SCALE_ACTIVE : MARKER_LOCATION_SCALE_NORMAL,
				strokeColor: '#ffffff',
				strokeWeight: 1,
				anchor: new window.google.maps.Point(LOCATION_ANCHOR_X, LOCATION_ANCHOR_Y),
			}
		case 'track':
			if (place.velocity) {
				if (place.velocity.bearing) {
					return {
						fillColor: '#136792',
						fillOpacity: 1,
						path: tracksSvgPath,
						scale: active ? MARKER_TRACK_SCALE_ACTIVE : MARKER_TRACK_SCALE_NORMAL,
						strokeColor: '#ffffff',
						strokeWeight: 1,
						rotation: place.velocity.bearing,
						anchor: new window.google.maps.Point(TRACK_ANCHOR_X, TRACK_ANCHOR_Y),
					}
				}
			}
			return {
				fillColor: place.status === 'Stopped' ? '#ff0000' : '#136792',
				fillOpacity: 1,
				path: circleSvgPath,
				scale: active ? MARKER_CIRCLE_SCALE_ACTIVE : MARKER_CIRCLE_SCALE_NORMAL,
				strokeColor: '#ffffff',
				strokeWeight: 1,
				anchor: new window.google.maps.Point(CIRCLE_ANCHOR_X, CIRCLE_ANCHOR_Y),
			}
		case 'geofence':
			return {
				fillColor: place.status === 'Arrive' ? '#0000E5' : '#C70039',
				fillOpacity: 1,
				path: place.status === 'Arrive' ? geofenceEnterSvgPath : geofenceExitSvgPath,
				scale: active ? MARKER_GEO_SCALE_ACTIVE : MARKER_GEO_SCALE_NORMAL,
				strokeColor: '#ffffff',
				strokeWeight: 1,
				anchor: new window.google.maps.Point(GEO_ANCHOR_X, GEO_ANCHOR_Y),
			}
		case 'timesheet':
		case 'timesheet_report':
			return {
				fillColor: '#ffa500',
				fillOpacity: 1,
				path: timeSheetSvgPath,
				scale: active ? MARKER_TIMESHEET_SCALE_ACTIVE : MARKER_TIMESHEET_SCALE_NORMAL,
				strokeColor: '#ffa500',
				strokeWeight: 1,
				anchor: new window.google.maps.Point(TIMESHEET_ANCHOR_X, TIMESHEET_ANCHOR_Y),
			}
		case 'track_pin':
			if (place.velocity) {
				if (place.velocity.bearing) {
					return {
						fillColor: place.color,
						fillOpacity: 1,
						path: tracksSvgPath,
						scale: active ? MARKER_TRACK_SCALE_ACTIVE : MARKER_TRACK_SCALE_NORMAL,
						strokeColor: '#ffffff',
						strokeWeight: 1,
						rotation: place.velocity.bearing,
						anchor: new window.google.maps.Point(TRACK_ANCHOR_X, TRACK_ANCHOR_Y),
					}
				}
			}
			return {
				fillColor: place.color,
				fillOpacity: 1,
				path: circleSvgPath,
				scale: active ? MARKER_CIRCLE_SCALE_ACTIVE : MARKER_CIRCLE_SCALE_NORMAL,
				strokeColor: '#ffffff',
				strokeWeight: 1,
				anchor: new window.google.maps.Point(CIRCLE_ANCHOR_X, CIRCLE_ANCHOR_Y),
			}
		case 'stop_report':
			return {
				fillColor: '#ff0000',
				fillOpacity: 1,
				path: circleSvgPath,
				scale: active ? MARKER_CIRCLE_SCALE_ACTIVE : MARKER_CIRCLE_SCALE_NORMAL,
				strokeColor: '#ffffff',
				strokeWeight: 1,
				anchor: new window.google.maps.Point(CIRCLE_ANCHOR_X, CIRCLE_ANCHOR_Y),
			}
		case 'device':
			if (place.velocity) {
				if (place.velocity.bearing) {
					return {
						fillColor: '#136792',
						fillOpacity: 1,
						path: tracksSvgPath,
						scale: active ? MARKER_TRACK_SCALE_ACTIVE : MARKER_TRACK_SCALE_NORMAL,
						strokeColor: '#ffffff',
						strokeWeight: 1,
						rotation: place.velocity.bearing,
						anchor: new window.google.maps.Point(TRACK_ANCHOR_X, TRACK_ANCHOR_Y),
					}
				}
			}
			return {
				fillColor: place.status === 'Stopped' ? '#ff0000' : '#136792',
				fillOpacity: 1,
				path: circleSvgPath,
				scale: active ? MARKER_CIRCLE_SCALE_ACTIVE : MARKER_CIRCLE_SCALE_NORMAL,
				strokeColor: '#ffffff',
				strokeWeight: 1,
				anchor: new window.google.maps.Point(CIRCLE_ANCHOR_X, CIRCLE_ANCHOR_Y),
			}
		default:
			break
	}
}

export const createMapGridIcon = (record) => {
	switch (record.type) {
		case 'event':
		case 'event_pin':
		case 'dispatch':
			return <EventIcon fontSize="small" />
		case 'location':
		case 'location_pin':
			return <LocationIcon fontSize="small" />
		case 'track':
		case 'track_pin':
			if (record.velocity) {
				if (record.velocity.bearing) {
					return (
						<div style={{ transform: `rotate(${record.velocity.bearing}deg)` }}>
							<TrackIcon fontSize="small" />
						</div>
					)
				}
			}
			if (record.status === 'Stopped') {
				return <TrackStopIcon fontSize="small" />
			}
			return <TrackStartIcon fontSize="small" />
		case 'geofence':
			if (record.status === 'Arrive') {
				return <EnterGeofence fontSize="small" />
			} else if (record.status === 'Depart') {
				return <ExitGeofence fontSize="small" />
			}
			break
		case 'timesheet':
		case 'timesheet_report':
			return <TimeSheetIcon fontSize="small" />
		case 'trip_report':
			return (
				<div style={{ transform: `rotate(90deg)` }}>
					<TripIcon fontSize="small" sx={{ color: record.color }} />
				</div>
			)
		case 'stop_report':
			return <TrackStopIcon fontSize="small" />
		case 'device':
			if (record.status === 'Stopped') {
				return <DeviceStopIcon fontSize="small" />
			} else {
				return <DeviceIcon fontSize="small" />
			}
		default:
			return
	}
}

export const formMapGridHeadCell = (detailState, deviceSelected, t, module) => {
	let ret = []
	if (!module.dispatchRecord4Map) {
		ret.push({
			id: 'device',
			numeric: false,
			disablePadding: true,
			sortable: true,
			label: t('common:misc.device'),
		})
	}
	ret.push({
		id: 'time',
		numeric: false,
		disablePadding: false,
		sortable: true,
		label: t('common:misc.time'),
		format: 'datetime',
	})
	ret.push({
		id: 'status',
		numeric: false,
		disablePadding: false,
		sortable: true,
		label: t('common:misc.status'),
	})

	let hasDescription = false
	let hasTraveled = false
	let hasDuration = false
	let hasSpeed = false
	let hasDistance = false
	let hasForm = false
	let hasRecord = false

	if (detailState && detailState.length > 0) {
		for (let i = 0; i < detailState.length; i++) {
			let item = detailState[i]
			if (item.checked) {
				switch (item.name) {
					case 'devices':
						hasDuration = true
						hasSpeed = true
						break
					case 'tracking':
						hasTraveled = true
						hasDuration = true
						hasSpeed = true
						hasDistance = true
						break
					case 'geofence':
						hasDescription = true
						hasDuration = true
						hasRecord = true
						break
					case 'dispatch':
						hasForm = true
						hasRecord = true
						break
					case 'timesheet':
						hasDescription = true
						hasDuration = true
						break
					case 'form':
						hasDescription = true
						hasForm = true
						hasRecord = true
						break
					default:
						break
				}
			}
		}
	}

	if (!module.dispatchRecord4Map && hasDescription) {
		ret.push({
			id: 'description',
			numeric: false,
			disablePadding: false,
			sortable: true,
			label: t('common:misc.description'),
		})
	}

	if (hasTraveled) {
		ret.push({
			id: 'elapsed',
			numeric: false,
			disablePadding: false,
			sortable: true,
			label: t('common:misc.traveled'),
		})
	}

	if (hasDuration) {
		ret.push({
			id: 'duration',
			numeric: false,
			disablePadding: false,
			sortable: true,
			label: t('common:misc.duration'),
		})
	}

	if (hasSpeed) {
		ret.push({
			id: 'velocity.speed',
			numeric: false,
			disablePadding: false,
			sortable: true,
			label: t('common:misc.speed'),
		})
	}

	if (hasDistance) {
		ret.push({
			id: 'distance',
			numeric: false,
			disablePadding: false,
			sortable: true,
			label: t('common:misc.distance'),
		})
	}

	ret.push({
		id: 'accuracy',
		numeric: true,
		disablePadding: false,
		sortable: true,
		label: t('common:misc.accuracy'),
	})

	if (hasForm) {
		ret.push({
			id: 'form',
			numeric: false,
			disablePadding: false,
			sortable: false,
			label: t('common:misc.form'),
		})
	}

	if (hasRecord) {
		ret.push({
			id: 'record',
			numeric: false,
			disablePadding: false,
			sortable: false,
			label: t('common:misc.recordName'),
		})
	}

	ret.push({
		id: 'address',
		numeric: false,
		disablePadding: false,
		sortable: false,
		label: t('common:misc.address'),
	})

	return ret
}

export const formMapGridHeadCell4Report = (isTimesheetChecked, deviceSelected, t) => {
	let ret = []
	if (deviceSelected && !deviceSelected.key) {
		ret.push({
			id: 'device',
			numeric: false,
			disablePadding: true,
			sortable: true,
			label: t('common:misc.device'),
		})
	}

	ret.push({
		id: 'status',
		numeric: false,
		disablePadding: false,
		sortable: true,
		label: t('common:misc.status'),
	})
	ret.push({
		id: 'beginDate',
		numeric: false,
		disablePadding: false,
		sortable: true,
		label: t('common:misc.begin'),
	})
	ret.push({
		id: 'endDate',
		numeric: false,
		disablePadding: false,
		sortable: true,
		label: t('common:misc.end'),
	})
	ret.push({
		id: 'duration',
		numeric: false,
		disablePadding: false,
		sortable: true,
		label: t('common:misc.duration'),
	})
	ret.push({
		id: 'distance',
		numeric: false,
		disablePadding: false,
		sortable: true,
		label: t('common:misc.distance'),
	})
	if (isTimesheetChecked) {
		ret.push({
			id: 'begin description',
			numeric: false,
			disablePadding: false,
			sortable: true,
			label: t('common:misc.beginDescription'),
		})
		ret.push({
			id: 'end description',
			numeric: false,
			disablePadding: false,
			sortable: true,
			label: t('common:misc.endDescription'),
		})
	}
	return ret
}

export const EventIcon = createSvgIcon(
	<g id="status" fill="none" stroke="#f98182" strokeWidth="1">
		<path d="M0,12 l6,0 m0,0 l3,-12 m0,0 l6,24 m0,0 l3,-12 m0,0 l6,0" />
	</g>,
	'Event'
)

export const LocationIcon = createSvgIcon(
	<g id="location" fill="none" stroke="#8f9ce2" strokeWidth="1">
		<path d="M0,12 l8,0 m8,0 l8,0 m-12,-12 l0,8 m0,8 l0,8 m-0,-3 a9,9 0 0 1 0,-18 a9,9 0 0 1 0,18" />
	</g>,
	'Location'
)

export const TrackIcon = createSvgIcon(
	<g id="track" fill="#136792" stroke="#136792" strokeWidth="1">
		<path d="M2,13 l10,-13 10,13 -5,0 0,11 -10,0 0,-11 -5,0" />
	</g>,
	'Track'
)

export const TrackStartIcon = createSvgIcon(
	<g id="trackstart" fill="#136792" stroke="#136792" strokeWidth="1">
		<circle cx="12" cy="12" r="8" />
	</g>,
	'TrackStart'
)

export const TrackStopIcon = createSvgIcon(
	<g id="trackstop" fill="#ff0000" stroke="#ff0000" strokeWidth="1">
		<circle cx="12" cy="12" r="8" />
	</g>,
	'TrackStop'
)

export const EnterGeofence = createSvgIcon(
	<g id="entergeofence" fill="#0000E5" stroke="#0000E5" strokeWidth="1">
		<path
			d="M8,0v8H4l6,8l6-8h-4V0H8z M4.81,13.03C1.67,13.67,0,14.78,0,16.25c0,2.92,6.29,3.69,10,3.69s10-0.77,10-3.69
	c0-1.47-1.67-2.59-4.81-3.22l-0.38,1.94c2.41,0.48,3.12,1.16,3.19,1.22c-0.2,0.5-2.88,1.75-8,1.75s-7.8-1.26-8-1.66
	c0.06-0.15,0.78-0.83,3.19-1.31L4.81,13.03z"
		/>
	</g>,
	'EnterGeofence'
)

export const ExitGeofence = createSvgIcon(
	<g id="exitgeofence" fill="#C70039" stroke="#C70039" strokeWidth="1">
		<path
			d="M12,16V8h4l-6-8L4,8h4v8H12z M4.81,13.03C1.67,13.67,0,14.78,0,16.25c0,2.92,6.29,3.69,10,3.69s10-0.77,10-3.69
        c0-1.47-1.67-2.59-4.81-3.22l-0.38,1.94c2.41,0.48,3.12,1.16,3.19,1.22
        c-0.2,0.5-2.88,1.75-8,1.75s-7.8-1.26-8-1.66c0.06-0.15,0.78-0.83,3.19-1.31L4.81,13.03z"
		/>
	</g>,
	'ExitGeofence'
)

export const TimeSheetIcon = createSvgIcon(
	<g id="timesheet" fill="#ffa500" stroke="#ffa500" strokeWidth="1">
		<path d="M12 3c-4.96 0-9 4.04-9 9 0 4.965 4.04 9 9 9 4.965 0 9-4.035 9-9 0-4.96-4.035-9-9-9Zm0 1.5c4.152 0 7.5 3.348 7.5 7.5s-3.348 7.5-7.5 7.5A7.487 7.487 0 0 1 4.5 12c0-4.152 3.348-7.5 7.5-7.5ZM11.25 6v6.75h5.25v-1.5h-3.75V6Zm0 0" />
	</g>,
	'TimeSheet'
)

export const PlaceIcon = createSvgIcon(
	<g id="place" fill="currentColor" stroke="none">
		<path d="M12 24 L9 20 C-10 -6 34 -6 15 20 L12 24" />
		<circle cx="12" cy="9" r="3" fill="white" stroke="none" />
	</g>,
	'Place'
)

export const TripIcon = createSvgIcon(
	<g id="track" strokeWidth="1">
		<path d="M2,13 l10,-13 10,13 -5,0 0,11 -10,0 0,-11 -5,0" />
	</g>,
	'Trip'
)

export const DeviceIcon = createSvgIcon(
	<g id="device" fill="#136792" stroke="#136792" strokeWidth="1">
		<circle cx="12" cy="12" r="8" />
	</g>,
	'DeviceStart'
)

export const DeviceStopIcon = createSvgIcon(
	<g id="device" fill="#ff0000" stroke="#ff0000" strokeWidth="1">
		<circle cx="12" cy="12" r="8" />
	</g>,
	'DeviceStop'
)
