import React, { useContext } from 'react'
import { LoadingButton } from '@mui/lab'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useDispatch } from 'react-redux'
import { FORM_ACTIONS } from '../../../reducers/formsReducer'
import { makeStyles } from '@mui/styles'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { LOOKUPS_ACTIONS } from '../../../reducers/lookupsReducer'
import { VIEWS_ACTIONS } from '../../../reducers/viewsReducer'
import { DEVICES_ACTIONS } from '../../../reducers/devicesReducer'
import { useTranslation } from 'react-i18next'
import { ACTIVITY_ACTIONS } from '../../../reducers/activityReducer'
import { callRecordsStorage } from '../../../utils/functions/helpers'
import useGetPathName from 'utils/hooks/useGetPathName'

const useStyles = makeStyles(() => ({
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))
const DoformsDataGridRefresh = ({ tab, recordsLoading, isLoading, setInfo }) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const firstPathName = useGetPathName()
	const dispatch = useDispatch()

	const handleRefresh = () => {
		if (isLoading) return setInfo(t('common:misc.pleaseWaitMoment'))
		callRecordsStorage.remove(firstPathName)
		switch (tab) {
			case 'lookups':
				dispatch({
					type: LOOKUPS_ACTIONS.LOOKUPS_REFRESH,
					payload: true,
				})
				break
			case 'views':
				dispatch({
					type: VIEWS_ACTIONS.VIEW_REFRESH,
					payload: true,
				})
				break
			case 'devices':
				dispatch({
					type: DEVICES_ACTIONS.DEVICE_REFRESH,
					payload: true,
				})
				break
			case 'activity':
				dispatch({
					type: ACTIVITY_ACTIONS.ACTIVITY_REFRESH,
					payload: true,
				})
				break
			default:
				dispatch({
					type: FORM_ACTIONS.FORM_REFRESH,
					payload: true,
				})
				break
		}
	}

	return (
		<div>
			<LoadingButton
				id={'refresh-datagrid'}
				className={classes.icon}
				disabled={recordsLoading}
				loadingPosition="start"
				onClick={handleRefresh}
				variant="text"
				startIcon={<RefreshIcon />}
			>
				{t('common:misc.refresh')}
			</LoadingButton>
		</div>
	)
}

export default DoformsDataGridRefresh
