import { Save } from '@mui/icons-material'
import { Stack } from '@mui/material'
import { makeStyles } from '@mui/styles'
import AppButton from 'custom-components/AppButton'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const useStyles = makeStyles(() => ({
	menuNavLink: {
		color: 'inherit',

		'&:hover': {
			color: 'inherit',
			textDecoration: 'none',
		},
	},
}))

const ActionButtons = ({ disabledBtn, onSave }) => {
	const { t } = useTranslation('common')
	const classes = useStyles()

	return (
		<Stack direction="row" spacing={1}>
			<AppButton disabled={disabledBtn} variant="text" startIcon={<Save />} onClick={onSave}>
				{t('common:misc.save')}
			</AppButton>
			<AppButton size="small" disabled={disabledBtn}>
				<Link className={classes.menuNavLink} to="/dis/menu-manager">
					{t('common:misc.cancel')}
				</Link>
			</AppButton>
		</Stack>
	)
}

export default ActionButtons
