import useCacheState from 'utils/hooks/useCacheState'
import { ViewColumn } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, Paper, Popover, Tooltip } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ACTIVITY_DEVICE_LIST } from '../../../constants'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import TransferList from '../../../custom-components/TransferList'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import { loadAllActivityColumns, loadAllColumns, loadRepeatableColumns } from '../dataServices'

const useStyles = makeStyles(() => ({
	columnsContainer: {
		padding: '16px 16px 0px 16px',
		backgroundColor: 'white',
	},
	footer: {
		display: 'flex',
		minHeight: '52px',
		alignItems: 'center',
		justifyContent: 'end',
	},
	button: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
}))

const DoformsDataColumns = (props) => {
	const [t] = useTranslation('common')
	const {
		viewData,
		environment,
		columns,
		tab,
		from,
		recordsLoading,
		isLoading,
		setInfo,
		disabled,
	} = props
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const [allColumns, setAllColumns] = useCacheState([], 'all_columns')
	const [repeatableColumns, setRepeatableColumns] = useState([])
	const [repeatableColumnsLoading, setRepeatableColumnsLoading] = useState(false)
	const [repeatableColumnsLoaded, setRepeatableColumnsLoaded] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const [anchorEl, setAnchorEl] = useState(null)

	const open = Boolean(anchorEl)
	const id = open ? 'form-columns' : undefined

	const showErrorMessage = (errors) =>
		errors && (
			<DoformsMessage message={errors} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const handleMessageClosed = () => {
		setError(null)
	}

	const handleLoadRepeatableColumns = (flag) => {
		if (flag) {
			setRepeatableColumnsLoading(true)
			setRepeatableColumnsLoaded(false)
			loadRepeatableColumns(viewData.formKey, environment.apiToken)
				.then((response) => {
					setRepeatableColumns(response.data)
					setRepeatableColumnsLoading(false)
					setRepeatableColumnsLoaded(true)
				})
				.catch((err) => {
					setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
					setRepeatableColumns([])
					setRepeatableColumnsLoading(false)
					setRepeatableColumnsLoaded(false)
				})
		} else {
			setRepeatableColumns([])
			setRepeatableColumnsLoading(false)
			setRepeatableColumnsLoaded(false)
		}
	}

	const handleClick = (event) => {
		if (isLoading) return setInfo(t('common:misc.pleaseWaitMoment'))
		const currentTarget = event.currentTarget
		setLoading(true)
		if (ACTIVITY_DEVICE_LIST.includes(viewData.type)) {
			if (allColumns.length > 0) {
				setAnchorEl(currentTarget)
				setLoading(false)
				return
			}
			loadAllActivityColumns(environment.apiToken)
				.then((response) => {
					setAllColumns(response.data)
					setAnchorEl(currentTarget)
					setLoading(false)
				})
				.catch((err) => {
					setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
					setAllColumns([])
					setLoading(false)
				})
		} else {
			if (allColumns.length > 0) {
				setAnchorEl(currentTarget)
				setLoading(false)
				return
			}
			loadAllColumns(viewData.formKey, environment.apiToken)
				.then((response) => {
					setAllColumns(response.data)
					setAnchorEl(currentTarget)
					setLoading(false)
				})
				.catch((err) => {
					setError('Code ' + err.response.data.code + ': ' + err.response.data.message)
					setAllColumns([])
					setLoading(false)
				})
		}
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const renderColumnLists = () => (
		<Popover
			target={id}
			open={open}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'left',
			}}
			onClose={handleClose}
			placement={'bottom'}
		>
			<Paper className={classes.columnsContainer}>
				<TransferList
					allColumns={repeatableColumnsLoaded ? repeatableColumns : allColumns}
					formColumns={columns}
					iconTheme={iconTheme}
					tab={tab}
					from={from}
					loading={repeatableColumnsLoading}
					isActivityView={ACTIVITY_DEVICE_LIST.includes(viewData.type)}
					onLoadRepeatableColumns={handleLoadRepeatableColumns}
					onColumnsChangedClosed={handleClose}
				/>
			</Paper>
		</Popover>
	)

	if (disabled) {
		return (
			<Button
				className={classes.icon}
				aria-describedby={id}
				disabled={disabled}
				startIcon={<ViewColumn />}
			>
				{t('common:misc.fields')}
			</Button>
		)
	}

	return (
		<div>
			{from === 'condition-builder' ? (
				!ACTIVITY_DEVICE_LIST.includes(viewData.type) && (
					<LoadingButton sx={iconTheme} loading={loading} onClick={handleClick}>
						{t('common:misc.addFields')}
					</LoadingButton>
				)
			) : (
				<LoadingButton
					className={classes.icon}
					aria-describedby={id}
					disabled={recordsLoading}
					loading={loading}
					loadingPosition="start"
					onClick={handleClick}
					startIcon={<ViewColumn />}
				>
					{t('common:misc.fields')}
				</LoadingButton>
			)}
			{showErrorMessage(error)}
			{allColumns && columns && renderColumnLists()}
		</div>
	)
}

export default DoformsDataColumns
