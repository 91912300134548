import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Button, Stack } from '@mui/material'
import { useState } from 'react'

const SliderSingleImage = ({ imagesSrc, onShowLightBox }) => {
	const [currentImageIndex, setCurrentImageIndex] = useState(0)

	const handlePrevClick = () => {
		setCurrentImageIndex((prevIndex) => (prevIndex === 0 ? imagesSrc.length - 1 : prevIndex - 1))
	}

	const handleNextClick = () => {
		setCurrentImageIndex((prevIndex) => (prevIndex === imagesSrc.length - 1 ? 0 : prevIndex + 1))
	}

	return (
		<Stack
			direction="row"
			alignItems="center"
			justifyContent="center"
			spacing={2}
			sx={{
				position: 'absolute',
				inset: 0,
				width: '100%',
				margin: 'auto',
				padding: '16px',
			}}
		>
			<Button
				aria-label="delete"
				size="large"
				variant="contained"
				sx={{
					borderRadius: '50%',
					width: '40px !important',
					minWidth: '40px !important',
					height: '40px !important',
				}}
				onClick={handlePrevClick}
			>
				<ChevronLeftIcon fontSize="small" />
			</Button>
			<img
				src={imagesSrc[currentImageIndex]}
				alt={`Slide ${currentImageIndex + 1}`}
				style={{
					aspectRatio: '16/9',
					width: '100%',
					height: '100%',
					cursor: 'pointer',
					objectFit: 'contain',
				}}
				onClick={() => onShowLightBox(currentImageIndex)}
			/>
			<Button
				aria-label="delete"
				size="large"
				variant="contained"
				sx={{
					borderRadius: '50%',
					width: '40px !important',
					minWidth: '40px !important',
					height: '40px !important',
				}}
				onClick={handleNextClick}
			>
				<ChevronRightIcon fontSize="small" />
			</Button>
		</Stack>
	)
}

export default SliderSingleImage
