import { Box, Checkbox, FormControlLabel, TextField } from '@mui/material'
import { isEmpty } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getMapLoggedInInfoFromStorage } from 'utils/functions/helpers'
import { DEFAULT_REPEAT_RELOAD_MAP_SECONDS } from '../../hooks/useVehicleLocation'
import DashboardList from '../DashboardKeyList'
import MapFilters from './MapFilters'
import MapLoginForm from './MapLoginForm'

export const MIN_REPEAT_RELOAD_MAP_VALUE = 5
export const MAX_REPEAT_RELOAD_MAP_VALUE = 60

const MapForm = ({
	vehicleLoading,
	filterData,
	vehicleList,
	linkedFields,
	dashboardKeyList,

	onFilterDataChange,
}) => {
	const { t } = useTranslation('common')

	const FIELD_LIST = [
		{
			name: 'vehicleName',
			label: t('common:input.vehicleName'),
		},
		{
			name: 'fromDate',
			label: t('common:input.fromDate'),
			type: 'DATE'
		},
		{
			name: 'toDate',
			label: t('common:input.toDate'),
			type: 'DATE'
		},
	]

	const [repeatReloadMapError, setRepeatReloadMapError] = useState('')

	// get user info from storage
	const userInfo = getMapLoggedInInfoFromStorage()

	const isLoggedIn = !isEmpty(userInfo)

	if (!isLoggedIn) {
		return <MapLoginForm />
	}

	return (
		<>
			<MapFilters
				vehicleList={vehicleList}
				vehicleLoading={vehicleLoading}
				vehicleName={filterData.locationData?.vehicleName}
				fromDate={filterData.locationData?.fromDate}
				toDate={filterData.locationData?.toDate}
				onFilters={(name, value) =>
					onFilterDataChange('locationData', { ...filterData.locationData, [name]: value })
				}
			/>
			<TextField
				size="small"
				id="repeat-reload-map-value"
				label={t('common:dis.autoRefreshInterval')}
				type="number"
				value={filterData.repeatReloadMapSeconds || DEFAULT_REPEAT_RELOAD_MAP_SECONDS}
				onChange={(e) => {
					const value = e.target.value
					onFilterDataChange('repeatReloadMapSeconds', value)
					if (value < MIN_REPEAT_RELOAD_MAP_VALUE || value > MAX_REPEAT_RELOAD_MAP_VALUE) {
						setRepeatReloadMapError('Repeat reload map value must be between 5 and 60')
					} else {
						setRepeatReloadMapError('')
					}
				}}
				error={Boolean(repeatReloadMapError)}
				helperText={repeatReloadMapError || ''}
				InputLabelProps={{
					shrink: true,
				}}
				inputProps={{
					inputMode: 'numeric',
					pattern: '[0-9]*',
					min: MIN_REPEAT_RELOAD_MAP_VALUE,
					max: MAX_REPEAT_RELOAD_MAP_VALUE,
				}}
			/>
			<FormControlLabel
				sx={{ mt: '1 !important' }}
				label={t('common:input.showHistory')}
				control={
					<Checkbox
						checked={filterData?.showHistory ?? false}
						onChange={() => onFilterDataChange('showHistory', !filterData.showHistory)}
						name={t('common:input.showHistory')}
					/>
				}
			/>
			<FormControlLabel
				sx={{ mt: '0px !important' }}
				label={t('common:input.showFilters')}
				control={
					<Checkbox
						checked={filterData?.showFilters ?? false}
						onChange={() => onFilterDataChange('showFilters', !filterData.showFilters)}
						name={t('common:input.showFilters')}
					/>
				}
			/>
			<FormControlLabel
				sx={{ mt: '0px !important' }}
				label={t('common:input.showPointsOfInterest')}
				control={
					<Checkbox
						checked={filterData?.pointOfInterest ?? false}
						onChange={() => onFilterDataChange('pointOfInterest', !filterData.pointOfInterest)}
						name={t('common:input.showPointsOfInterest')}
					/>
				}
			/>
			<Box>
				<DashboardList
					title={t('common:dis.dashboardKeys')}
					list={dashboardKeyList}
					fields={FIELD_LIST}
					onFieldsChange={(id, value) => {
						if (value === '') return
						onFilterDataChange('linkedFields', { ...filterData.linkedFields, [id]: value })
					}}
					linkedFields={filterData.linkedFields || {}}
					getLabelOption={(item) => item.label}
				/>
			</Box>
		</>
	)
}

export default MapForm
