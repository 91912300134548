import Stack from '@mui/material/Stack'
import Skeleton from '@mui/material/Skeleton'

const SkeletonLoaderDialog = () => {
	return (
		<Stack>
			<Skeleton variant="text" width={300} height={50} />
			<Skeleton variant="text" width={500} height={50} />
			<Skeleton variant="rectangular" height={300} />
		</Stack>
	)
}

export default SkeletonLoaderDialog
