import {
	Box,
	Divider,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
} from '@mui/material'
import classNames from 'classnames'
import PageSelect from 'components/pages/dis/menu-items-form/_components/PageSelect'
import produce from 'immer'
import { isEmpty } from 'lodash'
import { forwardRef, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { logErrorMessage } from 'utils/functions/helpers'
import { useMenu } from '../../contexts/MenuContext'
import { Handle } from '../../dnd/_components'
import styles from '../../dnd/_components/Item/Item.module.scss'
import { MENU_TYPE_OPTIONS } from '../../utils/constants'
import { getParentOfMenuItemKey } from '../_utils/common'
import MenuCardAction from './MenuCardAction'

const MenuCard = forwardRef((props, ref) => {
	const {
		dragOverlay,
		dragging,
		sorting,
		index,
		fadeIn,
		listeners,
		style,
		transform,
		transition,
		value,
		wrapperStyle,
		handle,
		disabled,
		subMenu,
	} = props
	const { t } = useTranslation('common')
	const location = useLocation()
	const history = useHistory()

	const { detailMenu, onUpdateMenu } = useMenu()

	const [menuName, setMenuName] = useState('')
	const [menuType, setMenuType] = useState('')
	const [menuPagePath, setMenuPagePath] = useState('')

	const currentSubMenu = useMemo(() => {
		try {
			if (isEmpty(detailMenu?.items)) return null

			for (const item of detailMenu?.items) {
				const foundItem = item.items.find((x) => x?.dashboard?.key === value)
				if (foundItem) return foundItem
			}
		} catch (error) {
			logErrorMessage(error)
			return null
		}
	}, [detailMenu?.items, value])
	const tileList = currentSubMenu?.tileInfo ?? []

	const updateMenuItems = (name, newValue) => {
		try {
			const newMenuItems = produce(detailMenu.items, (draft) => {
				draft.forEach((item) => {
					if (item?.items) {
						const subMenuIndex = item.items.findIndex(
							(x) => x?.dashboard?.key === subMenu?.dashboard?.key
						)

						if (subMenuIndex !== -1) {
							if (name === 'page') {
								item.items[subMenuIndex][name] = { path: newValue }
							} else {
								// remove page property if exists
								if (item.items[subMenuIndex].page) {
									delete item.items[subMenuIndex].page
								}

								item.items[subMenuIndex][name] = newValue
							}
						}
					}
				})
			})

			onUpdateMenu('items', newMenuItems)
		} catch (error) {
			logErrorMessage(error)
		}
	}

	useEffect(() => {
		if (subMenu) {
			setMenuName(subMenu.name)
			setMenuType(subMenu.type)
			setMenuPagePath(subMenu.page?.path)
		}
	}, [subMenu, value])

	const handleChangeTypeSelect = (e) => {
		setMenuType(e.target.value)
		updateMenuItems('type', e.target.value)
	}

	function handleMenuNameChange(event) {
		setMenuName(event.target.value)
		updateMenuItems('name', event.target.value)
	}

	const handleChangePagePath = (e) => {
		setMenuPagePath(e.target.value)
		updateMenuItems('page', e.target.value)
	}

	const handleDeleteSubMenu = () => {
		const newMenuItems = detailMenu.items.map((item) => {
			let clonedItem = { ...item }

			if (clonedItem?.items) {
				const subMenuIndex = clonedItem.items.findIndex(
					(x) => x?.dashboard?.key === subMenu?.dashboard?.key
				)
				if (subMenuIndex !== -1) {
					const newArray = [...clonedItem.items]
					newArray.splice(subMenuIndex, 1)
					clonedItem.items = newArray
				}
				return clonedItem
			}
		})

		onUpdateMenu('items', newMenuItems)
	}

	const handleGoToMenuItems = () => {
		const parentMenuOfCurrentSubMenu = getParentOfMenuItemKey({
			isSubMenu: true,
			menuItemsList: detailMenu?.items,
			menuKey: value,
		})
		const isEmptyCurrentSubMenuName = currentSubMenu.name === ''
		const isEmptyParentMenuName = parentMenuOfCurrentSubMenu?.name === ''
		const isEmptyMenuName = detailMenu?.name === ''

		if (isEmptyCurrentSubMenuName || isEmptyParentMenuName || isEmptyMenuName) {
			toast.error(t('common:toast.pleaseFillData'))
			return
		}

		const searchParams = new URLSearchParams(location.search)

		searchParams.set('key', value)
		searchParams.set('isSubmenuItems', true)
		if (currentSubMenu?.is_creating) {
			searchParams.set('isCreatingMenu', true)
		}

		history.push({
			pathname: `${location.pathname}/menu-items`,
			search: searchParams.toString(),
		})
	}

	return (
		<li
			className={classNames(
				styles.Wrapper,
				fadeIn && styles.fadeIn,
				sorting && styles.sorting,
				dragOverlay && styles.dragOverlay
			)}
			style={{
				...wrapperStyle,

				paddingLeft: '12px',

				transition: [transition, wrapperStyle?.transition].filter(Boolean).join(', '),

				'--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,

				'--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,

				'--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,

				'--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,

				'--index': index,
			}}
			ref={ref}
		>
			<div
				className={classNames(
					styles.Item,
					dragging && styles.dragging,
					handle && styles.withHandle,
					dragOverlay && styles.dragOverlay,
					disabled && styles.disabled
				)}
				style={style}
				data-cypress="draggable-item"
				tabIndex={!handle ? 0 : undefined}
			>
				<Handle
					{...listeners}
					style={{
						height: '100%',
					}}
				/>

				<Divider orientation="vertical" flexItem />

				<Stack direction="row" alignItems="center" gap={1} sx={{ flex: 1, ml: 2, py: 1 }}>
					<TextField
						id="menu-item"
						label={t('common:input.tabName')}
						variant="outlined"
						size="small"
						value={menuName}
						onChange={handleMenuNameChange}
					/>

					<FormControl size="small" sx={{ width: 210 }}>
						<InputLabel id="type-select-label">{t('common:input.type')}</InputLabel>
						<Select
							labelId="type-select-label"
							id="type-select"
							value={menuType}
							label={t('common:input.type')}
							name="type"
							onChange={handleChangeTypeSelect}
						>
							{MENU_TYPE_OPTIONS.map((type) => (
								<MenuItem value={type.value} key={type.value}>
									{type.label}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					{/* If type = page, show page select with menu default item */}
					{menuType === 'page' && (
						<Box sx={{ width: 210 }}>
							<PageSelect value={menuPagePath || ''} onChange={handleChangePagePath} isSmallSize />
						</Box>
					)}

					{tileList?.length > 0 && (
						<Stack direction="row" alignItems="center" gap={1}>
							<TextField
								id="menu-item"
								label={t('common:input.tilesCount')}
								variant="outlined"
								size="small"
								value={tileList?.length}
							/>
							<FormControl size="small" sx={{ width: 210 }}>
								<InputLabel id="tiles-select-label">{t('common:input.tiles')}</InputLabel>
								<Select
									labelId="tiles-select-label"
									id="tiles-select"
									label={t('common:input.tiles')}
									value={tileList[0]?.name || ''}
								>
									{tileList.map((tile) => (
										<MenuItem value={tile.name} key={tile.name}>
											{tile.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Stack>
					)}
				</Stack>

				<MenuCardAction
					showConfigBtn={!['page'].includes(currentSubMenu?.type)}
					showAddBtn={false}
					onDelete={handleDeleteSubMenu}
					onGoToMenuItems={handleGoToMenuItems}
				/>
			</div>
		</li>
	)
})

export default MenuCard
