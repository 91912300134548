import { useQuery } from '@tanstack/react-query'
import { useSelector } from 'react-redux'
import doFormMapApi from 'apis/disApi/doFormMapApi'
import _, { isEmpty } from 'lodash'
import { useEffect, useMemo, useState } from 'react'
import moment from 'moment'

export const DEVICE_TRACKING_STATUS = {
    APP_START: 'App start',
    BATTERY_LOW: 'Battery low',
    MOVING: 'Moving',
    NO_CELL_COVERAGE: 'NC',
    NO_GPS_COVERAGE: 'No GPS',
    TRACKING_SERVICE_RESTARTED: 'RS',
    SETTINGS_CHANGED: 'SC',
    STARTED_MOVING: 'Started moving',
    STOPPED: 'Stopped',
    WAKE_UP: 'Wake up'
}

export const doformsMapTileKeys = {
    basic: (tileKey) => ['doformsMapTile', tileKey],
    deviceTracks: (tileKey, deviceKey, fromDate, toDate) => [
        ...doformsMapTileKeys.basic(tileKey),
        deviceKey,
        fromDate,
        toDate,
    ],
}

export const DEFAULT_REPEAT_RELOAD_MAP_SECONDS = 10

function useDoformsMapDeviceLocation({
    tileKey,
    deviceKey,
    beginDate,
    endDate,
    apiCallIntervalInSeconds = DEFAULT_REPEAT_RELOAD_MAP_SECONDS,
    showHistory,
}) {
    const { environment } = useSelector((state) => state)

    const hasBeginDate = Boolean(beginDate)
    const hasEndDate = Boolean(endDate)
    const hasVehicleName = Boolean(deviceKey)

    const {
        data: deviceTracking,
        isLoading: isLoadDeviceTracks,
        isFetching: isFetchDeviceTracks,
    } = useQuery({
        queryKey: doformsMapTileKeys.deviceTracks(tileKey, deviceKey, beginDate, endDate),
        queryFn: async () => {
            const devicesResponse
                = await doFormMapApi.getDeviceTracks(deviceKey, beginDate, endDate, environment.apiToken)
            return devicesResponse.data
        },
        retry: false,
        refetchInterval: apiCallIntervalInSeconds * 1000,
        enabled: hasBeginDate && hasEndDate && hasVehicleName && showHistory,
    })

    const stopLocations = useMemo(() => {
        if (isEmpty(deviceTracking)) return []

        return deviceTracking.filter(device => (device.status === DEVICE_TRACKING_STATUS.STOPPED) && device.position)
    }, [deviceTracking])

    return {
        deviceTracking,
        stopLocations,
        isLoadingDeviceTracks: isLoadDeviceTracks || isFetchDeviceTracks,
    }
}

export default useDoformsMapDeviceLocation
