import { Alert, CircularProgress } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import mapApi from 'apis/disApi/mapApi'
import { vehicleListKey } from 'components/pages/dis-v2/menu-items/TileContent'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { logErrorMessage } from 'utils/functions/helpers'
import { useSearchParams } from 'utils/hooks/useSearchParams'
import { MAP_LOGGED_IN_INFO } from '../../../../../constants'
import myGeotabLogo from '../../../../../static/img/logos/mygeotab.svg'

export default function MapLoginForm({ onCloseDialog }) {
	const { t } = useTranslation('common')
	const environment = useSelector((state) => state.environment)
	const history = useHistory()
	const location = useLocation()
	const queryClient = useQueryClient()

	const { tile: tileKeyInUrl = '' } = useSearchParams()

	const [formValues, setFormValues] = useState({
		username: '',
		password: '',
		account: '',
	})
	const [isLoading, setIsLoading] = useState(false)
	const [errorMsg, setErrorMsg] = useState('')

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setFormValues((prev) => ({ ...prev, [name]: value }))
	}

	const handleLoginForm = async (e) => {
		try {
			e.preventDefault()

			if (!formValues.username || !formValues.password || !formValues.account) {
				setErrorMsg(t('common:dis.pleaseFillInformation'))
				return
			}

			const newUserInfo = {
				userName: formValues.username,
				password: formValues.password,
				account: {
					code: environment?.account?.code ?? 'dftest',
				},
				database: formValues.account,
			}

			setErrorMsg('')
			setIsLoading(true)
			const response = await mapApi.login(newUserInfo)
			const userValues = response?.data
			setIsLoading(false)

			onCloseDialog?.()

			if (userValues && Boolean(tileKeyInUrl)) {
				queryClient.invalidateQueries(vehicleListKey(tileKeyInUrl, userValues))
			}

			if (!userValues) return
			localStorage.setItem(MAP_LOGGED_IN_INFO, JSON.stringify(userValues))

			const searchParams = new URLSearchParams(location.search)
			searchParams.delete('isMapLogout')
			history.push({
				pathname: location.path,
				search: searchParams.toString(),
			})
		} catch (error) {
			logErrorMessage(error)
			setErrorMsg(error?.message)
			setIsLoading(false)
		}
	}

	return (
		<div className="login-form">
			<div className="logo">
				<img src={myGeotabLogo} alt="doForms" />
			</div>
			<form onSubmit={handleLoginForm}>
				<div className="form-element">
					<label className="form-label" htmlFor="username">
						Username:
					</label>
					<input
						type="text"
						className="form-input"
						id="username"
						name="username"
						onChange={handleInputChange}
					/>
				</div>
				<div className="form-element">
					<label className="form-label" htmlFor="password">
						Password:
					</label>
					<input
						type="password"
						className="form-input"
						id="password"
						name="password"
						onChange={handleInputChange}
					/>
				</div>
				<div className="form-element">
					<label className="form-label" htmlFor="account">
						Account:
					</label>
					<input
						type="text"
						className="form-input"
						id="account"
						name="account"
						onChange={handleInputChange}
					/>
				</div>
				{errorMsg && <Alert severity="error">{errorMsg}</Alert>}
				<button id="connect-btn" className="connect-btn" type="submit" disabled={isLoading}>
					Connect to myGEOTAB {isLoading && <CircularProgress color="inherit" size={20} />}
				</button>
			</form>
		</div>
	)
}
