import { SETTING_TYPES } from '../../../../../data/datagrid/CreatViewComponents/ViewDialogUtils'

export const INIT_SETTINGS = {
    primaryFilter: [],
    additionalFilters: []
}

export function dialogSettingsReducer(state, action) {
    switch (action.type) {
        case SETTING_TYPES.PRIMARY_FILTER:
            return {
                ...state,
                primaryFilter: action.value
            }

        case SETTING_TYPES.ADDITIONAL_FILTER:
            return {
                ...state,
                additionalFilters: action.value
            }
        default:
            return state
    }
}