import { Divider, Paper, Stack, Typography } from '@mui/material'
import SplitLayout from 'components/pages/dis/components/SplitLayout'
import useDashboardQuery from 'components/pages/dis/hooks/useDashboardQuery'
import { isEmpty } from 'lodash'
import { lazy, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { logErrorMessage } from 'utils/functions/helpers'
import { useSearchParams } from 'utils/hooks/useSearchParams'
import { IconThemeProvider } from '../../../../custom-components/context/IconThemesContext'
import { useMenu } from '../contexts/MenuContext'
import { getParentOfMenuItemKey } from '../menu/_utils/common'
import TileDashboard from './TileDashboard'

const TileContent = lazy(() => import('./TileContent'));

const MenuItemContent = ({ menuName, draftMenuItems, setDraftMenuItems, onSubmitTile }) => {
	const { t } = useTranslation('common')

	const {
		key: menuDashboardKeyInUrl = '',
		isSubmenuItems = '',
		isCreatingMenu: _isCreatingMenu = '',
	} = useSearchParams()

	const isSubMenu = isSubmenuItems === 'true'
	const isCreatingMenu = _isCreatingMenu === 'true'

	const { dashboardKeyList = [] } = useDashboardQuery({
		dashboardKey: menuDashboardKeyInUrl,
		enableConfig: !isCreatingMenu,
	})

	const parentMenuItem = useMemo(() => {
		return getParentOfMenuItemKey({
			isSubMenu,
			menuItemsList: draftMenuItems,
			menuKey: menuDashboardKeyInUrl,
		})
	}, [JSON.stringify(draftMenuItems), isSubMenu, menuDashboardKeyInUrl])

	const tileList = useMemo(() => {
		try {
			if (isEmpty(parentMenuItem)) return []

			if (isSubMenu) {
				const subMenuItem = parentMenuItem?.items?.find(
					(item) => item?.dashboard?.key === menuDashboardKeyInUrl
				)
				return subMenuItem?.tileInfo
			} else {
				return parentMenuItem?.tileInfo
			}
		} catch {
			return []
		}
	}, [JSON.stringify(parentMenuItem), menuDashboardKeyInUrl, isSubMenu])

	const validDashboardKeyList = useMemo(() => {
		if (!tileList || tileList.length <= 0) return [];

		const validDashBoardKey = tileList?.flatMap(tile => tile.key);
		return dashboardKeyList.filter(dashboardKey => validDashBoardKey.includes(dashboardKey.tileKey));
	}, [dashboardKeyList, tileList]);

	const currentSubMenuItem = useMemo(() => {
		try {
			return parentMenuItem?.items?.find((item) => item?.dashboard?.key === menuDashboardKeyInUrl)
		} catch (error) {
			logErrorMessage(error)
			return null
		}
	}, [JSON.stringify(parentMenuItem?.items), menuDashboardKeyInUrl])

	return (
		<Paper
			sx={{
				ml: 2,
				height: '100%',
				p: 2,
				overflowY: 'auto',
			}}
		>
			<Stack direction="row" spacing={2}>
				<Typography variant="subtitle2">
					{t('common:dis.menuName')}: {menuName}
				</Typography>
				<Divider orientation="vertical" flexItem />
				<Typography variant="subtitle2">
					{t('common:dis.parentName')}: {parentMenuItem?.name}
				</Typography>
				{currentSubMenuItem && (
					<>
						<Divider orientation="vertical" flexItem />
						<Typography variant="subtitle2">
							{t('common:dis.childName')}: {currentSubMenuItem?.name}
						</Typography>
					</>
				)}
			</Stack>
			<SplitLayout
				sidePanel={
					<TileDashboard
						tileList={tileList}
						parentMenuItem={parentMenuItem}
						draftMenuItems={draftMenuItems}
						setDraftMenuItems={setDraftMenuItems}
					/>
				}
				mainContent={
					<TileContent
						tileList={tileList}
						parentMenuItem={parentMenuItem}
						dashboardKeyList={validDashboardKeyList}
						draftMenuItems={draftMenuItems}
						setDraftMenuItems={setDraftMenuItems}
						onSubmitTile={onSubmitTile}
					/>
				}
			/>
		</Paper>
	)
}

const MenuDetailPage = () => {
	const { environment } = useSelector((state) => state)
	const iconTheme = environment.theme.icons
	const location = useLocation()
	const history = useHistory()

	const { detailMenu, onUpdateMenu } = useMenu()

	const [draftMenuItems, setDraftMenuItems] = useState(detailMenu?.items ?? [])

	const handleCancelTile = () => {
		// Create a new URLSearchParams object from the location search string
		const params = new URLSearchParams(location.search)

		// Create an empty object to store the filtered params
		const filteredParams = {}

		// Loop through each key-value pair in the original params
		for (const [key, value] of params.entries()) {
			// Check if the key is not 'menu'
			if (key !== 'menu') {
				// Skip this key-value pair
				continue
			}

			// Add the key-value pair to the filtered params object
			filteredParams[key] = value
		}

		// Create a new URLSearchParams object with the filtered params
		const filteredSearchParams = new URLSearchParams(filteredParams)

		// Replace the pathname with the desired path without the menu-items
		const pathname = location.pathname.replace('/menu-items', '')

		// Update the URL with the new pathname and search params
		history.push({
			pathname,
			search: filteredSearchParams.toString(),
		})
	}

	const handleSubmitTile = (newDraftMenuItems) => {
		onUpdateMenu('items', newDraftMenuItems)

		requestIdleCallback(() => {
			handleCancelTile()
		})
	}

	// Redirect to /form if detailMenu === null
	useEffect(() => {
		if (!detailMenu?.items) {
			history.push('/dis/menu-manager/form')
		}
	}, [detailMenu?.items])

	return (
		<IconThemeProvider values={iconTheme}>
			<MenuItemContent
				menuName={detailMenu?.name}
				draftMenuItems={draftMenuItems}
				setDraftMenuItems={setDraftMenuItems}
				onSubmitTile={handleSubmitTile}
			/>
		</IconThemeProvider>
	)
}

export default MenuDetailPage
