import { useContext, useState, useEffect, useMemo, memo, useLayoutEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { isEmpty, cloneDeep, isEqual } from 'lodash'
import { makeStyles } from '@mui/styles'
import { Delete } from '@mui/icons-material'
import {
	InputLabel,
	FormControl,
	Button,
	Autocomplete,
	Chip,
	TextField,
	Input,
	Stack,
	Box,
	Grid,
	Select,
	MenuItem,
} from '@mui/material'
import { useQuery } from '@tanstack/react-query'

import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import sectionsApi from 'apis/disApi/sectionsApi'

import { operatorOptions } from 'utils/functions/conditionsOptions'
import { IconThemeContext } from 'custom-components/context/IconThemesContext'
import { generateRandomID } from 'components/pages/dis/helpers'
import CollapseComponent from './CollapseComponent'
import {
	SETTING_TYPES,
	DATAGROUP_FILTER_ACTION_TYPE,
	shouldPreventRender,
	dataGroupFilterReducer,
} from '../ViewDialogUtils'
import { DEFAULT_LOGIC_OPERATOR, getLogicOperatorSelectList, sortDataGroupFilterKeyFn, convertFromDisplayKey } from 'utils/functions/helpers'
import { toDayjs } from 'utils/functions/doformsDateUtil'

const stringFilter = {
	contains: 'contains',
	equals: 'equals',
	startsWith: 'starts with',
	endsWith: 'ends width',
	isEmpty: 'is empty',
	isNotEmpty: 'is not empty',
	isAnyOf: 'is any of',
}
const numberFilter = {
	'=': '=',
	'!=': '!=',
	'>': '>',
	'>=': '>=',
	'<': '<',
	'<=': '<=',
	isEmpty: 'isEmpty',
	isNotEmpty: 'is not empty',
	isAnyOf: 'is any of',
}
const dateFilter = {
	is: 'is',
	not: 'not',
	after: 'after',
	onOrAfter: 'on or after',
	before: 'before',
	onOrBefore: 'on or before',
	isEmpty: 'is empty',
	isNotEmpty: 'is not empty',
}

const useStyles = makeStyles(() => ({
	closeIconSort: {
		marginRight: '20px',
		marginTop: '12px',
		width: '5%',
	},
	filterRowConType: {
		width: '10%',
	},
	filterRowField: {
		width: '30%',
	},
	filterRowOpe: {
		width: '20%',
	},
	dateBox: {
		marginTop: '16px',
	},
	input: {
		'& .MuiInput-input': {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: '400',
			fontSize: '1rem',
			lineHeight: '1.4375em',
			letterSpacing: '0.00938em',
		},
	},
	emptyDiv: {
		width: '25px !important',
		minWidth: '25px !important',
		height: '25px !important',
	}
}))

const getDefaultOperator = (type) => {
	if (type === 'STRING') {
		return Object.keys(stringFilter)[0]
	} else if (type === 'FLOAT' || type === 'INTEGER' || type === 'NUMBER') {
		return Object.keys(numberFilter)[0]
	} else if (type === 'DATETIME' || type === 'DATE' || type === 'TIME') {
		return Object.keys(dateFilter)[0]
	}
	return ''
}

const RenderValueCom = (props) => {
	/* type STRING ,operator isAnyOf => textbox multi */

	/* type STRING, operator isEmpty, isNotEmpty => not show values box*/

	/* type DATETIME, operator isEmpty, isNotEmpty => not show values box */

	/* type DATETIME, orther operator => show picker */

	/* type FLOAT, INTEGER, operator isEmpty, isNotEmpty => not show values box */

	/* type FLOAT, INTEGER, other operator => show values number box */

	/* else show textbox */

	//<Input id="component-simple" InputLabelProps={{ shrink: false }} />
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const { item, changeAddFilValue } = props
	const isAnyOfOprator = useMemo(() => item.operator === 'isAnyOf', [item])

	const changeValue = (e, valuesParam) => {
		//incase isAnyOf values is an array
		let newValue = e.target.value
		if (isAnyOfOprator && valuesParam) {
			changeAddFilValue([...valuesParam])
		} else {
			if (newValue === item.value) {
				return
			}

			changeAddFilValue(newValue)
		}
	}

	return (
		<FormControl
			variant="standard"
			fullWidth
			sx={{
				'& .MuiInput-input': {
					marginTop: '0.18rem',
					fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
					fontWeight: '400',
					lineHeight: '1.4375em',
					letterSpacing: '0.00938em',
				},
			}}
		>
			<InputLabel variant="standard" htmlFor="uncontrolled-native" shrink>
				Value
			</InputLabel>
			{item.operator === 'isEmpty' || item.operator === 'isNotEmpty' ? (
				<>{/*not show value box*/}</>
			) : (
				<>
					{item.operator === 'isAnyOf' ? (
						<>
							<Autocomplete
								multiple
								options={[]}
								freeSolo
								defaultValue={
									!isAnyOfOprator
										? item.value
										: Array.isArray(item.value)
											? [...item.value]
											: [item.value]
								}
								renderTags={(values, getTagProps) => (
									<Box sx={{ maxHeight: '30px', overflowY: 'auto' }}>
										{values.map((value, index) => (
											<Chip
												key={index}
												variant="standard"
												label={value}
												{...getTagProps({ index })}
											/>
										))}
									</Box>
								)}
								onChange={changeValue}
								sx={{
									'& .MuiAutocomplete-inputRoot': {
										height: '3em',
										paddingTop: '1em',
									},
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="standard"
										type={item.type === 'FLOAT' || item.type === 'INTEGER' ? 'number' : ''}
									/>
								)}
							/>
						</>
					) : (
						<>
							{item.type === 'DATETIME' ? (
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<DateTimePicker
										//label="With Time Clock
										value={toDayjs(item.value)}
										onChange={(newValue) => {
											const newDate = new Date(newValue.format('MM/DD/YYYY HH:mm:ss')).toISOString()
											changeAddFilValue(newDate)
										}}
										views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}
										timeSteps={{ minutes: 1, seconds: 1 }}
										slotProps={{
											textField: {
												variant: "standard",
												InputLabelProps: { shrink: true },
												InputProps: {
													className: classes.dateBox
												}
											}
										}}
									/>
								</LocalizationProvider>
							) : item?.type === 'FLOAT' || item?.type === 'INTEGER' ? (
								<Input type="number" value={item.value} onChange={changeValue} />
							) : (
								<Input id="filter-value" value={item.value} onChange={changeValue} />
							)}
						</>
					)}
				</>
			)}
		</FormControl>
	)
}

const DataGroupSpecificFiltersCom = (props) => {
	const {
		sectionDataGroupKey,
		item,
		index,
		userSpecificFilterDispatch,
		listActiveFieldsOptions,
		allColumns,
		dataGroupIndex
	} = props

	const [t] = useTranslation('common')
	const logicOperator = useMemo(() => item.logicOperator?.toLowerCase() || DEFAULT_LOGIC_OPERATOR, [item.logicOperator])

	function deleteAddFilter(id) {
		userSpecificFilterDispatch({
			type: DATAGROUP_FILTER_ACTION_TYPE.DELETE_FILTER,
			payload: {
				dataGroupKey: sectionDataGroupKey,
				dataGroupIndex,
				index,
			},
		})
	}

	const filterFieldChange = (e) => {
		const newField = e.target.value
		const newItem = cloneDeep(item)
		const tmpColumn = allColumns?.find((col) => col.name === newField)

		newItem['field'] = newField
		newItem['type'] = tmpColumn.type
		newItem['operator'] = getDefaultOperator(tmpColumn.type)
		newItem['value'] = ''
		userSpecificFilterDispatch({
			type: DATAGROUP_FILTER_ACTION_TYPE.UPDATE_FILTER,
			payload: {
				dataGroupKey: sectionDataGroupKey,
				dataGroupIndex,
				index,
				value: newItem,
			},
		})
	}

	const operatorChange = (e) => {
		const newOperator = e.target.value
		const newItem = cloneDeep(item)
		newItem['operator'] = newOperator
		if (newOperator === 'isAnyOf') {
			newItem['value'] = []
		} else if (newOperator === 'isEmpty' || newOperator === 'isNotEmpty') {
			newItem['value'] = ''
		}
		userSpecificFilterDispatch({
			type: DATAGROUP_FILTER_ACTION_TYPE.UPDATE_FILTER,
			payload: {
				dataGroupKey: sectionDataGroupKey,
				dataGroupIndex,
				index,
				value: newItem,
			},
		})
	}

	const changeValue = (newValue) => {
		if (isEqual(item.value, newValue)) return

		const newItem = cloneDeep(item)
		newItem['value'] =
			item.operator !== 'isAnyOf' ? newValue : Array.isArray(newValue) ? [...newValue] : [newValue]
		userSpecificFilterDispatch({
			type: DATAGROUP_FILTER_ACTION_TYPE.UPDATE_FILTER,
			payload: {
				dataGroupKey: sectionDataGroupKey,
				dataGroupIndex,
				index,
				value: newItem,
			},
		})
	}

	const changeLogicOperator = (e) => {
		const newOperator = e.target.value
		const newItem = cloneDeep(item)
		newItem['logicOperator'] = newOperator

		userSpecificFilterDispatch({
			type: DATAGROUP_FILTER_ACTION_TYPE.UPDATE_FILTER,
			payload: {
				dataGroupKey: sectionDataGroupKey,
				dataGroupIndex,
				index,
				value: newItem,
			},
		})
	}

	return (
		<Stack
			direction="row"
			alignItems="center"
			spacing={1}
			style={{ justifyContent: 'center', marginBottom: '10px' }}
		>
			<Grid container fullWidth spacing={1}>
				<Grid item xs={1} md={1} lg={1}>
					{index !== 0 && <FormControl variant="standard" style={{ width: '100%' }}>
						<InputLabel id={`logicOperator-${index}`} htmlFor="uncontrolled-native" />
						<Select
							labelId={`logicOperator-${index}`}
							id={`logicOperator-${index}`}
							value={logicOperator}
							defaultValue={logicOperator}
							onChange={changeLogicOperator}
						>
							{getLogicOperatorSelectList(t).map((item) => (
								<MenuItem value={item.value}>{item.label}</MenuItem>
							))}
						</Select>
					</FormControl>}
				</Grid>
				<Grid item xs={5} md={5} lg={5}>
					<FormControl variant="standard" style={{ width: '100%' }}>
						<InputLabel id={`fieldLabel-${index}`} htmlFor="uncontrolled-native">
							Field
						</InputLabel>
						<Select
							labelId={`fieldLabel-${index}`}
							id={`fieldSelect-${index}`}
							value={item.field}
							defaultValue={item.field}
							onChange={filterFieldChange}
						>
							{listActiveFieldsOptions.map((item) => (
								<MenuItem value={item.name}>{item.title}</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid>
				<Grid item xs md lg>
					<FormControl variant="standard" fullWidth>
						<InputLabel id={`operationsLabel-${index}`} htmlFor="uncontrolled-native">
							Operations
						</InputLabel>
						<Select
							labelId={`operationsLabel-${index}`}
							id={`operationsSelect-${index}`}
							value={item.operator}
							onChange={operatorChange}
						>
							{item.type === 'STRING' &&
								Object.keys(stringFilter).map((item2) => {
									return <MenuItem value={item2}>{stringFilter[item2]}</MenuItem>
								})}
							{(item.type === 'FLOAT' || item.type === 'INTEGER' || item.type === 'NUMBER') &&
								Object.keys(numberFilter).map((item2) => {
									return <MenuItem value={item2}>{numberFilter[item2]}</MenuItem>
								})}
							{(item.type === 'DATETIME' || item.type === 'DATE' || item.type === 'TIME') &&
								Object.keys(dateFilter).map((item2) => {
									return <MenuItem value={item2}>{dateFilter[item2]}</MenuItem>
								})}
						</Select>
					</FormControl>
				</Grid>
				{!['isNotEmpty', 'isEmpty'].includes(item.operator) && (
					<Grid item xs={2} md={2} lg={2}>
						<RenderValueCom item={item} changeAddFilValue={changeValue} />
					</Grid>
				)}
			</Grid>

			<div>
				<Button
					aria-label="delete"
					size="small"
					variant="contained"
					sx={{
						borderRadius: '50%',
						width: '25px !important',
						minWidth: '25px !important',
						height: '25px !important',
					}}
					onClick={(e) => deleteAddFilter(item.ranID)}
				>
					<Delete fontSize="small" />
				</Button>
			</div>
		</Stack>
	)
}

function DataGroupFilterSection(props) {
	const {
		userSpecificFilterDispatch,
		listActiveFields,
		listActiveFieldsOptions,
		allColumns,
		listDataGroups,
		usedGroupKeys,
		currentDataGroupKey,
		groupFilters = [],
		index: dataGroupIndex
	} = props
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const [sectionDataGroupKey, setSectionDataGroupKey] = useState('')
	useEffect(() => {
		setSectionDataGroupKey(currentDataGroupKey || '')
	}, [currentDataGroupKey])

	const dataGroupChange = (e) => {
		const newDataGroup = e.target.value
		setSectionDataGroupKey(newDataGroup)
		userSpecificFilterDispatch({
			type: DATAGROUP_FILTER_ACTION_TYPE.UPDATE_GROUP,
			payload: {
				oldGroupKey: currentDataGroupKey,
				newGroupKey: newDataGroup,
				index: dataGroupIndex
			},
		})
	}

	const addFilter = (e) => {
		const field = listActiveFields?.length > 0 ? listActiveFields[0] : ''
		const column = allColumns?.find((col) => col.name === field)
		const type = column?.type ? column.type : ''
		const ope = getDefaultOperator(type)
		const newItem = {
			field: field,
			type: type,
			operator: ope,
			value: '',
			dataGroupKey: sectionDataGroupKey,
			ranID: generateRandomID(),
			logicOperator: DEFAULT_LOGIC_OPERATOR
		}

		userSpecificFilterDispatch({
			type: DATAGROUP_FILTER_ACTION_TYPE.ADD_FILTER,
			payload: {
				dataGroupKey: sectionDataGroupKey,
				value: newItem,
				dataGroupIndex
			},
		})
	}

	return (
		<div>
			<div>
				<Stack
					direction="row"
					alignItems="center"
					spacing={1}
					style={{ justifyContent: 'center', marginBottom: '10px' }}
				>
					<Grid container fullWidth spacing={1}>
						<Grid item xs={1} md={1} lg={1}>

						</Grid>
						<Grid item xs={5} md={5} lg={5}>
							<FormControl variant="standard" style={{ width: '50%' }}>
								<InputLabel variant="standard" htmlFor="uncontrolled-native" shrink={true}>
									Data group
								</InputLabel>
								<Select value={sectionDataGroupKey} onChange={dataGroupChange}>
									{listDataGroups.map((item) => (
										<MenuItem key={item.key} value={item.key} disabled={usedGroupKeys?.includes(item.key)}>
											{item.name}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					</Grid>

					<div className={classes.emptyDiv}>
					</div>
				</Stack>
			</div>
			<div>
				{sectionDataGroupKey &&
					groupFilters?.map((item, index) => {
						return (
							<DataGroupSpecificFiltersCom
								sectionDataGroupKey={sectionDataGroupKey}
								key={item.id || item.ranID}
								index={index}
								item={item}
								userSpecificFilterDispatch={userSpecificFilterDispatch}
								listActiveFieldsOptions={listActiveFieldsOptions}
								allColumns={allColumns}
								dataGroupIndex={dataGroupIndex}
							/>
						)
					})}
			</div>
			<div>
				<Grid container fullWidth spacing={1}>
					<Grid item xs={1} md={1} lg={1}>

					</Grid>
					<Grid item xs={5} md={5} lg={5}>
						<Button variant="text" onClick={addFilter}>
							+ Add filter
						</Button>
					</Grid>
				</Grid>
			</div>
		</div>
	)
}

const DataGroupSpecificFiltersComponent = ({
	isAddNew,
	allColumns,
	allColumnsLoading,
	clientFilter,
	listActiveFields,
	listActiveFieldsOptions,
	saveSettingDispatch,
	expandedAccordion,
	setExpandedAccordion,
	environment,
}) => {
	const [t] = useTranslation('common')
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)
	const operators = useMemo(() => {
		return operatorOptions(t)
	}, [t])

	const [listDataGroups, setListDataGroups] = useState([])

	// additional filter collapse
	const [userSpecificFilter, userSpecificFilterDispatch] = useReducer(dataGroupFilterReducer, {})

	const { data, isLoading, isFetching } = useQuery({
		queryKey: ['section'],
		queryFn: () => sectionsApi.getSections(environment.apiToken),
		enabled: isAddNew ? environment.showAddView : environment.showEditView,
	})

	useEffect(() => {
		if (isLoading || isFetching) {
			setListDataGroups([])
			return
		}

		setListDataGroups(data?.data)
	}, [data, isLoading, isFetching])

	const usedGroupKeys = useMemo(() => {
		return Object.keys(userSpecificFilter).map(displayKey => convertFromDisplayKey(displayKey))
	}, [userSpecificFilter])

	function isIterable(obj) {
		// checks for null and undefined
		if (obj == null) {
			return false
		}
		return typeof obj[Symbol.iterator] === 'function'
	}

	useEffect(() => {
		if (isEmpty(allColumns)) return
		// const filters = isEmpty(clientFilter)
		// 	? {}
		// 	: isIterable(clientFilter)
		// 		? [...clientFilter]
		// 		: [clientFilter]

		if (isEmpty(clientFilter)) {
			userSpecificFilterDispatch({
				type: DATAGROUP_FILTER_ACTION_TYPE.INIT_DATA,
				initData: {},
			})
			return
		}


		const initData = cloneDeep(clientFilter)
		for (let key in clientFilter) {
			const filters = clientFilter[key]
			// condition get from grid not have type => first time load set type for it
			if (!filters[0]?.type) {
				initData[key] = filters
					?.filter((item) => item.name)
					.map((item) => ({
						...item,
						ranID: generateRandomID(),
						type: allColumns?.find((column) => column.name == item.field)?.type,
					}))
			}

		}

		userSpecificFilterDispatch({
			type: DATAGROUP_FILTER_ACTION_TYPE.INIT_DATA,
			initData: initData,
		})
	}, [allColumns, clientFilter])

	useLayoutEffect(() => {
		saveSettingDispatch({
			type: SETTING_TYPES.USER_SPECIFIC_FILTER,
			value: userSpecificFilter,
		})
	}, [userSpecificFilter])

	//const title = useMemo(() => t('common:view.applyAdditionalFilters'), [t])
	const title = useMemo(() => t('common:view.userSpecificFilters'), [t])

	const addDataGroupFilter = (e) => {
		//const additionalFilter = viewDataObj?.additionalFilter ? [...viewDataObj.additionalFilter] : []
		const field = listActiveFields?.length > 0 ? listActiveFields[0] : ''
		const availableDataGroups =
			isEmpty(usedGroupKeys)
				? listDataGroups
				: listDataGroups.filter(dataGroup => !usedGroupKeys.includes(dataGroup.key))
		const dataGroupKey = availableDataGroups?.length > 0 ? availableDataGroups[0]?.key : ''
		const column = allColumns?.find((col) => col.name === field)
		const type = column?.type ? column.type : ''
		const ope = getDefaultOperator(type)
		const newItem = {
			field: field,
			type: type,
			operator: ope,
			value: '',
			dataGroupKey: dataGroupKey,
			ranID: generateRandomID(),
		}

		userSpecificFilterDispatch({
			type: DATAGROUP_FILTER_ACTION_TYPE.ADD_GROUP,
			payload: {
				dataGroupKey,
				value: newItem,
			},
		})
	}

	return (
		<CollapseComponent
			title={title}
			expanded={expandedAccordion === title}
			setExpandedAccordion={setExpandedAccordion}
			value={''}
			content={
				<div>
					{!isEmpty(listActiveFieldsOptions) &&
						Object.keys(userSpecificFilter)?.sort(sortDataGroupFilterKeyFn).map((currentDataGroupKey, index) => {
							return (
								<DataGroupFilterSection
									index={index}
									userSpecificFilterDispatch={userSpecificFilterDispatch}
									listActiveFields={listActiveFields}
									listActiveFieldsOptions={listActiveFieldsOptions}
									allColumns={allColumns}
									listDataGroups={listDataGroups}
									usedGroupKeys={usedGroupKeys}
									currentDataGroupKey={convertFromDisplayKey(currentDataGroupKey)}
									groupFilters={userSpecificFilter?.[currentDataGroupKey]}
								/>
							)
						})}
					{(usedGroupKeys.length < listDataGroups.length) && <div>
						<Button variant="text" onClick={addDataGroupFilter}>
							+ Add data group
						</Button>
					</div>}
				</div>
			}
		/>
	)
}

export const DataGroupSpecificFiltersCollapse = memo(
	DataGroupSpecificFiltersComponent,
	shouldPreventRender
)
