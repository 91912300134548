import { Alert as MuiAlert, Snackbar } from '@mui/material'
import { forwardRef } from 'react'

const Alert = forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const ToastAlert = ({ alertInfo, setAlertInfo }) => {
	const handleClose = (_event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setAlertInfo((prev) => ({
			...prev,
			open: false,
		}))
	}

	return (
		<Snackbar
			anchorOrigin={{
				vertical: alertInfo?.vertical ?? 'bottom',
				horizontal: alertInfo?.horizontal ?? 'left',
			}}
			open={alertInfo.open || false}
			autoHideDuration={6000}
			onClose={handleClose}
			sx={{
				zIndex: '10000 !important',
			}}
		>
			<Alert onClose={handleClose} severity={alertInfo.type || 'success'} sx={{ width: '100%' }}>
				{alertInfo.text || 'Success'}
			</Alert>
		</Snackbar>
	)
}

export default ToastAlert
