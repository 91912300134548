import PublishTwoToneIcon from '@mui/icons-material/PublishTwoTone'
import { LoadingButton } from '@mui/lab'
import { Dialog, DialogContent, ToggleButton, ToggleButtonGroup } from '@mui/material'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconThemeContext } from '../../../custom-components/context/IconThemesContext'
import DoformsMessage from '../../../custom-components/DoformsMessage'
import DoformsPortal from '../../../custom-components/DoformsPortal'
import { performImportAction } from '../datagrid/recordsHelper'

const useStyles = makeStyles(() => ({
	root: {
		'& .MuiButton-root': {
			textTransform: 'none',
		},
	},

	buttonGroup: {
		'& .MuiToggleButtonGroup-grouped': {
			border: 0,
			textTransform: 'none',
			'&.Mui-disabled': {
				border: 0,
			},
		},
	},
	listBody: {
		maxHeight: '15em',
		overflow: 'auto',
	},
	footer: {
		display: 'flex',
		minHeight: '52px',
		alignItems: 'center',
		justifyContent: 'end',
		'& .MuiButton-root': {
			textTransform: 'none !important',
		},
	},
	icon: (props) => ({
		color: props.color,
		'&:hover': {
			color: props.active.color,
			backgroundColor: 'transparent',
		},
	}),
	dialog: {
		//height: '100%',
	},
}))

const boxStyle = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	backgroundColor: 'background.paper',
	boxShadow: 24,
	borderRadius: '5px',
	padding: '16px 32px',
	textAlign: 'center',
}

const DoformsDataImportView = (props) => {
	const [t] = useTranslation('common')
	const { environment, recordsLoading, tab, formSelected, onHandleImportComplete } = props
	const { iconTheme } = useContext(IconThemeContext)
	const classes = useStyles(iconTheme)

	const [open, setOpen] = useState(false)

	const [option, setOption] = useState(null)
	const [openImportDispatching, setOpenImportDispatching] = useState(false)
	const [openImportData, setOpenImportData] = useState(false)
	const initialState = {
		loaded: false,
		error: null,
	}

	const [values, setValues] = useState(initialState)
	const { loaded, error } = values
	const [iframe, setIframe] = useState(null)
	const [iframeDispatching, setIframeDispatching] = useState(null)
	const [iframeData, setIframeData] = useState(null)
	const [iframeHeight, setIframeHeight] = useState(null)

	const confirmImportDispatching = () => {
		setIframeHeight('580px')
		if (_.isEmpty(formSelected)) return
		setIframeDispatching(null)
		setValues({ ...values, loaded: false })
		performImportAction(environment, 'dispatching', formSelected, tab)
			.then((res) => {
				setIframeDispatching(res)
			})
			.catch((err) => {
				setValues({
					...values,
					error: 'Code ' + err.response.data.code + ': ' + err.response.data.message,
				})
			})
			.finally(() => {
				setValues({ ...values, loaded: true })
			})
	}

	const confirmImportData = () => {
		setIframeHeight('380px')
		if (_.isEmpty(formSelected)) return
		setIframeData(null)
		setValues({ ...values, loaded: false })
		performImportAction(environment, 'data', formSelected, tab)
			.then((res) => {
				setIframeData(res)
			})
			.catch((err) => {
				setValues({
					...values,
					error: 'Code ' + err.response.data.code + ': ' + err.response.data.message,
				})
			})
			.finally(() => {
				setValues({ ...values, loaded: true })
			})
	}

	useEffect(() => {
		if (!openImportDispatching) {
			setIframeDispatching(null)
			return
		} else {
			confirmImportDispatching()
		}
	}, [openImportDispatching])

	useEffect(() => {
		if (!openImportData) {
			setIframeData(null)
			return
		} else {
			confirmImportData()
		}
	}, [openImportData])

	const showErrorMessage = () =>
		error && (
			<DoformsMessage message={error} severity={'error'} onMessageClosed={handleMessageClosed} />
		)

	const handleMessageClosed = () => {
		setValues({ ...values, error: null })
	}

	const handleOptionChange = (e, newOption) => {
		setOption(newOption)
		switch (newOption) {
			case 'dispatching':
				setOpenImportData(false)
				setOpenImportDispatching(true)
				break
			case 'data':
				setOpenImportDispatching(false)
				setOpenImportData(true)
				break
		}
	}

	const handleClick = (event) => {
		event.preventDefault()
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const handleCloseIframe = (event, reason) => {
		if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
			//onClose(event, reason);
			if (reason === 'doforms-import-complete') {
				onHandleImportComplete()
			}
		}
		setOpen(false)
		setOpenImportDispatching(false)
		setOpenImportData(false)
	}

	const handleLoaded = () => {
		setValues({ ...values, loaded: true })
	}

	const renderColumnLists = () => (
		<Modal
			target={'form-import-view'}
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			open={open}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			onClose={handleClose}
		>
			<Fade in={open}>
				<Box sx={boxStyle}>
					<Typography id="transition-modal-title" variant="h6" component="h2">
						{t('common:misc.pleaseSelectAnImportOption')}
					</Typography>
					<ToggleButtonGroup
						className={classes.buttonGroup}
						sx={{ pt: 1 }}
						value={option}
						exclusive
						onChange={handleOptionChange}
						fullWidth
					>
						<ToggleButton
							value="dispatching"
							className={classes.icon}
							onClick={() => setOption('dispatching')}
						>{`${t('common:misc.impDispatch')}`}</ToggleButton>
						<ToggleButton
							value="data"
							className={classes.icon}
							onClick={() => setOption('data')}
						>{`${t('common:misc.impData')}`}</ToggleButton>
						<ToggleButton value="cancel" className={classes.icon} onClick={handleClose}>{`${t(
							'common:misc.cancel'
						)}`}</ToggleButton>
						<input id="hiddenImportOpening" type="hidden" value={open} />
					</ToggleButtonGroup>
				</Box>
			</Fade>
		</Modal>
	)

	return (
		<div>
			<LoadingButton
				className={classes.icon}
				disabled={recordsLoading}
				loadingPosition="start"
				onClick={handleClick}
				startIcon={<PublishTwoToneIcon />}
			>
				{t('common:misc.import')}
			</LoadingButton>
			{renderColumnLists()}
			<Dialog
				open={openImportDispatching || openImportData}
				onClose={handleCloseIframe}
				//fullWidth={true}
				//maxWidth="sm"
				className={classes.dialog}
				sx={{
					'& .MuiDialog-paper': {
						minWidth: '500px',
					},
				}}
			>
				{/* <DialogTitle id="dispatching-dialog-title" className={classes.dialogTitle}>
                    <IconButton onClick={handleCloseIframe}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle> */}
				<DialogContent>
					{showErrorMessage()}
					{iframeDispatching && loaded ? (
						<DoformsPortal
							iframeSrc={iframeDispatching}
							onClose={handleCloseIframe}
							onLoaded={handleLoaded}
							on-doforms-import-cancel={handleCloseIframe}
							iframeHeight={iframeHeight}
						/>
					) : null}
					{iframeData && loaded ? (
						<DoformsPortal
							iframeSrc={iframeData}
							onClose={handleCloseIframe}
							onLoaded={handleLoaded}
							on-doforms-import-cancel={handleCloseIframe}
							iframeHeight={iframeHeight}
						/>
					) : null}
				</DialogContent>
			</Dialog>
		</div>
	)
}

export default DoformsDataImportView
