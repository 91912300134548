import { useSelector } from 'react-redux'
import { useEffect, useState, useReducer } from 'react'
import { useQuery } from '@tanstack/react-query'


import feedApi from '../../../../apis/disApi/feedApi'
import { isEmpty, isEqual, cloneDeep } from 'lodash'
import database from 'firebase-config'
import { ref, off, onValue } from 'firebase/database'

const feedKeys = {
    allFeed: 'all',
}

const REF_KEY_REDUCER = {
    CUSTOMER: 'customer',
    FORM: 'form',
    ACTIVITY: 'activity',
    TIMESHEET: 'timesheet',
    GEOTAB: 'geotab',
}

function lastUpdatedListChange(state, type, index, value) {
    const currentList = state[type]
    if (isEmpty(currentList)) return { ...state, [type]: [value] }

    const currentIndexLastUpdate = currentList[index]
    if (isEqual(currentIndexLastUpdate, value)) return state

    const newValue = cloneDeep(state[type])
    newValue[index] = value

    return { ...state, [type]: newValue }
}

function lastUpdatedChange(state, type, value) {
    const currentLastUpdated = state[type]
    if (isEmpty(currentLastUpdated)) return { ...state, [type]: value }

    if (isEqual(currentLastUpdated, value)) return state
    return { ...state, [type]: value }
}

function lastUpdatedsReducer(state, action) {
    switch (action.type) {
        case REF_KEY_REDUCER.FORM:
            return lastUpdatedListChange(state, action.type, action.index, action.value)

        case REF_KEY_REDUCER.CUSTOMER:
        case REF_KEY_REDUCER.ACTIVITY:
        case REF_KEY_REDUCER.TIMESHEET:
        case REF_KEY_REDUCER.GEOTAB:
            return lastUpdatedChange(state, action.type, action.value)
        default:
            return state
    }
}
function useFeedQuery(props) {
    const { tile } = props
    const { environment } = useSelector((state) => state)
    const [feedData, setFeedData] = useState([])

    // const [dispatchCountRefs, setDispatchCountRefs] = useState([])

    const [dispatchCustomerCountRef, setDispatchCustomerCountRef] = useState('')
    const [activityCountRef, setActivityCountRef] = useState('')
    const [timesheetCountRef, setTimesheetCountRef] = useState('')
    const [geotabCountRef, setGeotabCountRef] = useState('')

    const [fbLastUpdateds, lastUpdatedsDispatch] = useReducer(lastUpdatedsReducer, {})

    const { data, isLoading, isFetching, refetch } = useQuery(
        ['feed', tile.key, feedKeys.allFeed],
        () =>
            feedApi.getAll(environment.apiToken),
        {
            enabled: Boolean(tile.key),
        }
    )

    useEffect(() => {
        const customerKey = environment.account.key
        if (!customerKey) return
        if (!isEmpty(dispatchCustomerCountRef)) {
            off(dispatchCustomerCountRef)
        }
        if (!isEmpty(activityCountRef)) {
            off(activityCountRef)
        }
        if (!isEmpty(timesheetCountRef)) {
            off(timesheetCountRef)
        }
        if (!isEmpty(geotabCountRef)) {
            off(geotabCountRef)
        }


        setDispatchCustomerCountRef(ref(database, `userSessions/customerKey/${customerKey}`))
        setActivityCountRef(ref(database, `userSessions/viewActivity/customerKey/${customerKey}`))
        setTimesheetCountRef(ref(database, `userSessions/timeSheet/customerKey/${customerKey}`))
        setGeotabCountRef(ref(database, `userSessions/geotab/customerKey/${customerKey}`))
    }, [environment.account.key])

    useEffect(() => {
        if (isEmpty(dispatchCustomerCountRef)) return

        onValue(dispatchCustomerCountRef, (snapshot) => {
            snapshot.forEach((childSnap) => {
                var lastUpdated = childSnap.val()
                lastUpdatedsDispatch({
                    type: REF_KEY_REDUCER.CUSTOMER,
                    value: lastUpdated
                })
            })
        })
    }, [dispatchCustomerCountRef])

    useEffect(() => {
        if (isEmpty(activityCountRef)) return

        onValue(activityCountRef, (snapshot) => {
            snapshot.forEach((childSnap) => {
                var lastUpdated = childSnap.val()
                lastUpdatedsDispatch({
                    type: REF_KEY_REDUCER.ACTIVITY,
                    value: lastUpdated
                })
            })
        })
    }, [activityCountRef])

    useEffect(() => {
        if (isEmpty(timesheetCountRef)) return

        onValue(timesheetCountRef, (snapshot) => {
            snapshot.forEach((childSnap) => {
                var lastUpdated = childSnap.val()
                lastUpdatedsDispatch({
                    type: REF_KEY_REDUCER.TIMESHEET,
                    value: lastUpdated
                })
            })
        })
    }, [timesheetCountRef])

    useEffect(() => {
        if (isEmpty(geotabCountRef)) return

        onValue(geotabCountRef, (snapshot) => {
            snapshot.forEach((childSnap) => {
                var lastUpdated = childSnap.val()
                lastUpdatedsDispatch({
                    type: REF_KEY_REDUCER.GEOTAB,
                    value: lastUpdated
                })
            })
        })
    }, [geotabCountRef])

    useEffect(() => {
        if (isLoading || isFetching) return
        const allFeed = data?.data || [];
        const filteredData = allFeed.filter(feed => feed.device);
        setFeedData(filteredData)
    }, [isLoading, isFetching, data])

    // useEffect(() => {
    //     if (!isEmpty(dispatchCountRefs)) {
    //         dispatchCountRefs.forEach(ref => {
    //             off(ref)
    //         })
    //     }

    //     const dispatchToSubcribe = {}
    //     feedData.filter(feed => feed.project && feed.form).forEach(feed => {
    //         const projectKey = feed.project?.key

    //         const currentValue = dispatchToSubcribe[projectKey]
    //         dispatchToSubcribe[projectKey] =
    //             isEmpty(currentValue)
    //                 ? [feed.form?.key]
    //                 : currentValue.includes(feed.form?.key) ? currentValue : [...currentValue, feed.form?.key]

    //     })
    //     if (!isEmpty(dispatchToSubcribe)) {
    //         const countRefs = []
    //         Object.keys(dispatchToSubcribe).forEach(projectKey => {
    //             dispatchToSubcribe[projectKey]?.forEach(formKey => {
    //                 countRefs.push(ref(database, 'formSessions/' + projectKey + '/dispatch' + formKey))
    //             })
    //         })

    //         setDispatchCountRefs(countRefs)
    //     }
    // }, [feedData])

    // useEffect(() => {
    //     if (isEmpty(dispatchCountRefs)) return

    //     dispatchCountRefs.forEach((ref, index) => {
    //         onValue(ref, (snapshot) => {
    //             snapshot.forEach((childSnap) => {
    //                 var lastUpdated = childSnap.val()
    //                 lastUpdatedsDispatch({
    //                     type: REF_KEY_REDUCER.FORM,
    //                     index,
    //                     value: lastUpdated
    //                 })
    //             })
    //         })
    //     })
    // }, [dispatchCountRefs])

    useEffect(() => {
        refetch()
    }, [fbLastUpdateds])


    return {
        allFeed: feedData || [],
        isFeedLoading: isLoading || isFetching,
        refetch
    }
}

export default useFeedQuery