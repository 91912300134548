import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import React from 'react'

const ConfirmBox = ({ positiveLabel, positiveAction, negativeLabel, negativeAction, visible, onCancel, onConfirm, children }) => {
	const confirm = () => {
		onCancel()
		onConfirm()
	}

	return (
		<>
			{visible && (
				<React.Fragment>
					<Dialog
						open={visible}
						onClose={onCancel}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">{children}</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={confirm} autoFocus>
								{positiveLabel}
							</Button>
							<Button onClick={onCancel}>{negativeLabel}</Button>
						</DialogActions>
					</Dialog>
				</React.Fragment>
			)}
		</>
	)
}

export default ConfirmBox
