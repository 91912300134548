import axios from 'axios'
import { apiUrl } from '../apiUrl'

const url = apiUrl()
const apiHeader = (token) => {
    return {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    }
}

const doFormMapApi = {
    devices(token, cancelToken) {
        return axios.get(`${url}/devices`, {
            headers: apiHeader(token),
            cancelToken: cancelToken?.token,
        })
    },
    getDeviceLastTrack(deviceKey, token, cancelToken) {
        return axios.get(`${url}/devices/${deviceKey}`, {
            headers: apiHeader(token),
            cancelToken: cancelToken?.token,
        })
    },
    getDeviceTracks(deviceKey, beginDate, endDate, token, cancelToken) {
        return axios.get(
            `${url}/devices/${deviceKey}/tracks?@beginDate=${beginDate}&@endDate=${endDate}`, {
            headers: apiHeader(token),
            cancelToken: cancelToken?.token,
        })
    },
}

export default doFormMapApi