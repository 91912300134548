import { Fullscreen, MoreVert } from '@mui/icons-material'
import ImageIcon from '@mui/icons-material/Image'
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver'
import SatelliteIcon from '@mui/icons-material/Satellite'
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack'
import { Box, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import { blueGrey } from '@mui/material/colors'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import tileApi from 'apis/disApi/tileApi'
import {
	getSubmissionRecordData,
	getSubmissionRecordImageBlobs,
	getSubmissionRecordImageData,
	getSubmissionRecordImageViewerData,
} from 'components/data/datagrid/recordsService'
import LoadingSpinner from 'custom-components/LoadingSpinner'
import { IconThemeProvider } from 'custom-components/context/IconThemesContext'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { checkAdminRole, isJson, logErrorMessage } from 'utils/functions/helpers'
import { tileKeys } from '../../hooks/useTileQuery'
import ImageViewerSettingsDialog from './ImageViewerSettingsDialog'

import ExpandedDialog from '../../../dis-v2/_components/ExpandedDialog'
import { useTileDashboard } from '../../dashboard/Dashboard'
import { generateRandomID } from '../../helpers'
import AudioScreen from './AudioScreen'
import ImageScreen from './ImageScreen'
import VideoScreen from './VideoScreen'

const loadImageData = async (record, blob, token) => {
	let promise = await getSubmissionRecordImageData(record, blob, token)
	return promise
}

const loadImageViewerData = async (record, token) => {
	let promise = await getSubmissionRecordImageViewerData(record, token)
	return promise
}

export default function ImageViewerTile({ tile, hideActionBtns }) {
	const { environment } = useSelector((state) => state)
	const iconTheme = environment.theme.icons
	const isAdmin = checkAdminRole(environment?.userCurrent)
	const { t } = useTranslation('common')
	const { id: dashboardKey } = useParams()

	const queryClient = useQueryClient()

	const { selectedFields } = useTileDashboard()

	const updateMutation = useMutation(tileApi.update, {
		onSuccess: () => queryClient.invalidateQueries(tileKeys.allWithKey(dashboardKey)),
	})

	const [settingTileDialogOpen, setSettingTileDialogOpen] = React.useState(false)
	const [expandedDialogOpen, setExpendedDialogOpen] = React.useState(false)
	const [record, setRecord] = React.useState(null)
	const [recordBlobs, setRecordBlobs] = React.useState([])
	const [photos, setPhotos] = React.useState([])
	const [viewerPhotos, setViewerPhotos] = React.useState([])
	const [videos, setVideos] = React.useState([])
	const [audios, setAudios] = React.useState([])
	const [locationImageList, setLocationImageList] = React.useState([])
	const [screenTab, setScreenTab] = React.useState(0) // 0: image, 1: video, 2: audio, 3: location
	const [isLoading, setIsLoading] = React.useState(false)

	const tileRef = React.useRef(null)
	const fetchIndex = React.useRef(0)

	const settings = React.useMemo(() => {
		if (tile?.settings && isJson(tile?.settings)) {
			return JSON.parse(tile?.settings ?? '{}')
		}

		return {}
	}, [tile?.settings])

	const selectedRecordFromConnectedDatagrid = React.useMemo(() => {
		const selectedField = selectedFields?.[settings?.connectedDatagridKey]
		if (settings?.connectedDatagridKey && selectedField) {
			return selectedField
		}
		return null
	}, [settings?.connectedDatagridKey, JSON.stringify(selectedFields)])

	const photosLength = photos.length + viewerPhotos.length

	function resetRecord() {
		setRecord(null)
		setRecordBlobs([])
		setPhotos([])
		setLocationImageList([])
		setVideos([])
		setAudios([])
	}

	React.useEffect(() => {
		setViewerPhotos([])
	}, [selectedRecordFromConnectedDatagrid])

	React.useEffect(() => {
		if (!selectedRecordFromConnectedDatagrid) {
			resetRecord()
			return
		}

		async function runEffect() {
			try {
				const record = {
					type: selectedRecordFromConnectedDatagrid?.recordType,
					submissionKey: selectedRecordFromConnectedDatagrid?.recordKey,
				}

				setIsLoading(true)

				const startingFetchIndex = fetchIndex.current + 1
				fetchIndex.current = startingFetchIndex

				const currentRecordRes = await getSubmissionRecordData(record, environment.apiToken)

				const currentRecord = await currentRecordRes?.data

				if (startingFetchIndex === fetchIndex.current) {
					if (!currentRecord) {
						resetRecord()
						return
					}

					setRecord(currentRecord)

					const recordBlobsRes = await getSubmissionRecordImageBlobs(
						currentRecord,
						environment.apiToken
					)

					if (!recordBlobsRes?.data) {
						resetRecord()
						return
					}

					setRecordBlobs([...recordBlobsRes.data])
					setIsLoading(false)
				}
			} catch (error) {
				logErrorMessage(error)
			}
			setIsLoading(false)
		}

		runEffect()
	}, [selectedRecordFromConnectedDatagrid, environment.apiToken])

	const initiateLoadAllImageData = async () => {
		try {
			const promises = []
			const imageList = []
			const videoList = []
			const audioList = []
			const locationList = []

			setIsLoading(true)

			recordBlobs.forEach((blob) => {
				const promise = new Promise((resolve, reject) => {
					loadImageData(record, blob, environment.apiToken)
						.then((res) => {
							if (['image', 'sketch', 'signature'].includes(blob?.field?.type)) {
								let img = new Image()
								img.src = URL.createObjectURL(res.data)
								img.onload = function () {
									imageList.push({
										key: blob.key,
										src: this.src,
										width: this.width,
										height: this.height,
										alt: blob.fileName,
										description: blob.field.options.label.en,
									})

									setPhotos([...imageList])
									resolve(res)
								}
								img.remove()
							} else if (blob?.field?.type === 'location') {
								let img = new Image()
								img.src = URL.createObjectURL(res.data)
								img.onload = function () {
									locationList.push({
										key: blob.key,
										src: this.src,
										width: this.width,
										height: this.height,
										alt: blob.fileName,
										description: blob.field.options.label.en,
									})

									setLocationImageList([...locationList])
									resolve(res)
								}
								img.remove()
							} else if (blob?.field?.type === 'video') {
								setVideos([
									...videos,
									{ blob: res.data, fileName: blob.fileName, fileType: blob.type },
								])
							} else if (blob?.field?.type === 'audio') {
								setAudios([
									...audios,
									{ blob: res.data, fileName: blob.fileName, fileType: blob.type },
								])
							}
						})
						.catch((err) => {
							console.error('error', err)
							reject()
						})
				})
				promises.push(promise)
			})

			setRecord(null)
			setRecordBlobs([])
			setIsLoading(false)
		} catch (error) {
			logErrorMessage(error)
		}
	}

	React.useEffect(() => {
		if (recordBlobs?.length === 0) {
			setPhotos([])
			setLocationImageList([])
			setVideos([])
			setAudios([])
			return
		}

		if (recordBlobs?.length > 0 && Boolean(record)) {
			initiateLoadAllImageData()
		}
	}, [recordBlobs, record])

	React.useEffect(() => {
		if (!record || !environment.apiToken) return

		async function runEffect() {
			try {
				const viewerImageResponse = await loadImageViewerData(record, environment.apiToken)
				const imageViewerList = await viewerImageResponse?.data

				imageViewerList.forEach((imageViewer) => {
					const newPhoto = {
						key: generateRandomID(),
						src: imageViewer.text,
						alt: imageViewer.name,
						height: 1000,
						width: 1000,
						description: imageViewer.name,
					}

					setViewerPhotos((prev) => [...prev, newPhoto])
				})
			} catch (error) {
				logErrorMessage(error)
			}
		}

		runEffect()
	}, [record, environment.apiToken])

	const handleResizeTileWidth = async (width) => {
		try {
			const editedSettings = JSON.stringify({ ...settings, tileWidth: width })

			await updateMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: { settings: editedSettings },
				token: environment.apiToken,
			})
		} catch (error) {
			logErrorMessage(error)
		}
	}

	const handleOpenSettingTileDialog = () => {
		setSettingTileDialogOpen(true)
	}

	const handleCloseSettingTileDialog = () => {
		setSettingTileDialogOpen(false)
	}

	const handleOpenExpandedDialog = () => {
		setExpendedDialogOpen(true)
	}

	const handleCloseExpandedDialog = () => {
		setExpendedDialogOpen(false)
	}

	const handleTabChange = (event, newValue) => {
		setScreenTab(newValue)
	}

	const handleSubmitSetting = async (data) => {
		try {
			const { imageType, connectedDatagridKey } = data

			await updateMutation.mutateAsync({
				dashboardKey,
				tileKey: tile.key,
				data: {
					settings: JSON.stringify({
						...settings,
						imageType,
						connectedDatagridKey,
					}),
				},
				token: environment.apiToken,
			})

			handleCloseSettingTileDialog()
		} catch (error) {
			logErrorMessage(error)
		}
	}

	return (
		<IconThemeProvider values={iconTheme}>
			<Box
				ref={tileRef}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					width: '100%',
					height: '100%',
					boxShadow: 1,
					backgroundColor: blueGrey[50],
					position: 'relative',
					overflowX: 'hidden',
				}}
			>
				<Stack
					px={2}
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					sx={{
						position: 'sticky',
						top: 0,
						backgroundColor: '#eceff1',
						zIndex: 999,
					}}
				>
					<Stack direction="row" spacing={1}>
						<Typography fontWeight="bold">{tile?.i}</Typography>
						<Typography>
							{screenTab === 0 && photosLength > 0 ? `(${photosLength})` : null}
							{screenTab === 1 && videos.length > 0 ? `(${videos.length})` : null}
							{screenTab === 2 && audios.length > 0 ? `(${audios.length})` : null}
							{screenTab === 3 && locationImageList.length > 0
								? `(${locationImageList.length})`
								: null}
						</Typography>
					</Stack>
					<Tabs
						value={screenTab}
						onChange={handleTabChange}
						aria-label="tabs"
						sx={{
							'& .MuiButtonBase-root': {
								minHeight: '0 !important',
							},

							'& .MuiTabs-flexContainer': {
								flexWrap: 'wrap !important',
							},
						}}
					>
						<Tooltip title={t('common:input.image')}>
							<Tab icon={<ImageIcon />} aria-label={t('common:input.image')} />
						</Tooltip>
						<Tooltip title={t('common:input.video')}>
							<Tab icon={<VideoCameraBackIcon />} aria-label={t('common:input.video')} />
						</Tooltip>
						<Tooltip title={t('common:input.audio')}>
							<Tab icon={<RecordVoiceOverIcon />} aria-label={t('common:input.audio')} />
						</Tooltip>
						<Tooltip title={t('common:input.location')}>
							<Tab icon={<SatelliteIcon />} aria-label={t('common:input.location')} />
						</Tooltip>
					</Tabs>
					<Stack direction="row" alignItems="center" spacing={2}>
						{!hideActionBtns && (
							<>
								<Tooltip title={t('common:tooltip.expand')}>
									<IconButton aria-label="expand" onClick={handleOpenExpandedDialog}>
										<Fullscreen />
									</IconButton>
								</Tooltip>

								{isAdmin && (
									<IconButton
										aria-label="delete"
										size="small"
										onClick={handleOpenSettingTileDialog}
									>
										<MoreVert fontSize="small" />
									</IconButton>
								)}
							</>
						)}
					</Stack>
				</Stack>

				<Box sx={{ flex: 1, padding: 2, position: 'relative' }}>
					{isLoading && <LoadingSpinner />}

					{screenTab === 0 && (
						<ImageScreen photos={[...photos, ...viewerPhotos]} type={settings?.imageType} />
					)}
					{screenTab === 1 && <VideoScreen videos={videos} />}
					{screenTab === 2 && <AudioScreen audios={audios} />}
					{screenTab === 3 && (
						<ImageScreen photos={locationImageList} type={settings?.imageType} isLocation />
					)}
				</Box>

				<ImageViewerSettingsDialog
					tileElementWidth={tileRef?.current?.clientWidth}
					defaultTileWidth={settings.tileWidth}
					tile={tile}
					open={settingTileDialogOpen}
					settings={settings}
					isSubmitting={updateMutation.isLoading}
					showStable={hideActionBtns}
					onClose={handleCloseSettingTileDialog}
					onSubmit={handleSubmitSetting}
					onResizeTileWidth={handleResizeTileWidth}
				/>

				{expandedDialogOpen && (
					<ExpandedDialog open={expandedDialogOpen} onClose={handleCloseExpandedDialog}>
						<ImageViewerTile tile={tile} hideActionBtns />
					</ExpandedDialog>
				)}
			</Box>
		</IconThemeProvider>
	)
}
