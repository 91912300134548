import { Box, Button, CircularProgress, Stack, TextField } from '@mui/material'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { isEqual } from 'lodash'
import ToastAlert from '../../../../core/Layouts/ToastAlert'
import SideDialog from '../../components/SideDialog'
import useDashboardQuery from '../../hooks/useDashboardQuery'
import ChartForm from './ChartForm'

function ChartSettingsDialog({
	tileElementWidth,
	defaultTileWidth,
	tile,
	open,
	settings,
	isChartLoading,
	chartSetting,
	setChartSetting,
	dispatchChartData,
	setIsChartLoading,
	onClose,
	onSubmit,
	onResizeTileWidth,
	masterDateTimeConditions,
}) {
	const { t } = useTranslation('common')
	const { mapTileInfo: { linkedFields = {}, viewKey: viewKeySetting = '' } = {} } = settings
	const { id: dashboardKey } = useParams()

	const { dashboardKeyList } = useDashboardQuery({
		dashboardKey,
	})

	const [alertInfo, setAlertInfo] = useState({
		open: false,
		text: '',
		type: 'success',
	})

	const [filterData, setFilterData] = useState({})

	const handleFilterDataChange = (id, value) => {
		setFilterData((prev) => ({ ...prev, [id]: value }))
	}

	useEffect(() => {
		setFilterData((prev) => ({ ...prev, otherOptionsChecked: settings?.otherOptionsChecked }))
	}, [settings?.otherOptionsChecked])

	useEffect(() => {
		handleFilterDataChange('chart', chartSetting)
	}, [chartSetting])

	const handleSubmit = () => {
		if (!filterData?.viewKey) {
			setAlertInfo((prev) => ({
				...prev,
				open: true,
				type: 'error',
				text: t('common:dis.pleaseFillInformation'),
			}))
			return
		}

		onSubmit?.({
			viewKey: filterData?.viewKey,
			formKey: filterData?.formKey,
			projectKey: filterData?.projectKey,
			linked: filterData?.linked,
			// rows: filterData?.gridRows,
			// columns: filterData?.columns,
			otherOptionsChecked: filterData?.otherOptionsChecked,
			chartSetting: filterData?.chart,
		})
	}

	return (
		<>
			<ToastAlert alertInfo={alertInfo} setAlertInfo={setAlertInfo} />

			{/* {hasMoreRecords && (
				<Button
					id="cancel-loading-records"
					className={classes.cancelLoadingIcon}
					startIcon={<CircularProgress color="inherit" size={16} />}
					loadingPosition="start"
					variant={'text'}
					sx={{
						textTransform: 'none',
						position: 'absolute',
						top: '40px',
						left: '8px',
						zIndex: 999,
					}}
					onClick={onCancelLoadingRecords}
				>
					{t('common:misc.cancelLoading')}
				</Button>
			)} */}

			<SideDialog
				defaultTileWidth={defaultTileWidth}
				open={open}
				onClose={onClose}
				width={tile?.w ?? 6}
				tileElementWidth={tileElementWidth}
				onResizeWidth={onResizeTileWidth}
			>
				<Stack
					sx={{
						'& .MuiInputBase-input': {
							fontSize: '0.9rem !important',
						},
						'& .MuiCheckbox-root': {
							p: 0,
						},
					}}
				>
					<Box sx={{ px: 1 }}>
						<TextField
							fullWidth
							id="label-basic"
							label={t('common:chart.labels')}
							variant="standard"
							value={tile.i}
							disabled={true}
							size="small"
						/>
					</Box>

					<ChartForm
						tileKey={tile.key}
						setIsChartLoading={setIsChartLoading}
						linkedFields={linkedFields}
						viewKeySetting={viewKeySetting}
						filterData={filterData}
						dashboardKeyList={dashboardKeyList}
						dashboardKey={dashboardKey}
						onFilterDataChange={handleFilterDataChange}
						enableLoadView={open}
						masterDateTimeConditions={masterDateTimeConditions}
						dispatchChartData={dispatchChartData}
						setChartSetting={setChartSetting}
					/>

					<Stack direction="row" justifyContent="flex-end" mt={2}>
						<Stack spacing={2} direction="row">
							<Button
								size="small"
								variant="contained"
								onClick={handleSubmit}
								disabled={isChartLoading}
								startIcon={isChartLoading ? <CircularProgress color="inherit" size="1em" /> : null}
							>
								{t('common:misc.ok')}
							</Button>
							<Button size="small" variant="text" onClick={onClose}>
								{t('common:misc.cancel')}
							</Button>
						</Stack>
					</Stack>
				</Stack>
			</SideDialog>
		</>
	)
}

export default ChartSettingsDialog
