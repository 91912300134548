import {
	Box,
	Divider,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
} from '@mui/material'
import classNames from 'classnames'
import { useMenu } from 'components/pages/dis-v2/contexts/MenuContext'
import MenuCardAction from 'components/pages/dis-v2/menu/_components/MenuCardAction'
import { generateNewPosition } from 'components/pages/dis-v2/menu/_utils/common'
import { MENU_TYPE_OPTIONS } from 'components/pages/dis-v2/utils/constants'
import { generateRandomID } from 'components/pages/dis/helpers'
import PageSelect from 'components/pages/dis/menu-items-form/_components/PageSelect'
import produce from 'immer'
import { forwardRef, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Handle } from '../Item'
import styles from './Container.module.scss'

export const Container = forwardRef(
	(
		{
			children,
			columns = 1,
			handleProps,
			horizontal,
			hover,
			onClick,
			onRemove,
			label,
			placeholder,
			style,
			scrollable,
			shadow,
			unstyled,
			value,
			...props
		},
		ref
	) => {
		const { t } = useTranslation('common')
		const location = useLocation()
		const history = useHistory()

		const Component = onClick ? 'button' : 'div'

		const { detailMenu, onUpdateMenu } = useMenu()

		const [menuName, setMenuName] = useState('')
		const [menuType, setMenuType] = useState('')
		const [menuPagePath, setMenuPagePath] = useState('')

		const currentMenu = useMemo(() => {
			if (!detailMenu?.items) return null

			return detailMenu?.items.find((x) => x?.dashboard?.key === value)
		}, [detailMenu?.items, value])

		const currentMenuWithKey = useMemo(() => {
			if (!detailMenu?.items) return null

			return detailMenu?.items.find((x) => x?.dashboard?.key === value)
		}, [detailMenu?.items, value])

		const tileList = currentMenuWithKey?.tileInfo ?? []

		const includesChildren = currentMenuWithKey?.items?.length > 0

		const updateMenuItems = (name, newValue) => {
			const newMenuItems = produce(detailMenu.items, (draft) => {
				const menuIndex = draft.findIndex((x) => x?.dashboard?.key === value)

				if (menuIndex !== -1) {
					if (name === 'page') {
						draft[menuIndex][name] = { path: newValue }
					} else {
						// remove page property if exists
						if (draft[menuIndex].page) {
							delete draft[menuIndex].page
						}

						draft[menuIndex][name] = newValue
					}
				}
			})

			onUpdateMenu('items', newMenuItems)
		}

		useEffect(() => {
			if (currentMenuWithKey) {
				setMenuName(currentMenuWithKey.name || '')
				setMenuType(currentMenuWithKey.type || '')
				setMenuPagePath(currentMenuWithKey.page?.path)
			}
		}, [currentMenuWithKey, value])

		function handleMenuNameChange(event) {
			setMenuName(event.target.value)
			updateMenuItems('name', event.target.value)
		}

		const handleChangeTypeSelect = (e) => {
			setMenuType(e.target.value)
			updateMenuItems('type', e.target.value)
		}

		const handleChangePagePath = (e) => {
			setMenuPagePath(e.target.value)
			updateMenuItems('page', e.target.value)
		}

		const handleDeleteMenu = () => {
			const newMenuItems = detailMenu.items.filter(
				(item) => item?.dashboard?.key !== currentMenuWithKey?.dashboard?.key
			)

			onUpdateMenu('items', newMenuItems)
		}

		const handleAddNewSubMenu = () => {
			const newMenuItems = detailMenu.items.map((item) => {
				if (item?.dashboard?.key === value) {
					const menuItems = item?.items ?? []
					const newPosition = generateNewPosition(menuItems)

					return {
						...item,
						items: [
							...item.items,
							{
								name: '',
								position: `${newPosition}`,
								type: 'dashboard',
								items: [],
								dashboard: {
									key: generateRandomID(),
								},
								is_creating: true,
							},
						],
					}
				}
				return item
			})

			onUpdateMenu('items', newMenuItems)
		}

		const handleGoToMenuItems = () => {
			const isEmptyCurrentMenuName = currentMenu?.name === ''
			const isEmptyMenuName = detailMenu?.name === ''
			const isEmptyNameOfMenuItems = currentMenu?.items?.some((item) => item?.name === '')

			if (isEmptyCurrentMenuName || isEmptyMenuName || isEmptyNameOfMenuItems) {
				toast.error(t('common:toast.pleaseFillData'))
				return
			}

			const searchParams = new URLSearchParams(location.search)

			searchParams.set('key', value)
			if (currentMenuWithKey?.is_creating) {
				searchParams.set('isCreatingMenu', true)
			}

			history.push({
				pathname: `${location.pathname}/menu-items`,
				search: searchParams.toString(),
			})
		}

		return (
			<Component
				{...props}
				ref={ref}
				style={{
					...style,
					overflow: 'initial',
					'--columns': columns,
				}}
				className={classNames(
					styles.Container,
					includesChildren && styles.Spacing,
					unstyled && styles.unstyled,
					horizontal && styles.horizontal,
					hover && styles.hover,
					placeholder && styles.placeholder,
					scrollable && styles.scrollable,
					shadow && styles.shadow
				)}
				onClick={onClick}
				tabIndex={onClick ? 0 : undefined}
			>
				{label ? (
					<div className={styles.Header} style={{ position: 'relative' }}>
						<Handle
							{...handleProps}
							style={{
								height: '100%',
							}}
						/>

						<Divider orientation="vertical" flexItem />

						<Stack direction="row" alignItems="center" gap={1} sx={{ flex: 1, ml: 2, py: 1 }}>
							<TextField
								id="menu-item"
								label={t('common:input.tabName')}
								variant="outlined"
								size="small"
								value={menuName}
								onChange={handleMenuNameChange}
							/>

							<FormControl size="small" sx={{ width: 210 }}>
								<InputLabel id="type-select-label">{t('common:input.type')}</InputLabel>
								<Select
									labelId="type-select-label"
									id="type-select"
									value={menuType}
									label={t('common:input.type')}
									name="type"
									onChange={handleChangeTypeSelect}
								>
									{MENU_TYPE_OPTIONS.map((type) => (
										<MenuItem value={type.value} key={type.value}>
											{type.label}
										</MenuItem>
									))}
								</Select>
							</FormControl>

							{/* If type = page, show page select with menu default item */}
							{menuType === 'page' && (
								<Box sx={{ width: 210 }}>
									<PageSelect
										value={menuPagePath || ''}
										onChange={handleChangePagePath}
										isSmallSize
									/>
								</Box>
							)}

							{tileList?.length > 0 && (
								<Stack direction="row" alignItems="center" gap={1}>
									<TextField
										id="menu-item"
										label={t('common:input.tilesCount')}
										variant="outlined"
										size="small"
										value={tileList?.length}
									/>
									<FormControl size="small" sx={{ width: 210 }}>
										<InputLabel id="tiles-select-label">{t('common:input.tiles')}</InputLabel>
										<Select
											labelId="tiles-select-label"
											id="tiles-select"
											label={t('common:input.tiles')}
											value={tileList[0]?.name || ''}
										>
											{tileList.map((tile) => (
												<MenuItem value={tile.name} key={tile.name}>
													{tile.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Stack>
							)}
						</Stack>

						<MenuCardAction
							showConfigBtn={!['page'].includes(currentMenu?.type)}
							onDelete={handleDeleteMenu}
							onAddNewSubMenu={handleAddNewSubMenu}
							onGoToMenuItems={handleGoToMenuItems}
						/>
					</div>
				) : null}
				{placeholder ? children : <ul>{children}</ul>}
			</Component>
		)
	}
)
