import { makeStyles } from '@mui/styles'
import React from 'react'

const useStyles = makeStyles(() => ({
	root: {
		position: 'relative',
		margin: '0',
		backgroundColor: 'white',
		display: 'flex',
		flex: '1 1 auto',
		flexDirection: 'row',
		overflow: 'hidden',
		height: '100%',
	},
	leftPanel: {
		display: 'flex',
		position: 'relative',
		boxShadow: 'rgb(0 0 0 / 20%) -4px 0px 10px 0px',
	},
	mainContent: {
		flexGrow: 2,
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		overflowY: 'auto',
	},
}))

const SplitLayout = ({ sidePanel, mainContent }) => {
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<div className={classes.leftPanel}>{sidePanel}</div>
			<div className={classes.mainContent} style={{ width: 'initial' }}>
				{mainContent}
			</div>
		</div>
	)
}

export default SplitLayout
