import { useRef, useEffect } from 'react'

export function useFirstRender() {
	const ref = useRef(true)

	useEffect(() => {
		return () => {
			ref.current = false
		}
	})

	return ref.current
}
