import React from 'react'
import './ColorPicker.css'

const ColorPicker = ({ value, onClick, customStyle }) => {
	return (
		<label className="color-selector" style={{ ...customStyle?.label }} onClick={onClick}>
			<span className="circle" style={{ background: value, ...customStyle?.circle }} />
			<span>{value}</span>
		</label>
	)
}

export default ColorPicker
